import React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

interface props {
  isSwitchDefaultChecked?: any;
  handleSwitch?: any;
}
const SwitchButton: React.FC<props> = ({ isSwitchDefaultChecked, handleSwitch }) => {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)'
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(18px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#2A72DD'
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 20,
      height: 20,
      borderRadius: 10,
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: 30 / 2,
      opacity: 1,
      backgroundColor: '#E4E7E7',
      boxSizing: 'border-box'
    }
  }));
  return (
    <AntSwitch
      defaultChecked={isSwitchDefaultChecked}
      onChange={handleSwitch}
      inputProps={{ 'aria-label': 'ant design' }}
    />
  );
};

export default SwitchButton;
