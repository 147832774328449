import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './add-team-member.module.scss';
import { Modal, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

const AddTeamMemberModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  queryClient,
  selectedUser
}) => {
  const isEdit = JSON.stringify(selectedUser) !== '{}';
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();

  const addMemberPost = async (data: any) => {
    const requestData = JSON.stringify({
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      role: data.role ? 'admin' : 'prospector',
      organizationID: storage.get('organizationId')
    });

    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/organization/invite-user`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };

    await axios(config);
  };
  const { status: mutationStatus, mutateAsync: addMember } = useMutation(addMemberPost, {
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries(`userdetail`);
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });
  const editmenberHandler = async (data: any) => {
    const requestData = JSON.stringify({
      email: data.email,
      role: data.role ? 'admin' : 'prospector',
      organizationID: storage.get('organizationId')
    });

    const config = {
      method: 'patch',
      url: `${BASE_URL}/v1/organization/update-team-member-role`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };

    await axios(config);
  };
  const { status: EditStatus, mutateAsync: EditMember } = useMutation(editmenberHandler, {
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries(`userdetail`);
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });
  const onSubmit = (data: any) => {
    if (data.email && data.last_name && data.first_name) {
      isEdit ? EditMember(data) : addMember(data);
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '649px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        >
          {!isEdit ? 'Add Team Member' : 'Edit Team Member'}
        </ModalHeader>

        <Formik
          initialValues={{
            first_name: selectedUser.firstName,
            last_name: selectedUser.lastName,
            email: selectedUser.email,
            role: selectedUser.role === 'admin' ? true : false
          }}
          validationSchema={yup.object({
            first_name: yup
              .string()
              .trim()
              .required('First name is required')
              .matches(/^[a-zA-Z ]*$/, 'First name must contain only alphabets'),
            last_name: yup
              .string()
              .trim()
              .required('Last name is required')
              .matches(/^[a-zA-Z ]*$/, 'Last name must contain only alphabets'),
            email: yup
              .string()
              .trim()
              .required('Email address is required')
              .email('Email address is invalid')
          })}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {props => (
            //TODO: Add member form validation
            <Form>
              <div className={styles.form_wrapper}>
                <div className={styles.two_fields}>
                  <div className={styles.single_field}>
                    <Field
                      name='first_name'
                      placeholder='First name'
                      component={CustomisedTextField}
                      disabled={isEdit}
                      required={true}
                    />
                  </div>

                  <div className={styles.single_field}>
                    <Field
                      name='last_name'
                      placeholder='Last name'
                      component={CustomisedTextField}
                      disabled={isEdit}
                      required={true}
                    />
                  </div>
                </div>

                <div className={styles.two_fields}>
                  <div className={styles.single_field}>
                    <Field
                      name='email'
                      placeholder='Email'
                      component={CustomisedTextField}
                      disabled={isEdit}
                      required={true}
                    />
                  </div>
                </div>
                <label style={{ marginTop: '28px', width: '500px' }}>
                  <Field type='checkbox' name='role' />
                  <span style={{ position: 'relative', marginLeft: '10px', width: '500px' }}>
                    <span className={styles.checkbox_label}>Give admin permissions</span>
                    <br />
                    <span className={styles.checkbox_label_description}>
                      Only admin can modify projects, accounts and team members
                    </span>
                  </span>
                </label>
                <div className={styles.button_wrapper}>
                  <WrappedButton
                    btnText='Cancel'
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setShowModal(!showModal);
                    }}
                    btnType={BTN_SECONDARY}
                    className={styles.submit_button}
                  />
                  <WrappedButton
                    btnText={!isEdit ? 'Add Team Member' : 'Edit Role'}
                    btnType={BTN_PRIMARY}
                    isDisabled={!(props.isValid && props.dirty)}
                    className={styles.submit_button}
                    isInProgress={mutationStatus === 'loading' ? true : false}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddTeamMemberModal;
