import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/auto';

ChartJS.register(LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const options: ChartOptions<'line'> = {
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day'
        // displayFormats: {
        //   month: 'MMM'
        // }
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 12
      }
    },
    y: {
      ticks: {
        stepSize: 1
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        title: context => {
          let date = new Date(context[0].parsed.x);
          let formattedDate = date.toLocaleString([], {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          });
          return formattedDate;
        }
      },
      usePointStyle: true // Ensures the tooltip uses point styles like the legend
    },
    legend: {
      labels: {
        usePointStyle: true, // Use circular markers instead of squares
        pointStyle: 'circle', // Specify the style of the point
        boxWidth: 20, // Adjust the width of the legend box
        boxHeight: 10 // Adjust the height of the legend box
      }
    }
  }
};

interface props {
  selectedDayRange: any;
  labelSent?: any;
  valueSent?: any;
  valueOpened?: any;
  valueProspect?: any;
  valueDeclined?: any;
  valueDeadLeads?: any;
  valueLeads?: any;
}

const LineChart: React.FC<props> = ({
  selectedDayRange,
  labelSent,
  valueSent,
  valueOpened,
  valueProspect,
  valueDeclined,
  valueDeadLeads,
  valueLeads
}) => {
  const allLabels = labelSent;
  const allData = [
    {
      label: 'Emails sent',
      data: valueSent,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)'
    },
    {
      label: 'Emails opened',
      data: valueOpened,
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)'
    },
    {
      label: 'Prospects',
      data: valueProspect,
      borderColor: '#9966FF',
      backgroundColor: 'rgba(153, 102, 255, 0.2)'
    },
    {
      label: 'Leads',
      data: valueLeads,
      borderColor: '#FFCE56',
      backgroundColor: 'rgba(255, 206, 86, 0.2)'
    },
    {
      label: 'Declined',
      data: valueDeclined,
      borderColor: '#FF9F40',
      backgroundColor: ' rgba(255, 159, 64, 0.2)'
    },
    {
      label: 'Dead leads',
      data: valueDeadLeads,
      borderColor: '#80CBC4',
      backgroundColor: ' rgba(128, 203, 196, 0.2)'
    }
  ];

  const [chartData, setChartData] = useState(allData);
  const [chartLabel, setChartLabel] = useState(allLabels);

  useEffect(() => {
    setChartData(allData);
    setChartLabel(allLabels);
  }, [labelSent, valueSent]);

  // filter data and labels based on selected date range

  return (
    <>
      <Line options={options} data={{ labels: chartLabel, datasets: chartData }} />
    </>
  );
};

export default LineChart;
