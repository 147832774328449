import React from "react";

import styles from "./index.module.scss";
import { props } from "./props";

const RequestDemoLeftSection: React.FunctionComponent<props> = ({
  children,
}) => {
  return <div className={styles.demo_left_section}>{children}</div>;
};

export default RequestDemoLeftSection;
