import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './additional-filters.module.scss';
import { Modal, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import { cx } from '../../../utils/class-name-service';
import axios from 'axios';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';

const FITER_CHECKBOXES = [
  {
    id: 'note',
    label: 'Notes',
    option1: 'With',
    option2: 'Without'
  },
  {
    id: 'Emails',
    label: 'Emails',
    option1: 'Opened',
    option2: 'Not opened'
  },
  {
    id: 'Inquiries',
    label: 'Inquiries',
    option1: 'Made',
    option2: 'Not made'
  },
  {
    id: 'Leads',
    label: 'Leads',
    option1: 'Yes',
    option2: 'No'
  },
  {
    id: 'Interested',
    label: 'Interested',
    option1: 'Yes',
    option2: 'No'
  },
  {
    id: 'EmailSent',
    label: 'Email(s) sent ',
    option1: 'Yes',
    option2: 'No'
  },
  // {
  //   id: 'Called',
  //   label: 'Called',
  //   option1: 'Yes',
  //   option2: 'No'
  // },
  {
    id: 'emailAddress',
    label: 'Email address',
    option1: 'Yes',
    option2: 'No'
  }
  // {
  //   id: 'PreferredSQFT',
  //   label: 'Preferred SQ FT',
  //   option1: 'Yes',
  //   option2: 'No'
  // },
  // {
  //   id: 'LinkedInAddress',
  //   label: 'LinkedIn address',
  //   option1: 'Yes',
  //   option2: 'No'
  // },
  // {
  //   id: 'ChainStores',
  //   label: 'Chain Stores',
  //   option1: 'Yes',
  //   option2: 'No'
  // },
  // {
  //   id: 'MultiUnitFranchisee',
  //   label: 'Multi Unit Franchisee',
  //   option1: 'Yes',
  //   option2: 'No'
  // },
  // {
  //   id: 'MultiBrandOperator',
  //   label: 'Multi-Brand Operator ',
  //   option1: 'Yes',
  //   option2: 'No'
  // },
  // {
  //   id: 'ExpansionToStateOfProject',
  //   label: 'Expansion to State of Project',
  //   option1: 'Yes',
  //   option2: 'No'
  // }
];

// Create types for additionalFilters state
type AdditionalFilters = {
  onlyNotes: string;
  Emails: string;
  Inquiries: string;
  Leads: string;
  Interested: string;
};

const AdditionalFiltersModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  filterOptions,
  handleFilterOptions,
  projectType,
  queryString,
  setQueryString,
  additionalFilters,
  setAdditionalFilters,
  minValue,
  setMinValue,
  maxValue,
  setMaxValue,
  maxValueDashboard,
  setMaxValueDashboard,
  minValueDashboard,
  setMinValueDashboard,
  sliderFilterOptions,
  distanceQueryString,
  setDistanceQueryString
}) => {
  // const [queryString, setQueryString] = useState<any>();
  const falseFilter = ['No', 'Not Made', 'Not Opened', 'Without'];
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;

  const NationalList = ['MultiUnitFranchisee', 'MultiBrandOperator', 'ExpansionToStateOfProject'];

  const additionalFiltersHandler = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}v1/filters/additionalFilters/:${storage.get('projectId')}?${queryString}`
    };

    const temp = await axios(config);
    return temp;
  };

  useEffect(() => {
    let temp = Object.keys(additionalFilters)
      .map(key => {
        if (
          additionalFilters[key] &&
          key !== 'minDistance' &&
          key !== 'maxDistance' &&
          key !== 'minPreferredSize' &&
          key !== 'maxPreferredSize'
        ) {
          let str = additionalFilters[key].toString();
          let index = str.indexOf(':');
          return !falseFilter.includes(str.substring(index + 1, str.length)) ? key : `_${key}`;
        }
      })
      .filter((item: any) => item);
    setQueryString(JSON.stringify(temp));
    if (maxValueDashboard !== 100 || minValueDashboard !== 0)
      sliderFilterOptions([minValueDashboard, maxValueDashboard], 'sliderDashboard');

    // .join('&')
  }, [filterOptions]);

  const clearAllFilterHandler = () => {
    setAdditionalFilters({
      minPreferredSize: 0,
      maxPreferredSize: 40,
      maxDistance: 100,
      minDistance: 0,
      note: '',
      Emails: '',
      Inquiries: '',
      Leads: '',
      Interested: '',
      EmailSent: '',
      Called: '',
      emailAddress: '',
      PreferredSQFT: '',
      LinkedInAddress: '',
      ChainStores: '',
      MultiUnitFranchisee: '',
      MultiBrandOperator: '',
      ExpansionToStateOfProject: ''
    });
    setMinValueDashboard(0);
    setMaxValueDashboard(100);
    setMaxValue(40);
    setMinValue(0);
    setDistanceQueryString('');
  };
  const ApplyFilterHandler = () => {
    handleFilterOptions({ ...filterOptions, ...additionalFilters });
  };
  const ignoreKeys = ['zipCode', 'subcategory', 'state', 'savedSearches', 'category'];
  const filterclear = () => {
    let emptyKeys: any = {};
    for (const key in filterOptions) {
      if (
        !ignoreKeys.includes(key) &&
        (filterOptions[key] === '' ||
          (key === 'maxPreferredSize' && filterOptions[key] === 40) ||
          (key === 'minPreferredSize' && filterOptions[key] === 0))
      ) {
        emptyKeys[key] = filterOptions[key];
      }
    }
    return emptyKeys;
  };
  function hasMatchingProperty(obj1: any, obj2: any) {
    for (let key in obj2) {
      if (obj1.hasOwnProperty(key)) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          if (!hasMatchingProperty(filterOptions, additionalFilters)) {
            clearAllFilterHandler();
          } else {
            let filterObj = filterclear();
            setAdditionalFilters({ ...additionalFilters, ...filterObj });
          }
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '508px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            if (!hasMatchingProperty(filterOptions, additionalFilters)) {
              clearAllFilterHandler();
            } else {
              let filterObj = filterclear();
              setAdditionalFilters({ ...additionalFilters, ...filterObj });
            }
            setShowModal(!showModal);
          }}
        >
          Filters
        </ModalHeader>

        <div className={styles.form_wrapper}>
          {/* <div className={styles.two_fields}>
            <div>
              <input
                className={styles.state_dropdown}
                name='minSize'
                id='minSize'
                placeholder='Min Size'
                value={minValue}
                onChange={e => {
                  setMinValue(Number(e.target.value));
                  setAdditionalFilters({
                    ...additionalFilters,
                    minPreferredSize: `minPreferredSize:${Number(e.target.value)}`
                  });
                }}
              ></input>
            </div>

            <div>
              <input
                className={styles.state_dropdown}
                name='maxSize'
                id='maxSize'
                placeholder='Max Size'
                value={maxValue}
                onChange={e => {
                  setMaxValue(Number(e.target.value));
                  setAdditionalFilters({
                    ...additionalFilters,
                    maxPreferredSize: `maxPreferredSize:${Number(e.target.value)}`
                  });
                }}
              ></input>
            </div>
          </div>

          <div className={styles.two_fields}>
            <div className={styles.range_slider}>
              <Box sx={{ width: '100%' }}>
                <Slider
                  value={[minValue, maxValue]}
                  // Add an onChange event to update the state avoiding all TypeScript errors.
                  onChange={(event, newValue) => {
                    if (Array.isArray(newValue)) {
                      setMaxValue(newValue[1]);
                      setMinValue(newValue[0]);
                      setAdditionalFilters({
                        ...additionalFilters,
                        maxPreferredSize: `maxPreferredSize:${Number(newValue[1])}`,
                        minPreferredSize: `minPreferredSize:${Number(newValue[0])}`
                      });
                    }
                  }}
                  valueLabelDisplay='auto'
                  aria-labelledby='range-slider'
                  getAriaValueText={value => `${value} mi`}
                  min={0}
                  max={40}
                  sx={{
                    color: '#2A72DD',
                    '& .MuiSlider-thumb': {
                      color: '#FFFFFF',
                      border: '1px solid #2A72DD'
                    },
                    '& .MuiSlider-rail': {
                      color: '#2A72DD'
                    },
                    '& .MuiSlider-track': {
                      color: '#2A72DD'
                    }
                  }}
                />
              </Box>
            </div>
          </div> */}
          {projectType !== 'National' ? (
            <>
              {' '}
              <div className={styles.form_heading}>
                <span className={styles.form_heading_text}>Distance From Project</span>
              </div>
              <div className={styles.min_max_range_slider}>
                <div className={styles.value_button}>Min: {minValueDashboard} mi</div>
                <div className={styles.range_slider}>
                  <Box sx={{ width: 250 }}>
                    <Slider
                      value={[minValueDashboard, maxValueDashboard]}
                      onChange={(event, newValue: any) => {
                        setMinValueDashboard(() => newValue[0]);
                        setMaxValueDashboard(() => newValue[1]);
                        setAdditionalFilters({
                          ...additionalFilters,
                          minDistance: `minDistance:${newValue[0]}`,
                          maxDistance: `maxDistance:${newValue[1]}`
                        });
                      }}
                      valueLabelDisplay='auto'
                      aria-labelledby='range-slider'
                      getAriaValueText={value => `${value} mi`}
                      min={0}
                      max={100}
                      sx={{
                        color: '#2A72DD',
                        '& .MuiSlider-thumb': {
                          color: '#FFFFFF',
                          border: '1px solid #2A72DD'
                        },
                        '& .MuiSlider-rail': {
                          color: '#2A72DD'
                        },
                        '& .MuiSlider-track': {
                          color: '#2A72DD'
                        }
                      }}
                    />
                  </Box>
                </div>
                <div className={styles.value_button}>Max: {maxValueDashboard} mi</div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className={styles.checkboxes_container}>
            <div className={styles.form_heading}>
              <span className={styles.form_heading_text}>Only</span>
            </div>
            <>
              {FITER_CHECKBOXES.map((item, index) => {
                return (
                  <>
                    {((!NationalList.includes(item.id) && projectType === 'Local') ||
                      projectType === 'National') && (
                      <div className={styles.checkbox_wrapper}>
                        <div>
                          <span className={styles.checkbox_label}>{item.label}</span>
                        </div>
                        <div className={styles.checkbox_options_wrapper}>
                          <label className={cx(styles.checkbox, styles.bounce)}>
                            <input
                              type='checkbox'
                              checked={
                                additionalFilters[item.id] === `${item.label}:${item.option1}`
                                  ? true
                                  : false
                              }
                              onChange={e => {
                                setAdditionalFilters({
                                  ...additionalFilters,
                                  [item.id]: e.target.checked ? `${item.label}:${item.option1}` : ''
                                });
                              }}
                            />
                            <svg viewBox='0 0 21 21'>
                              <polyline points='5 10.75 8.5 14.25 16 6'></polyline>
                            </svg>
                          </label>
                          <span className={styles.option_label}>{item.option1}</span>

                          <label className={cx(styles.checkbox, styles.bounce)}>
                            <input
                              type='checkbox'
                              checked={
                                additionalFilters[item.id] === `${item.label}:${item.option2}`
                                  ? true
                                  : false
                              }
                              onChange={e => {
                                setAdditionalFilters({
                                  ...additionalFilters,
                                  [item.id]: e.target.checked ? `${item.label}:${item.option2}` : ''
                                });
                              }}
                            />
                            <svg viewBox='0 0 21 21'>
                              <polyline points='5 10.75 8.5 14.25 16 6'></polyline>
                            </svg>
                          </label>
                          <span className={styles.option_label}>{item.option2}</span>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </>
          </div>

          <div className={styles.button_wrapper}>
            <WrappedButton
              btnText='Clear All'
              onClick={() => {
                clearAllFilterHandler();
              }}
              btnType={BTN_SECONDARY}
              className={styles.submit_button}
            />
            <WrappedButton
              btnText='Apply'
              onClick={() => {
                ApplyFilterHandler();

                setShowModal(false);
              }}
              btnType={BTN_PRIMARY}
              className={styles.submit_button}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdditionalFiltersModal;
