import React from 'react';
import { props } from './model';
import styles from './audit-trail.module.scss';
import AuditTrailCard from '../../audit-trail-card';
import AppConfig from '../../../config';
import Storage from '../../../utils/local-storage';
import { useMutation, useQuery } from 'react-query';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';

const AuditTrail: React.FunctionComponent<props> = ({ queryClient, businessId }) => {
  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();

  const fetchDetails = async () => {
    const res = await fetch(
      `${BASE_URL}/v1/auditTrail/getAuditTrails?businessId=${businessId}&projectId=${storage.get(
        'projectId'
      )}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${storage.get('accessToken')}`
        }
      }
    );
    return res.json();
  };
  const { data, status } = useQuery(`auditTrail${businessId}`, fetchDetails);
  return (
    <div>
      {status === 'success' &&
        data.auditTrail.map((el: any) => {
          return (
            <AuditTrailCard
              createdAt={el.createdAt}
              actionType={el.actionType}
              metaData={el.metaData}
              emailSent={data.emailSent}
            />
          );
        })}
      {status === 'loading' ? (
        <Backdrop sx={{ color: '#0c252d', zIndex: '100' }} open>
          {<CircularProgress color='inherit' />}
        </Backdrop>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AuditTrail;
