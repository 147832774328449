import React from 'react';
import { cx } from '../../../utils/class-name-service';
import { props } from './model';
import styles from './tag-select.module.scss'
import { deepCopy } from '../../../utils/deep-copy';

const TagSelect: React.FC<props> = ({ form, field, placeholder, isMulti, tags, ...props }) => {

    const { name, value, onChange } = field;
    const { setFieldValue, errors, touched } = form;

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e);
        if (isMulti) {
            let newValue = deepCopy(value);
            if (newValue?.includes(e.target.value)) {
                newValue = newValue.filter((item: string) => item !== e.target.value)
            } else {
                newValue?.push(e.target.value)
            }
            setFieldValue(field.name, newValue);
        }
        else {
            setFieldValue(field.name, e.target.value);
        }
    };

    return (
        <>
            <div className={styles.field_label}>{placeholder}</div>
            <div className={styles.tags_wrapper}>
                {
                    tags.map((tag, index) => {
                        return (
                            <div className={value?.includes(tag) ? cx(styles.tag, styles.active_tag) : styles.tag} key={index} onClick={
                                () => {
                                    onChangeHandler({
                                        target: {
                                            value: tag
                                        }
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }
                            }>{tag}</div>
                        )
                    }
                    )
                }
            </div>
        </>
    )
}

export default TagSelect