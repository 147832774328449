import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styles from './reportFilter.module.scss';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import SetProjectGoalModal from '../../modals/set-project-goal';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY } from '../../../constants/button-constants';

import { props } from './modal';
import SourceDetialModal from '../../modals/source-detail';

const customStyles = {
  dropdownIndicator: (base: any) => ({
    ...base
  }),
  control: (base: any) => ({
    ...base,
    // :'80px',
    height: '35px',
    minHeight: '35px',
    minWidth: '150px',
    cursor: 'pointer',
    fontSize: '12px',
    fontFamily: 'Lato'
  }),
  placeholder: (base: any) => ({
    ...base,
    fontFamily: 'Lato',
    fontSize: '12px'
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: 'Lato',
    fontSize: '12px'
  })
};
const option = [
  {
    label: 'Today',
    value: 'Today'
  },
  { label: 'Last 7 days', value: 'Last 7 Days' },
  // { label: 'Last 14 Days', value: 'Last 14 Days' },
  { label: 'Custom', value: 'Custom' }
];
const sourceOption = [
  {
    label: 'Today',
    value: 'Today'
  },
  { label: 'Last 7 days', value: 'Last 7 Days' },
  { label: 'Last 30 days', value: 'Last 30 Days' },
  { label: 'Last 60 days', value: 'Last 60 Days' },
  // { label: 'Last 14 Days', value: 'Last 14 Days' },
  { label: 'Custom', value: 'Custom' }
];

const statusOption = [
  {
    label: 'Prospect',
    value: 'prospect'
  },
  { label: 'Lead', value: 'lead' }
  // { label: 'Last 14 Days', value: 'Last 14 Days' },
];

const reportType1 = [
  {
    label: 'Overview report',
    value: 'overall'
  },
  {
    label: 'Prospector comparision',
    value: 'prospectorComparision'
  },
  {
    label: 'Prospector project comparision',
    value: 'prospectorProject'
  },
  {
    label: 'Project goal',
    value: 'goal'
  },
  {
    label: 'Source',
    value: 'source'
  }
];

const ReportFilter: React.FC<props> = ({
  reportType,
  setReportType,
  selectedDayRange,
  setSelectedDayRange,
  prospectorsList,
  setSelectedProspector,
  selectedReportType,
  prospectorsProjectList,
  setSelectedProspectorProject,
  selectedProspectorProject,
  selectedProspector,
  isProjectGoalSet,
  queryClient,
  allSourceData,
  selectedSource,
  setSelectedSource,
  promiseOptions,
  selectedSourceDayRange,
  setSelectedSourceDayRange
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isSourceDatePickerOpen, setIsSourceDatePickerOpen] = useState(false);

  const [DateFilter, setDateFilter] = useState('Today');
  const [SourceDateFilter, setSourceDateFilter] = useState('Today');

  const [showModal, setShowModal] = useState(false);
  const [showSourceModal, setShowSourceModal] = useState(false);

  useEffect(() => {
    if (DateFilter === 'Today') {
      let date = new Date();
      setSelectedDayRange({
        from: { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() },
        to: { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }
      });
    }
    if (DateFilter === 'Last 7 Days') {
      const today = new Date();
      const lastSeventhDay = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);

      setSelectedDayRange({
        from: {
          day: lastSeventhDay.getDate(),
          month: lastSeventhDay.getMonth() + 1,
          year: lastSeventhDay.getFullYear()
        },
        to: {
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        }
      });
    }
  }, [DateFilter]);

  useEffect(() => {
    if (SourceDateFilter === 'Today') {
      let date = new Date();
      setSelectedSourceDayRange({
        from: { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() },
        to: { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }
      });
    }
    if (SourceDateFilter === 'Last 7 Days') {
      const today = new Date();
      const lastSeventhDay = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);
      setSelectedSourceDayRange({
        from: {
          day: lastSeventhDay.getDate(),
          month: lastSeventhDay.getMonth() + 1,
          year: lastSeventhDay.getFullYear()
        },
        to: {
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        }
      });
    }
    if (SourceDateFilter === 'Last 30 Days') {
      const today = new Date();
      const lastSeventhDay = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
      setSelectedSourceDayRange({
        from: {
          day: lastSeventhDay.getDate(),
          month: lastSeventhDay.getMonth() + 1,
          year: lastSeventhDay.getFullYear()
        },
        to: {
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        }
      });
    }
    if (SourceDateFilter === 'Last 60 Days') {
      const today = new Date();
      const lastSeventhDay = new Date(today.getTime() - 59 * 24 * 60 * 60 * 1000);
      setSelectedSourceDayRange({
        from: {
          day: lastSeventhDay.getDate(),
          month: lastSeventhDay.getMonth() + 1,
          year: lastSeventhDay.getFullYear()
        },
        to: {
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        }
      });
    }
  }, [SourceDateFilter]);

  return (
    <>
      <div className={styles.main_wrapper}>
        <div className={styles.heading}>Reports</div>
        <div className={styles.filter}>
          <div className={styles.view_by}>View by</div>

          <div className={styles.dropdown_filter}>
            <Select
              options={reportType1}
              value={reportType1.filter((item: any) => item['value'] === reportType)}
              styles={customStyles}
              placeholder='Report Type'
              components={{
                IndicatorSeparator: () => null
              }}
              isOptionDisabled={(option: any) => option.isdisabled} // disable an option
              onChange={(e: any) => {
                setReportType(e.value);
              }}
            />
          </div>
          {isProjectGoalSet && selectedReportType === 'goal' && (
            <div className={styles.dropdown_filter}>
              <div className={styles.button_wrapper}>
                <WrappedButton
                  btnText='Set Project Goal'
                  onClick={() => {
                    setShowModal(true);
                  }}
                  btnType={BTN_PRIMARY}
                  className={styles.submit_button}
                />
              </div>
            </div>
          )}
          {selectedReportType === 'overall' && (
            <div className={styles.dropdown_filter}>
              <Select
                options={option}
                value={option.filter((item: any) => item['value'] === DateFilter)}
                styles={customStyles}
                placeholder='Select Date'
                components={{
                  IndicatorSeparator: () => null
                }}
                isOptionDisabled={(option: any) => option.isdisabled} // disable an option
                onChange={(e: any) => {
                  if (e.value === 'Custom') {
                    setIsDatePickerOpen(true); // Open date picker
                  } else {
                    setIsDatePickerOpen(false);
                  }
                  setDateFilter(e.value);
                }}
              />
            </div>
          )}

          {selectedReportType === 'prospectorComparision' && (
            <div className={styles.dropdown_filter}>
              <Select
                value={prospectorsList.filter((item: any) => item['value'] === selectedProspector)}
                options={prospectorsList}
                styles={customStyles}
                placeholder='Prospectors List'
                onChange={(e: any) => {
                  setSelectedProspector(e.value);
                }}
              />
            </div>
          )}
          {selectedReportType === 'prospectorProject' && (
            <div className={styles.dropdown_filter}>
              <Select
                value={prospectorsProjectList.filter(
                  (item: any) => item['value'] === selectedProspectorProject
                )}
                options={prospectorsProjectList}
                styles={customStyles}
                placeholder='Project List'
                onChange={(e: any) => {
                  setSelectedProspectorProject(e.value);
                }}
              />
            </div>
          )}
          {selectedReportType === 'source' && (
            <>
              <div className={styles.dropdown_filter}>
                <Select
                  value={allSourceData.filter((item: any) => item['value'] === selectedSource)}
                  options={allSourceData}
                  styles={customStyles}
                  placeholder='Source'
                  onChange={(e: any) => {
                    setSelectedSource(e.value);
                  }}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                />
              </div>
              <div className={styles.dropdown_filter}>
                <Select
                  options={sourceOption}
                  value={sourceOption.filter((item: any) => item['value'] === SourceDateFilter)}
                  styles={customStyles}
                  placeholder='Select Date'
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  isOptionDisabled={(option: any) => option.isdisabled} // disable an option
                  onChange={(e: any) => {
                    if (e.value === 'Custom') {
                      setIsSourceDatePickerOpen(true); // Open date picker
                    } else {
                      setIsSourceDatePickerOpen(false);
                    }
                    setSourceDateFilter(e.value);
                  }}
                />
              </div>
              {isSourceDatePickerOpen && reportType === 'source' && (
                <div style={{ paddingLeft: '5px' }}>
                  <DatePicker
                    value={selectedSourceDayRange}
                    onChange={setSelectedSourceDayRange}
                    colorPrimary=' #2A72DD'
                    colorPrimaryLight='rgba(54, 162, 235, 0.2)'
                    maximumDate={utils('en').getToday()}
                    inputClassName={styles.input_element}
                  />
                </div>
              )}

              <div className={styles.button_wrapper}>
                <WrappedButton
                  btnText='Source Details'
                  onClick={() => {
                    setShowSourceModal(true);
                  }}
                  btnType={BTN_PRIMARY}
                  className={styles.submit_button}
                />
              </div>
            </>
          )}

          {isDatePickerOpen && reportType === 'overall' && (
            <div style={{ paddingLeft: '5px' }}>
              <DatePicker
                value={selectedDayRange}
                onChange={setSelectedDayRange}
                colorPrimary=' #2A72DD'
                colorPrimaryLight='rgba(54, 162, 235, 0.2)'
                maximumDate={utils('en').getToday()}
                inputClassName={styles.input_element}
              />
            </div>
          )}
        </div>
      </div>
      <SetProjectGoalModal
        showModal={showModal}
        setShowModal={setShowModal}
        queryClient={queryClient}
      />
      <SourceDetialModal
        showModal={showSourceModal}
        setShowModal={setShowSourceModal}
        queryClient={queryClient}
        allSourceData={allSourceData}
        promiseOptions={promiseOptions}
      />
    </>
  );
};

export default ReportFilter;
