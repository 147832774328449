import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './delete-member.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';

const DeleteTemplateModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  selectedItem,
  deleteTemplate
}) => {
  const onSubmit = () => {
    deleteTemplate(selectedItem);
    setShowModal(false);
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '649px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        >
          Delete template
        </ModalHeader>
        <ModalBody>
          <div className={styles.text}>Are you sure you want to delete selected template?</div>
          <div className={styles.sub_text} style={{ marginTop: '15px' }}>
            This action can't be undone
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={styles.button_container}>
            <button
              className={styles.cancel_button}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className={styles.delete_button}
              onClick={() => {
                onSubmit();
              }}
            >
              Delete Template
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeleteTemplateModal;
