import React, { useContext, useEffect } from 'react';
import styles from './upload-csv-form.module.scss';
import Select from 'react-select';
import { props } from './model';
import DragAndDrop from '../../components/drag-and-drop';
import WrappedButton from '../../components/form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../constants/button-constants';
import { useState } from 'react';
import axios from 'axios';
import AppConfig from '../../config';
import Storage from '../../utils/local-storage';
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from '../../context/ProjectContext';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';

const customStyles = {
  dropdownIndicator: (base: any) => ({
    ...base
  }),
  control: (base: any) => ({
    ...base,
    // :'80px',
    height: '55px',
    minHeight: '35px',
    minWidth: '150px',
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: 'Lato'
  }),
  placeholder: (base: any) => ({
    ...base,
    fontFamily: 'Lato',
    fontSize: '12px'
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: 'Lato',
    fontSize: '16px'
  })
};

const UploadCSV = () => {
  const { projectData, setProjectData } = useContext(ProjectContext);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [selectedProject, setSelectedProject] = useState<any>();
  const [projectListData, setProjectListData] = useState<any>();

  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let projectId = storage.get('projectId');

  const getProjectList = async () => {
    const storage = new Storage();
    const BASE_URL = AppConfig.API_BASE_URL;
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/project/${storage.get('organizationId')}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    let result = res.data.map((item: any) => {
      return { label: item.projectName, value: item._id };
    });
    setSelectedProject(result[0].value);
    setProjectListData(result);
    return result;
  };
  useEffect(() => {
    getProjectList();
  }, []);

  const updateSaveChanges = async () => {
    const requestData1 = {
      listUrl: imagesUrl[0],
      projectId: selectedProject
    };
    const requestData = JSON.stringify(requestData1);

    const config = {
      method: 'put',
      url: `${BASE_URL}/v1/project/updateProject`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };
    setProjectData({ ...projectData, ...requestData1 });
    await axios(config);
  };
  return (
    <div className={styles.form_container}>
      <div className={styles.form_container_due}>
        <div>
          <div className={styles.form_title}>Select project</div>
          <Select
            value={projectListData?.filter((item: any) => item['value'] === selectedProject)}
            options={projectListData}
            styles={customStyles}
            placeholder='Project'
            onChange={(e: any) => {
              setSelectedProject(e.value);
            }}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        </div>
        <div>
          <div className={styles.form_title}>Upload additional businesses</div>
          <DragAndDrop imagesUrl={imagesUrl} setImagesUrl={setImagesUrl} comp={'newListUploader'} />
        </div>
        <div className={styles.add_project_footer}>
          <div className={styles.button_wrapper}>
            <WrappedButton
              btnText='Save Changes'
              btnType={BTN_SECONDARY}
              onClick={(e: React.FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                updateSaveChanges()
                  .then(() => {
                    enqueueSnackbar('CSV file is uploaded successfully', {
                      variant: 'success',
                      header: 'Success'
                    });
                    navigate(`/dashboard/${storage.get('projectId')}`);
                    // if (!addProject)
                    //   window.location.href = `/view-project/${storage.get('projectId')}`;
                  })
                  .catch(err => {
                    let message = err.response.data.message;
                    enqueueSnackbar(message, { variant: 'error', header: 'Error' });
                  });
              }}
              className={styles.submit_button}
              type='submit'
              isDisabled={storage.get('role') === 'prospector'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCSV;
