import React from 'react';
import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './terms-and-conditions.module.scss';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.landing_page_wrapper}>
        <div className={styles.max_width}>
          <HeaderLanding />

          <div className={styles.content_wrapper}>
            <div className={styles.heading_cover_image}>
              <div className={styles.heading}>Terms of Use</div>
              <img src='aboutUs-Eclipse1.svg' alt='' className={styles.eclipseOne} />
              <img src='aboutUs-Eclipse2.svg' alt='' className={styles.eclipseTwo} />

              {isMobile ? (
                <img src='/term-use-mobile.jpg' alt='About Us' className={styles.termsImage} />
              ) : (
                <img src='/term-condition.jpg' alt='About Us' className={styles.termsImage} />
              )}
            </div>

            <div className={styles.content}>
              <br/>
              Welcome to ReachTenants.com (the “Website”). Each person entering must read and agree
              to be bound by the following terms of use. Please read them carefully. This
              document is an agreement between you, the user, (“you,” “your,” or the “user”) and
              Reach Faster, Inc, the owner of the Website (“we,” “our,” “us” “ReachTenants” or the
              “Company”). By entering our Website, you acknowledge you have read, understand, and
              are agreeing to become bound by these Terms of Use, which constitute a binding legal
              agreement in which you agree to use the Website in accordance with these Terms of Use
              and in compliance with all applicable laws. We reserve the right, at our discretion,
              to update or revise these Terms of Use from time to time. Please check the Terms of
              Use periodically for changes. Your use of this Website following the posting of any
              changes to the Terms of Use will be deemed your acceptance of such change(s). SHOULD
              YOU OBJECT TO ANY TERM OR CONDITION OF THESE TERMS OF USE OR TO ANY GUIDELINES, OR ANY
              FUTURE MODIFICATION THEREOF, OR FOR ANY REASON YOU ARE NOT SATISFIED WITH THE Website,
              THEN YOUR SOLE REMEDY IS TO DISCONTINUE THE USE OF THE WEBSITE IMMEDIATELY.
              <br />
              <br />
              The Company provides visitors to its Website with access to content (“Content”) via
              the World Wide Web. For most users, in order to have access to most features of the
              Website, the user will have to sign a subscription licensing agreement, (Subscription
              Licensing Agreement” pay for the use and create an account. Once created, the user may
              also be also referred to as a “Registered User.”
              <br />
              <br />
              REGISTERED USER LICENSE
              <br />
              <br />
              The Company also provides subscription services for use of data, services,
              communications and the prospecting platform. Registered Users have the opportunity to
              submit content, text, graphics, photographs, message, and articles (“Articles”) for
              posting on the Website. The, Articles, communications, data, other features, products,
              services, and all other access and use (together “Use of the Website”). Registered
              Users may have only one user account. User accounts are not transferable. You further
              acknowledge and warrant that you have provided the Website with your current correct
              legal email address, name, and phone number. You further acknowledge that you are
              solely responsible for the Use of the Website, or other information you publish,
              display, or otherwise communicate in any manner or transmit to others. The Company
              does not and cannot make any warranty or guarantee about the accuracy, validity, or
              timeliness of any information provided in the Use of the Website or elsewhere on the
              Website. By communicating with a person through this Website or use of another product
              or service or submitting an Article for publication, you are warranting that the
              content of such posting, Article or other product or service, if factual, is accurate
              and that you have obtained all necessary permission to publish or send such content.
              The Company reserves the right to refuse Articles for any reason or for no reason. You
              agree not to submit Articles or communications, another person’s personal information,
              including but not limited to name, e-mail or physical address, telephone number, or
              other personal information, without that person’s explicit consent.
              <br />
              <br />
              ReachTenants.com has the right, but not the obligation, to verify or censor any
              content. All Articles for inclusion on the ReachTenants’ Website, upon submission to
              the Website, become the sole property of the Company. We may change the features and
              functionality of the Website at any time. This may include adding, modifying or
              removing any features or functionality of the Website. The Terms of Use will apply to
              any changed version of the Website. We also may suspend or permanently take down the
              Website altogether. In addition, we may impose or alter fees for new or existing
              aspects of the Website. If we suspend the Website temporarily or if the Website
              becomes nonfunctional for more than 24 hours, we will extend the service term for all
              paid Registered Users one day for each 24 hour period the Website was non-operating.
              If we take down the Website permanently, all paid Registered Users will receive a
              prorated refund of the unused portion of the fees. Fees will not be increased for any
              unexpired period of a Subscription Licensing Agreement.
              <br />
              <br />
              The license granted to you is to use the data, communication tools and other features
              of the Website for your personal or your company’s business purposes or both and for
              no other purpose. The data on the Website may not be shared with others unless they
              are included in your Subscription Licensing Agreement. You may not facilitate the use
              of, develop any service, product, toolset, dataset or derivative work from the data to
              verify, confirm, enhance, audit, update, or develop any files, products, or databases
              owned, licensed, or marketed by you, another person or other companies or any content
              or feature of the Website in an aggregated or non-aggregated form, and whether in
              identified or de-identified form.
              <br />
              <br />
              You agree to pay to us the applicable fees (“Fees”) set forth in a Subscription
              Licensing Agreement whether agreed to on paper or digitally, signed or otherwise
              agreed to by you and by us, based on the timetable set forth in the Subscription
              Licensing Agreement. We may increase or otherwise change the Fees at the end of any
              Term set forth in a Subscription Licensing Agreement, in our sole discretion, by
              providing you with written notice which may be by email or regular mail.
              <br />
              <br />
              You must raise any disputes regarding Fees within sixty (60 days) from receipt of the
              first applicable invoice. Any disputes not waived within that time period shall be
              deemed waived.
              <br />
              <br />
              Invoices must be paid in full no later than thirty (30) days from the date on which
              they are received. Unpaid amounts are subject to all expenses of collection (including
              reasonable attorney’s fees) that we may incur. We reserve the right to terminate or
              suspend service immediately without notice where bills are more than thirty (30) days
              in arrears.
              <br />
              <br />
              You are responsible for all taxes other than income taxes associated with fees due in
              accordance with the Subscription Licensing Agreement.
              <br />
              <br />
              The Initial License Term is as specified in the Subscription Licensing Agreement and
              shall be automatically renewed for additional periods of the same duration (but not
              including partial month) as the Initial License Term, unless either party requests
              non-renewal at least sixty (60) days prior to the end of the then current term. The
              subscription is on a per user basis and is for a single user unless otherwise
              indicated in the Subscription Licensing Agreement. We may permit you (in our
              discretion) to increase the number of users licensed to you during a term, in which
              case we will then begin to charge you at the new per-user price. While you may (in our
              discretion) increase the number of users during a term, you may not decrease the
              number of users during a term. Once you increase your per user license, you will be
              charged on that per user basis through the remainder of a term. You may not share a
              user license or time-share. You may only permit one user per license at any given
              time. The number of Projects that may be used with your license will be set forth in
              the Subscription Licensing Agreement and can be increased during a term for an
              additional fee, but not decreased.
              <br />
              <br />
              NO LIABILITY OR DAMAGES FOR CONTENT
              <br />
              <br />
              Regarding published Articles, postings or other information submitted or used by
              Registered Users, the Company claims immunity from liability to the fullest extent
              under the law and as provided under the Communications Decency Act for the content
              provided by third parties and members and nothing in this Terms of Use is intended to
              waive or remove such immunity. In no event shall the Company be liable to you or to
              any other party for damages or losses in connection with content including but not
              limited to slander and/or defamation of character.
              <br />
              <br />
              DISCLAIMER OF WARRANTY
              <br />
              <br />
              Please be advised that the Company uses its best efforts to maintain the accessibility
              and operability of the Website but does not warrant that the Website will operate
              uninterrupted, securely or error-free. The Company advises you that the Internet is
              used at your own risk without any expectation of indemnity or entitlement to
              consequential damages, including lost profits and lost business. To the fullest extent
              permitted by law, the Company, its directors, officers, shareholders, employees,
              agents, representatives, successors, and assigns, hereby disclaim all liability for
              any loss, cost, or damage (direct, indirect, consequential, or otherwise) suffered by
              you as a result of your Use of the Website or from any computer virus or other harmful
              code transmitted through the Website, whether such loss, cost or damage arises from
              our negligence or otherwise and even if we are expressly informed of the possibility
              of such loss or damage.
              <br />
              <br />
              The Company hereby disclaims all responsibility or liability for the accuracy,
              content, completeness, legality, reliability, or availability of information, data or
              material in this Website or sent through the Website. The Company further disclaims
              any responsibility for the damage, loss of data, customer information, revenue, or
              other harm to business arising out of delays, mis-delivery, untimely delivery or
              non-delivery of information, deletion, failure to store, of any information or
              material restriction or loss of access, bugs or other errors, unauthorized use due to
              user sharing of access to our log in information, or other interaction with the
              Website. The Company disclaims any responsibility for any harm resulting from
              downloading or accessing any information or material through the Website. You will
              bear all risks associated with any content that you access. The Company further
              disclaims that the results that may be obtained from the use of our service will be
              accurate or reliable; the quality of any data, products, services, information, or
              other material licensed or obtained by you through our data or service will meet your
              expectations, or that any errors in our service will be corrected. Any data or
              material downloaded or otherwise obtained through our Website is done at your own
              discretion and risk and you are solely responsible for any damage to your computer
              system or loss of data that results from the download of any such material. No advice
              or information, whether oral or written, obtained by you from the Company or through
              or from our Website shall create any warranty not expressly stated in these Terms of
              Use.
              <br />
              <br />
              THIS WEBSITE AND ALL PRODUCTS AND SERVICES PROVIDED THROUGH IT ARE PROVIDED "AS IS"
              WITH NO WARRANTIES WHATSOEVER. ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES,
              INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, AND NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. THE COMPANY
              DISCLAIMS ANY WARRANTIES FOR OTHER SERVICES OR GOODS RECEIVED THROUGH THIS WEBSITE OR
              RECEIVED THROUGH ANY LINKS PROVIDED IN THE WEBSITE. THE COMPANY SIMILARLY DISCLAIMS,
              TO THE FULLEST EXTENT PERMITTED BY LAW, ANY WARRANTIES FOR ANY INFORMATION OR ADVICE
              OBTAINED THROUGH THE WEBSITE OR THROUGH ANY LINKS PROVIDED IN THE WEBSITE OR FROM ANY
              STAFF OF THE COMPANY.
              <br />
              <br />
              UNDER NO CIRCUMSTANCES SHALL THE COMPANY BE LIABLE TO ANY USER ON ACCOUNT OF THAT
              USER'S USE OR MISUSE OF AND RELIANCE ON THE WEBSITE OR ITS ASSOCIATED PRODUCTS AND
              SERVICES. SUCH LIMITATION OF LIABILITY SHALL APPLY TO PREVENT RECOVERY OF DIRECT,
              INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, AND PUNITIVE DAMAGES (EVEN IF
              THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). SUCH LIMITATION OF
              LIABILITY SHALL APPLY WHETHER
              <br />
              <br />
              THE DAMAGES ARISE FROM USE OR MISUSE OF AND RELIANCE ON THE WEBSITE, PRODUCTS OR
              SERVICES, FROM THE INABILITY TO USE THE WEBSITE, OR FROM THE INTERRUPTION, SUSPENSION,
              OR TERMINATION OF THE WEBSITE OR DAMAGES INCURRED BY REASON OF OTHER SERVICES OR GOODS
              RECEIVED THROUGH OR ADVERTISED ON THIS WEBSITE OR RECEIVED THROUGH ANY LINKS PROVIDED
              ON THIS WEBSITE, AS WELL AS BY REASON OF ANY INFORMATION OR ADVICE RECEIVED THROUGH OR
              ADVERTISED ON THE WEBSITE OR RECEIVED THROUGH ANY LINKS PROVIDED IN THIS WEBSITE, OR
              THROUS SPEAKING WITH STAFF OF THE COMPANY. YOU AGREE THAT YOU ARE NEITHER A PARTNER
              NOR AGENT OF THE COMPANY.
              <br />
              <br />
              THE FOREGOING DISCLAIMERS, WAIVERS, AND LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY
              FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
              <br />
              <br />
              INDEMNITY
              <br />
              <br />
              You agree to defend, indemnify and hold the Company, and its directors, officers,
              shareholders, employees, agents, representatives, successors, and assigns harmless
              from and against any and all claims, expenses, and demands, including attorneys' fees,
              made by any third party due to, or arising out of your failure to abide by any
              applicable Federal, State and local laws, statutes, ordinances, rules or regulations,
              or asserted in connection with your use or misuse of the Website, including but not
              limited to claims relating to communications, Articles, or content you submit to or
              transmit through or as a result of the Website or make use of, and arising out of or
              related to your connection to and use of this Website or the content or data
              associated with it. The Company reserves the right to control any defense and control
              any other matter subject to indemnification by you. You agree to cooperate with the
              Company in asserting all available defenses.
              <br />
              <br />
              TRADEMARKS
              <br />
              <br />
              The Company claims exclusive service mark rights in the name ReachTenants and Reach
              Faster, Inc. All trademarks and logos featured on ReachTenants.com are owned by the
              Company or are displayed pursuant to licenses obtained from the owners of those
              trademarks and logos and may not be used without the written consent of the owners of
              such trademarks and logos. All users of the Website agree not to post, use, copy,
              modify, transmit, show in public or private, distribute or reproduce in any way any
              service mark, trademark, or other intellectual property without obtaining the prior
              consent of the owner of such proprietary rights. Use of any of our trademarks as
              metatags on any other Website also is strictly prohibited.
              <br />
              <br />
              COPYRIGHT AND LICENSES
              <br />
              <br />
              Except for public domain material, this Website is protected by intellectual property
              laws, including U.S. copyright laws. Except to the extent required for the limited
              purpose of reviewing material on our Website, copying, publication, adaptation,
              distribution, performance, or display of any content or functionality on our Website
              (even if you are the registered user who originally submitted it) is prohibited. You
              may not display the Website in frames (or any of the postings via in-line links)
              without the Company’s express written permission, which may be requested by contacting
              the Company through email. By submitting Articles for posting to any public area of
              the Website, you automatically grant, and you represent and warrant that you have the
              right to grant, to the Company an irrevocable, perpetual, non-exclusive, fully-paid,
              worldwide license to use, copy, publicly perform, publicly display, distribute,
              prepare derivative works of, or incorporate into other works, such Articles, and
              postings, and to grant and authorize sub-licenses of the foregoing for all of the
              foregoing rights in any medium whatsoever for any purpose, including, but not limited
              to, advertising and promotional purposes. You acknowledge that you will receive no
              credit or compensation for any such Article.
              <br />
              <br />
              INFORMATION COLLECTED
              <br />
              <br />
              The information we collect is used to improve the content of our Website and
              newsletter, used to notify the Company’s visitors and Registered Users about updates
              to our Website and newsletter. We may use cookies to store visitors and Registered
              Users preferences, record session information, such as items that visitors and
              Registered Users add to their shopping cart, emails opened, record user-specific
              information on what pages visitors and registered users access or visit, alert
              visitors and Registered Users to new areas that we think might be of interest to them
              when they return to the Company Website, record past activity at a site in order to
              provide better service when visitors and Registered Users return to our Website,
              ensure that visitors and Registered Users are not repeatedly sent the same banner ads,
              customize Website page content based on visitors and Registered User’s browser type or
              other information that the visitor and registered user sends. Information may also be
              collected of Registered Users so that they may receive reports of their activity. This
              Website may be monitored by one or more third-party monitoring software(s) and may
              capture information about your visit that will help us improve the quality of our
              service. You may opt-out from the data being collected on your visit by not visiting
              the Website.
              <br />
              <br />
              LINKS AND THIRD-PARTY SERVICES
              <br />
              <br />
              You may not establish ordinary links to the home page of our Website without our
              written permission. The Company may produce automated search results or otherwise link
              you to other sites on the Internet or run promotions or advertisements (“Linked
              Sites”). Such Linked Sites may contain information or material that some people may
              find inappropriate or offensive. These Linked Sites are not the Company’s property and
              are not under the Company’s control, and you acknowledge that the Company is not
              responsible for the accuracy, copyright compliance, legality, decency, or any other
              aspect of such Linked Sites. The inclusion of such a link does not imply endorsement
              or sponsorship of, or affiliation with a Linked Site by the Company, or any
              association with its owners or operators. You acknowledge and agree that we are not
              responsible or liable, directly, or indirectly, for any damage, loss, or cost caused
              or alleged to be caused by or in connection with such Linked Sites.
              <br />
              <br />
              USAGE POLICY FOR THOSE SUBMITTING MATERIAL, USING DATA OR COMMUNICATING THROUGH THE
              WEBSITE.
              <br />
              <br />
              By submitting Articles or postings to this Website, by using data or communicating
              through the Website or making other Use of the Website, you:
              <br />
              <br />
              Agree that you, and not the Company, are solely responsible for all content or
              information, including text, communications, software, images, sounds, data, or other
              information that you generate, upload, post, publish, display, e-mail, or otherwise
              transmit via our Website or otherwise communicate as a result of data obtained through
              our Website to another Registered User or anyone else.
              <br />
              <br />
              Agree that the Company does not guarantee the accuracy, integrity, appropriateness, or
              quality of any posting or data or information found on or through the Website. You
              will not upload, post, e-mail or otherwise transmit any defamatory, inaccurate,
              abusive, obscene, profane, offensive, sexually-oriented, threatening, harassing,
              racially offensive, or illegal material, or any material that potentially infringes or
              violates another party's rights without such party’s express written permission
              (including, but not limited to, intellectual property rights, and rights of privacy
              and publicity such as any personally identifying information including social
              security, driver’s license or passport numbers, addresses, telephone/fax numbers,
              references to business or personal addresses, employer's names or e-mail addresses).
              Further, you will not upload, post, e-mail, or otherwise transmit language, images, or
              other material relating to children that are abusive, sexual, offensive, obscene, or
              pornographic, or any other material that could result in any civil or criminal
              liability under state or federal law. You will not upload, post, submit for posting,
              e-mail or otherwise transmit any information or content that is confidential or
              proprietary by law or agreement, such as insider information or information learned in
              a fiduciary capacity.
              <br />
              <br />
              Agree to use the Website in a manner consistent with all applicable local, state,
              national and international laws, rules, and regulations, including, but not limited to
              the United States CAN-SPAM Act, The Telephone Consumer Protection Act, Fair Credit
              Reporting Act and Export Control Laws. You acknowledge that you are not located in,
              under the control of, or a national or resident of any country that is the subject of
              the United States or United Nations sanction, control, or embargo.
              <br />
              <br />
              Agree not to upload, submit for posting, e-mail or otherwise transmit any information
              or content that: (i) is false, deceptive, misleading, inaccurate, or that could be
              construed as a "bait and switch" tactic; (ii) advertises the sale or lease of
              anything, the sale or lease of which is prohibited by applicable law; (iii) advertises
              any illegal service; or (iv) falsely purports to be from any individual or entity or
              misrepresents your affiliation with any individual or entity.
              <br />
              <br />
              Agree not to e-mail, or otherwise transmit information or content using any automated
              device except the email system and drip system provided by the Website
              <br />
              <br />
              Agree that the Company is not obligated to maintain backup copies of any Articles,
              postings, templates, records, or other portions of the Website and shall have no
              liability for failure to maintain such items. We recommend that you retain a copy of
              all Articles, postings and records that you submit or make, and contacts you email.
              <br />
              <br />
              Agree and understand that the Company encourages the submission of ideas and
              suggestions from users relating to the use and improvement of the Website. The Company
              may use such suggestions or ideas for any purpose whatsoever; however, the Company is
              under no obligation to incorporate your or other users’ ideas or suggestions into its
              services, products, or Website, and in no event will the Company provide compensation
              for the submission of ideas or suggestions. You understand that all intellectual
              property rights associated with any ideas, concepts, techniques, inventions,
              processes, or works of authorship developed or created by you or another user in
              submitting ideas or suggestions to the Website will belong exclusively to the Company
              upon submission or communication.
              <br />
              <br />
              Agree that except as expressly provided in these Terms of Use, you shall not use,
              download, upload, copy, print, display, perform, reproduce, publish, license, post,
              sell, give, transmit, or distribute any materials from this Website, in whole or in
              part, without the prior written consent of an officer of the Company which permission
              may be withheld, conditioned or delayed for any reason or no reason.
              <br />
              <br />
              USAGE POLICY FOR THOSE USING THE WEBSITE
              <br />
              <br />
              By using this Website, you agree that you shall not:
              <br />
              <br />
              Use misleading e-mail addresses, forge headers, or otherwise manipulate any
              identifying information in order to disguise the origin of any content transmitted
              through the Website;
              <br />
              <br />
              Upload, submit for posting, e-mail, or otherwise transmit any material that contains
              software viruses or any other computer code, files, or programs designed to interrupt,
              destroy or limit the functionality of any computer software; upload, post, e-mail, or
              otherwise transmit any unsolicited or unauthorized advertising, promotional materials,
              "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of
              solicitation with misleading subject lines or untruthful content or solicitations
              other than those specifically in conformity with the purpose of the Website;
              <br />
              <br />
              Interfere with or disrupt (or attempt to interfere with or disrupt) the Website or
              servers or networks connected to the Website, or engage in any activity that
              diminishes the quality or interferes with the performance of the Website or servers or
              networks connected to the Website, or disobey any requirements, procedures, policies
              or regulations of networks connected to the Website;
              <br />
              <br />
              Disrupt the normal flow of content through the Website by flooding it with an
              excessive amount of content, or otherwise negatively affect other users’ ability to
              use the Website;
              <br />
              <br />
              Reproduce, modify, distribute, decompile, or reverse engineer any portion of the
              Website.
              <br />
              <br />
              Contact anyone who has requested not to be contacted, or harass or “stalk” anyone;
              <br />
              <br />
              Collect personally identifiable information about other users for illegal or
              commercial purposes;
              <br />
              <br />
              Use any automated device or means, including but not limited to spiders, robots,
              crawlers, or other data mining tools, to download data from the Website.
              <br />
              <br />
              Use the Website in any manner that exposes the Company to harm or liability of any
              nature.
              <br />
              <br />
              GOVERNING LAW, VENUE, DISPUTE RESOLUTION BY BINDING ARBITRATION AND CLASS ACTION
              WAIVER
              <br />
              <br />
              If the Company is not able to resolve a dispute with you after attempting to do so
              informally, then as a condition to your Use of the Website, you and the Company
              mutually agree to resolve such dispute through binding arbitration under the auspices
              of JAMS Alternative Dispute Resolution ("JAMS"). JAMS will administer any such
              arbitration under its Commercial Arbitration Rules and the Supplementary Procedures
              for Commercial-Related Disputes. The arbitration will be held in Miami-Dade County,
              Florida, in the United States, or in any other location as to which you and the
              Company may then mutually agree. A party seeking arbitration must first send to the
              other, by certified mail, a written notice of dispute. Any notice to the Company
              should be addressed to Reach Faster, Inc., 915 NE 173 Street, Miami, Florida 33162
              Attention: General Counsel and sent by certified mail, return receipt requested or Fed
              Ex. Any notice to you shall be sent to your address or email address as set forth in
              the Company’s account or such other legal address as the Company is able to identify.
              You may only resolve disputes with the Company on an individual basis and may not
              bring a claim as a plaintiff or a class member in a class, consolidated, or
              representative action. Class arbitrations, class actions, private attorney general
              actions, and consolidation with other arbitrations aren't allowed. Notwithstanding our
              agreement to arbitrate our disputes as provided above, the following exceptions will
              apply to the resolution of disputes between us: The Company may bring a lawsuit
              against you in any court of competent jurisdiction solely for injunctive relief to
              stop any unauthorized use or abuse of the services or intellectual property
              infringement or for collection of Subscription Licensing Agreement fees and costs
              without first engaging in arbitration or the informal dispute-resolution process
              described above. In the event that the agreement to arbitrate provided herein is found
              to be inapplicable or unenforceable for any reason, then as a condition to your Use of
              the Website, we agree that any resulting judicial proceedings will be brought in the
              federal or state courts of Miami-Dade County, Florida and by your Use of the Website,
              you expressly consent to venue and personal jurisdiction of the courts therein.
              <br />
              <br />
              All legal issues arising from or related to your Use of this Website shall be
              construed in accordance with and determined by the laws of the United States and the
              State of Florida unless preempted by U.S. federal law, without regard to conflict of
              law rules.
              <br />
              <br />
              COMMENTS
              <br />
              <br />
              The Company welcomes your comments on this Website. Please send any comments to
              alan@ReachTenants.com.
              <br />
              <br />
              PAYMENTS
              <br />
              <br />
              Registered Users agree to pay via credit card or ACH immediately upon receipt of
              invoice.
              <br />
              <br />
              CONFIDENTIALITY
              <br />
              <br />
              This Agreement shall be kept strictly private and confidential among you and the
              Company. You recognize that while performing its duties under this Agreement, You may
              be granted access to certain proprietary and confidential information regarding the
              Company’s business. You agrees to keep such information confidential, and the
              obligations of this paragraph will survive the termination of this Agreement. This
              paragraph does not apply to information that was previously known or information that
              is available in the public domain
              <br />
              <br />
              ENTIRE AGREEMENT AND REMEDIES
              <br />
              <br />
              These Terms of Use together with any Subscription Licensing Agreement, if any, and all
              referenced documents, constitute the entire agreement between you, and the Company
              with respect to the subject matter contained herein and supersede all proposals or
              quotations, oral or written, and all negotiations, conversations, discussions,
              representations, promises, conditions, inducements, relating to the subject matter of
              this Agreement. Any waiver of any provision of these Terms of Use must be in writing
              and signed by the Company, and any single waiver shall not be construed as a future
              waiver of the same or a different provision. The unenforceability of any provision of
              this Agreement shall not affect the remaining provisions or any portions thereof and,
              to the extent permitted by law, the arbitrator or when in court, a court of law shall
              endeavor to uphold the parties’ intentions as reflected in such unenforceable
              provision.
              <br />
              <br />
              Should you violate these Terms of Use or any other legal right of the Company or
              should the Company in its sole reasonable discretion find that your use of the Website
              is abusive, the Company has the right to pursue all legal and equitable remedies
              against you, including but not limited to prohibiting you from using the Website. The
              Company may terminate your account and delete any or all postings, Articles, e-mails,
              and other material at any time without notice to you for conduct violating these Terms
              of Use or other policies set forth elsewhere on the Website, or otherwise potentially
              violating any applicable law.
              <br />
              <br />
              The Company reserves the right at all times to disclose any information posted or
              transmitted by you or any other user as necessary to satisfy any law, regulation, or
              governmental request.
            </div>
          </div>

          <div className={styles.bottom_page_container}>
            <div className={styles.cta_wrapper}>
              <img src='term-use.jpg' className={styles.cta_image} alt=''></img>

              <div className={styles.cta_details}>
                <div className={styles.cta_detail_heading}>
                  The most effective way to reach your next tenant
                </div>

                <div
                  className={styles.cta_button_wrapper}
                  onClick={() => navigate('/request-demo')}
                >
                  Book a Demo
                </div>
              </div>
            </div>

            <FooterLanding />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
