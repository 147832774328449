import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { props } from './props';

const CircularLoader: React.FunctionComponent<props> = ({}) => {
  return (
    <>
      <div className={styles.ldsRing}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default CircularLoader;
