import { useEffect, useState } from 'react';
import styles from './index.module.scss';

interface AuditTrailCardProps {
  createdAt: any;
  actionType: any;
  metaData: any;
  emailSent?: any;
}

// metaData:
// {propertyChanged: 'terms', updatedValue: true, userName: 'Shikhar Sharma'}

function AuditTrailCard(props: AuditTrailCardProps) {
  const [text, setText] = useState('');
  const [viewedEmails, setViewedEmails] = useState('');
  function formatTime(isoTimeString: any) {
    // Convert ISO string to Date object
    const date = new Date(isoTimeString);

    // Format date object as desired
    const formatted = date.toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return formatted;
  }

  const getAuditTrailText = () => {
    if (props.actionType === 'inquiry_details_update') {
      if (props.metaData.propertyChanged === 'contenancies') {
        return `${props.metaData.userName} updated cotenancies`;
      } else return `${props.metaData.userName} updated ${props.metaData.propertyChanged}`;
    }
    if (props.actionType === 'email_sent') {
      return `${props.metaData.userName} sent email <br/> <br/> ${props.metaData.emailMessage}`;
    }
    if (props.actionType === 'business_details_update') {
      return `${props?.metaData?.userName} updated <br/> <br/> ${props?.metaData?.propertyChanged}`;
    } else return '';
  };

  useEffect(() => {
    let ve = props.emailSent?.filter((el: any) => el.messageRead);
    setViewedEmails(ve);
  }, []);

  const showRead = () => {
    const x = props.emailSent.find((o: any) => o.messageId === props.metaData.messageId);
    let status = x?.messageRead;
    if (x?.messageReplied) {
      status = false;
    }
    return status;
  };
  const showReplied = () => {
    const x = props.emailSent.find((o: any) => o.messageId === props.metaData.messageId);
    return x?.messageReplied;
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.date_time}>
          <p>{formatTime(props.createdAt)}</p>
          <div className={styles.email_status}>
            {props.metaData?.messageId && showRead() && (
              <span className={styles.read_tag}>OPENED</span>
            )}
            {props.metaData?.messageId && showReplied() && (
              <span className={styles.reply_tag}>REPLIED</span>
            )}
          </div>
        </div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: getAuditTrailText() }}
        ></div>
      </div>
    </>
  );
}

export default AuditTrailCard;
