import React from 'react';
import Select from 'react-select';
import styles from './statistic.module.scss';
import { cx } from '../../../../utils/class-name-service';

interface SingleValueCardProp {
  header: string;
  sent: number;
  outOf: number;
  description: string;
  background: string;
  shadow?: any;
}

const ProjectGoalCard = (props: SingleValueCardProp) => {
  const calculatePercentage: any = (value: any, outOf: any) => {
    let result = value > 0 ? ((value / outOf) * 100).toFixed(1) : 0;
    return result;
  };
  return (
    <>
      <div
        className={
          props.shadow === false ? styles.card_wrapper : cx(styles.card_wrapper, styles.card_shadow)
        }
      >
        <div className={styles.top_wrapper}>
          {<div className={styles.header}>{props.header}</div>}
          <div className={styles.value_sent}>
            {`${props.sent} `}
            <span className={styles.value_sent_percent}>
              / {calculatePercentage(props.sent, props.outOf)}%
            </span>
          </div>
          <div className={styles.outOf}>
            {props.description} {props.outOf}
          </div>
        </div>
        <div className={styles.progressBar_wrapper}>
          <div className={styles.progressBar_container}>
            <div
              className={styles.progressBar}
              style={{
                width: `${
                  calculatePercentage(props.sent, props.outOf) > 100
                    ? 100
                    : calculatePercentage(props.sent, props.outOf)
                }%`,
                background: `${props.background}`
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectGoalCard;
