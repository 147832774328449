import React, { useState, useEffect, useContext } from 'react';
import styles from './header-landing.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { cx } from '../../../../utils/class-name-service';
import { ProjectContext } from '../../../../context/ProjectContext';

const HeaderLanding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { blogs, setBlogs } = useContext(ProjectContext);

  const [menuSelected, setMenuSelected] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // console.log(window.innerWidth);
      // console.log('window.innerWidth:', window.innerWidth);
      // console.log('document.documentElement.clientWidth:', document.documentElement.clientWidth);
      // console.log('document.body.clientWidth:', document.body.clientWidth);
      if (document.documentElement.clientWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile ? (
    <>
      <div className={styles.header_container}>
        <div className={styles.logo_wrapper}>
          <img
            src='/RTLogo.svg'
            alt='logo'
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
        <div
          className={styles.hamburger_menu}
          onClick={() => {
            setMenuSelected(!menuSelected);
          }}
        >
          <img src='/hamburger_icon.svg' alt='menu' />
        </div>
      </div>
      {menuSelected && (
        <>
          <div className={styles.side_menu_mobile}>
            <div className={styles.header_container}>
              <div className={styles.logo_wrapper}>
                <img
                  src='/RTLogo.svg'
                  alt='logo'
                  onClick={() => {
                    navigate('/');
                  }}
                />
              </div>
              <div
                className={styles.hamburger_menu}
                onClick={() => {
                  setMenuSelected(!menuSelected);
                }}
              >
                <img src='/hamburger_icon.svg' alt='menu' />
              </div>
            </div>
            <div className={styles.mobile_menu}>
              <div
                className={
                  location.pathname === '/about'
                    ? cx(styles.header_link, styles.header_link_selected)
                    : styles.header_link
                }
                onClick={() => {
                  navigate('/about');
                }}
              >
                About
              </div>
              <div
                className={
                  location.pathname === '/features'
                    ? cx(styles.header_link, styles.header_link_selected)
                    : styles.header_link
                }
                onClick={() => {
                  navigate('/features');
                }}
              >
                Features
              </div>
              <div
                className={
                  location.pathname === '/calculators'
                    ? cx(styles.header_link, styles.header_link_selected)
                    : styles.header_link
                }
                onClick={() => {
                  navigate('/calculators');
                }}
              >
                Calculators
              </div>
              <div
                className={
                  location.pathname === '/pricing'
                    ? cx(styles.header_link, styles.header_link_selected)
                    : styles.header_link
                }
                onClick={() => {
                  navigate('/pricing');
                }}
              >
                Pricing
              </div>
              {blogs && blogs.length > 0 && (
                <div
                  className={
                    location.pathname === '/blog'
                      ? cx(styles.header_link, styles.header_link_selected)
                      : styles.header_link
                  }
                  onClick={() => {
                    navigate('/blog');
                  }}
                >
                  Blog
                </div>
              )}
              <div
                className={
                  location.pathname === '/time'
                    ? cx(styles.header_link, styles.header_link_selected)
                    : styles.header_link
                }
                onClick={() => {
                  navigate('/time');
                }}
              >
                Time
              </div>
              <div
                className={
                  location.pathname === '/faq'
                    ? cx(styles.header_link, styles.header_link_selected)
                    : styles.header_link
                }
                onClick={() => {
                  navigate('/faq');
                }}
              >
                FAQ
              </div>
              {/* <div
                className={styles.login_link}
                onClick={() => {
                  navigate('/sign-in');
                }}
              >
                Login
              </div> */}
              <div
                className={styles.button_wrapper}
                onClick={() => {
                  navigate('/request-demo');
                }}
              >
                Book a Demo1
              </div>
            </div>
          </div>
        </>
      )}
    </>
  ) : (
    <div>
      <div className={styles.header_container}>
        <div className={styles.logo_wrapper}>
          <img
            src='/RTLogo.svg'
            alt='logo'
            onClick={() => {
              navigate('/');
            }}
          />
        </div>

        <div className={styles.header_links}>
          <div
            className={
              location.pathname === '/about'
                ? cx(styles.header_link, styles.header_link_selected)
                : styles.header_link
            }
            onClick={() => {
              navigate('/about');
            }}
          >
            About
          </div>

          <div
            className={
              location.pathname === '/features'
                ? cx(styles.header_link, styles.header_link_selected)
                : styles.header_link
            }
            onClick={() => {
              navigate('/features');
            }}
          >
            Features
          </div>
          <div
            className={
              location.pathname === '/calculators'
                ? cx(styles.header_link, styles.header_link_selected)
                : styles.header_link
            }
            onClick={() => {
              navigate('/calculators');
            }}
          >
            Calculators
          </div>
          <div
            className={
              location.pathname === '/pricing'
                ? cx(styles.header_link, styles.header_link_selected)
                : styles.header_link
            }
            onClick={() => {
              navigate('/pricing');
            }}
          >
            Pricing
          </div>

          <div
            className={
              location.pathname === '/blog'
                ? cx(styles.header_link, styles.header_link_selected)
                : styles.header_link
            }
            onClick={() => {
              navigate('/blog');
            }}
          >
            Blog
          </div>
          <div
            className={
              location.pathname === '/time'
                ? cx(styles.header_link, styles.header_link_selected)
                : styles.header_link
            }
            onClick={() => {
              navigate('/time');
            }}
          >
            Time
          </div>
          <div
            className={
              location.pathname === '/faq'
                ? cx(styles.header_link, styles.header_link_selected)
                : styles.header_link
            }
            onClick={() => {
              navigate('/faq');
            }}
          >
            FAQ
          </div>
        </div>
        <div className={styles.header_links}>
          <div
            className={styles.login_link}
            onClick={() => {
              navigate('/sign-in');
            }}
          >
            Sign in
          </div>

          <div
            className={styles.button_wrapper}
            onClick={() => {
              navigate('/request-demo');
            }}
          >
            Book a Demo
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLanding;
