import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './delete-member.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';

const DeleteMemberModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  queryClient,
  selectedUser
}) => {
  const BASE_URL = AppConfig.API_BASE_URL;

  const storage = new Storage();
  const deleteMemberPost = async () => {
    const requestData = JSON.stringify({
      organizationID: storage.get('organizationId'),
      userids: [selectedUser.id]
    });

    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/organization/remove-user`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };

    await axios(config);
  };

  const { status: mutationStatus, mutateAsync: deleteMember } = useMutation(deleteMemberPost, {
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries(`userdetail`);
    }
  });
  const onSubmit = () => {
    deleteMember();
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '649px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        >
          Delete Member
        </ModalHeader>
        <ModalBody>
          <div className={styles.text}>
            Are you sure you want to delete “{selectedUser.firstName} {selectedUser.lastName}”?
          </div>
          <div className={styles.sub_text}>This action can't be undone</div>
        </ModalBody>
        <ModalFooter>
          <div className={styles.button_container}>
            <button
              className={styles.cancel_button}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className={styles.delete_button}
              onClick={() => {
                onSubmit();
              }}
            >
              Delete Member
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeleteMemberModal;
