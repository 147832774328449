import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';
import styles from './playground.module.scss';
import { ProjectContext } from '../../context/ProjectContext';
import TableSettingModal from '../../components/modals/table-setting';
import SidePane from '../../components/side-pane';
import Storage from '../../utils/local-storage';
import { useParams } from 'react-router-dom';
import { deepCopy } from '../../utils/deep-copy';
import AppConfig from '../../config';
import { STATE } from '../../constants/dropDown-constanst';
import CustomDataTableLoader from '../../components/customDatatableLoader';
import { useQuery } from 'react-query';
import EditBusinessDetailModal from '../../components/modals/edit-business-details';

interface PlaygroundProps {
  queryClient: any;
  handleChange?: any;
  setSelectedRows?: any;
  filterOptions?: any;
  selectedBusinessList?: any;
  columnOrder: any[];
  projectType?: any;
  queryString?: any;
  setQueryString?: any;
  setDashboardQueryString: any;
  dashboardQueryString: any;
  selectedCategory: any;
  selectedSubcategory: any;
}
const BACKGROUND_COLOR = (value: any) => {
  switch (value) {
    case 'Declined':
      return '#FFF2F2';
    case 'Closed':
      return '#F7FFF3';
    case 'Dead Lead':
      return '#F1F1F1';
    case 'Follow up':
      return '#FFF8F2';
    case 'Lead':
      return '#F7FFF3';
    case 'Prospect':
      return '#EAFAFA';
    default:
      return '#F2FAFF';
  }
};
const TEXT_COLOR = (value: any) => {
  switch (value) {
    case 'Declined':
      return '#FF3737';
    case 'Closed':
      return '#39AC3E';
    case 'Dead Lead':
      return '#C9C6C5';
    case 'Follow up':
      return '#FF8B37';
    case 'Lead':
      return '#39AC3E';
    case 'Prospect':
      return '#2CD7EE';
    default:
      return '#37ABFF';
  }
};
function EditPlayground(props: PlaygroundProps) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [BussinessList, setBussinessList] = useState([]);
  const { selectedBusiness, setSelectedBusiness } = useContext(ProjectContext);
  const [businessDetails, setBusinessDetails] = useState<any>({});
  const [responseDetailsUpdated, setResponseDetailsUpdated] = useState(false);

  const [columns, setColumns] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const storage = new Storage();
  let { businessId } = useParams();
  const fetchBusiness = async () => {
    setLoading(true);
    const config = {
      method: 'get',
      url: `/v1/businessDetail/updateBusinessDetails/${businessId}`
      // headers: {
      //   Authorization: `Bearer ${storage.get('accessToken')}`
      // }
    };
    await axios(config)
      .then(function (response) {
        setBussinessList(response.data.businessList);
        setData(response.data.businessList);
        let defaultColumn = [
          {
            name: 'Business Name',
            selector: (data: any) => data['businessName'],
            style: {
              color: '#2A72DD'
            },
            columnName: 'Business Name',
            omit: false,
            id: 'business_name'
          },
          {
            name: 'Name',
            selector: (data: any) => `${data['prefix']} ${data['firstName']} ${data['lastName']}`,
            columnName: 'Name',
            omit: false,
            id: 'Name'
          },

          {
            name: 'Email',
            selector: (data: any) => `${data['email'][0]}`,
            columnName: 'Email',
            omit: false,
            id: 'Email'
          },
          {
            selector: 'edit',
            cell: (row: any) => (
              <img
                src='/images/pencil.svg'
                onClick={e => {
                  e.stopPropagation();
                  setBusinessDetails(row);
                  setShowModal(true);
                }}
              />
            ),
            ignoreRowClick: false
          }
        ];
        setColumns(defaultColumn);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
  const {
    data: editBusinessDetail,
    status: editBusinessDetailStatus,
    refetch
  } = useQuery(`getEditBusiness`, fetchBusiness, {
    onSuccess: res => {}
  });

  const customStyles = {
    tableWrapper: {
      style: {
        width: '100%'
        // marginLeft: '20px',
        // marginTop: '20px',
        // maxWidth: '850px'
      }
    },
    table: {
      style: {
        width: '100%',
        background: '#fafafa'
      }
    },
    headRow: {
      style: {
        borderRadius: '8px',

        borderBottomWidth: '0px',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        /* identical to box height */
        textTransform: 'uppercase',
        color: '#667584',
        zIndex: 0
      }
    },
    rows: {
      style: {
        minHeight: '58px',
        border: '0px',
        margin: '4px 0px',
        borderRadius: '8px',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '17px',
        /* identical to box height */

        letterSpacing: '0.01em'
      }
    },
    headCells: {
      style: {
        paddingLeft: '10px', // override the cell padding for head cells
        paddingRight: '8px',
        background: '#F1F4FF'
      }
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px'
      }
    }
  };

  return (
    <div>
      <div className={styles.header}>Update Business Details</div>
      <div className={styles.table_container}>
        <DataTable
          // title='Users'
          columns={columns}
          data={data}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<CustomDataTableLoader />}
          // selectableRows
          fixedHeader
          fixedHeaderScrollHeight='calc(100vh - 236px)'
          highlightOnHover
          pointerOnHover
          onRowClicked={e => {
            setBusinessDetails(e);
          }}
          // pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={(newPerPage, page) => {
            setPage(page - 1);
            setPerPage(newPerPage);
          }}
          onSelectedRowsChange={props.handleChange}
          onChangePage={page => {
            setPage(page - 1);
          }}
        />
      </div>
      <EditBusinessDetailModal
        showModal={showModal}
        setShowModal={setShowModal}
        queryClient={props.queryClient}
        data={businessDetails}
      />
    </div>
  );
}

export default EditPlayground;
