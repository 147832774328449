import React, { useContext } from 'react';
import { props } from './model';
import styles from '../sign-in-container.module.scss';
import Storage from '../../../utils/local-storage';
import { Formik, Form, Field } from 'formik';
import WrappedButton from '../../../components/form-elements/buttons/index';
import CustomisedTextField from '../../../components/form-elements/customised-text-field/index';
import * as yup from 'yup';
import { BTN_PRIMARY } from '../../../constants/button-constants';
import PasswordField from '../../../components/form-elements/password-field';
import { useQuery, useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import AppConfig from '../../../config';
import { useSnackbar } from 'notistack';
import { ProjectContext } from '../../../context/ProjectContext';

const SetEmail: React.FC<props> = ({ setStep }) => {
  const { setColumnOrder, setToken, setUserData, setImageURL } = useContext(ProjectContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;

  const postLoginDetails = async (data: any) => {
    const requestData = JSON.stringify({
      email: data.email,
      password: data.password,
      rememberMe: true
    });

    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/auth/login`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: requestData
    };

    const { data: res } = await axios(config);
    return res;
  };

  const { status: mutationStatus, mutateAsync: login } = useMutation(postLoginDetails, {
    onSuccess: data => {
      storage.save('accessToken', data.tokens.access.token);
      storage.save('email', data.user.email);
      storage.save('role', data.user.role);
      storage.save('userId', data.user.id);
      storage.save('ownerUserId', data.organization[0].owner);
      storage.save('organizationId', data.organization[0]._id);
      setImageURL(`https://reachtenants-dev-assets.s3.amazonaws.com/${data.user.profileImage}`);
      storage.save('userName', `${data.user.firstName} ${data.user.lastName}`);
      setUserData(data.user);
      if (data.user.columnLayout.length) setColumnOrder(data.user.columnLayout);
      else {
        let defaultOrder = [
          'Business Name',
          'Name',
          'Email',
          'Zip',
          'Status',
          'Title',
          'Address',
          'States',
          'Phone',
          // 'County',
          'Franchise',
          'Website',
          // 'Year Established',
          'No. Of Employees',
          'Distance',
          'Location Sales',
          'Category'
        ];
        setColumnOrder(defaultOrder);
      }

      setToken(data.tokens.access.token);

      navigate('/projects');
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
      setSearchParams({ error: 'Invalid credentials' });
    }
  });

  const onLoginSubmit = (data: any) => {
    if (data.email && data.password) {
      login(data);
    }
  };

  const forgotPasswordApiCall = (email: any) => {
    if (email) {
      const data = JSON.stringify({
        email
      });

      const config = {
        method: 'post',
        url: `${BASE_URL}/v1/auth/forgot-password`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          enqueueSnackbar('Email with reset link sent', { variant: 'success', header: 'Success' });
        })
        .catch(function (error) {
          enqueueSnackbar(error.message, { variant: 'error', header: 'Error' });
        });
    } else {
      enqueueSnackbar('Email cannot be empty', { variant: 'error', header: 'Error' });
    }
  };

  return (
    <>
      <div className={styles.form_wrapper}>
        <div className={styles.heading}>Sign in to your account</div>

        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={values => {
            onLoginSubmit(values);
          }}
          validationSchema={yup.object({
            email: yup
              .string()
              .trim()
              .email('Please enter a valid email address')
              .required('Please enter a valid email address'),

            password: yup.string().trim().required('Please enter a valid password')
          })}
          enableReinitialize={true}
          validateOnChange={true}
        >
          {(props: any) => (
            <Form>
              <div className={styles.form_container}>
                <div className={styles.form_field}>
                  <Field
                    name='email'
                    placeholder={'Enter email address'}
                    title={'Email address'}
                    type={'text'}
                    component={CustomisedTextField}
                  />
                </div>

                <div className={styles.form_field}>
                  <Field
                    name='password'
                    placeholder={'Enter password'}
                    title={'Enter Password'}
                    type={'text'}
                    component={PasswordField}
                  />
                </div>

                <div
                  className={styles.forgot_password}
                  onClick={() => {
                    forgotPasswordApiCall(props.values.email);
                  }}
                >
                  Forgot Password?
                </div>

                <div className={styles.button_wrapper}>
                  <WrappedButton
                    btnText='Sign in'
                    type='submit'
                    btnType={BTN_PRIMARY}
                    className={styles.submit_button}
                    isInProgress={mutationStatus === 'loading' ? true : false}
                  />
                </div>

                <div className={styles.terms_and_policy}>
                  By signing in, you agree to our{' '}
                  <span
                    className={styles.cta_link}
                    onClick={() => navigate('/terms-of-use')}
                  >
                    Terms of Use
                  </span>{' '}
                  and{' '}
                  <span className={styles.cta_link} onClick={() => navigate('/privacy-policy')}>
                    Privacy Policy
                  </span>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SetEmail;
