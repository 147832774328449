import React, { useContext, useEffect, useState } from 'react';
import Card from '../../components/cards';
import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './landing-page-container.module.scss';
import { useNavigate } from 'react-router-dom';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import axios from 'axios';
import { ProjectContext } from '../../context/ProjectContext';

const FEATURES: any[] = [
  {
    img: '/images/homepic1.png',
    title: 'Create a project',
    description:
      'Enter all the information about your project and upload flyers, demographics, pictures, and any other important information so you have everything in one place to provide prospects as needed.'
  },
  {
    img: '/images/homeimg2.png',
    title: 'Prospect alone or select a team',
    description:
      'If you will not be prospecting alone, select a team. The same or a different team can be assembled for each project. All team members can share all information so there is no duplication of efforts, and everyone is in the loop.'
  },
  {
    img: '/images/projectStep_2.svg',
    title: 'Choose target businesses',
    description:
      'ReachTenants licenses the perfect list of local business contacts complete with the owner’s or manager’s name and email address and uploads them  for the prospector’s use. National expansion tenants are available as well.'
  },
  {
    img: '/images/projectStep_4.svg',
    title: 'Send personalized emails quickly',
    description: `Say goodbye to info@, hello@, and support@. ReachTenants licenses the personal contact information of local business people and facilitates sending personalized prospecting emails individually, resulting in higher deliverability and response rates. It's platform enables faster, more reliable, and trackable prospecting while being cost-effective.`
  },
  {
    img: '/images/projectStep_5.svg',
    title: 'Metrics',
    description:
      'ReachTenants’ reporting metrics are thorough and prioritize where the prospectors should be spending their time. This keeps the prospectors laser focused on signing up their next tenant. The reports quickly and clearly tell the complete story in an easy to understand format. The contact status report pinpoints who and when to follow up with the most valuable contacts.'
  },
  {
    img: '/images/projectStep_6.svg',
    title: 'Competitor location reports',
    description:
      "Supercharge your leasing efforts to national expansion tenants by utilizing ReachTenants' mapping system instantly displaying gaps and opportunities. A picture is worth 1,000 words, but a map showing gaps and opportunities is worth 10,000 words to a busy real estate executive of a national expansion tenant."
  }
];

const LandingPageContainer = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useCheckMobileScreen();
  const [showMore, setShowMore] = useState(false);
  const [email, setEmail] = useState('');
  const { blogs, setBlogs } = useContext(ProjectContext);
  const [visibleCards, setVisibleCards] = useState(4);
  const divToScroll = React.useRef(document.createElement('div'));
  const testimonialScroll = React.useRef(document.createElement('div'));
  const handleShowMore = () => {
    navigate(`/blog`);
  };

  const onChangeHandler = (e: any) => {
    setEmail(e.target.value);
  };
  function handleEmailValidation() {
    const regex = /\S+@\S+\.\S+/;
    if (regex.test(email)) {
      return true;
    } else {
      return false;
    }
  }
  const getBlogsDetail = async () => {
    const config = {
      method: 'get',
      url: `/v1/blog`
    };
    const res = await axios(config);
    return res.data;
  };
  const { data: blogDetails, status: blogDetailStatus } = useQuery(
    `getBlogsDetails`,
    getBlogsDetail,
    {
      onSuccess: res => {
        setBlogs(res);
      }
    }
  );
  const getTestimonialDetail = async () => {
    const config = {
      method: 'get',
      url: `/v1/testimonial`
    };
    const res = await axios(config);
    return res.data;
  };
  const { data: testimonialDetails, status: testimonialDetailStatus } = useQuery(
    `getTestimonialDetails`,
    getTestimonialDetail,
    {
      onSuccess: res => {}
    }
  );

  return (
    <>
      <div className={styles.landing_page_wrapper}>
        <div className={styles.max_width}>
          <HeaderLanding />

          <div
            className={
              !isMobile ? styles.top_container_wrapper : styles.top_container_wrapper_mobile
            }
          >
            {isMobile && (
              <div className={styles.large_heading}>
                <img src='/RTLogo.svg' /> <span>makes opportunities visible</span>
              </div>
            )}
            <div className={styles.heading_section}>
              <div className={styles.heading_subheading_wrapper}>
                <div className={styles.heading}>
                  <img src='/RTLogo.svg' />{' '}
                  <span> is the most effective way to reach your next tenant...</span>
                </div>

                <div className={styles.sub_heading}>
                  ReachTenants is designed to shorten the time and lower the cost it takes for
                  commercial real estate leasing agents and landlords to fill vacant retail,
                  restaurant, office spaces.
                </div>
              </div>

              <div className={styles.field_button_wrapper}>
                <input
                  type='text'
                  placeholder='Enter your email'
                  onChange={e => onChangeHandler(e)}
                  className={styles.input_field}
                />

                <div
                  className={styles.button_wrapper}
                  onClick={() => {
                    if (email) {
                      if (handleEmailValidation()) navigate('/request-demo', { state: { email } });
                      else
                        enqueueSnackbar('Enter valid Email', { variant: 'error', header: 'Error' });
                    } else {
                      enqueueSnackbar('Email field is empty', {
                        variant: 'error',
                        header: 'Error'
                      });
                    }
                  }}
                >
                  Book a Demo
                </div>
              </div>

              {!isMobile && (
                <div className={styles.large_heading}>
                  <img src='/RTLogo.svg' /> <span>makes opportunities visible</span>
                </div>
              )}
            </div>

            {/* <div className={styles.illustration_Wrapper}>
                <img src='/illustration.svg' alt='illustration' />
              </div> */}
          </div>

          <div className={styles.features_container}>
            <div className={styles.features_heading}>
              How <span className={styles.red}>Reach</span>
              <span className={styles.blue}>Tenants</span> works
            </div>

            {FEATURES.map((item, index) => (
              <>
                {(index + 1) % 2 !== 0 ? (
                  <div className={styles.individual_feature}>
                    

                    <div className={styles.detail}>
                      <div className={styles.number}>{index + 1}</div>

                      <div className={styles.title}>{item && item.title}</div>

                      <div className={styles.description}>{item && item.description}</div>
                    </div>


                    <div className={styles.image_wrapper}>
                      <img src={item.img} style={{ zIndex: 2 }}></img>

                      {index === 5 ? <div className={styles.left_blue_circle}></div> : <></>}
                    </div>
                  </div>
                ) : (
                  <div className={styles.individual_feature_even}>
                    <div className={styles.image_wrapper}>
                      <img src={item.img} style={{ zIndex: 2 }}></img>

                      {index === 2 ? <div className={styles.blue_circle}></div> : <></>}
                    </div>
                    <div className={styles.detail}>
                      <div className={styles.number}>{index + 1}</div>

                      <div className={styles.title}>{item && item.title}</div>

                      <div className={styles.description}>{item && item.description}</div>
                    </div>

                    
                  </div>
                )}
              </>
            ))}
          </div>

          {testimonialDetailStatus === 'success' && (
            <div className={styles.testimonials}>
              <div className={styles.testimonials_heading}>
                Here is what our customers say about us
              </div>

              <div className={styles.testimonials_cards} ref={testimonialScroll}>
                {testimonialDetails.map((item: any, index: any) => (
                  <>
                    <div className={styles.testimonials_cards_item}>
                      <div className={styles.testimonial_text}>{item.message}</div>
                      <div className={styles.blog_author_details}>
                        <div className={styles.author_image}>
                          <img src={item.author_image} />
                        </div>

                        <div className={styles.author_details}>
                          <div className={styles.author_name}>{item.author}</div>

                          <div
                            className={styles.author_date}
                          >{`${item.author_designation}`}</div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className={styles.arrow_wrapper}>
                <img
                  src='/white_left_arrow.svg'
                  alt='arrow'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    testimonialScroll.current.scrollLeft -= isMobile ? 321 : 590;
                  }}
                />

                <img
                  src='/white_right_arrow.svg'
                  alt='arrow'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    testimonialScroll.current.scrollLeft += isMobile ? 321 : 590;
                  }}
                />
              </div>
            </div>
          )}

          {blogDetailStatus === 'success' && blogDetails && blogDetails.length > 0 && (
            <div className={styles.blogs_container}>
              <div className={styles.heading_wrapper}>
                <div className={styles.blogs_heading}>Blog</div>

                {/* {!isMobile && (
                <div className={styles.arrow_wrapper}>
                  <img
                    src='/left_arrow.svg'
                    alt='arrow'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      divToScroll.current.scrollLeft -= 400;
                    }}
                  />

                  <img
                    src='/right_arrow.svg'
                    alt='arrow'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      divToScroll.current.scrollLeft += 400;
                    }}
                  />
                </div>
              )} */}
              </div>
              <div className={styles.blog_cards_wrapper} ref={divToScroll}>
                {blogDetails.slice(0, visibleCards).map((item: any, index: any) => {
                  return (
                    <Card
                      image={item.image_card}
                      title={item.title}
                      description={item.description}
                      key={index}
                      link={`/blog/${item._id}`}
                      linkText='Read More'
                    />
                  );
                })}
              </div>

              <div className={styles.view_all}>
                <div className={styles.button_wrapper} onClick={handleShowMore}>
                  View All
                </div>
              </div>
            </div>
          )}
          <div className={styles.bottom_page_container}>
            <div className={styles.cta_wrapper}>
              <img src='/images/footer-home-page.jpg' className={styles.cta_image} width={370} />

              <div className={styles.cta_details}>
                <div className={styles.cta_detail_heading}>
                  The most effective way to reach your next tenant
                </div>

                <div
                  className={styles.cta_button_wrapper}
                  onClick={() => navigate('/request-demo')}
                >
                  Book a Demo
                </div>
              </div>
            </div>

            <FooterLanding />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageContainer;
