import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Card from '../../components/cards';
import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './individual-blog.module.scss';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import AppConfig from '../../config';
import { useQuery } from 'react-query';
import Storage from '../../utils/local-storage';

const BLOG_DETAILS = {
  datePosted: '20th Oct 2022',
  authorName: 'Shikhar',
  title: 'Artificial Intelligence beyond imaginations',
  subHeading:
    'Artifical Intelligence has been advancing beyond what humans have imagnied for decades and will dominate humans in the coming years.',
  coverImge: '/cover_image_blog_mobile.svg',
  blogContentHeading: 'Insight on demand, at any scale, from edge to cloud',
  blogContentText: `The digital revolution has already changed how people live, work, and communicate. And it’s only just getting started. But the same technologies that have the potential to help billions of people live happier, healthier, and more productive lives are also creating new challenges for citizens and governments around the world. From election meddling to data breaches and cyberattacks, recent events have shown that technology is changing how we think about privacy, national security, and maybe even democracy itself. In this project, we examine challenges in five key areas that will shape the future of the digital age: justice system, impact on democracy, global security and international conflict, the impact of automations and AI on the jobs marketplace, identity, and privacy. Explore provocative and through-provoking topics on how technology impacts our lives.
    
    Build your AI platform with IT infrastructure that’s flexible and unbounded. Our on-prem, cloud and hybrid options take into account your team’s location, access needs, security, and cost constraints, and our open systems use best-of-breed GPU and CPU technologies. With purpose-built, AI-optimised solutions, you can quickly operationalise machine learning while turning data into a strategic resource with our data management platforms.`,
  relatedPosts: [
    {
      title: 'How to get more traffic to your website',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      image: '/Blog1.svg',
      authorImg: '/test-user-landing.svg',
      author: 'Shikhar',
      date: '12th May 2021'
    },
    {
      title: 'How to get more traffic to your website',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      image: '/Blog1.svg',
      authorImg: '/test-user-landing.svg',
      author: 'Shikhar',
      date: '12th May 2021'
    },
    {
      title: 'How to get more traffic to your website',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      image: '/Blog1.svg',
      authorImg: '/test-user-landing.svg',
      author: 'Shikhar',
      date: '12th May 2021'
    }
  ]
};
const formattedDate = (ISODate: any) => {
  const date = new Date(ISODate);
  const options: any = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };

  // Format the date in the desired format
  const formattedDateString = date.toLocaleDateString('en-US', options);
  // Combine the formatted day, month, and year
  const finalFormattedDate = `${formattedDateString} `;

  return finalFormattedDate;
};

const BlogDetailScreen = () => {
  const { id } = useParams();
  const isMobile = useCheckMobileScreen();
  const BASE_URL = AppConfig.API_BASE_URL;
  const [blogs, setBlogs] = useState<any>();
  const storage = new Storage();

  const getBlogsDetail = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/blog/${id}`
    };
    const res = await axios(config);
    return res.data;
  };
  const { data: blogDetail, status: blogDetailStatus } = useQuery(`getBlogDetail`, getBlogsDetail);
  const getBlogshandler = async () => {
    const config = {
      method: 'get',
      url: `/v1/blog`
    };
    const res = await axios(config);
    return res.data;
  };
  const { data: blogsDetail, status: blogStatus } = useQuery(`getBlogsDetails`, getBlogshandler);
  useEffect(() => {
    if (blogStatus === 'success') {
      setBlogs(blogsDetail.filter((item: any) => item._id !== id));
    }
  }, [blogStatus]);

  return (
    <>
      <div className={styles.page_wrapper}>
        <div className={styles.blogs_page_wrapper}>
          <div className={styles.max_width}>
            <HeaderLanding />

            <div className={styles.blog_detail_wrapper}>
              {blogDetailStatus === 'success' && (
                <>
                  <div className={styles.author_date}>
                    {blogDetail.updatedAt && (
                      <span className={styles.date_posted}>
                        {formattedDate(blogDetail.updatedAt)}
                      </span>
                    )}

                    {blogDetail.author && (
                      <span className={styles.author_name}>{blogDetail.author}</span>
                    )}
                  </div>
                  <div className={styles.blog_title}>{blogDetail.title}</div>
                  <div className={styles.sub_heading}>{blogDetail.description}</div>
                  <div className={styles.cover_image}>
                    {isMobile ? (
                      <img src={blogDetail.image} className={styles.cover_image}></img>
                    ) : (
                      <img src={blogDetail.image} className={styles.cover_image}></img>
                    )}
                  </div>
                  <div className={styles.blog_content}>
                    <div className={styles.blog_text}>

                      <div
                        className={styles.blog_description}
                        dangerouslySetInnerHTML={{ __html: blogDetail.content }}
                      ></div>
                    </div>

                    {/* <div className={styles.social_icons}>
                      <img src={'/FbIcon.svg'} className={styles.social_fb_icon}></img>
                      <img src={'/TwitterIcon.svg'} className={styles.social_fb_icon}></img>
                      <img src={'/LinkedinIcon.svg'} className={styles.social_fb_icon}></img>
                    </div> */}
                  </div>
                  <div className={styles.dividing_line}></div>
                  {blogStatus === 'success' && blogs && blogs.length > 0 && (
                    <div className={styles.related_posts}>
                      <div className={styles.related_heading}>Related topics</div>

                      <div className={styles.blogs_wrapper}>
                        {blogs.map((item: any, index: any) => {
                          return (
                            <Card
                              key={index}
                              title={item.title}
                              description={item.description}
                              image={item.image_card}
                              authorImg={item.authorImg}
                              author={item.author}
                              date={item.date}
                              link={`/blog/${item._id}`}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <FooterLanding />
        </div>
      </div>
    </>
  );
};

export default BlogDetailScreen;
