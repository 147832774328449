export const STATE = [
  {
    value: 'S',
    label: 'State',
    isdisabled: true // disable this option
  },
  {
    label: 'Alabama',
    value: 'AL'
  },
  {
    label: 'Alaska',
    value: 'AK'
  },
  {
    label: 'American Samoa',
    value: 'AS'
  },
  {
    label: 'Arizona',
    value: 'AZ'
  },
  {
    label: 'Arkansas',
    value: 'AR'
  },
  {
    label: 'California',
    value: 'CA'
  },
  {
    label: 'Colorado',
    value: 'CO'
  },
  {
    label: 'Connecticut',
    value: 'CT'
  },
  {
    label: 'Delaware',
    value: 'DE'
  },
  {
    label: 'District Of Columbia',
    value: 'DC'
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    label: 'Florida',
    value: 'FL'
  },
  {
    label: 'Georgia',
    value: 'GA'
  },
  {
    label: 'Guam',
    value: 'GU'
  },
  {
    label: 'Hawaii',
    value: 'HI'
  },
  {
    label: 'Idaho',
    value: 'ID'
  },
  {
    label: 'Illinois',
    value: 'IL'
  },
  {
    label: 'Indiana',
    value: 'IN'
  },
  {
    label: 'Iowa',
    value: 'IA'
  },
  {
    label: 'Kansas',
    value: 'KS'
  },
  {
    label: 'Kentucky',
    value: 'KY'
  },
  {
    label: 'Louisiana',
    value: 'LA'
  },
  {
    label: 'Maine',
    value: 'ME'
  },
  {
    label: 'Marshall Islands',
    value: 'MH'
  },
  {
    label: 'Maryland',
    value: 'MD'
  },
  {
    label: 'Massachusetts',
    value: 'MA'
  },
  {
    label: 'Michigan',
    value: 'MI'
  },
  {
    label: 'Minnesota',
    value: 'MN'
  },
  {
    label: 'Mississippi',
    value: 'MS'
  },
  {
    label: 'Missouri',
    value: 'MO'
  },
  {
    label: 'Montana',
    value: 'MT'
  },
  {
    label: 'Nebraska',
    value: 'NE'
  },
  {
    label: 'Nevada',
    value: 'NV'
  },
  {
    label: 'New Hampshire',
    value: 'NH'
  },
  {
    label: 'New Jersey',
    value: 'NJ'
  },
  {
    label: 'New Mexico',
    value: 'NM'
  },
  {
    label: 'New York',
    value: 'NY'
  },
  {
    label: 'North Carolina',
    value: 'NC'
  },
  {
    label: 'North Dakota',
    value: 'ND'
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    label: 'Ohio',
    value: 'OH'
  },
  {
    label: 'Oklahoma',
    value: 'OK'
  },
  {
    label: 'Oregon',
    value: 'OR'
  },
  {
    label: 'Palau',
    value: 'PW'
  },
  {
    label: 'Pennsylvania',
    value: 'PA'
  },
  {
    label: 'Puerto Rico',
    value: 'PR'
  },
  {
    label: 'Rhode Island',
    value: 'RI'
  },
  {
    label: 'South Carolina',
    value: 'SC'
  },
  {
    label: 'South Dakota',
    value: 'SD'
  },
  {
    label: 'Tennessee',
    value: 'TN'
  },
  {
    label: 'Texas',
    value: 'TX'
  },
  {
    label: 'Utah',
    value: 'UT'
  },
  {
    label: 'Vermont',
    value: 'VT'
  },
  {
    label: 'Virgin Islands',
    value: 'VI'
  },
  {
    label: 'Virginia',
    value: 'VA'
  },
  {
    label: 'Washington',
    value: 'WA'
  },
  {
    label: 'West Virginia',
    value: 'WV'
  },
  {
    label: 'Wisconsin',
    value: 'WI'
  },
  {
    label: 'Wyoming',
    value: 'WY'
  }
];
export const options = [
  { value: 'Square Feet', label: 'Square Feet' },
  { value: 'Acre(s)', label: 'Acre(s)' }
];
export const YearlyOptions = [
  { value: 'Month', label: 'Month' },
  { value: 'Year', label: 'Year' }
];
export const GroupByOptions = [
  { value: 'all-business', label: 'All Business' },
  { value: 'all-group', label: 'All group' },
  { value: 'none', label: 'No group' },
  { value: 'MUF', label: 'MUF' },
  { value: 'MBF', label: 'MBF' },
  { value: 'MBC', label: 'MBC' }
];
export const commercialOptions = [
  { value: 'For Rent', label: 'For rent' },
  { value: 'For Sale', label: 'For sale' },
  { value: 'For Rent and For Sale', label: 'For rent and for sale' }
];
export const sqOptions = [
  { value: 'Under Air', label: 'Under air' },
  { value: 'Under Heat', label: 'Under heat' }
];
export const bedroomsOptions = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' }
];
export const bathroomsOptions = [
  { value: '0', label: '0' },
  { value: '0.5', label: '0.5' },
  { value: '1', label: '1' },
  { value: '1.5', label: '1.5' },
  { value: '2', label: '2' },
  { value: '2.5', label: '2.5' },
  { value: '3', label: '3' },
  { value: '3.5', label: '3.5' },
  { value: '4', label: '4' },
  { value: '4.5', label: '4.5' },
  { value: '5', label: '5' },
  { value: '5.5', label: '5.5' },
  { value: '6', label: '6' },
  { value: '6.5', label: '6.5' },
  { value: '7', label: '7' },
  { value: '7.5', label: '7.5' },
  { value: '8', label: '8' },
  { value: '8.5', label: '8.5' },
  { value: '9', label: '9' },
  { value: '9.5', label: '9.5' },
  { value: '10', label: '10' }
];
export const garageOptions = [
  { value: 'Car port', label: 'Car port' },
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' }
];
export const poolOptions = [
  { value: 'None', label: 'None' },
  { value: 'Private', label: 'Private' },
  { value: 'Community', label: 'Community' }
];

export const DaysToSentEmailOptions = [
  // { value: 0, label: '0' },
  { value: 1, label: '1 day' },
  { value: 2, label: '2 days' },
  { value: 3, label: '3 days' },
  { value: 4, label: '4 days' },
  { value: 5, label: '5 days' },
  { value: 6, label: '6 days' },
  { value: 7, label: '7 days' },
  { value: 8, label: '8 days' },
  { value: 9, label: '9 days' },
  { value: 10, label: '10 days' }
];

export const PersonHasOptions = [
  { value: 'Opened', label: 'Opened' },
  { value: 'Not Opened', label: 'Not opened' },
  { value: 'Declined', label: 'Declined' },
  { value: 'Prospect', label: 'Prospect' },
  { value: 'Lead', label: 'Lead' },
  { value: 'Dead Lead', label: 'Dead lead' },
  // { value: 'Pending', label: 'Pending' },
  { value: 'Closed', label: 'Closed' }
];

export const originalOrder = [
  'Business Name',
  'Company Group',
  'Group Type',
  'Name',
  'Email',
  'Zip',
  'Status',
  'Title',
  'Address',
  'States',
  'Phone',
  // 'County',
  'Franchise',
  'Website',
  // 'Year Established',
  'No. Of Employees',
  'Distance',
  'Location Sales',
  'Category'
];
