import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './response-details-modal.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, GREY_BUTTON } from '../../../constants/button-constants';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import AppConfig from '../../../config';
import * as yup from 'yup';
import Storage from '../../../utils/local-storage';
import { useEffect, useState } from 'react';
import CustomCreatableSelectField from '../../form-elements/customised-creatable-select';

const ResponseDetailsModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  title,
  valueLabel,
  setEditTextValue,
  type
}) => {
  const [selectedSource, setSelectedSource] = useState('');
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  let initialValues;
  if (valueLabel) {
    initialValues = valueLabel.reduce((result: any, { id, value }: any) => {
      result[id] = value;
      return result;
    }, {});
  }
  const promiseOptions = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/report/allSources/${storage.get('projectId')}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    let option: any = [];
    res.data.map((el: any) => {
      option.push({ label: el._id, value: el._id });
    });
    return option;
  };
  const {
    data: sourceDetails,
    status: sourceDetailStatus,
    refetch
  } = useQuery(`getSourceOptions`, promiseOptions);
  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{
          maxWidth: '450px',
          width: '100%',
          zIndex: '999999999 !important'
        }}
      >
        {!valueLabel ? (
          <Formik
            initialValues={{
              value: ''
            }}
            validationSchema={yup.object({
              value: yup.string().trim().required('Value is required')
            })}
            onSubmit={values => {
              // const data = {
              //   title: values.value
              // };
              setEditTextValue(values.value);
              setShowModal(!showModal);
            }}
          >
            {props => (
              //TODO: Add member form validation
              <Form>
                <ModalHeader
                  toggle={() => {
                    setShowModal(!showModal);
                  }}
                >
                  {title}
                </ModalHeader>
                <ModalBody>
                  <div className={styles.form_wrapper}>
                    <div className={styles.single_field}>
                      {type === 'date' ? (
                        <Field name='value' as={'input'} type={'date'} required={true} />
                      ) : (
                        <Field
                          name='value'
                          placeholder='Enter value'
                          component={CustomisedTextField}
                          required={true}
                          // disabled={}
                        />
                      )}
                    </div>
                  </div>
                  {/* </div> */}
                </ModalBody>
                <ModalFooter>
                  <div className={styles.button_wrapper}>
                    <WrappedButton
                      btnText='Cancel'
                      onClick={(e: React.FormEvent<HTMLInputElement>) => {
                        e.preventDefault();
                        setShowModal(!showModal);
                      }}
                      btnType={GREY_BUTTON}
                      className={styles.submit_button}
                    />
                    <WrappedButton
                      btnText={'Save'}
                      btnType={BTN_PRIMARY}
                      className={styles.submit_button}
                      type={'submit'}
                      // isInProgress={mutationStatus === 'loading' ? true : false}
                    />
                  </div>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={
              title === 'Update Business details' &&
              yup.object({
                businessName: yup.string().required('Business name is required'),
                address: yup.string().required('Company address is required'),
                city: yup.string().required('Company city is required'),
                territory: yup.string().required('Company territory is required'),
                zipCode: yup.string().required('Company zip code is required'),
                phone: yup.string().required('Company phone is required'),
                url: yup.string().required('Company URL is required')
              })
            }
            onSubmit={values => {
              setEditTextValue(values);
              setShowModal(!showModal);
            }}
          >
            {props => (
              //TODO: Add member form validation
              <Form>
                <ModalHeader
                  toggle={() => {
                    setShowModal(!showModal);
                  }}
                >
                  {title}
                </ModalHeader>
                <ModalBody style={{ minHeight: '200px' }}>
                  <div className={styles.form_wrapper}>
                    {valueLabel.map((el: any) => (
                      <>
                        {title !== 'Source' ? (
                          <div className={styles.single_field}>
                            <Field
                              name={el.id}
                              placeholder={`Enter ${el.label}`}
                              component={CustomisedTextField}
                              required={true}

                              // disabled={}
                            />
                          </div>
                        ) : (
                          <>
                            {sourceDetailStatus === 'success' && (
                              <div className={styles.single_field} style={{ height: '160px' }}>
                                <Field
                                  name={el.id}
                                  placeholder={`Enter ${el.label}`}
                                  options={sourceDetails}
                                  component={CustomCreatableSelectField}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </div>
                  {/* </div> */}
                </ModalBody>
                <ModalFooter>
                  <div className={styles.button_wrapper}>
                    <WrappedButton
                      btnText='Cancel'
                      onClick={(e: React.FormEvent<HTMLInputElement>) => {
                        e.preventDefault();
                        setShowModal(!showModal);
                      }}
                      btnType={GREY_BUTTON}
                      className={styles.submit_button}
                    />
                    <WrappedButton
                      btnText={'Save'}
                      btnType={BTN_PRIMARY}
                      className={styles.submit_button}
                      type={'submit'}
                      // isInProgress={mutationStatus === 'loading' ? true : false}
                    />
                  </div>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

export default ResponseDetailsModal;
