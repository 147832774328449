import React, { useState } from 'react'
import SetEmail from './set-email';
import SetPassword from './set-password';

function SignInContainer() {

    // const [step, setStep] = useState(1);

    // const returnComponent = () => {
    //     switch (step) {
    //         case 0:
    //             return (<SetEmail setStep={setStep} />)
    //         case 1:
    //             return (<SetPassword />)
    //     }
    // }

    return (
        <>
            <SetEmail />
        </>

    )
}

export default SignInContainer