import React, { useEffect, useState } from 'react';
import { props } from './model';
import styles from './drip-system.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, GREY_BUTTON } from '../../../constants/button-constants';
import AppConfig from '../../../config';
import { useSnackbar } from 'notistack';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import Storage from '../../../utils/local-storage';
const dateConverter = (dates: any) => {
  const arrayOfDateString = dates;
  if (arrayOfDateString?.length > 0) {
    const convertedDate = arrayOfDateString.map((item: any) => {
      let date = new Date(item);
      return {
        day: date.getUTCDate(),
        month: date.getUTCMonth() + 1, // Adding 1 since month indices start from 0
        year: date.getUTCFullYear()
      };
    });
    return convertedDate;
  } else {
    return [];
  }
};
const HolidayCalender: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  setDate,
  date
}) => {
  const [selectedDay, setSelectedDay] = useState<any>(dateConverter(date));
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '394px', width: '100%', zIndex: '999999999 !important' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        >
          Holiday calendar
        </ModalHeader>
        <ModalBody>
          <div className={styles.form_wrapper}>
            <div className={styles.single_field}></div>
            <Calendar
              value={selectedDay}
              onChange={setSelectedDay}
              colorPrimary=' #2A72DD' // added this
              // minimumDate={utils('en').getToday()}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={styles.button_wrapper}>
            <WrappedButton
              btnText='Cancel'
              onClick={(e: React.FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                setShowModal(!showModal);
              }}
              btnType={GREY_BUTTON}
              className={styles.submit_button}
            />
            <WrappedButton
              btnText={'Add'}
              btnType={BTN_PRIMARY}
              className={styles.submit_button}
              onClick={() => {
                let date = selectedDay.map((item: any) =>
                  new Date(Date.UTC(item.year, item.month - 1, item.day)).toISOString()
                );
                setDate(date);
                setShowModal(false);
              }}
              // isInProgress={mutationStatus === 'loading' ? true : false}
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default HolidayCalender;
