import React, { useEffect, useState } from 'react';
import Card from '../../components/cards';
import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import { cx } from '../../utils/class-name-service';
import styles from './blogs-screen.module.scss';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { useQuery } from 'react-query';
import axios from 'axios';

const TAGS = [
  'All',
  'Marketing',
  'Technology',
  'Restaurant',
  'Fitness',
  'Retail',
  'Medical',
  'Dental',
  'Office',
  'Closings',
  'Growth and Innovation'
];

const BLOGS = [
  {
    title: 'How to get more traffic to your website',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: '/Blog1.svg',
    authorImg: '/test-user-landing.svg',
    author: 'Shikhar',
    date: '12th May 2021'
  },
  {
    title: 'How to get more traffic to your website',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: '/Blog1.svg',
    authorImg: '/test-user-landing.svg',
    author: 'Shikhar',
    date: '12th May 2021'
  },
  {
    title: 'How to get more traffic to your website',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: '/Blog1.svg',
    authorImg: '/test-user-landing.svg',
    author: 'Shikhar',
    date: '12th May 2021'
  },
  {
    title: 'How to get more traffic to your website',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: '/Blog1.svg',
    authorImg: '/test-user-landing.svg',
    author: 'Shikhar',
    date: '12th May 2021'
  },
  {
    title: 'How to get more traffic to your website',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: '/Blog1.svg',
    authorImg: '/test-user-landing.svg',
    author: 'Shikhar',
    date: '12th May 2021'
  },
  {
    title: 'How to get more traffic to your website',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: '/Blog1.svg',
    authorImg: '/test-user-landing.svg',
    author: 'Shikhar',
    date: '12th May 2021'
  },
  {
    title: 'How to get more traffic to your website',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: '/Blog1.svg',
    authorImg: '/test-user-landing.svg',
    author: 'Shikhar',
    date: '12th May 2021'
  },
  {
    title: 'How to get more traffic to your website',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: '/Blog1.svg',
    authorImg: '/test-user-landing.svg',
    author: 'Shikhar',
    date: '12th May 2021'
  },
  {
    title: 'How to get more traffic to your website',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: '/Blog1.svg',
    authorImg: '/test-user-landing.svg',
    author: 'Shikhar',
    date: '12th May 2021'
  }
];
const formattedDate = (ISODate: any) => {
  const date = new Date(ISODate);
  const options: any = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };

  // Format the date in the desired format
  const formattedDateString = date.toLocaleDateString('en-US', options);
  // Combine the formatted day, month, and year
  const finalFormattedDate = `${formattedDateString} `;

  return finalFormattedDate;
};

const BlogsScreen = () => {
  const isMobile = useCheckMobileScreen();
  const [selectedTag, setSelectedTag] = useState('All');
  const [showMore, setShowMore] = useState(false);
  const [visibleCards, setVisibleCards] = useState(8);

  const getBlogsDetail = async () => {
    const config = {
      method: 'get',
      url: `/v1/blog`
    };
    const res = await axios(config);
    return res.data;
  };
  const { data: blogDetails, status: blogDetailStatus } = useQuery(
    `getBlogsDetails`,
    getBlogsDetail
  );

  const handleShowMore = () => {
    if (visibleCards < BLOGS.length) {
      if (!isMobile) setVisibleCards(visibleCards + 8);
      else setVisibleCards(visibleCards + 4);
    }

    if (visibleCards >= BLOGS.length) {
      if (!isMobile) setVisibleCards(8);
      else setVisibleCards(4);
    }
  };
  useEffect(() => {
    if (!isMobile) setVisibleCards(8);
    else setVisibleCards(4);
  }, [isMobile]);
  useEffect(() => {
    if (visibleCards >= BLOGS.length) {
      setShowMore(true);
    } else setShowMore(false);
  }, [visibleCards]);

  return (
    <>
      <div className={styles.page_wrapper}>
        <div className={styles.blogs_page_wrapper}>
          <div className={styles.max_width}>
            <HeaderLanding />
          </div>

          <div className={styles.cover_image_wrapper}>
            <div className={styles.cover_image}>
              {isMobile ? (
                <img src='/cover_image_blog_mobile.svg'></img>
              ) : (
                <img src='/blog_page_top_img.jpg'></img>
              )}
            </div>

            {blogDetailStatus === 'success' && blogDetails && blogDetails.length > 0 && (
              <div className={styles.cover_blog_details}>
                <div className={styles.cover_blog_heading}>{blogDetails[0].title}</div>

                <div className={styles.cover_blog_sub_heading}>{blogDetails[0].description}</div>

                {/* <div className={styles.blog_author_details}>
                  <div className={styles.author_image}>
                    <img src='/test-user-landing.svg'></img>
                  </div>

                  <div className={styles.author_details}>
                    <div className={styles.author_name}>{blogDetails[0].author}</div>

                    <div className={styles.author_date}>
                      {formattedDate(blogDetails[0].updatedAt)}
                    </div>
                  </div>
                </div> */}
              </div>
            )}
          </div>

          {/* <div className={styles.tags_nav}>
            {TAGS.map((tag, index) => {
              return (
                <div
                  className={selectedTag !== tag ? styles.tag : cx(styles.tag, styles.active)}
                  key={index}
                  onClick={() => setSelectedTag(tag)}
                >
                  {tag}
                </div>
              );
            })}
          </div> */}

          <div className={styles.blogs_container}>
            {blogDetailStatus === 'success' &&
              blogDetails.slice(0, visibleCards).map((blog: any, index: any) => {
                return (
                  <>
                    <Card
                      title={blog.title}
                      description={blog.description}
                      image={blog.image_card}
                      author={blog.author}
                      authorImg={'/test-user-landing.svg'}
                      date={formattedDate(blog.updatedAt)}
                      link={`/blog/${blog._id}`}
                    />
                  </>
                );
              })}
          </div>

          {/* <div className={styles.load_more} onClick={handleShowMore}>
            {showMore ? 'Load Less' : 'Load More'}
          </div> */}

          <FooterLanding />
        </div>
      </div>
    </>
  );
};

export default BlogsScreen;
