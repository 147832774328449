import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import styles from './customised-text-field.module.scss';
import { props } from './model';
import { usePlacesWidget } from 'react-google-autocomplete';

const CustomisedTextField: React.FC<props> = ({
  form,
  field,
  placeholder,
  required,
  label,
  ...props
}) => {
  const { name, value, onChange } = field;
  const { setFieldValue, errors, touched } = form;

  function formatPhoneNumber(input: any) {
    // Remove all non-numeric characters from the input
    let phoneNumber = input.replace(/\D/g, '');

    // If the phone number is longer than 10 digits, trim it down to 10 digits
    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.substring(0, 10);
    }

    // Add the dots in the correct places
    let formattedPhoneNumber = '';
    for (let i = 0; i < phoneNumber.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += '.';
      }
      formattedPhoneNumber += phoneNumber.charAt(i);
    }

    // Set the value of the input field to the formatted phone number
    return formattedPhoneNumber;
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    if (props.isPhone) setFieldValue(field.name, formatPhoneNumber(e.target.value));
    if (props.isNumber) {
      const inputValue = e.target.value.replace(/,/g, ''); // Remove existing commas
      const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      setFieldValue(field.name, formattedValue);
    } else if (field.name === 'projectName') {
      const limitedInput = e.target.value;
      setFieldValue(field.name, limitedInput);
    } else if (props.isNumber) {
      const numericValue = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
      setFieldValue(field.name, numericValue);
    } else setFieldValue(field.name, e.target.value);
  };
  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyDyVFBvaToxr86d53etAKEyld360Hx3w4M',
    options: {
      types: ['premise', 'street_address', 'country'],
      componentRestrictions: { country: 'USA' }
    },
    onPlaceSelected: place => {
      let latitude = place.geometry?.location?.lat();
      let longitude = place.geometry?.location?.lng();
      setFieldValue('projectLatLong', `${latitude},${longitude}`);
      return setFieldValue(field.name, place.formatted_address);
    }
  });
  return (
    <>
      {placeholder ? (
        <div className={styles.field_label}>{`${placeholder}${required ? ' *' : ''}`}</div>
      ) : (
        <div className={styles.field_label}></div>
      )}
      {props.type === 'address' ? (
        <TextField
          variant='outlined'
          id={name}
          {...field}
          onChange={onChangeHandler}
          className={styles.cutomisedTextField}
          disabled={props.disabled}
          inputRef={ref}
          error={(value || touched[name]) && errors && errors[name]}
        ></TextField>
      ) : (
        <TextField
          label={label}
          variant='outlined'
          id={name}
          {...field}
          onChange={onChangeHandler}
          className={styles.cutomisedTextField}
          // disabled={props.disabled}
          inputProps={{ readOnly: props.disabled }}
          error={(value || touched[name]) && errors && errors[name]}
        ></TextField>
      )}

      {/* <input type="text" name={name} className={styles.cutomisedTextField}></input> */}
      {(value || touched[name]) && errors && errors[name] && (
        <div className={styles.error_message}>{errors[name]}</div>
      )}
    </>
  );
};

export default CustomisedTextField;
