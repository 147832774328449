import Storage from './local-storage';
function getFormattedDate(dateISO: any) {
  const dateObj = new Date(dateISO);
  const year = dateObj.getFullYear();
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const date = ('0' + dateObj.getDate()).slice(-2);
  const formatedDate = `${year}-${month}-${date}`;
  return formatedDate;
}

function getDatesInRange(startDate: any, endDate: any) {
  const dates = [];
  let currentDate = new Date(startDate);
  endDate = new Date(endDate);

  while (currentDate <= endDate) {
    dates.push(currentDate.toISOString().slice(0, 10));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

export const getOverallMetrics = (data: any, selectedDayRange: any) => {
  const startDate = new Date(
    `${selectedDayRange.from?.year}-${selectedDayRange.from?.month}-${selectedDayRange.from?.day}`
  );
  const endDate = new Date(
    `${selectedDayRange.to?.year}-${selectedDayRange.to?.month}-${selectedDayRange.to?.day}`
  );

  const filteredEmailSent = data.emailSent.filter((obj: any) => {
    const createdAtDate = new Date(obj.createdAt);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  });

  const filteredProspector = data.prospect.filter((obj: any) => {
    const createdAtDate = new Date(obj['prospectBy'].atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  });
  const filteredDeclined = data.declined.filter((obj: any) => {
    const createdAtDate = new Date(obj['declined'].atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  });
  const filteredDeadLeads = data.deadLead.filter((obj: any) => {
    const createdAtDate = new Date(obj['deadLead'].atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  });
  const filteredTours = data.tour.filter((obj: any) => {
    const createdAtDate = new Date(obj['scheduleATour'].atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  });
  const filteredLeads = data.leads.filter((obj: any) => {
    const createdAtDate = new Date(obj['leadBy'].atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  });
  const filteredDirectlyConvertedToLeads = data.leads.filter((obj: any) => {
    return !obj['prospectBy']['checked'];
  });
  const filteredOffers = data.offer.filter((obj: any) => {
    const createdAtDate = obj['ownerOffer']?.atTime
      ? new Date(obj['ownerOffer']?.atTime)
      : new Date(obj['prospectOffer']?.atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  });
  const dateCountSent = filteredEmailSent.reduce((acc: any, curr: any) => {
    const createdAt = getFormattedDate(curr.createdAt);
    // extract the date part of the createdAt value
    if (acc[createdAt]) {
      acc[createdAt]++;
    } else {
      acc[createdAt] = 1;
    }
    return acc;
  }, {});

  const dateCountOpened = filteredEmailSent
    .filter((obj: any) => obj.messageRead === true)
    .reduce((acc: any, curr: any) => {
      const createdAt = getFormattedDate(curr.createdAt); // extract the date part of the createdAt value
      if (acc[createdAt]) {
        acc[createdAt]++;
      } else {
        acc[createdAt] = 1;
      }
      return acc;
    }, {});
  const dateCountProspect = filteredProspector.reduce((acc: any, curr: any) => {
    const createdAt = getFormattedDate(curr['prospectBy'].atTime); // extract the date part of the updated value
    if (acc[createdAt]) {
      acc[createdAt]++;
    } else {
      acc[createdAt] = 1;
    }
    return acc;
  }, {});
  const dateCountDeclined = filteredDeclined.reduce((acc: any, curr: any) => {
    const createdAt = getFormattedDate(curr['declined'].atTime); // extract the date part of the updated value
    if (acc[createdAt]) {
      acc[createdAt]++;
    } else {
      acc[createdAt] = 1;
    }
    return acc;
  }, {});

  const dateCountLeads = filteredLeads.reduce((acc: any, curr: any) => {
    const createdAt = getFormattedDate(curr['leadBy'].atTime); // extract the date part of the updated value
    if (acc[createdAt]) {
      acc[createdAt]++;
    } else {
      acc[createdAt] = 1;
    }
    return acc;
  }, {});

  const dateCountOffers = filteredOffers.reduce((acc: any, curr: any) => {
    const createdAt = curr['ownerOffer']?.atTime
      ? getFormattedDate(curr['ownerOffer'].atTime)
      : getFormattedDate(curr['prospectOffer'].atTime); // extract the date part of the updated value
    if (acc[createdAt]) {
      acc[createdAt]++;
    } else {
      acc[createdAt] = 1;
    }
    return acc;
  }, {});

  const dateCountTour = filteredTours.reduce((acc: any, curr: any) => {
    const createdAt = getFormattedDate(curr['scheduleATour'].atTime); // extract the date part of the updated value
    if (acc[createdAt]) {
      acc[createdAt]++;
    } else {
      acc[createdAt] = 1;
    }
    return acc;
  }, {});

  const dateCountDeadLeads = filteredDeadLeads.reduce((acc: any, curr: any) => {
    const createdAt = getFormattedDate(curr['deadLead'].atTime); // extract the date part of the updated value
    if (acc[createdAt]) {
      acc[createdAt]++;
    } else {
      acc[createdAt] = 1;
    }
    return acc;
  }, {});

  const keysSent = Object.keys(dateCountSent);

  const emailSentDateLabel = getDatesInRange(
    getFormattedDate(startDate),
    getFormattedDate(endDate)
  );

  let emailSentDateValue: any[] = [];
  emailSentDateLabel.forEach((el: any) => {
    if (dateCountSent[el]) {
      emailSentDateValue.push(dateCountSent[el]);
    } else {
      emailSentDateValue.push(0);
    }
  });

  let emailOpenedDateValue: any[] = [];
  emailSentDateLabel.forEach((el: any) => {
    if (dateCountOpened[el]) {
      emailOpenedDateValue.push(dateCountOpened[el]);
    } else {
      emailOpenedDateValue.push(0);
    }
  });

  let prospectDateValue: any[] = [];
  emailSentDateLabel.forEach((el: any) => {
    if (dateCountProspect[el]) {
      prospectDateValue.push(dateCountProspect[el]);
    } else {
      prospectDateValue.push(0);
    }
  });

  let declinedDateValue: any[] = [];
  emailSentDateLabel.forEach((el: any) => {
    if (dateCountDeclined[el]) {
      declinedDateValue.push(dateCountDeclined[el]);
    } else {
      declinedDateValue.push(0);
    }
  });

  let deadLeadsDateValue: any[] = [];
  emailSentDateLabel.forEach((el: any) => {
    if (dateCountDeadLeads[el]) {
      deadLeadsDateValue.push(dateCountDeadLeads[el]);
    } else {
      deadLeadsDateValue.push(0);
    }
  });

  let leadsDateValue: any[] = [];
  emailSentDateLabel.forEach((el: any) => {
    if (dateCountLeads[el]) {
      leadsDateValue.push(dateCountLeads[el]);
    } else {
      leadsDateValue.push(0);
    }
  });

  let toursDateValue: any[] = [];
  emailSentDateLabel.forEach((el: any) => {
    if (dateCountTour[el]) {
      toursDateValue.push(dateCountTour[el]);
    } else {
      toursDateValue.push(0);
    }
  });

  let offersDateValue: any[] = [];
  emailSentDateLabel.forEach((el: any) => {
    if (dateCountOffers[el]) {
      offersDateValue.push(dateCountOffers[el]);
    } else {
      offersDateValue.push(0);
    }
  });

  const emailOpened = filteredEmailSent.filter((obj: any) => obj.messageRead === true);
  const uniqueBusiness = [...new Set(filteredEmailSent.map((obj: any) => obj.businessId))];
  const uniqueOpened = [...new Set(emailOpened.map((item: any) => item.businessId))].length;
  // const uniqueObjs = filteredEmailSent.reduce((acc: any, obj: any) => {
  //   if (uniqueBusiness.includes(obj.businessId)) {
  //     acc.push(obj);
  //     uniqueBusiness.splice(uniqueBusiness.indexOf(obj.name), 1);
  //   }
  //   return acc;
  // }, []);
  // source data extraction
  const storage = new Storage();
  const projectId = storage.get('projectId');
  const extractLeadCounts = data.sourceData.leadCounts.flatMap((obj: any) => obj.leadCount || []);
  const filteredLeadCount = extractLeadCounts?.filter((el: any) => {
    let res = el.status.filter((obj: any) => {
      return obj.status === 'Lead' && obj.projectId === projectId;
    });
    const createdAtDate = new Date(res[0]?.atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  }).length;
  let extractProspectCounts = data.sourceData.prospectCounts.flatMap(
    (obj: any) => obj.ProspectCount || []
  );
  const filteredProspectCount = extractProspectCounts?.filter((el: any) => {
    let res = el.status.filter((obj: any) => {
      return obj.status === 'Prospect' && obj.projectId === projectId;
    });
    const createdAtDate = new Date(res[0]?.atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  }).length;

  return {
    emailSent: filteredEmailSent.length,
    emailOpened: emailOpened.length,
    emailOpenedPercentage:
      filteredEmailSent.length > 0 &&
      ((emailOpened.length / filteredEmailSent.length) * 100).toFixed(1),
    uniqueSent: uniqueBusiness.length,
    uniqueOpened: uniqueOpened,
    uniqueOpenedPercentage:
      uniqueBusiness.length > 0 && ((uniqueOpened / uniqueBusiness.length) * 100).toFixed(1),
    prospect: filteredProspector.length + filteredProspectCount,
    leads: filteredLeads.length + filteredLeadCount,
    offer: filteredOffers.length,
    tour: filteredTours.length,
    declined: filteredDeclined.length,
    deadlead: filteredDeadLeads.length,
    totalRunningLeads: data.leads.length,
    totalRunningProspects: filteredDirectlyConvertedToLeads.length + data.prospect.length,
    lineChartLabel: emailSentDateLabel,
    lineChartValue: emailSentDateValue,
    lineChartValueOpened: emailOpenedDateValue,
    lineChartValueProspect: prospectDateValue,
    lineChartValueDeclined: declinedDateValue,
    lineChartValueDeadLeads: deadLeadsDateValue,
    lineChartValueLeads: leadsDateValue
  };
};
export const getProspectorComparionMetrics = (data: any, members: [], selectedMember: any) => {
  const selectedMemberDetails: any = members.find((obj: any) => obj['name'] === selectedMember);

  const emailSentByMember = data.emailSent.filter(
    (obj: any) => obj['user'] === selectedMemberDetails['_id']
  );

  const emailSentByEachIndidual = members.map((item: any) => {
    return {
      name: item['name'],
      emailSent: data.emailSent.filter((obj: any) => obj['user'] === item['_id'])
    };
  });
  const filteredProspectorByEachIndidual = members.map((item: any) => {
    return {
      name: item['name'],
      prospect: data.prospect.filter((obj: any) => obj['prospectBy']['userChecked'] === item['_id'])
    };
  });
  const filteredLeadByEachIndidual = members.map((item: any) => {
    return {
      name: item['name'],
      lead: data.leads.filter((obj: any) => obj['leadBy']['userChecked'] === item['_id'])
    };
  });
  const filteredTourByEachIndidual = members.map((item: any) => {
    return {
      name: item['name'],
      tour: data.tour.filter((obj: any) => obj['scheduleATour']['userChecked'] === item['_id'])
    };
  });
  const filteredOfferByEachIndidual = members.map((item: any) => {
    return {
      name: item['name'],
      offer: data.offer.filter(
        (obj: any) =>
          obj['ownerOffer']?.userChecked === item['_id'] ||
          obj['prospectOffer']?.userChecked === item['_id']
      )
    };
  });

  const filteredDeadLeadsByEachIndidual = members.map((item: any) => {
    return {
      name: item['name'],
      deadLead: data.deadLead.filter((obj: any) => obj['deadLead']['userChecked'] === item['_id'])
    };
  });
  const filteredDeclinedByEachIndidual = members.map((item: any) => {
    return {
      name: item['name'],
      declined: data.declined.filter((obj: any) => obj['declined']['userChecked'] === item['_id'])
    };
  });
  const emailOpenedByEachIndidual = members.map((item: any) => {
    return {
      name: item['name'],
      emailOpened: data.emailSent.filter(
        (obj: any) => obj['user'] === item['_id'] && obj.messageRead === true
      ).length
    };
  });
  const uniqueBusinessContactByEachIndividual = emailSentByEachIndidual.map((item: any) => {
    return {
      name: item['name'],
      uniqueBusiness: [...new Set(item['emailSent'].map((obj: any) => obj.businessId))].length
    };
  });
  const uniqueBusiness = [...new Set(emailSentByMember.map((obj: any) => obj.businessId))];
  const emailOpened = emailSentByMember.filter((obj: any) => obj.messageRead === true);
  const uniqueOpened = [...new Set(emailOpened.map((item: any) => item.businessId))].length;

  return {
    emailSent: emailSentByMember.length,
    emailOpened: emailOpened.length,
    emailOpenedPercentage:
      emailSentByMember.length > 0 &&
      ((emailOpened.length / emailSentByMember.length) * 100).toFixed(1),
    uniqueSent: uniqueBusiness.length,
    uniqueOpened: uniqueOpened,
    uniqueOpenedPercentage:
      uniqueBusiness.length > 0 && ((uniqueOpened / uniqueBusiness.length) * 100).toFixed(1),
    leads: filteredLeadByEachIndidual.filter((obj: any) => obj['name'] === selectedMember)[0].lead
      .length,
    prospect: filteredProspectorByEachIndidual.filter(
      (obj: any) => obj['name'] === selectedMember
    )[0].prospect.length,
    offer: filteredOfferByEachIndidual.filter((obj: any) => obj['name'] === selectedMember)[0].offer
      .length,
    tour: filteredTourByEachIndidual.filter((obj: any) => obj['name'] === selectedMember)[0].tour
      .length,
    declined: filteredDeclinedByEachIndidual.filter((obj: any) => obj['name'] === selectedMember)[0]
      .declined.length,
    deadLeads: filteredDeadLeadsByEachIndidual.filter(
      (obj: any) => obj['name'] === selectedMember
    )[0].deadLead.length,
    emailSentByEachIndidual: emailSentByEachIndidual.map((item: any) => item['emailSent'].length),
    emailOpenedByEachIndidual: emailOpenedByEachIndidual.map((item: any) => item['emailOpened']),
    uniqueBusinessContactByEachIndividual: uniqueBusinessContactByEachIndividual.map(
      (item: any) => item['uniqueBusiness']
    ),
    prospectorByEachIndidual: filteredProspectorByEachIndidual.map(
      (item: any) => item['prospect'].length
    ),
    deadLeadsByEachIndidual: filteredDeadLeadsByEachIndidual.map(
      (item: any) => item['deadLead'].length
    ),
    declinedByEachIndidual: filteredDeclinedByEachIndidual.map(
      (item: any) => item['declined'].length
    ),
    leadByEachIndidual: filteredLeadByEachIndidual.map((item: any) => item['lead'].length)
  };
};
export const getProspectorProjectComparionMetrics = (
  data: any,
  projectList: [],
  selectedProject: any
) => {
  const storage = new Storage();
  const userId = storage.get('userId');
  const emailSentInEachProject = projectList.map((item: any) => {
    return {
      projectName: item['projectName'],
      emailSent: data.emailSent.filter(
        (obj: any) => obj['projectId'] === item['_id'] && obj['user'] === userId
      )
    };
  });
  const emailOpenedInEachProject = emailSentInEachProject.map((item: any) => {
    return {
      projectName: item['projectName'],
      emailOpened: item.emailSent.filter((obj: any) => obj.messageRead === true)
    };
  });
  const prospectInEachProject = projectList.map((item: any) => {
    return {
      projectName: item['projectName'],
      prospect: data.prospect.filter(
        (obj: any) =>
          obj['projectId'] === item['_id'] && obj['prospectBy']['userChecked'] === userId
      )
    };
  });
  const leadsInEachProject = projectList.map((item: any) => {
    return {
      projectName: item['projectName'],
      lead: data.leads.filter(
        (obj: any) => obj['projectId'] === item['_id'] && obj['leadBy']['userChecked'] === userId
      )
    };
  });
  const tourInEachProject = projectList.map((item: any) => {
    return {
      projectName: item['projectName'],
      tour: data.tour.filter(
        (obj: any) => obj['projectId'] === item['_id'] && obj['tour']['userChecked'] === userId
      )
    };
  });
  const offerInEachProject = projectList.map((item: any) => {
    return {
      projectName: item['projectName'],
      offer: data.offer.filter((obj: any) => {
        if (
          obj['projectId'] === item['_id'] &&
          obj['ownerOffer'] &&
          obj['ownerOffer']['userChecked'] === userId
        ) {
          return obj;
        } else if (
          obj['projectId'] === item['_id'] &&
          obj['prospectOffer'] &&
          obj['prospectOffer']['userChecked'] === userId
        ) {
          return obj;
        }
      })
    };
  });
  const declinedInEachProject = projectList.map((item: any) => {
    return {
      projectName: item['projectName'],
      declined: data.declined.filter(
        (obj: any) => obj['projectId'] === item['_id'] && obj['declined']['userChecked'] === userId
      )
    };
  });
  const deadLeadsInEachProject = projectList.map((item: any) => {
    return {
      projectName: item['projectName'],
      deadLead: data.deadLead.filter(
        (obj: any) => obj['projectId'] === item['_id'] && obj['deadLead']['userChecked'] === userId
      )
    };
  });
  const uniqueBusinessContactInEachProject = emailSentInEachProject.map((item: any) => {
    return {
      projectName: item['projectName'],
      uniqueBusiness: [...new Set(item['emailSent'].map((obj: any) => obj.businessId))]
    };
  });
  const emailSentInSelectedProject = emailSentInEachProject.filter(
    (item: any) => item['projectName'] === selectedProject
  )[0].emailSent.length;

  const emailOpenedInSelectedProject = emailOpenedInEachProject.filter(
    (item: any) => item['projectName'] === selectedProject
  )[0].emailOpened;

  const DeclinedInSelectedProject = declinedInEachProject.filter(
    (item: any) => item['projectName'] === selectedProject
  )[0].declined.length;

  const deadLeadsInSelectedProject = deadLeadsInEachProject.filter(
    (item: any) => item['projectName'] === selectedProject
  )[0].deadLead.length;

  const tourInSelectedProject = tourInEachProject.filter(
    (item: any) => item['projectName'] === selectedProject
  )[0].tour.length;

  const offersInSelectedProject = offerInEachProject.filter(
    (item: any) => item['projectName'] === selectedProject
  )[0].offer.length;

  const prospectInSelectedProject = prospectInEachProject.filter(
    (item: any) => item['projectName'] === selectedProject
  )[0].prospect.length;

  const leadsInSelectedProject = leadsInEachProject.filter(
    (item: any) => item['projectName'] === selectedProject
  )[0].lead.length;

  const uniqueBussinessContactInSelectedProject = uniqueBusinessContactInEachProject.filter(
    (item: any) => item['projectName'] === selectedProject
  )[0].uniqueBusiness.length;
  const uniqueOpenedInSelectedProject = [
    ...new Set(emailOpenedInSelectedProject.map((item: any) => item.businessId))
  ].length;
  const obj = {
    emailSent: emailSentInSelectedProject,
    emailOpened: emailOpenedInSelectedProject.length,
    emailOpenedPercentage:
      emailSentInSelectedProject > 0 &&
      ((emailOpenedInSelectedProject.length / emailSentInSelectedProject) * 100).toFixed(1),

    uniqueSent: uniqueBussinessContactInSelectedProject,
    uniqueOpened: uniqueOpenedInSelectedProject,
    uniqueOpenedPercentage:
      uniqueBussinessContactInSelectedProject > 0 &&
      ((uniqueOpenedInSelectedProject / uniqueBussinessContactInSelectedProject) * 100).toFixed(1),

    deadlead: deadLeadsInSelectedProject,
    prospect: prospectInSelectedProject,
    lead: leadsInSelectedProject,
    offer: offersInSelectedProject,
    tour: tourInSelectedProject,
    declined: DeclinedInSelectedProject,
    emailSentByEachIndidual: emailSentInEachProject.map((item: any) => item['emailSent'].length),
    emailOpenedByEachIndidual: emailOpenedInEachProject.map(
      (item: any) => item['emailOpened'].length
    ),
    uniqueBusinessContactByEachIndividual: uniqueBusinessContactInEachProject.map(
      (item: any) => item['uniqueBusiness'].length
    ),
    declinedByEachIndidual: declinedInEachProject.map((item: any) => item['declined'].length),
    deadLeadByEachIndidual: deadLeadsInEachProject.map((item: any) => item['deadLead'].length),
    prospectByEachIndidual: prospectInEachProject.map((item: any) => item['prospect'].length),

    leadByEachIndidual: leadsInEachProject.map((item: any) => item['lead'].length)
  };
  return obj;
};

export const getProjectGoalMetrics = (data: any) => {
  const emailSentToday = data.emailSent.filter((email: any) => {
    const emailSentDate = new Date(email.createdAt);
    const today = new Date();
    return emailSentDate.toDateString() === today.toDateString();
  });

  const emailSentThisWeek = data.emailSent.filter((email: any) => {
    const emailSentDate = new Date(email.createdAt);
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    return emailSentDate >= startOfWeek;
  });

  const emailSentThisMonth = data.emailSent.filter((email: any) => {
    const emailSentDate = new Date(email.createdAt);
    const startOfMonth = new Date();
    startOfMonth.setDate(1);
    return emailSentDate >= startOfMonth;
  });

  return {
    emailSentToday: emailSentToday.length,
    emailSentThisWeek: emailSentThisWeek.length,
    emailSentThisMonth: emailSentThisMonth.length
  };
};

export const getSourceMetrics = (
  data: any,
  selectedDayRange: any,
  selectedSource: any,
  allSourceData: any
) => {
  const startDate = new Date(
    `${selectedDayRange.from?.year}-${selectedDayRange.from?.month}-${selectedDayRange.from?.day}`
  );
  const endDate = new Date(
    `${selectedDayRange.to?.year}-${selectedDayRange.to?.month}-${selectedDayRange.to?.day}`
  );
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
  // The total number of days between the start and end date is
  const totalDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
  const storage = new Storage();
  const projectId = storage.get('projectId');

  let leadCounting = data.data.leadCounts.filter(
    (item: any) => item._id === selectedSource?.toLowerCase()
  )[0]?.leadCount;

  const filteredLeadCount = leadCounting?.filter((el: any) => {
    let res = el.status.filter((obj: any) => {
      return obj.status === 'Lead' && obj.projectId === projectId;
    });
    const createdAtDate = new Date(res[0]?.atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  }).length;

  let propectorCounting = data.data.prospectCounts.filter(
    (item: any) => item._id === selectedSource?.toLowerCase()
  )[0]?.ProspectCount;
  const filteredProspectCount = propectorCounting?.filter((el: any) => {
    let res = el.status.filter((obj: any) => {
      return obj.status === 'Prospect' && obj.projectId === projectId;
    });
    const createdAtDate = new Date(res[0]?.atTime);
    createdAtDate.setHours(0, 0, 0, 0);
    return createdAtDate >= startDate && createdAtDate <= endDate;
  }).length;

  let sourceCost = parseInt(
    allSourceData.filter((item: any) => item.label === selectedSource)[0]?.cost
  );

  return {
    leadCounts: filteredLeadCount ? filteredLeadCount : 0,
    leadAveragePriceCostPercetage:
      filteredLeadCount &&
      parseFloat((((sourceCost / 30) * totalDays) / filteredLeadCount).toFixed(2)),
    prospectCounts: filteredProspectCount ? filteredProspectCount : 0,
    ProspectorAveragePriceCostPercetage:
      filteredProspectCount &&
      parseFloat((((sourceCost / 30) * totalDays) / filteredProspectCount).toFixed(2)),
    res: data.data.res
  };
};
