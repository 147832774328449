import React, { useEffect, useState } from 'react';
import ReportFilter from '../../components/web-report/report-filter';
import styles from './report.module.scss';
// import CustomBarChart from '../../components/web-report/chart/BarChart';
import Storage from '../../utils/local-storage';
import { useQuery } from 'react-query';
import axios from 'axios';
import OverallReport from '../../components/web-report/overallReport';
import CircularLoader from '../../components/circular-loader';
import {
  getOverallMetrics,
  getProjectGoalMetrics,
  getProspectorComparionMetrics,
  getProspectorProjectComparionMetrics,
  getSourceMetrics
} from '../../utils/reports';
import DatePicker, { DayRange } from '@hassanmojab/react-modern-calendar-datepicker';
import ProspectorComparision from '../../components/web-report/prospectorComparision';
import ProspectorProject from '../../components/web-report/prospectorProject';
import ProjectGoal from '../../components/web-report/projectGoal';
import ListSourceReport from '../../components/web-report/listSourceReport';
interface props {
  queryClient: any;
}
const WebReport: React.FC<props> = ({ queryClient }) => {
  let date = new Date();
  const storage = new Storage();
  const [reportType, setReportType] = useState('overall');
  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>({
    from: { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() },
    to: { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }
  });
  const [selectedSourceDayRange, setSelectedSourceDayRange] = useState<DayRange>({
    from: { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() },
    to: { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }
  });
  const [overallMetrics, setOverallMetrics] = useState({});
  const [sourceMetrics, setSourceMetrics] = useState<any>({});
  const [prospectorComparisonMetrics, setProspectorComparisonMetrics] = useState<any>({});
  const [projectComparisonMetrics, setProjectComparisonMetrics] = useState<any>({});
  const [ProjectGoalMetrics, setProjectGoalMetrics] = useState<any>({});
  const [ProjectGoals, setProjectGoal] = useState<any>({});
  const [isProjectGoalSet, setIsProjectGoalSet] = useState(false);
  const [allSourceData, setAllSourceData] = useState<any>([]);

  const [selectedProspector, setSelectedProspector] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedProspectorProject, setSelectedProspectorProject] = useState();
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [projectList, setProjectList] = useState<any>([]);
  const localUserRole = storage.get('role');
  const roleValue: any = {
    owner: 2,
    admin: 1,
    prospector: 0
  };
  const getUserDetails = async () => {
    const config = {
      method: 'get',
      url: `/v1/users/${storage.get('userId')}`
    };
    return await axios(config);
  };

  const { data: userDetails, status: userDetailsStatus } = useQuery(`user`, getUserDetails, {
    onSuccess: (res: any) => {
      let members = res.data.organization[0]['team'];
      let TeamMembers = members
        .filter((el: any) => roleValue[localUserRole] >= roleValue[el.role])
        .map((item: any) => {
          return {
            name: `${item['firstName']} ${item['lastName']}`,
            _id: item['id'],
            role: item['role']
          };
        });
      setTeamMembers(TeamMembers);
    }
  });

  const projectReportDetails = async () => {
    const config = {
      method: 'get',
      url: `/v1/report/reportforuser/${storage.get('projectId')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };

    return await axios(config);
  };

  const { data: projectReportDetail, status: projectReportDetailsStatus } = useQuery(
    `projectReportdetail`,
    projectReportDetails,
    {
      onSuccess: res => {
        let allProjectObj = res.data['allProjectForUser'];
        let formattedProjectObj = allProjectObj.map((item: any) => {
          return {
            projectName: item.projectName,
            _id: item['_id']
          };
        });
        setProjectList(formattedProjectObj);
      }
    }
  );

  const getProjectList = async () => {
    const storage = new Storage();
    const config = {
      method: 'get',
      url: `/v1/project/${storage.get('organizationId')}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    return res.data;
  };
  const { data: projectList1, status: projectListStatus } = useQuery(
    `projectdetails`,
    getProjectList,
    {
      onSuccess: (res: any) => {
        let currentProject = res.filter((item: any) => item['_id'] === storage.get('projectId'));
        if (
          currentProject[0].emailGoalDay &&
          currentProject[0].emailGoalWeek &&
          currentProject[0].emailGoalYear
        ) {
          setProjectGoal({
            emailGoalSetForDay: currentProject[0].emailGoalDay,
            emailGoalSetForWeek: currentProject[0].emailGoalWeek,
            emailGoalSetForYear: currentProject[0].emailGoalYear
          });
          setIsProjectGoalSet(true);
        }
      }
    }
  );

  const reportDetails = async () => {
    const config = {
      method: 'get',
      url: `/v1/report/overall/${storage.get('projectId')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };

    return await axios(config);
  };

  const { data: reportDetail, status: reportDetailsStatus } = useQuery(
    `reportdetail`,
    reportDetails,
    {
      onSuccess: res => {
        setOverallMetrics(getOverallMetrics(res.data, selectedDayRange));
        setProjectGoalMetrics(getProjectGoalMetrics(res.data));
      }
    }
  );

  const sourceDetails = async () => {
    const config = {
      method: 'get',
      url: `/v1/report/source/${storage.get('projectId')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };

    return await axios(config);
  };

  const { data: sourceDetail, status: sourceDetailsStatus } = useQuery(
    `sourcedetail`,
    sourceDetails,
    {
      refetchOnWindowFocus: false
    }
  );

  const promiseOptions = async () => {
    const config = {
      method: 'get',
      url: `/v1/report/allSources/${storage.get('projectId')}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    let option: any = [];
    res.data.map((el: any) => {
      option.push({ label: el._id, value: el._id, cost: el.cost });
    });
    setAllSourceData(option);
  };

  useEffect(() => {
    promiseOptions();
  }, []);

  // report type - overall,

  useEffect(() => {
    if (
      reportDetailsStatus === 'success' &&
      userDetailsStatus == 'success' &&
      teamMembers.length > 0
    ) {
      if (!selectedProspector) {
        let curerrt_user = storage.get('userId');
        let curerrt_user_index = teamMembers.findIndex((item: any) => item['_id'] === curerrt_user);
        setSelectedProspector(teamMembers[curerrt_user_index]?.name);
        setProspectorComparisonMetrics(
          getProspectorComparionMetrics(reportDetail.data, teamMembers, teamMembers[0]?.name)
        );
      } else
        setProspectorComparisonMetrics(
          getProspectorComparionMetrics(reportDetail.data, teamMembers, selectedProspector)
        );
    }
  }, [selectedProspector, reportDetailsStatus, userDetailsStatus]);
  useEffect(() => {
    if (projectReportDetailsStatus === 'success' && projectList.length > 0) {
      if (!selectedProspectorProject) {
        let curerrt_project = storage.get('projectId');
        let curerrt_project_index = projectList.findIndex(
          (item: any) => item['_id'] === curerrt_project
        );
        setSelectedProspectorProject(projectList[curerrt_project_index]?.projectName);

        setProjectComparisonMetrics(
          getProspectorProjectComparionMetrics(
            projectReportDetail.data,
            projectList,
            projectList[0]?.projectName
          )
        );
      } else
        setProjectComparisonMetrics(
          getProspectorProjectComparionMetrics(
            projectReportDetail.data,
            projectList,
            selectedProspectorProject
          )
        );
    }
  }, [selectedProspectorProject, projectReportDetailsStatus]);
  useEffect(() => {
    if (reportDetailsStatus === 'success')
      setOverallMetrics(getOverallMetrics(reportDetail.data, selectedDayRange));
  }, [selectedDayRange, reportDetailsStatus]);
  useEffect(() => {
    if (sourceDetailsStatus === 'success' && allSourceData.length > 0) {
      if (!selectedSource) {
        setSelectedSource(allSourceData[0]?.label);
        setSourceMetrics(
          getSourceMetrics(
            sourceDetail,
            selectedSourceDayRange,
            allSourceData[0]?.label,
            allSourceData
          )
        );
      } else {
        setSourceMetrics(
          getSourceMetrics(sourceDetail, selectedSourceDayRange, selectedSource, allSourceData)
        );
      }
    }
  }, [selectedSource, sourceDetailsStatus, allSourceData, selectedSourceDayRange]);

  const SourceData = {
    labels: sourceDetail?.data.prospectCounts?.map((item: any) => item['_id']),
    sourceList: allSourceData,
    datasets: [
      {
        data: sourceDetail?.data.prospectCounts?.map((item: any) => item.ProspectCount.length),
        backgroundColor: '#9966FF',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#9966FF',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Prospects'
      },
      {
        data: sourceDetail?.data.leadCounts?.map((item: any) => item.leadCount.length),
        backgroundColor: '#FFCE56',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#FFCE56',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Leads'
      }
    ]
  };
  const prospectorsData = {
    labels: teamMembers.map((item: any) => item['name']),
    prospectorList: teamMembers.map((item: any) => ({
      label: item['name'],
      value: item['name'],
      role: item['role']
    })),
    datasets: [
      {
        data: prospectorComparisonMetrics?.emailSentByEachIndidual,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: 'rgb(255, 99, 132)',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Emails Sent'
      },
      {
        data: prospectorComparisonMetrics?.emailOpenedByEachIndidual,
        backgroundColor: 'rgb(53, 162, 235)',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: 'rgb(53, 162, 235)',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Emails Opened'
      },
      // {
      //   data: prospectorComparisonMetrics?.uniqueBusinessContactByEachIndividual,
      //   backgroundColor: '#b3b3b3',
      //   borderColor: 'transparent',
      //   borderWidth: 1,
      //   hoverBackgroundColor: '#b3b3b3',
      //   borderRadius: 5,
      //   pointStyle: 'circle',
      //   label: 'Unique contacts sent'
      // },
      {
        data: prospectorComparisonMetrics?.prospectorByEachIndidual,
        backgroundColor: '#9966FF',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#9966FF',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Prospects'
      },
      {
        data: prospectorComparisonMetrics?.leadByEachIndidual,
        backgroundColor: '#FFCE56',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#FFCE56',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Leads'
      },
      {
        data: prospectorComparisonMetrics?.declinedByEachIndidual,
        backgroundColor: '#FF9F40',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#FF9F40',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Declined'
      },
      {
        data: prospectorComparisonMetrics?.deadLeadsByEachIndidual,
        backgroundColor: '#80CBC4',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#80CBC4',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Dead Leads'
      }
    ]
  };
  const prospectorProjectData = {
    labels: projectList.map((item: any) => item['projectName']),
    prospectorList: projectList.map((item: any) => ({
      label: item['projectName'],
      value: item['projectName']
    })),
    datasets: [
      {
        data: projectComparisonMetrics?.emailSentByEachIndidual,
        backgroundColor: '#36A2EB',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#5F45FF',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Email Sent'
      },
      {
        data: projectComparisonMetrics?.emailOpenedByEachIndidual,
        backgroundColor: '#FF9F40',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#5F45FF',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Email Opened'
      },
      // {
      //   data: projectComparisonMetrics?.uniqueBusinessContactByEachIndividual,
      //   backgroundColor: '#9966FF',
      //   borderColor: 'transparent',
      //   borderWidth: 1,
      //   hoverBackgroundColor: '#5F45FF',
      //   borderRadius: 5,
      //   pointStyle: 'circle',
      //   label: 'Unique contact sent'
      // }
      {
        data: projectComparisonMetrics?.prospectByEachIndidual,
        backgroundColor: '#9966FF',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#9966FF',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Prospects'
      },
      {
        data: projectComparisonMetrics?.leadByEachIndidual,
        backgroundColor: '#FFCE56',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#FFCE56',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Leads'
      },
      {
        data: projectComparisonMetrics?.declinedByEachIndidual,
        backgroundColor: '#FF9F40',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#FF9F40',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Declined'
      },
      {
        data: projectComparisonMetrics?.deadLeadByEachIndidual,
        backgroundColor: '#80CBC4',
        borderColor: 'transparent',
        borderWidth: 1,
        hoverBackgroundColor: '#80CBC4',
        borderRadius: 5,
        pointStyle: 'circle',
        label: 'Dead Leads'
      }
    ]
  };
  const getChartsBasedOnReportType = (type: any) => {
    if (type === 'overall')
      return <OverallReport overallMetrics={overallMetrics} selectedDayRange={selectedDayRange} />;
    if (type === 'prospectorComparision')
      return (
        <ProspectorComparision
          overallMetrics={prospectorComparisonMetrics}
          prospectorsData={prospectorsData}
        />
      );
    if (type === 'prospectorProject')
      return (
        <ProspectorProject
          overallMetrics={projectComparisonMetrics}
          prospectorsData={prospectorProjectData}
        />
      );
    if (type === 'source')
      return <ListSourceReport overallMetrics={sourceMetrics} allSourceData={SourceData} />;
    if (type === 'goal')
      return (
        <ProjectGoal
          overallMetrics={{ ...ProjectGoalMetrics, ...ProjectGoals }}
          isProjectGoalSet={isProjectGoalSet}
          queryClient={queryClient}
        />
      );
  };

  return (
    <>
      {reportDetailsStatus === 'loading' ? (
        <div
          style={{
            width: '100%',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularLoader />
        </div>
      ) : (
        <div className={styles.main_wrapper}>
          <div className={styles.filter}>
            <ReportFilter
              reportType={reportType}
              setReportType={setReportType}
              selectedDayRange={selectedDayRange}
              setSelectedDayRange={setSelectedDayRange}
              prospectorsList={prospectorsData.prospectorList.filter(
                (el: any) => roleValue[localUserRole] >= roleValue[el.role]
              )}
              selectedSource={selectedSource}
              setSelectedSource={setSelectedSource}
              setSelectedProspector={setSelectedProspector}
              selectedReportType={reportType}
              prospectorsProjectList={prospectorProjectData.prospectorList}
              setSelectedProspectorProject={setSelectedProspectorProject}
              selectedProspectorProject={selectedProspectorProject}
              selectedProspector={selectedProspector}
              isProjectGoalSet={isProjectGoalSet}
              allSourceData={allSourceData}
              queryClient={queryClient}
              promiseOptions={promiseOptions}
              selectedSourceDayRange={selectedSourceDayRange}
              setSelectedSourceDayRange={setSelectedSourceDayRange}
            />
          </div>

          {getChartsBasedOnReportType(reportType)}
        </div>
      )}
    </>
  );
};

export default WebReport;
