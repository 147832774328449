import React, { useState } from 'react';

import styles from './index.module.scss';
import { props } from './model';
import WrappedButton from '../../components/form-elements/buttons/index';
import Storage from '../../utils/local-storage';

const SuccessMessage: React.FunctionComponent<props> = ({}) => {
  const storage = new Storage();
  const [name, setName] = useState(storage.get('bookDemoName'));
  const [email, setEmail] = useState(storage.get('bookDemoEmail'));

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.emoji}>
          <img src='/images/success-emoji.svg' />
        </div>

        <div className={styles.thankstext}>
          Thank you for requesting a demo so you can start <br /> prospecting the{' '}
          <span className={styles.reach}>Reach</span>
          <span className={styles.tenants}>Tenants</span> way!
        </div>

        <div className={styles.smalltext}>
          We will be in touch to test drive ReachTenants with you.
        </div>

        <div
          className={styles.btn}
          // onClick={window.open('https://schedule.nylas.com/reachtenants', '_blank')}
        >
          <WrappedButton
            btnText='Book a Demo'
            className={styles.av}
            onClick={() =>
              window.open(
                `https://calendly.com/reachtenants1/30min?email=${email}&name=${name}`,
                '_blank'
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default SuccessMessage;
