import React, { useContext } from 'react';
import styles from './new-password.module.scss';
import { Formik, Form, Field } from 'formik';
import { props } from './model';
import WrappedButton from '../../components/form-elements/buttons/index';
import * as yup from 'yup';
import { BTN_PRIMARY } from '../../constants/button-constants';
import PasswordField from '../../components/form-elements/password-field';
import { useQuery, useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import AppConfig from '../../config';
import Storage from '../../utils/local-storage';
import { ProjectContext } from '../../context/ProjectContext';
import { useSnackbar } from 'notistack';

const NewPasswordContainer: React.FC<props> = ({}) => {
  const storage = new Storage();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { userData, setUserData } = useContext(ProjectContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const { token, setToken } = useContext(ProjectContext);
  const postPassword = async (data: any) => {
    const requestData = JSON.stringify({
      password: data.newPassword,
      token: searchParams.get('token')
    });

    const config = {
      method: 'patch',
      url: `${BASE_URL}/v1/users/new-password`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: requestData
    };

    const { data: res } = await axios(config);
    let requestBody = { ...res };
    return requestBody;
  };
  const { status: mutationStatus, mutateAsync: setPassword } = useMutation(postPassword, {
    onSuccess: data => {
      storage.save('accessToken', data.tokens.access.token);
      storage.save('email', data.user.email);
      storage.save('role', data.user.role);
      storage.save('userId', data.user.id);
      storage.save('ownerUserId', data.organization[0].owner);
      storage.save('userName', `${data.user.firstName} ${data.user.lastName}`);
      storage.save('organizationId', data.organization[0]._id);
      setUserData(data.user);
      setToken(data.tokens.access.token);
      navigate('/settings');
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });

  //   if (!searchParams.has('token')) {
  //     navigate('/login');
  //   }
  const setError = () => {};

  const onSubmitPassword = (data: any) => {
    if (data.newPassword === data.confirmPassword) {
      setPassword(data);
    }
  };

  return (
    <>
      <div className={styles.form_wrapper}>
        <div className={styles.heading}>Set Password</div>

        <div className={styles.sub_heading}>
          Password must contain at least 1 letter, 1 number
          <br />
          and 1 symbol. Minimum length is 8 characters
        </div>

        <Formik
          initialValues={{
            newPassword: '',
            confirmPassword: ''
          }}
          onSubmit={values => {
            onSubmitPassword(values);
          }}
          validationSchema={yup.object({
            newPassword: yup
              .string()
              .required('Password is required')
              // .min(8, 'Password must be at least 8 characters')
              .matches(
                /^(?=.*?[A-Za-z]){1,}(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
                'Password must contain atleast 1 letter, 1 number and 1 symbol. Minimum length is 8 characters'
              ),
            confirmPassword: yup
              .string()
              .required('Password is required')
              .oneOf([yup.ref('newPassword')], 'Passwords must match')
          })}
          enableReinitialize={true}
          validateOnChange={true}
        >
          {props => (
            <Form>
              <div className={styles.form_container}>
                <div className={styles.form_field}>
                  <Field
                    name='newPassword'
                    placeholder={'Enter Password'}
                    title={'Enter Password'}
                    type={'text'}
                    component={PasswordField}
                  />
                </div>

                <div className={styles.form_field}>
                  <Field
                    name='confirmPassword'
                    placeholder={'Confirm Password'}
                    title={'Confirm Password'}
                    type={'text'}
                    component={PasswordField}
                  />
                </div>

                <div className={styles.button_wrapper}>
                  <WrappedButton
                    btnText='Sign in'
                    type='submit'
                    btnType={BTN_PRIMARY}
                    className={styles.submit_button}
                    isInProgress={mutationStatus === 'loading' ? true : false}
                  />
                </div>

                <div className={styles.terms_and_policy}>
                  By signing in, you agree to our{' '}
                  <span className={styles.cta_link}>Terms of Use</span> and{' '}
                  <span className={styles.cta_link}>Privacy Policy</span>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default NewPasswordContainer;
