export const dashboardTableCustomStyles = {
  tableWrapper: {
    style: {
      width: '100%'
    }
  },
  table: {
    style: {
      width: '100%',
      background: '#fafafa'
    }
  },
  headRow: {
    style: {
      borderRadius: '8px',

      borderBottomWidth: '0px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      /* identical to box height */
      textTransform: 'uppercase',
      color: '#667584',
      zIndex: 0
    }
  },
  rows: {
    style: {
      minHeight: '58px',
      border: '0px',
      margin: '4px 0px',
      borderRadius: '8px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '17px',
      /* identical to box height */

      letterSpacing: '0.01em'
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px', // override the cell padding for head cells
      paddingRight: '8px',
      background: '#F1F4FF'
    }
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px'
    }
  }
};
export const conditionalRowStyles = [
  {
    when: (row: any) => {
      return row.businessType === 'national';
    },
    style: (row: any) => {
      if (row.chainType === 'MBC') {
        return { borderLeft: '6px solid rgb(255 44 80)' };
      }
      if (row.chainType === 'MUF') {
        return { borderLeft: '6px solid rgb(0 170 255)' };
      }
      if (row.chainType === 'MBF') {
        return { borderLeft: '6px solid rgb(123 57 255)' };
      }
    }
  }
];
export const customStyles = {
  dropdownIndicator: (base: any) => ({
    ...base
  }),
  control: (base: any) => ({
    ...base,
    // :'80px',
    height: '35px',
    minHeight: '35px',
    minWidth: '130px',
    maxWidth: '145px',
    cursor: 'pointer',
    fontSize: '12px',
    fontFamily: 'Lato'
  }),
  placeholder: (base: any) => ({
    ...base,
    fontFamily: 'Lato',
    fontSize: '12px'
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: 'Lato',
    fontSize: '12px'
  })
};
export const OriginalFilter = {
  state: [],
  zipCode: [],
  category: '',
  subcategory: [],
  groupBy: '',
  groupCompany: '',
  minDistance: 0,
  maxDistance: 100,
  minPreferredSize: 0,
  maxPreferredSize: 40,
  note: '',
  Emails: '',
  Inquiries: '',
  Leads: '',
  Interested: '',
  EmailSent: '',
  Called: '',
  savedSearches: '',
  emailAddress: '',
  PreferredSQFT: '',
  LinkedInAddress: '',
  ChainStores: '',
  MultiUnitFranchisee: '',
  MultiBrandOperator: '',
  ExpansionToStateOfProject: ''
};
