import React from 'react';
import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './about-us.module.scss';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.landing_page_wrapper}>
        <div className={styles.max_width}>
          <HeaderLanding />

          <div className={styles.content_wrapper}>
            <div className={styles.heading_cover_image}>
              <div className={styles.heading}>About</div>
              <img src='aboutUs-Eclipse1.svg' alt='' className={styles.eclipseOne} />
              <img src='aboutUs-Eclipse2.svg' alt='' className={styles.eclipseTwo} />

              {isMobile ? (
                <img src='/about-us-mobile.png' alt='About Us' className={styles.aboutImage} />
              ) : (
                <img src='/Mask group.svg' alt='About Us' className={styles.aboutImage} />
              )}
            </div>

            <div className={styles.content}>
              After spending over two years working with a world class team of software developers
              to create a program in a completely different field, it highlighted for us just how
              archaic commercial real estate prospecting is and how much would be gained by
              implementing smart automation.
              <br />
              <br />
              Having a combined 50+ years’ experience in business, prospecting commercial real
              estate sales, leasing, law and development, the co-founders set out to eliminate
              common, tedious, and repetitive tasks by harnessing the power of smart automation and
              build a program to reach tenants faster with all the bells and whistles of expensive
              programs in other industries without the big price tag.
              <br />
              <br />
              The goal is to create a powerful, yet simple and intuitive program uniquely designed
              to identify the most likely tenants as faster and cost effectively as possible. By
              design, they would remain the focus, get critical attention, and follow-up; thereby
              allowing the prospector to seize the opportunity to close the most valuable
              transactions at the most opportune times.
              <br />
              <br />
              Welcome to ReachTenants. Welcome to the 21st century in commercial real estate
              prospecting.
              <br />
            </div>

            <div className={styles.man_behind}>
              <img src='/abc.png' alt='' width={292} />

              <div style={{ padding: `${isMobile && '0px 20px'}`, textAlign: 'justify' }}>
                <div className={styles.man_behind_heading}>
                  Why{' '}
                  <span
                    style={{
                      color: 'rgba(216, 31, 31, 1)'
                    }}
                  >
                    Reach
                  </span>
                  <span
                    style={{
                      color: 'rgba(25, 45, 115, 1)'
                    }}
                  >
                    Tenants
                  </span>
                </div>
                <div className={styles.man_behind_content}>
                  We at ReachTenants believe everything we do must take into account that everyone's
                  time matters. Therefore, no tasks should exist where the outcome can be achieved
                  in a simpler fashion, at a lower cost or with better results.
                  <br />
                  <br />
                  We believe everything we do must be focused on providing the greatest user
                  experience. We recognize that everyone's time and money are important and neither
                  can be wasted.
                  <br />
                  <br />
                  ReachTenants helps you see more possibilities faster, so you can compare options,
                  and eliminate those businesses that are not interested so you can rent your
                  available space to the right business on the the best terms possible, all while
                  saving time and money in the process.
                </div>
              </div>
            </div>
          </div>

          <div className={styles.bottom_page_container}>
            <div className={styles.cta_wrapper}>
              <img src='aboutus.jpg' className={styles.cta_image} alt=''></img>

              <div className={styles.cta_details}>
                <div className={styles.cta_detail_heading}>
                  The most effective way to reach your next tenant
                </div>

                <div
                  className={styles.cta_button_wrapper}
                  onClick={() => navigate('/request-demo')}
                >
                  Book a Demo
                </div>
              </div>
            </div>

            <FooterLanding />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
