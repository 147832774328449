import React, { useState } from 'react';
import { useQuery } from 'react-query';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import HeaderLanding from '../../components/core/layout/header-landing';
import FooterLanding from '../../components/core/layout/footer-landing';
import AppConfig from '../../config';
import styles from './faq-screen.module.scss';

const FAQScreen = () => {
  const [open, setOpen] = useState(0);
  const BASE_URL = AppConfig.API_BASE_URL;

  const fetchFAQ = async () => {
    const res = await fetch(`${BASE_URL}/v1/content?type=faq`);
    return res.json();
  };
  const { data: faqList, status: faqListStatus } = useQuery(`faq`, fetchFAQ);

  const accordianData = [
    {
      title: 'Is ReachTenants a list company or an email blaster?',
      description: `No. ReachTenants is an all-in-one prospecting system which includes: 
        A data rich list or relevant local contacts. 
        Access to national expansion tenants 
        A dynamic system for quickly sending personalized emails and automatically
        sending follow up emails. 
        A tracking system to always know what has been done and what needs to be done next. 
        Prospectors can create a team with all team members having access to the same information. 
        ReachTenants platform makes the prospecting process much faster, more reliable,
        completely trackable and cost effective.`
    },
    {
      title: 'What is the difference between a project and an organization?',
      description:
        'An organization is a group of people who work together to achieve a common goal. A project is a temporary endeavour undertaken to create a unique product, service or result.'
    }
  ];

  const toggle = (id: React.SetStateAction<number>) => {
    if (open === id) {
      setOpen(0);
    } else {
      setOpen(id);
    }
  };

  return (
    <>
      <div className={styles.page_wrapper}>
        <div className={styles.faq_page_wrapper}>
          <div className={styles.max_width}>
            <HeaderLanding />
            <div className={styles.faq_header}>
              <div className={styles.faq_header_title}>Frequently asked questions</div>
              <div className={styles.faq_header_sub}>Have questions? We’re here to help </div>
            </div>

            <div className={styles.faq_accordian}>
              {faqListStatus === 'success' &&
                faqList.map((item: { question: String; answer: String }, index: any) => (
                  <Accordion
                    disableGutters
                    key={index}
                    sx={{ backgroundColor: '#F7F4F2', boxShadow: 'none' }}
                    expanded={open === index + 1}
                    onChange={() => toggle(index + 1)}
                    square={true}
                  >
                    <AccordionSummary
                      className={styles.faq_accordian_item_header}
                      expandIcon={
                        open === index + 1 ? (
                          <img src='images/minus.svg' alt='arrow-down' />
                        ) : (
                          <img src='images/plus.svg' alt='arrow-up' />
                        )
                      }
                    >
                      <div className={styles.faq_accordian_item_header_title}>{item.question}</div>
                    </AccordionSummary>
                    <AccordionDetails className={styles.faq_accordian_item_body}>
                      <div className={styles.faq_accordian_item_body_description}>
                        {item.answer}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
            <div className={styles.bottom_page_container}>
              <div className={styles.cta_wrapper}>
                <div className={styles.cta_details}>
                  <div className={styles.cta_detail_heading}>Still have questions?</div>

                  <div className={styles.cta_detail_description}>
                  Can't find the answer you're looking for? Please email us at support@reachtenants.com
                  </div>
                </div>
              </div>

              <FooterLanding />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQScreen;
