import { isLocallyServed } from '../utils/appHelper';

let devConfig = require('./dev.json');
let localConfig = require('./local.json');
let prodConfig = require('./production.json');

console.log('🔥 Environment is ' + process.env.REACT_APP_STAGE);

let appVar =
  process.env.REACT_APP_STAGE === 'production'
    ? prodConfig
    : isLocallyServed()
    ? localConfig
    : devConfig;

const AppConfig = {
  ...appVar
};
export default AppConfig;
