import React from 'react';
import Select from 'react-select';
import styles from './statistic.module.scss';

interface SingleValueCardProp {
  image: string;
  count: string;
  description: string;
  background: string;
  percentage?: any;
}

const SingleValueCard = (props: SingleValueCardProp) => {
  return (
    <>
      <div className={styles.card_wrapper}>
        <div
          style={{
            backgroundColor: props.background,
            width: '42px',
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0px 20px',
            borderRadius: '50%'
          }}
        >
          <img src={props.image} className={styles.image} />
        </div>
        <div>
          <div className={styles.count}>
            {props.count}{' '}
            {props.percentage ? (
              <span className={styles.count_percentage}>{props.percentage}</span>
            ) : (
              ''
            )}
          </div>
          <div className={styles.description}>{props.description}</div>
        </div>
      </div>
    </>
  );
};

export default SingleValueCard;
