import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './add-team-member.module.scss';
import { Modal, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

const EditBusinessDetailModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  queryClient,
  data
}) => {
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();

  const updateBusinessRequestHandler = async (data: any) => {
    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/updateRequest`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios(config);
  };

  const { status: updateBusiness, mutateAsync: updateBusinessDetails } = useMutation(
    updateBusinessRequestHandler,
    {
      onSuccess: () => {
        setShowModal(false);
        // queryClient.invalidateQueries(`userdetail`);
        enqueueSnackbar('Update Request Sent', { variant: 'success', header: 'Success' });
      },
      onError: (err: any) => {
        let message = err.response.data.message;
        enqueueSnackbar(message, { variant: 'error', header: 'Error' });
      }
    }
  );

  const FORM_CONSTANT: any = [
    {
      name: 'businessName',
      placeholder: 'Business name'
    },
    {
      name: 'firstName',
      placeholder: 'First name'
    },
    {
      name: 'lastName',
      placeholder: 'Last name'
    },
    {
      name: 'title',
      placeholder: 'Title'
    },
    {
      name: 'address',
      placeholder: 'Address'
    },
    {
      name: 'territory',
      placeholder: 'Territory'
    },
    {
      name: 'phone',
      placeholder: 'Phone'
    },
    {
      name: 'email',
      placeholder: 'Email'
    },
    {
      name: 'zipCode',
      placeholder: 'Zip code'
    },
    {
      name: 'lat',
      placeholder: 'Latitude'
    },
    {
      name: 'long',
      placeholder: 'Longitude'
    },
    // {
    //   name: 'county',
    //   placeholder: 'County'
    // },
    {
      name: 'category',
      placeholder: 'Category'
    },
    {
      name: 'subcategory1',
      placeholder: 'Subcategory 1'
    },
    {
      name: 'subcategory2',
      placeholder: 'Subcategory 2'
    },
    {
      name: 'subcategory3',
      placeholder: 'Subcategory 3'
    },
    {
      name: 'subcategory4',
      placeholder: 'Subcategory 4'
    },
    {
      name: 'franchise',
      placeholder: 'Franchise'
    },
    {
      name: 'chainStore',
      placeholder: 'Chain Store'
    },
    {
      name: 'linkedin',
      placeholder: 'LinkedIn'
    },
    {
      name: 'url',
      placeholder: 'URL'
    },
    {
      name: 'locationSales',
      placeholder: 'Location sales'
    },
    {
      name: 'prefix',
      placeholder: 'Prefix'
    },
    {
      name: 'city',
      placeholder: 'City'
    },
    {
      name: 'numberEmployees',
      placeholder: 'Number of Employees'
    }
  ];
  const getInitialvalues = (data: any) => {
    const valueObj: any = {};
    FORM_CONSTANT.map((formItem: any) => {
      Object.assign(valueObj, { [formItem.name]: data[formItem.name] });
    });

    return valueObj;
  };
  const onSubmit = (values: any) => {
    updateBusinessDetails({ ...values, businessUpdated: data._id });
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '649px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        >
          Update Business details
        </ModalHeader>

        <Formik
          initialValues={getInitialvalues(data)}
          // validationSchema={yup.object({
          //   businessName: yup
          //     .string()
          //     .trim()
          //     .required('Business Name is required')
          //     .matches(/^[a-zA-Z ]*$/, 'Business Name must contain only alphabets'),
          //   firstName: yup
          //     .string()
          //     .trim()
          //     .required('First Name is required')
          //     .matches(/^[a-zA-Z ]*$/, 'First Name must contain only alphabets'),
          //   lastName: yup
          //     .string()
          //     .trim()
          //     .required('Last Name is required')
          //     .matches(/^[a-zA-Z ]*$/, 'Last Name must contain only alphabets'),
          //   email: yup.string().trim().required('Email is required').email('Email is invalid')
          // })}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {props => (
            //TODO: Add member form validation
            <Form>
              <div className={styles.form_wrapper}>
                {FORM_CONSTANT.map((formElement: any, i: any) => {
                  return (
                    <div className={styles.single_field} key={i}>
                      <Field
                        name={formElement.name}
                        placeholder={formElement.placeholder}
                        component={CustomisedTextField}
                      />
                    </div>
                  );
                })}

                <div className={styles.button_wrapper}>
                  <WrappedButton
                    btnText='Cancel'
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setShowModal(!showModal);
                    }}
                    btnType={BTN_SECONDARY}
                    className={styles.submit_button}
                  />
                  <WrappedButton
                    btnText={'Save Change'}
                    btnType={BTN_PRIMARY}
                    isDisabled={!(props.isValid && props.dirty)}
                    className={styles.submit_button}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default EditBusinessDetailModal;
