import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './set-reminder.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, GREY_BUTTON } from '../../../constants/button-constants';
import AppConfig from '../../../config';
import { useSnackbar } from 'notistack';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import * as yup from 'yup';
import Storage from '../../../utils/local-storage';
import HttpHelperWithoutAuth from '../../../utils/http-helper-without-auth';
import { CALCULATORS } from '../../../constants/calculators';

const CalculatorSave: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  selectedCalc,
  id,
  formValues
}) => {
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();
  const [showSuccess, setShowSuccess] = useState(false);
  useEffect(() => {
    setShowSuccess(false);
  }, []);

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowSuccess(false);
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '649px', width: '100%', zIndex: '999999999 !important' }}
      >
        {showSuccess ? (
          <div className={styles.succCheck}>
            <img src='images/successCheck.svg' alt='' width={93} />
            <h2>Results have been sent to your email</h2>
            <p>Please open your email and download the results</p>
          </div>
        ) : (
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={yup.object({
              email: yup.string().trim().required('Email address is required')
            })}
            onSubmit={async values => {
              console.log('submitted');
              const { postRequest } = new HttpHelperWithoutAuth();
              let cal_sub;
              if (id === 7) {
                cal_sub = CALCULATORS.find(obj => obj.id === id)?.name;
              } else {
                cal_sub = CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc].name;
              }
              await postRequest(`${BASE_URL}/v1/calculator/`, {
                data: formValues,
                calculator_type: id,
                email: values.email,
                calculator_subcategory: cal_sub
              });
              setShowSuccess(true);
            }}
          >
            {props => (
              <Form>
                <ModalHeader
                  toggle={() => {
                    setShowModal(!showModal);
                  }}
                >
                  Enter email id
                </ModalHeader>
                <ModalBody>
                  <div className={styles.form_wrapper}>
                    <div className={styles.single_field}>
                      <Field
                        name='email'
                        placeholder='Add email id'
                        component={CustomisedTextField}
                        required={true}
                      />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className={styles.button_wrapper}>
                    <WrappedButton
                      btnText='Cancel'
                      onClick={(e: React.FormEvent<HTMLInputElement>) => {
                        e.preventDefault();
                        setShowModal(!showModal);
                      }}
                      btnType={GREY_BUTTON}
                      className={styles.submit_button}
                    />
                    <WrappedButton
                      btnText={'Save'}
                      btnType={BTN_PRIMARY}
                      className={styles.submit_button}
                    />
                  </div>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

export default CalculatorSave;
