import React, { useEffect, useState } from 'react';
import { props } from './model';
import styles from './table-setting.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';
// import { Draggable } from 'react-drag-reorder';
import { deepCopy } from '../../../utils/deep-copy';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack';

const TableSettingModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  columns,
  setColumns,
  hiddenColumns,
  setHiddenColumns,
  projectType
  //   queryClient
}) => {
  const [columnCopy, setColumnCopy] = useState(columns);
  const hiddenColumnCopy = deepCopy(hiddenColumns);
  const [checked, setChecked] = useState(false);
  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  const [localHidden, setLocalHidden] = useState<any>(hiddenColumns);
  // const getChangedPos = (currentPos: any, newPos: any) => {
  //   newColumns.splice(newPos, 0, newColumns.splice(currentPos, 1)[0]);
  // };
  const { enqueueSnackbar } = useSnackbar();

  const CustomizeColumnHandler = async (data: any) => {
    let requestData: any = {};
    requestData = {
      projectId: storage.get('projectId'),
      orderForLocal: data
    };
    if (projectType === 'National')
      requestData = { projectId: storage.get('projectId'), orderForNational: data };
    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/users/user-column-layout/${storage.get('userId')}`,
      data: requestData
    };

    await axios(config);
  };

  const { status: CustomizeColumnStatus, mutateAsync: CustomizeColumn } = useMutation(
    CustomizeColumnHandler,
    {
      onSuccess: () => {
        setChecked(false);

        setShowModal(false);
        //   queryClient.invalidateQueries(`userdetails`);
      }
    }
  );
  useEffect(() => {
    setLocalHidden(hiddenColumns);
  }, [hiddenColumns]);
  useEffect(() => {
    setColumnCopy(columns);
  }, [columns]);

  const onSubmit = () => {
    // deleteMember();
    setHiddenColumns(localHidden);
    setColumns(columnCopy);

    if (checked) {
      let data = columnCopy
        .filter((item: any) => {
          if (!localHidden.includes(item.name)) {
            return item.name;
          }
        })
        .map((item: any) => item.name);
      CustomizeColumn(data);
    }

    setShowModal(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '422px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
            setLocalHidden(hiddenColumnCopy);
          }}
        >
          Customize Columns
        </ModalHeader>

        <DragDropContext
          onDragEnd={param => {
            const srcI = param.source.index;
            const desI = param.destination?.index;
            if (desI || desI === 0) {
              columnCopy.splice(desI, 0, columnCopy.splice(srcI, 1)[0]);
            }
            setColumnCopy(deepCopy(columnCopy));
          }}
        >
          <ModalBody>
            <Droppable droppableId='droppable-1'>
              {(provided, _) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {columnCopy.map((col: any, index: any) => (
                    <Draggable key={col.id} draggableId={'draggable-' + col.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className={styles.table_col}
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: snapshot.isDragging ? '0 0 .4rem #666' : 'none'
                          }}
                        >
                          <img
                            id={'eye'}
                            src={
                              localHidden.includes(col.columnName)
                                ? '/images/hide.svg'
                                : '/images/view.svg'
                            }
                            alt=''
                            width={18}
                            height={18}
                            onClick={() => {
                              let newHiddenColumns = deepCopy(localHidden);

                              if (localHidden.includes(col.columnName)) {
                                newHiddenColumns = newHiddenColumns.filter(
                                  (item: any) => item !== col.columnName
                                );
                                setLocalHidden(newHiddenColumns);
                              } else {
                                if (columns.length - newHiddenColumns.length > 3) {
                                  newHiddenColumns.push(col.columnName);
                                  setLocalHidden(newHiddenColumns);
                                } else {
                                  enqueueSnackbar('', {
                                    variant: 'error',
                                    header: 'Cannot hide more columns'
                                  });
                                }
                              }
                            }}
                          />
                          <div
                            {...provided.dragHandleProps}
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {col.columnName}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </ModalBody>
        </DragDropContext>

        <ModalFooter>
          <div className={styles.button_container}>
            <div className={styles.button_container}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    size='small'
                    color='primary'
                    onChange={handleChange}
                  />
                }
                label={<span className={styles.checkbox_label}>Save as default</span>}
              />
            </div>
            <div className={styles.button_container}>
              <button
                className={styles.cancel_button}
                onClick={() => {
                  setShowModal(false);
                  setLocalHidden(hiddenColumnCopy);
                }}
              >
                Cancel
              </button>
              <button
                className={styles.confirm_button}
                onClick={() => {
                  onSubmit();
                }}
              >
                Customize
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TableSettingModal;
