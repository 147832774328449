import React, { useState } from 'react';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './pricing.module.scss';
import { pricingCardProps } from './model';
import { pricing_constant } from '../../constants/pricing-constants';
import { cx } from '../../utils/class-name-service';
import FooterLanding from '../../components/core/layout/footer-landing';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
const PricingCard: React.FunctionComponent<pricingCardProps> = ({ type, pricingPeriod }) => {
  let value, color;
  if (type === 'Standard') {
    value = pricingPeriod === 'monthly' ? 249 : 199;
    color = '#E5E2F8';
  } else if (type === 'Professional') {
    value = pricingPeriod === 'monthly' ? 499 : 399;
    color = '#F9F0E1';
  } else if (type === 'Enterprise') {
    value = pricingPeriod === 'monthly' ? 999 : 799;
    color = '#BFE4EC';
  }
  const handleEmailClick = () => {
    const email = 'support@reachtenants.com';
    const subject = 'ReachTenants pricing support';
    const body = '';
    const mailtoLink = `mailto:${email}?subject=${subject}`;
    window.location.href = mailtoLink;
  };
  return (
    <div className={styles.price_card} style={{ backgroundColor: color }}>
      <div className={styles.price_card_title}>{type}</div>
      <div className={styles.price_card_amount}>
        <div className={styles.price_card_amount_value}>${value}</div>
        <div className={styles.price_card_amount_period}>
          per {pricingPeriod === 'monthly' ? 'month' : 'month'}
        </div>
      </div>
      <button className={styles.price_card_button} onClick={() => handleEmailClick()}>
        Contact Us
      </button>
    </div>
  );
};

function PricingContainer() {
  const [pricingPeriod, setPricingPeriod] = useState('monthly');
  const isMobile = useCheckMobileScreen();
  return (
    <>
      {!isMobile ? (
        <div className={styles.landing_page_wrapper}>
          <HeaderLanding />
          <div className={styles.pricing_header}>
            <div className={styles.pricing_header_title}>Plans that help you grow</div>
            <div className={styles.pricing_header_sub}>
              Simple, transparent pricing that grows with you{' '}
            </div>
            <div className={styles.pricing_header_buttons}>
              <button
                className={
                  pricingPeriod === 'monthly'
                    ? cx(
                        styles.pricing_header_buttons_primary,
                        styles.pricing_header_buttons_primary_active
                      )
                    : styles.pricing_header_buttons_primary
                }
                onClick={() => setPricingPeriod('monthly')}
              >
                Monthly
              </button>
              <button
                className={
                  pricingPeriod === 'yearly'
                    ? cx(
                        styles.pricing_header_buttons_secondary,
                        styles.pricing_header_buttons_secondary_active
                      )
                    : styles.pricing_header_buttons_secondary
                }
                onClick={() => setPricingPeriod('yearly')}
              >
                Yearly
              </button>
            </div>
          </div>

          <div className={styles.pricing_table}>
            <table className={styles.pricing_table_container}>
              <tr style={{ marginBottom: '40px' }}>
                <td className={styles.pricing_table_col1}></td>
                <td className={styles.pricing_table_col2}>
                  <PricingCard type={'Standard'} pricingPeriod={pricingPeriod} />
                </td>
                <td className={styles.pricing_table_col3}>
                  <PricingCard type={'Professional'} pricingPeriod={pricingPeriod} />
                </td>
                <td className={styles.pricing_table_col4}>
                  <PricingCard type={'Enterprise'} pricingPeriod={pricingPeriod} />
                </td>
              </tr>
              {pricing_constant.map(element => {
                return (
                  <>
                    <tr className={styles.table_feature_row}>
                      <td className={styles.table_feature_row_feature}>{element.feature}</td>
                      <td className={styles.table_feature_row_standard} align='center'>
                        {element.standard === 'true' ? (
                          <img src={'images/feature_check.svg'} />
                        ) : element.standard === 'false' ? (
                          <img src={'images/featur_uncheck.svg'} />
                        ) : (
                          element.standard
                        )}
                      </td>
                      <td className={styles.table_feature_row_professional} align='center'>
                        {element.professional === 'true' ? (
                          <img src={'images/feature_check.svg'} />
                        ) : element.professional === 'false' ? (
                          <img src={'images/featur_uncheck.svg'} />
                        ) : (
                          element.professional
                        )}
                      </td>
                      <td className={styles.table_feature_row_enterprise} align='center'>
                        {element.enterprise === 'true' ? (
                          <img src={'images/feature_check.svg'} />
                        ) : element.enterprise === 'false' ? (
                          <img src={'images/featur_uncheck.svg'} />
                        ) : (
                          element.enterprise
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
          <FooterLanding />
        </div>
      ) : (
        <div className={styles.landing_page_wrapper}>
          <HeaderLanding />
          <div className={styles.pricing_header}>
            <div className={styles.pricing_header_title}>Plans that help you grow</div>
            <div className={styles.pricing_header_sub}>
              Simple, transparent pricing that grows with you{' '}
            </div>
            <div className={styles.pricing_header_buttons}>
              <button
                className={
                  pricingPeriod === 'monthly'
                    ? cx(
                        styles.pricing_header_buttons_primary,
                        styles.pricing_header_buttons_primary_active
                      )
                    : styles.pricing_header_buttons_primary
                }
                onClick={() => setPricingPeriod('monthly')}
              >
                Monthly
              </button>
              <button
                className={
                  pricingPeriod === 'yearly'
                    ? cx(
                        styles.pricing_header_buttons_secondary,
                        styles.pricing_header_buttons_secondary_active
                      )
                    : styles.pricing_header_buttons_secondary
                }
                onClick={() => setPricingPeriod('yearly')}
              >
                Yearly
              </button>
            </div>
          </div>

          <div className={styles.pricing_table}>
            <PricingCard type={'Standard'} pricingPeriod={pricingPeriod} />
            <table className={styles.pricing_table_container}>
              <tr style={{ marginBottom: '40px' }}>
                <td className={styles.pricing_table_col1}></td>
                <td className={styles.pricing_table_col2}></td>
              </tr>
              {pricing_constant.map(element => {
                return (
                  <>
                    <tr className={styles.table_feature_row}>
                      <td className={styles.table_feature_row_feature}>{element.feature}</td>
                      <td className={styles.table_feature_row_standard} align='center'>
                        {element.standard === 'true' ? (
                          <img src={'images/feature_check.svg'} />
                        ) : element.standard === 'false' ? (
                          `-`
                        ) : (
                          element.standard
                        )}
                      </td>
                      {/* <td className={styles.table_feature_row_professional} align='center'>
                        {element.professional === 'true' ? (
                          <img src={'images/feature_check.svg'} />
                        ) : element.professional === 'false' ? (
                          `-`
                        ) : (
                          element.professional
                        )}
                      </td>
                      <td className={styles.table_feature_row_enterprise} align='center'>
                        {element.enterprise === 'true' ? (
                          <img src={'images/feature_check.svg'} />
                        ) : element.enterprise === 'false' ? (
                          `-`
                        ) : (
                          element.enterprise
                        )}
                      </td> */}
                    </tr>
                  </>
                );
              })}
            </table>
            <PricingCard type={'Professional'} pricingPeriod={pricingPeriod} />
            <table className={styles.pricing_table_container}>
              <tr style={{ marginBottom: '40px' }}>
                <td className={styles.pricing_table_col1}></td>
                <td className={styles.pricing_table_col2}></td>
              </tr>
              {pricing_constant.map(element => {
                return (
                  <>
                    <tr className={styles.table_feature_row}>
                      <td className={styles.table_feature_row_feature}>{element.feature}</td>
                      <td className={styles.table_feature_row_professional} align='center'>
                        {element.professional === 'true' ? (
                          <img src={'images/feature_check.svg'} />
                        ) : element.professional === 'false' ? (
                          `-`
                        ) : (
                          element.professional
                        )}
                      </td>
                      {/* <td className={styles.table_feature_row_enterprise} align='center'>
                        {element.enterprise === 'true' ? (
                          <img src={'images/feature_check.svg'} />
                        ) : element.enterprise === 'false' ? (
                          `-`
                        ) : (
                          element.enterprise
                        )}
                      </td> */}
                    </tr>
                  </>
                );
              })}
            </table>
            <PricingCard type={'Enterprise'} pricingPeriod={pricingPeriod} />
            <table className={styles.pricing_table_container}>
              <tr style={{ marginBottom: '40px' }}>
                <td className={styles.pricing_table_col1}></td>
                <td className={styles.pricing_table_col2}></td>
              </tr>
              {pricing_constant.map(element => {
                return (
                  <>
                    <tr className={styles.table_feature_row}>
                      <td className={styles.table_feature_row_feature}>{element.feature}</td>
                      <td className={styles.table_feature_row_enterprise} align='center'>
                        {element.enterprise === 'true' ? (
                          <img src={'images/feature_check.svg'} />
                        ) : element.enterprise === 'false' ? (
                          `-`
                        ) : (
                          element.enterprise
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
          <FooterLanding />
        </div>
      )}
    </>
  );
}

export default PricingContainer;
