export const features = [
  {
    featurename: 'Business contacts',
    featuredescription: `Local and national business contacts with owner’s or key executive's name, number and email address.`,
    image: '/images/email.svg'
  },
  {
    featurename: 'Automation',
    featuredescription:
      'Robust personalized communications are delivered seamlessly while eliminating repetitive, tedious tasks.',
    image: '/images/automation.svg'
  },
  {
    featurename: 'Full integration',
    featuredescription: `Emails on ReachTenants platform are two-way synced in real-time with the prospector's communication devices.`,
    image: '/images/integration.svg'
  },
  {
    featurename: 'Opportunity management',
    featuredescription:
      'All data is organized, centrally located, and easily accessible by all team members.',
    image: '/images/management.svg'
  },
  {
    featurename: 'Competitor location maps',
    featuredescription:
      'Leasing to national businesses is supercharged by mapping capabilities showing gaps and opportunities in your market.',
    image: '/images/location.svg'
  }
];
