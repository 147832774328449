import s from '../calculator-container.module.scss';
const styles = s;
function CustomCalcs({ calculatorName, formValues, setFormValues, handleStringInputChange }: any) {
  return (
    <div>
      {calculatorName === 'CALCULATE_DAYS_BETWEEN' && (
        <>
          <div className={styles.form_item}>
            <p>Enter dates</p>
            <div className={styles.input_container}>
              <input
                type='date'
                onChange={e => handleStringInputChange('from_date', e.target.value)}
                value={formValues['from_date'] ? formValues['from_date'] : ''}
              />
              <span style={{ margin: '0px 9px' }}>To</span>
              <input
                type='date'
                onChange={e => handleStringInputChange('to_date', e.target.value)}
                value={formValues['from_date'] ? formValues['to_date'] : ''}
              />
            </div>
          </div>

          <div className={styles.form_item} style={{ marginTop: '15px' }}>
            <div className={styles.input_container}>
              <input
                type='checkbox'
                onChange={e => handleStringInputChange('first_day', e.target.checked)}
                checked={formValues['first_day'] ? formValues['first_day'] : ''}
                style={{ width: '14px', height: '14px' }}
              />
              <span style={{ margin: '0px 9px' }}>Count first day</span>
              <input
                type='checkbox'
                onChange={e => handleStringInputChange('last_day', e.target.checked)}
                value={formValues['last_day'] ? formValues['last_day'] : ''}
                style={{ width: '14px', height: '14px' }}
              />
              <span style={{ margin: '0px 9px' }}>Count last day</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CustomCalcs;
