import React, { useEffect, useState } from 'react';
import AppConfig from '../../config';
import styles from './unsubscribe.module.scss';
const axios = require('axios');


const Unsubscribe = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const unsubemail = queryParams.get("email")
  const [unsubed, setUnsubed] = useState(false);
  const BASE_URL = AppConfig.API_BASE_URL;
 
  
  async function makeRequest() {
    let config = {
      method: 'get',
      url: `${BASE_URL}/unsubscribe?email=${unsubemail}`,
      headers: { }
    };
    try {
      const response = await axios.request(config);
      setUnsubed(true)
    }
    catch (error) {
      console.log(error);
    }
  }
  async function makeRequestCheck() {
    let config = {
      method: 'get',
      url: `${BASE_URL}/unsubscribe/check?email=${unsubemail}`,
      headers: { }
    };
    try {
      const response = await axios.request(config);
      if(response.data.status) setUnsubed(true)
    }
    catch (error) {
      console.log(error);
    }
  }
  async function makeRequestSubscribe() {
    let config = {
      method: 'get',
      url: `${BASE_URL}/unsubscribe/sub?email=${unsubemail}`,
      headers: { }
    };
    try {
      const response = await axios.request(config);
      setUnsubed(false)
    }
    catch (error) {
      console.log(error);
    }
  }
  
  useEffect(()=>{
    (async()=>{
      const res = await makeRequestCheck()
    })()

  },[unsubed])

  return (
    <>
    {
      unsubed ? 
      <div className={styles.page_wrapper}>
      <h3>
        You are currently unsubscribed from ReachTenants? 
      </h3>
      <p>Please consider subscribing again to get property details around you.</p>
      <button className={styles.btn} onClick={()=> makeRequestSubscribe()}>Subscribe</button>
    </div> :
     <div className={styles.page_wrapper}>
     <h3>
       Are you sure you want to unsubscribe from ReachTenants? 
     </h3>
     {/* <p>You will still recieve transactional emails.</p> */}
     <button className={styles.btn} onClick={()=> makeRequest()}>Unsubscribe</button>
   </div>
    }
     
    </>
  );
};

export default Unsubscribe;
