import React from 'react';

import { cx } from '../../../utils/class-name-service';
import { props } from './model';
import { BTN_PRIMARY, BTN_SECONDARY, GREY_BUTTON } from '../../../constants/button-constants';
import styles from './button.module.scss';

const WrappedButton: React.FC<props> = props => {
  const returnButtonStyle = () => {
    if (props.btnType === BTN_PRIMARY) {
      return styles.btn_primary;
    }
    if (props.btnType === BTN_SECONDARY) {
      return styles.btn_secondary;
    }
    if (props.btnType === GREY_BUTTON) {
      return styles.grey_button;
    }
    return styles.btn_primary;
  };

  return (
    <button
      className={cx(
        styles.button,
        returnButtonStyle(),
        props.className,
        props.isDisabled ? styles.disabled : ''
      )}
      onClick={props.onClick}
      disabled={props.isDisabled}
      type={props.type}
    >
      <span className={styles.button_text}>
        {props.isInProgress ? (
          <div className={styles.ldsRing}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          props.btnText
        )}
      </span>
    </button>
  );
};

WrappedButton.defaultProps = {
  isDisabled: false,
  className: ''
};

export default WrappedButton;
