import React, { useEffect, useState } from 'react';
import { props } from './model';
import styles from './chooseFromProject.module.scss';
import { useMutation } from 'react-query';
import axios from 'axios';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';
import { HandleFileDelete } from '../../../utils/form-handler';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import { cx } from '../../../utils/class-name-service';

const ChooseFromProject: React.FunctionComponent<props> = ({
  imagesUrl,
  setImagesUrl,
  selectedFiles,
  setSelectedFiles,
  setchangesMade
}) => {
  const storage = new Storage();
  const [allUploadedFiles, setAllUploadedFiles] = useState<any[] | any>();

  const BASE_URL = AppConfig.API_BASE_URL;
  const [isUploading, setIsUploading] = useState(false);
  const allUploadedFilesHandler = async () => {
    setIsUploading(true);
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/project/individualProject/${storage.get('projectId')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: {}
    };

    return await axios(config);
  };
  useEffect(() => {
    (async () => {
      await allUploadedFilesHandler().then(res => {
        let tempArr = [...res.data.dueDeligenceFiles, ...res.data.filesAndVideos];
        setAllUploadedFiles(tempArr);
        let temp = tempArr.map((item: any, index: any) => {
          return { id: index, img: item };
        });
        setSelectedFiles(temp);
        setIsUploading(false);
      });
    })();
  }, []);

  const onchangeHandler = (e: any, id: any) => {
    const { name, checked } = e.target;
    let temp = selectedFiles.map((item: any) =>
      item.id === id ? { ...item, isChecked: checked } : item
    );
    setSelectedFiles(temp);
  };

  return (
    <>
      <div
        className={styles.chooseFromProject_container}
        // style={{ display: 'flex', width: '769px' }}
      >
        {selectedFiles?.length > 0 ? (
          <>
            {selectedFiles?.map((item: any, index: any) => (
              <div
                key={index}
                className={styles.upload_button}
                onClick={() => {
                  // window.open(item[index].img);
                }}
              >
                {/* <div className={styles.uploaded_file_header}></div> */}
                <div className={styles.uploaded_file}>
                  {item.img.substring(item.img.lastIndexOf('.') + 1, item.img.length) === 'pdf' ||
                  item[index]?.img.substring(item.img.lastIndexOf('.') + 1, item.img.length) ===
                    'doc' ? (
                    <img className={styles.account_statement} src={'/images/doc.svg'} />
                  ) : (
                    <img
                      className={styles.account_statement}
                      src={item.img}
                      style={{ borderRadius: '10px 10px 0 0' }}
                    />
                  )}
                </div>
                <div className={styles.uploaded_file_bottom}>
                  <div className={styles.file_name}>
                    {item.img.substring(item.img.lastIndexOf('/') + 1, item.img.length)}
                  </div>
                  <div className={styles.checkbox_options_wrapper}>
                    <label className={cx(styles.checkbox, styles.bounce)}>
                      <input
                        type='checkbox'
                        name={item.id}
                        checked={item?.isChecked}
                        onChange={event => {
                          // props.updateDetails(event.target.checked, props.id, props.responseDetailId);
                          onchangeHandler(event, item.id);
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            ))}{' '}
          </>
        ) : (
          <></>
        )}
      </div>

      {isUploading ? (
        <Backdrop sx={{ color: '#0c252d', zIndex: '100' }} open>
          {<CircularProgress color='inherit' />}
        </Backdrop>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChooseFromProject;
