import React, { useState } from 'react';
import styles from '../../../screens/report/report.module.scss';
import { props } from './model';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY } from '../../../constants/button-constants';
import SetProjectGoalModal from '../../modals/set-project-goal';
import ProjectGoalCard from './projectGoalStatistic';

const ProjectGoal: React.FC<props> = ({ overallMetrics, isProjectGoalSet, queryClient }) => {
  const [showModal, setShowModal] = useState(false);
  const [showChart, setShowChart] = useState(isProjectGoalSet);
  const projectGoalStats = [
    {
      header: 'Email sent today',
      sent: overallMetrics.emailSentToday,
      outOf: overallMetrics.emailGoalSetForDay,
      description: 'Email to be sent',
      background: '#FBA372'
    },
    {
      header: 'Email sent this week',
      sent: overallMetrics.emailSentThisWeek,
      outOf: overallMetrics.emailGoalSetForWeek,
      description: 'Email to be sent',
      background: '#FAD965'
    },
    {
      header: 'Email sent this month',
      sent: overallMetrics.emailSentThisMonth,
      outOf: overallMetrics.emailGoalSetForYear,
      description: 'Email to be sent',
      background: '#3E72D8'
    }
  ];
  return (
    <div className={styles.goal_container}>
      {!isProjectGoalSet ? (
        <>
          <div className={styles.goal_not_setup_header}>Project Goal Not Set</div>
          <div className={styles.button_wrapper}>
            <WrappedButton
              btnText='Set Project Goal'
              onClick={() => {
                setShowModal(true);
              }}
              btnType={BTN_PRIMARY}
              className={styles.submit_button}
            />
          </div>
          <SetProjectGoalModal
            showModal={showModal}
            setShowModal={setShowModal}
            setShowChart={setShowChart}
            queryClient={queryClient}
          />
        </>
      ) : (
        <div className={styles.project_goal_card}>
          {projectGoalStats.map(item => (
            <div style={{ width: '390px' }}>
              <ProjectGoalCard
                header={item.header}
                sent={item.sent}
                outOf={item.outOf}
                description={item.description}
                background={item.background}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectGoal;
