import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './competitor-report.module.scss';
import Map, { MapRef, Marker, NavigationControl, Popup } from 'react-map-gl';
import Storage from '../../utils/local-storage';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';

interface CompetitorReportScreen {
  queryClient: any;
}

function getElementFrequency(data: any) {
  const urlCountMap: any = {};
  const colorPalette = [
    '#5D12D2',
    '#B931FC',
    '#FF6AC2',
    '#662549',
    '#AE445A',
    '#ffa300',
    '#dc0ab4',
    '#b3d4ff',
    '#e60049'
  ];
  // Iterate through the array of objects
  for (const [index, item] of data.entries()) {
    const { url, businessName } = item;
    let color;

    // If the index is less than 5, use color from the palette
    if (index < 9) {
      color = colorPalette[index];
    } else {
      // Otherwise, generate a random color
      color = '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    // If the URL is not in the map, initialize its count, names, and color
    if (!urlCountMap[url]) {
      urlCountMap[url] = {
        url,
        count: 1,
        names: [businessName],
        color
      };
    } else {
      // If the URL is already in the map, increment its count and add the name
      urlCountMap[url].count++;
      urlCountMap[url].names.push(businessName);
    }
  }
  // Convert the map values into an array
  const resultArray = Object.values(urlCountMap);

  return resultArray;
}

const CompetitorReportScreen = (props: CompetitorReportScreen) => {
  const storage = new Storage();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let { competitorListId } = useParams();
  const [competitorData, setCompetitorData] = useState<any>();
  const [projectLatLong, setprojectLatLong] = useState<any>(storage.get('projectLatLong'));
  const [projectType, setprojectType] = useState<any>();
  const [selectedMarker, setSelectedMarker] = useState(null);

  const getCompetitorDetails = async () => {
    const config = {
      method: 'get',
      url: `/v1/savedcompetitorlist/get-all-competitor/${competitorListId}`
    };
    const res = await axios(config);
    return res.data;
  };
  const { data: competitorDetails, status: competitorStatus } = useQuery(
    `getCompetitor`,
    getCompetitorDetails,
    {
      onSuccess: res => {
        let competitorData = getElementFrequency(res.businessList);
        // storage.save('projectLatLong', res.projectLatLong);
        setprojectLatLong(res.projectLatLong);
        setprojectType(res.projectType);
        setCompetitorData(competitorData);
      }
    }
  );
  let randomColor;
  const markers = competitorDetails?.businessList?.map((el: any, index: any) => {
    let objColor = competitorData?.find((x: any) => x.url === el.url).color;
    randomColor = objColor;

    return (
      <div
        onMouseEnter={() => setSelectedMarker(el)}
        onMouseLeave={() => setSelectedMarker(null)}
        style={{ width: '26px', height: '37px' }}
      >
        <Marker key={el.id} longitude={el.long} latitude={el.lat}>
          <svg
            width='26'
            height='37'
            viewBox='0 0 26 37'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M13 0C5.82214 0 0 5.79975 0 12.95C0 22.6625 13 37 13 37C13 37 26 22.6625 26 12.95C26 5.79975 20.1779 0 13 0ZM13 17.575C10.4371 17.575 8.35714 15.503 8.35714 12.95C8.35714 10.397 10.4371 8.325 13 8.325C15.5629 8.325 17.6429 10.397 17.6429 12.95C17.6429 15.503 15.5629 17.575 13 17.575Z'
              fill={randomColor}
            />
          </svg>
          {/* <p>{el.businessName}</p> */}
        </Marker>
        {selectedMarker === el && (
          <Popup
            longitude={el.long}
            latitude={el.lat}
            // onClose={() => setSelectedMarker(null)}
            anchor='bottom'
          >
            <p className={styles.businessNameMarker}>{el.businessName}</p>
          </Popup>
        )}
      </div>
    );
  });

  return (
    <>
      <div className={styles.main_container}>
        <div className={styles.header}>
          {storage.get('accessToken') && !searchParams.get('creatTemplate') && (
            <div className={styles.circular_hover} onClick={() => navigate(-1)}>
              <img src='/back-arrow.svg' alt='Back' width='20' height='20' />
            </div>
          )}
          Competitors report
        </div>
        <div className={styles.main_wrapper}>
          <div className={styles.business_details_wrapper}>
            {competitorStatus === 'success' &&
              competitorData?.map((item: any) => (
                <>
                  <div className={styles.business_wrap}>
                    <div className={styles.business_wrap_name}>
                      <div className={styles.dot} style={{ background: item['color'] }}></div>
                      <div className={styles.business_name}>{item['names'][0]}</div>
                    </div>
                    <div className={styles.business_count}>{item['count']} store near project</div>
                  </div>
                </>
              ))}
          </div>
          {console.log(projectType, projectLatLong)}

          <div className={styles.map_wrapper}>
            {projectType &&
              projectType !== 'National' &&
              projectLatLong &&
              projectLatLong !== 'undefined' && (
                <Map
                  mapboxAccessToken={
                    'pk.eyJ1Ijoic2hpa2hhcnNoYXJtYSIsImEiOiJjbGJldzNpeXkwMGZ2M3BwNDh6bzdmcWw5In0.io_4UtjsI6cadWpGdThPxg'
                  }
                  mapStyle='mapbox://styles/mapbox/streets-v12'
                  style={{
                    borderRadius: '8px',
                    transition: '1.5s'
                  }}
                  initialViewState={{
                    longitude: projectLatLong.split(',').map(parseFloat)[1],
                    latitude: projectLatLong.split(',').map(parseFloat)[0],
                    zoom: 11
                  }}
                >
                  {/* Adding company specific icons and description on hover */}
                  {markers}
                  {
                    <Marker
                      longitude={projectLatLong.split(',').map(parseFloat)[1]}
                      latitude={projectLatLong.split(',').map(parseFloat)[0]}
                    >
                      <svg
                        width='26'
                        height='37'
                        viewBox='0 0 26 37'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M13 0C5.82214 0 0 5.79975 0 12.95C0 22.6625 13 37 13 37C13 37 26 22.6625 26 12.95C26 5.79975 20.1779 0 13 0ZM13 17.575C10.4371 17.575 8.35714 15.503 8.35714 12.95C8.35714 10.397 10.4371 8.325 13 8.325C15.5629 8.325 17.6429 10.397 17.6429 12.95C17.6429 15.503 15.5629 17.575 13 17.575Z'
                          fill='#D81F1F'
                        />
                      </svg>
                    </Marker>
                  }
                  <NavigationControl position='bottom-right' showCompass={false} />
                </Map>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompetitorReportScreen;
