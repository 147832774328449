import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styles from './dripSystemFilter.module.scss';
import { props } from './modal';
import { PersonHasOptions, DaysToSentEmailOptions } from '../../constants/dropDown-constanst';
import HttpHelper from '../../utils/http-helper';
import Storage from '../../utils/local-storage';

const customStyles = {
  dropdownIndicator: (base: any) => ({
    ...base
  }),
  control: (base: any) => ({
    ...base,
    // :'80px',
    height: '35px',
    minHeight: '35px',
    minWidth: '157px',
    cursor: 'pointer',
    fontSize: '12px',
    fontFamily: 'Lato'
  }),
  placeholder: (base: any) => ({
    ...base,
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '400'
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: 'Lato',
    fontSize: '12px'
  })
};
const AutomationStatus = [
  {
    label: 'Active',
    value: true
  },
  { label: 'Inactive', value: false }
];

const scheduledFor = [
  {
    label: 'Immediately',
    value: 'Immediately'
  },
  ...DaysToSentEmailOptions
];

const DripSystemFilters: React.FC<props> = ({
  triggerType,
  setTriggerType,
  scheduledforType,
  setScheduledforType,
  automationType,
  setAutomationType,
  selecteBusinessList,
  setSelecteBusinessList
}) => {
  const { getRequest } = new HttpHelper();
  const storage = new Storage();
  const [savedList, setsavedList] = useState([]);
  const getSavedList = async () => {
    // Get all saved lists for project
    const url = `/v1/savedbusinesslist/${storage.get('projectId')}`;
    const { data } = await getRequest({ url });
    if (data.length <= 0) {
    } else {
      const bListArray: any = [];
      data.forEach((el: any) => {
        const obj: any = {};
        obj.value = el._id;
        obj.label = el.list_name;
        obj.count = el.business_ids.length;
        bListArray.push(obj);
      });
      setsavedList(bListArray);
    }
  };
  useEffect(() => {
    (async () => {
      await getSavedList();
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  return (
    <>
      <div className={styles.main_wrapper}>
        <div className={styles.heading}></div>
        <div className={styles.filter}>
          <div className={styles.view_by}>Filter</div>

          <div className={styles.dropdown_filter}>
            <Select
              options={PersonHasOptions}
              value={PersonHasOptions.filter((item: any) => item['value'] === triggerType)}
              styles={customStyles}
              placeholder='Status'
              components={{
                IndicatorSeparator: () => null
              }}
              isOptionDisabled={(option: any) => option.isdisabled} // disable an option
              onChange={(e: any) => {
                setTriggerType(e.value);
              }}
            />
          </div>

          <div className={styles.dropdown_filter}>
            <Select
              options={scheduledFor}
              value={scheduledFor.filter((item: any) => item['value'] === scheduledforType)}
              styles={customStyles}
              placeholder='Scheduled for'
              components={{
                IndicatorSeparator: () => null
              }}
              isOptionDisabled={(option: any) => option.isdisabled} // disable an option
              onChange={(e: any) => {
                setScheduledforType(e.value);
              }}
            />
          </div>

          <div className={styles.dropdown_filter}>
            <Select
              value={AutomationStatus.filter((item: any) => item['value'] === automationType)}
              options={AutomationStatus}
              styles={customStyles}
              placeholder='Automation status'
              components={{
                IndicatorSeparator: () => null
              }}
              onChange={(e: any) => {
                setAutomationType(e.value);
              }}
            />
          </div>

          <div className={styles.dropdown_filter}>
            <Select
              value={savedList.filter((item: any) => item['value'] === selecteBusinessList)}
              options={savedList}
              styles={customStyles}
              placeholder='Business list'
              components={{
                IndicatorSeparator: () => null
              }}
              onChange={(e: any) => {
                setSelecteBusinessList(e.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DripSystemFilters;
