import React from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { props } from './model';
import styles from '../customised-text-field/customised-text-field.module.scss';
import { cx } from '../../../utils/class-name-service';

interface State {
  showPassword: boolean;
}
interface State1 {
  isError: boolean;
}

const PasswordField: React.FunctionComponent<props> = ({
  form,
  field,
  placeholder,
  fieldClassName
}) => {
  const [values, setValues] = React.useState<State>({
    showPassword: false
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const { name, value } = field;
  const { setFieldValue, errors, touched } = form;

  return (
    <div className={cx(styles.password_field_root)}>
      <div>
        <div className={styles.field_label}>{placeholder}</div>
        <OutlinedInput
          error={(value || touched[name]) && errors && errors[name]}
          value={value}
          onChange={(e: any) => {
            setFieldValue(name, e.target.value);
          }}
          id={name}
          type={values.showPassword ? 'text' : 'password'}
          className={styles.cutomisedTextField}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                edge='end'
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {(value || touched[name]) && errors && errors[name] && (
          <div className={styles.error_message}>{errors[name]}</div>
        )}
      </div>
    </div>
  );
};

export default PasswordField;
