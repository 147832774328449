import React from 'react';
import FeaturesContainer from '../../containers/features';
import styles from './features.module.scss';

function FeaturesScreen() {
  return (
    <div className={styles.page_wrapper}>
      <FeaturesContainer />
    </div>
  );
}

export default FeaturesScreen;
