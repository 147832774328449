import styles from './business-details.module.scss';
import { props } from './model';
import BusinessDetailPill from '../../business-details';
import AppConfig from '../../../config';
import Storage from '../../../utils/local-storage';
import { useMutation, useQuery } from 'react-query';
import ResponseDetailsModal from '../../modals/response-details';
import { useContext, useEffect, useState } from 'react';
import ReportErrorModal from '../../modals/report-error-modal';
import { useSnackbar } from 'notistack';
import { ProjectContext } from '../../../context/ProjectContext';

const axios = require('axios');
const BusinessDetails: React.FunctionComponent<props> = ({
  queryClient,
  business,
  setBusinessDetails,
  projectType
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editTextHeader, setEditTextHeader] = useState('');
  const [editTextValue, setEditTextValue] = useState('');
  const [valueLabel, setValueLabel] = useState<any>();
  const [propertyUpdated, setPropertyUpdated] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const [errorModal, setShowErrorModal] = useState(false);
  const [isDNC, setIsDNC] = useState();
  const [phoneType, setPhoneType] = useState();
  const [showPhoneDate, setShowPhoneData] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [businessDetailConstant, setBusinessDetailsConstant] = useState<any>();
  const { changesMadeInBusiness, setchangesMadeInBusiness } = useContext(ProjectContext);

  function formatPhoneNumber(phoneNumber: any) {
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);

    return `${areaCode}.${firstPart}.${secondPart}`;
  }
  const businessId = business['_id'];
  const NATIONAL_BUSSINESS_DETAILS_CONSTANTS = [
    {
      section: 'Business Information',
      pills_left: [
        {
          label: 'Business details',
          readOnly: false,
          value: [
            business.businessName,
            business.address,
            [business.city, business.territory, business.zipCode],
            business.phone[0],
            business.url
          ],
          showHeader: true,
          id: 'businessName',
          headers: [
            { label: 'Business Name', id: 'businessName', value: business.businessName },
            { label: 'Company Address', id: 'address', value: business.address },
            { label: 'Company City', id: 'city', value: business.city },
            { label: 'Company Territory', id: 'territory', value: business.territory },
            { label: 'Company zipCode', id: 'zipCode', value: business.zipCode },
            { label: 'Company Phone', id: 'phone', value: business.phone[0] },
            { label: 'Company URL', id: 'url', value: business.url }
          ]
        }
      ],
      pills_right: [
        {
          label: 'Categories',
          readOnly: true,
          value: business.category,
          showHeader: true,
          id: 'category'
        },
        {
          label: 'Subcategories',
          readOnly: true,
          value: business.subcategory,
          showHeader: true,
          id: 'subcategory'
        },
        {
          label: 'Franchise',
          readOnly: true,
          value: business.franchise,
          showHeader: true,
          id: 'franchise'
        }
      ]
    },
    {
      section: 'Contact Information',
      pills_left: [
        {
          label: 'First name Last name',
          readOnly: false,
          value: [[business.firstName, business.lastName]],
          showHeader: true,
          id: '',
          headers: [
            { label: 'First name', id: 'firstName', value: business.firstName },
            { label: 'Last name', id: 'lastName', value: business.lastName }
          ]
        },
        {
          label: 'Title',
          readOnly: false,
          value: business.title,
          showHeader: true,
          id: 'title',
          headers: [
            {
              label: 'Title',
              id: 'title',
              value: business.title
            }
          ]
        },
        {
          label: 'Phone number',
          readOnly: true,
          value: business.phone[0],
          showHeader: true,
          id: 'phone',
          headers: [
            {
              label: 'Phone number',
              id: 'phone',
              value: business.phone[0]
            }
          ]
        },
        {
          label: 'Email address',
          readOnly: false,
          value: business.email,
          showHeader: true,
          id: 'email',
          headers: [
            {
              label: 'Email address',
              id: 'email',
              value: business.email
            }
          ]
        },
        {
          label: 'Linkedin',
          readOnly: false,
          value: business.linkedin,
          showHeader: true,
          id: 'linkedin',
          headers: [
            {
              label: 'Linkedin',
              id: 'linkedin',
              value: business.linkedin
            }
          ]
        }
      ],
      pills_right: [
        {
          label: 'Location Sales',
          readOnly: false,
          value: business.locationSales,
          showHeader: true,
          id: 'locationSales',
          headers: [
            {
              label: 'Location Sales',
              id: 'locationSales',
              value: business.locationSales
            }
          ]
        },
        {
          label: 'Distance to project',
          readOnly: true,
          value: 10,
          showHeader: true,
          headers: [
            {
              label: 'Distance to project',
              id: 'distanceToProject',
              value: 10
            }
          ]
        },
        {
          label: 'Year established',
          readOnly: true,
          value: business.yearEstablished,
          showHeader: true,
          id: 'yearEstablished',
          headers: [
            {
              label: 'Year established',
              id: 'yearEstablished',
              value: business.yearEstablished
            }
          ]
        }
      ]
    }
  ];
  const LOCAL_BUSSINESS_DETAILS_CONSTANTS = [
    {
      section: 'Business Information',
      pills_left: [
        {
          label: 'Business details',
          readOnly: false,
          value: [
            business.businessName,
            business.address,
            [business.city, business.territory, business.zipCode],
            business.phone[0],
            business.url
          ],
          showHeader: true,
          id: 'businessName',
          headers: [
            { label: 'Business Name', id: 'businessName', value: business.businessName },
            { label: 'Company Address', id: 'address', value: business.address },
            { label: 'Company City', id: 'city', value: business.city },
            { label: 'Company Territory', id: 'territory', value: business.territory },
            { label: 'Company zipCode', id: 'zipCode', value: business.zipCode },
            { label: 'Company Phone', id: 'phone', value: business.phone[0] },
            { label: 'Company URL', id: 'url', value: business.url }
          ]
        }
      ],
      pills_right: [
        {
          label: 'Categories',
          readOnly: true,
          value: business.category,
          showHeader: true,
          id: 'category'
        },
        {
          label: 'Subcategories',
          readOnly: true,
          value: business.subcategory,
          showHeader: true,
          id: 'subcategory'
        },
        {
          label: 'Franchise',
          readOnly: true,
          value: business.franchise,
          showHeader: true,
          id: 'franchise'
        }
      ]
    },
    {
      section: 'Contact Information',
      pills_left: [
        {
          label: 'First name Last name',
          readOnly: false,
          value: [[business.firstName, business.lastName]],
          showHeader: true,
          id: '',
          headers: [
            { label: 'First name', id: 'firstName', value: business.firstName },
            { label: 'Last name', id: 'lastName', value: business.lastName }
          ]
        },
        {
          label: 'Title',
          readOnly: false,
          value: business.title,
          showHeader: true,
          id: 'title',
          headers: [
            {
              label: 'Title',
              id: 'title',
              value: business.title
            }
          ]
        },
        {
          label: 'Phone number',
          readOnly: true,
          value: business.phone[0],
          showHeader: true,
          id: 'phone',
          headers: [
            {
              label: 'Phone number',
              id: 'phone',
              value: business.phone[0]
            }
          ]
        },
        {
          label: 'Email address',
          readOnly: false,
          value: business.email,
          showHeader: true,
          id: 'email',
          headers: [
            {
              label: 'Email address',
              id: 'email',
              value: business.email
            }
          ]
        },
        {
          label: 'Linkedin',
          readOnly: false,
          value: business.linkedin,
          showHeader: true,
          id: 'linkedin',
          headers: [
            {
              label: 'Linkedin',
              id: 'linkedin',
              value: business.linkedin
            }
          ]
        }
      ],
      pills_right: [
        {
          label: 'Location Sales',
          readOnly: false,
          value: business.locationSales,
          showHeader: true,
          id: 'locationSales',
          headers: [
            {
              label: 'Location Sales',
              id: 'locationSales',
              value: business.locationSales
            }
          ]
        },
        {
          label: 'Distance to project',
          readOnly: true,
          value: 10,
          showHeader: true,
          headers: [
            {
              label: 'Distance to project',
              id: 'distanceToProject',
              value: 10
            }
          ]
        },
        {
          label: 'Year established',
          readOnly: true,
          value: business.yearEstablished,
          showHeader: true,
          id: 'yearEstablished',
          headers: [
            {
              label: 'Year established',
              id: 'yearEstablished',
              value: business.yearEstablished
            }
          ]
        },
        {
          label: 'Source',
          readOnly: false,
          value: business.source,
          showHeader: true,
          id: 'source',
          headers: [
            {
              label: 'Source',
              id: 'source',
              value: business.source
            }
          ]
        }
      ]
    }
  ];

  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  useEffect(() => {
    if (projectType === 'Local') setBusinessDetailsConstant(LOCAL_BUSSINESS_DETAILS_CONSTANTS);
    else setBusinessDetailsConstant(NATIONAL_BUSSINESS_DETAILS_CONSTANTS);
  }, [projectType, business]);

  const postDetails = async (data: any) => {
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: '/v1/businessDetail/updateBusinessDetails',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: JSON.stringify({
        ...data,
        businessId: businessId,
        projectId: storage.get('projectId')
      })
    };

    axios
      .request(config)
      .then((response: any) => {
        setchangesMadeInBusiness(true);
        setBusinessDetails(response.data);
      })
      .catch((error: any) => {});
  };

  const { status: mutationStatus, mutateAsync: updateDetailsAPI } = useMutation(postDetails, {
    onSuccess: data => {}
  });

  useEffect(() => {
    if (propertyUpdated) updateDetailsAPI(editTextValue);
  }, [editTextValue]);

  return (
    <div>
      <div className={styles.report_error}>
        <div
          onClick={() => {
            setShowErrorModal(true);
          }}
        >
          <img
            src='/report_error.svg'
            alt=''
            width='16px'
            style={{ marginRight: '4px', verticalAlign: 'sub' }}
          />{' '}
          Report Error
        </div>
      </div>
      {businessDetailConstant &&
        businessDetailConstant.map((section: any) => {
          return (
            <>
              <div style={{ marginBottom: '20px' }}>
                <div className={styles.section_header}>{section.section}</div>
                <div className={styles.business_details}>
                  <div className={styles.business_details_left}>
                    {section.pills_left.map((pill: any, index: any) => {
                      return (
                        <>
                          {(pill.value || pill.label === 'Source') && (
                            <div
                              className={
                                pill.showHeader
                                  ? styles.business_detail_pill
                                  : styles.business_detail_pill_header
                              }
                            >
                              {pill.showHeader && (
                                <div className={styles.business_detail_pill_header}>
                                  {pill.label}
                                </div>
                              )}
                              <div
                                className={styles.business_detail_pill_value}
                                onClick={() => {
                                  if (!pill.readOnly) {
                                    pill.label !== 'Phone number' && setShowModal(true);
                                    setEditTextHeader(`Update ${pill.label}`);
                                    setValueLabel(pill.headers);
                                    setPropertyUpdated(pill.headers);
                                  }
                                }}
                              >
                                {Array.isArray(pill.value) ? (
                                  pill.value.map((element: any) => {
                                    return Array.isArray(element) ? (
                                      element.map((el: any, index: any) => {
                                        return (
                                          <>
                                            {el} {index === element.length - 1 && <br />}
                                          </>
                                        );
                                      })
                                    ) : (
                                      <>
                                        {element} <br />
                                      </>
                                    );
                                  })
                                ) : (
                                  <>
                                    <>
                                      {pill.label === 'Phone number'
                                        ? formatPhoneNumber(pill.value)
                                        : pill.value}
                                    </>
                                    <>
                                      {pill.label === 'Phone number' && (
                                        <img
                                          src={'/images/recheck.svg'}
                                          alt=''
                                          style={{
                                            cursor: 'pointer',
                                            marginLeft: '6px',
                                            marginBottom: '2px'
                                          }}
                                          onClick={async () => {
                                            if (showPhoneDate) {
                                              enqueueSnackbar('Rechecks allowed after 30 days.', {
                                                variant: 'error',
                                                header: 'Error'
                                              });
                                            }
                                            let data = JSON.stringify({
                                              phone: parseInt(pill.value)
                                            });

                                            let config = {
                                              method: 'post',
                                              url: '/v1/project/phoneValidation',
                                              headers: {
                                                'Content-Type': 'application/json',
                                                Authorization: `Bearer ${storage.get(
                                                  'accessToken'
                                                )}`
                                              },
                                              data: data
                                            };
                                            const response = await axios.request(config);
                                            setShowPhoneData(true);
                                            setIsDNC(response.data.isDNC);
                                            setPhoneType(response.data.phoneType);
                                          }}
                                        />
                                      )}
                                    </>
                                  </>
                                )}
                              </div>

                              {pill.label === 'Phone number' && showPhoneDate && (
                                <div className={styles.business_detail_pill_value_phone}>
                                  <div
                                    style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                                  >
                                    <img
                                      src={isDNC ? '/images/dnc_error.svg' : '/images/dnc_succ.svg'}
                                      alt=''
                                    />
                                    <span>
                                      {phoneType} - {formatPhoneNumber(business.phone[0])}
                                    </span>
                                  </div>
                                  <p>
                                    {isDNC
                                      ? 'Phone number is in Do Not Call list'
                                      : 'Phone number is not in Do Not Call list'}
                                  </p>
                                  <p>Last checked on 08/10/2023</p>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div className={styles.business_details_right}>
                    {section.pills_right.map((pill: any, index: any) => {
                      return (
                        <>
                          {(pill.value || pill.label === 'Source') && (
                            <div
                              className={
                                pill.showHeader
                                  ? styles.business_detail_pill
                                  : styles.business_detail_pill_header
                              }
                            >
                              {pill.showHeader && (
                                <div className={styles.business_detail_pill_header}>
                                  {pill.label}
                                </div>
                              )}
                              <div
                                className={styles.business_detail_pill_value}
                                onClick={() => {
                                  if (!pill.readOnly) {
                                    setShowModal(true);
                                    setEditTextHeader(`${pill.label}`);
                                    setPropertyUpdated(pill.id);
                                    setValueLabel(pill.headers);
                                  }
                                }}
                              >
                                {pill.value ? pill.value : 'None'}
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      <ResponseDetailsModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={editTextHeader}
        valueLabel={valueLabel}
        setEditTextValue={setEditTextValue}
        setSelectedSource={setSelectedSource}
        selectedSource={selectedSource}
      />
      <ReportErrorModal
        showModal={errorModal}
        setShowModal={setShowErrorModal}
        businessId={businessId}
      />
    </div>
  );
};

export default BusinessDetails;
