export const ADD_PROJECT_FORM_STEPS = [
  'Add project',
  'Details',
  // 'Property and community description',
  'Description',
  // 'Due diligence, flyers and offering memorandum',
  'Documents',
  // 'Upload photos and Documents'
  'Photos'
];
export const VIEW_PROJECT_FORM_STEPS = [
  'View project',
  'Details',
  // 'Property and community description',
  'Description',
  // 'Due diligence, flyers and offering memorandum',
  'Documents',
  // 'Photos and Documents'
  'Photos'
];
