import { useState, forwardRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface ReportCompleteProps extends CustomContentProps {
  header?: string;
}

const CustomSnackbar = forwardRef<HTMLDivElement, ReportCompleteProps>((props, ref) => {
  const { id, header,variant,message } = props;
  const useStyles = makeStyles(() => ({
    root: {
      minheight: '80px',
      maxWidth: '200px',
      '@media (min-width:600px)': {
        minWidth: '344px !important'
      }
    },
    card: {
      display: 'flex',
      backgroundColor: '#ffffff',
      width: '90%'
    },
    div1: {
      width: '2%',
      backgroundColor: variant==='error'?'#FF0000':'#43a047'
    },
    typography1: {
      fontSize: '16px',
      fontWeight: '700',
      margin: '0px',
      marginBottom: '10px'
    },
    typography: {
      color: '#000',
      marginLeft: '0px !important'
    },
    actionRoot: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      alignItems: 'start !important'
      // padding: "8px 8px 8px 1px",
      // justifyContent: "space-between"
    },
    icons: {
      display: 'flex',
      // width: '18%',
      justifyContent: 'end'
    },
    icon_wrapper: {
      height: '20px'
    },
    expand: {
      padding: '8px 8px',
      transform: 'rotate(0deg)',
      color: '#000',
      transition: 'all .2s'
    },
    checkIcon: {
      fontSize: 20,
      paddingRight: 4
    },
    button: {
      padding: 0,
      textTransform: 'none'
    }
  }));
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.div1}></div>
        <CardActions classes={{ root: classes.actionRoot }}>
          <p className={classes.typography1}>{header}</p>
          <Typography variant='body2' className={classes.typography}>
            {message}
          </Typography>
        </CardActions>
        <div className={classes.icons}>
          <div className={classes.icon_wrapper}>
            <IconButton size='small' className={classes.expand} onClick={handleDismiss}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
        </div>
      </Card>
    </SnackbarContent>
  );
});

CustomSnackbar.displayName = 'CustomSnackbar';

export default CustomSnackbar;
