import React, { useState, useContext, useEffect } from 'react';
import styles from './header.module.scss';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getCurrentPathWithoutLastPart } from '../../../../utils/getBaseUrl';
import { cx } from '../../../../utils/class-name-service';
import { logout } from '../../../../utils/user-actions-helper';
import Storage from '../../../../utils/local-storage';
import { avatarGenerator } from '../../../../utils/avatarGenerator';
import UserProfileModal from '../../../modals/user-profile/UserProfileModal';
import useClickOutside from '../../../../utils/hooks/useClickOutside';
import { ProjectContext } from '../../../../context/ProjectContext';
import SearchBar from '../../../search-bar';
import NotificationModal from '../../../modals/notification/NotificationModal';
import axios from 'axios';
import { useQuery } from 'react-query';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [counter, setCounter] = useState();

  const location = useLocation();
  const { imageURL, setImageURL } = useContext(ProjectContext);
  const { userData, setUserData } = useContext(ProjectContext);

  const navigate = useNavigate();

  const storage = new Storage();
  const projectName = storage.get('projectName');
  const projectId: any = storage.get('projectId');
  const isProjectId = projectId ? true : false;
  const userName = storage.get('userName');
  let name = userName.split(' ');
  const email = storage.get('email');
  const accessToken = storage.get('accessToken');
  const path = getCurrentPathWithoutLastPart(location.pathname);
  const currentURL = window.location.pathname;

  let domNode: any = useClickOutside(() => {
    setIsMenuOpen(false);
  });
  let domNode1: any = useClickOutside(() => {
    setIsNotificationModalOpen(false);
  });
  const getReminder = async () => {
    const config = {
      method: 'get',
      url: `/v1/reminder/${projectId}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    return res.data;
  };
  const {
    data: reminder,
    status: reminderStatus,
    refetch
  } = useQuery(`getReminder`, getReminder, {
    enabled: isProjectId,
    onSuccess: res => {
      let today = new Date();
      let filterdReminder = res?.filter((item: any) => new Date(item['reminderDate']) >= today);
      let result = filterdReminder.filter((item: any) => item.seen === false);
      setCounter(result.length);
    }
  });
  useEffect(() => {
    if (storage.get('projectId')) refetch(); // Trigger the API call whenever the route changes
  }, [location.pathname]);

  return (
    <div className={styles.header_sticky}>
      <div className={styles.header_wrapper} style={{ position: 'relative' }}>
        <div
          className={styles.header_logo}
          onClick={() => {
            if (accessToken) {
              navigate('/projects');
            } else {
              navigate('/');
            }
          }}
        >
          <img src='/RTLogo.svg' alt='logo' width={192} />
        </div>
        {(path === '/dashboard' ||
          path === '/templates' ||
          path === '/create-template' ||
          path === '/email-automation' ||
          path === '/activity-report') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '75%'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'unset',
                cursor: 'pointer',
                width: '100%',
                maxWidth: '300px'
              }}
              onClick={() => {
                window.location.href = `/view-project/${projectId}`;
              }}
            >
              <div style={{ marginRight: '10px' }}>
                <svg
                  width='12'
                  height='16'
                  viewBox='0 0 12 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6 0.5C3.10125 0.5 0.75 2.85125 0.75 5.75C0.75 9.6875 6 15.5 6 15.5C6 15.5 11.25 9.6875 11.25 5.75C11.25 2.85125 8.89875 0.5 6 0.5ZM6 7.625C4.965 7.625 4.125 6.785 4.125 5.75C4.125 4.715 4.965 3.875 6 3.875C7.035 3.875 7.875 4.715 7.875 5.75C7.875 6.785 7.035 7.625 6 7.625Z'
                    fill='#D81F1F'
                  />
                </svg>
              </div>
              <span
                style={{
                  width: '100%',
                  fontFamily: "'Lato'",
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '30px',
                  color: '#222222',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {projectName}
              </span>
            </div>
            <div
              style={{
                color: '#aaa',
                fontSize: '16px',
                width: '440px',
                minWidth: '440px',
                zIndex: '1000',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <SearchBar />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginLeft: 'auto' }}>
              <span
                className={
                  location.pathname === '/activity-report'
                    ? cx(styles.activity_report, styles.header_link_selected)
                    : styles.activity_report
                }
                onClick={() => (window.location.href = '/activity-report')}
              >
                Activity Report
              </span>

              <span
                // className={styles.activity_report}
                className={
                  location.pathname === '/email-automation'
                    ? cx(styles.activity_report, styles.header_link_selected)
                    : styles.activity_report
                }
                onClick={() => {
                  navigate('/email-automation');
                }}
              >
                {' '}
                Email Automation{' '}
              </span>

              <div
                className={styles.notification}
                ref={domNode1}
                onClick={() => {
                  setIsNotificationModalOpen(!isNotificationModalOpen);
                }}
              >
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M4.70014 2.03285C5.57531 1.15768 6.76229 0.666016 7.99997 0.666016C9.23765 0.666016 10.4246 1.15768 11.2998 2.03285C12.175 2.90802 12.6666 4.09501 12.6666 5.33268C12.6666 7.56779 13.1447 8.95577 13.5852 9.76345C13.8064 10.169 14.0218 10.435 14.1714 10.5929C14.2464 10.6721 14.3053 10.7246 14.3408 10.7541C14.3586 10.7689 14.3705 10.778 14.3756 10.7818C14.3764 10.7824 14.377 10.7829 14.3775 10.7832C14.6165 10.9474 14.7223 11.2477 14.638 11.5259C14.5529 11.807 14.2937 11.9993 14 11.9993H1.99997C1.70619 11.9993 1.44704 11.807 1.36191 11.5259C1.27768 11.2477 1.38344 10.9474 1.62243 10.7832C1.6229 10.7829 1.62353 10.7824 1.62434 10.7818C1.62947 10.778 1.64137 10.7689 1.65912 10.7541C1.6946 10.7246 1.75352 10.6721 1.8285 10.5929C1.97812 10.435 2.19351 10.169 2.41471 9.76345C2.85526 8.95577 3.3333 7.56779 3.3333 5.33268C3.3333 4.09501 3.82497 2.90802 4.70014 2.03285ZM1.62917 10.7787C1.62922 10.7786 1.62927 10.7786 1.62933 10.7785C1.62932 10.7785 1.62932 10.7785 1.62932 10.7786L1.62917 10.7787ZM3.43285 10.666H12.5671C12.5164 10.5829 12.4654 10.4949 12.4147 10.4019C11.8553 9.37626 11.3333 7.76424 11.3333 5.33268C11.3333 4.44863 10.9821 3.60078 10.357 2.97566C9.73187 2.35054 8.88403 1.99935 7.99997 1.99935C7.11592 1.99935 6.26807 2.35054 5.64295 2.97566C5.01783 3.60078 4.66664 4.44863 4.66664 5.33268C4.66664 7.76424 4.14468 9.37626 3.58523 10.4019C3.5345 10.4949 3.48358 10.5829 3.43285 10.666Z'
                    fill='#384852'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.51213 13.4241C6.83062 13.2393 7.23857 13.3478 7.42331 13.6662C7.48192 13.7673 7.56603 13.8511 7.66724 13.9094C7.76844 13.9677 7.88319 13.9984 7.99998 13.9984C8.11677 13.9984 8.23152 13.9677 8.33272 13.9094C8.43393 13.8511 8.51804 13.7673 8.57665 13.6662C8.76139 13.3478 9.16934 13.2393 9.48783 13.4241C9.80631 13.6088 9.91473 14.0168 9.72998 14.3353C9.55417 14.6384 9.30182 14.8899 8.99821 15.0648C8.69459 15.2397 8.35036 15.3317 7.99998 15.3317C7.6496 15.3317 7.30537 15.2397 7.00175 15.0648C6.69814 14.8899 6.44579 14.6384 6.26998 14.3353C6.08523 14.0168 6.19365 13.6088 6.51213 13.4241Z'
                    fill='#384852'
                  />
                </svg>
                {reminderStatus === 'success' && counter ? (
                  <div className={styles.counter}>{counter}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )}
        {accessToken && !currentURL.includes('/updateBusinessDetails') && (
          <div className={styles.dropdown_user_menu_container}>
            <div
              className={styles.user_details}
              ref={domNode}
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <span className={styles.avatar}>
                <img
                  src={
                    imageURL && !imageURL.includes('undefined')
                      ? imageURL
                      : avatarGenerator(name[0][0], name[1][0])
                  }
                  alt='user'
                  className={styles.user_icon}
                />
              </span>
              {Object.keys(userData).length > 0 && (
                <div className={styles.user_name}>{`${userData?.firstName}`}</div>
              )}

              <div className={styles.dropdown_icon}>
                <img src='/drop_icon.svg' alt='dropdown' />
              </div>

              {isMenuOpen && <UserProfileModal name={name} userName={userName} email={email} />}
              {isNotificationModalOpen && <NotificationModal />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
