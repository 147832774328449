import React from 'react';
import Feature from '../../components/feature';
import RequestDemoLeftSection from '../../components/RequestDemoLeftSection';
import styles from './index.module.scss';
import { features } from './../../constants/feature-constants';
import RequestDemoForm from '../../containers/request-demo-form';
import SuccessMessage from '../../containers/success-message';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';

function RequestDemo() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const LP_EMAIL = location.state?.email;
  const isMobile = useCheckMobileScreen();

  // const submit = () => {
  //   setValue(1);
  // };
  return (
    <div className={styles.screen_wrapper}>
      {!isMobile && (
        <RequestDemoLeftSection>
          <div className={styles.featurecolumn}>
            <img
              src={'/images/ReachTenants-logo.svg'}
              height='43px'
              width='204px'
              className={styles.logo}
            />
            <div className={styles.heading}>
              The most effective way to <br />reach your next tenant
            </div>
            {features.map(feature => (
              <Feature
                featurename={feature.featurename}
                featuredescription={feature.featuredescription}
                image={feature.image}
              />
            ))}
          </div>
        </RequestDemoLeftSection>
      )}
      {!value && <RequestDemoForm setValue={setValue} email={LP_EMAIL} />}
      {!!value && <SuccessMessage />}
    </div>
  );
}

export default RequestDemo;
