import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './request-demo.module.scss';
import CustomisedTextField from '../../components/form-elements/customised-text-field/index';
import CustomSelectBox from '../../components/form-elements/customised-select-box/index';
import MenuItem from '@mui/material/MenuItem';
import WrappedButton from '../../components/form-elements/buttons/index';
import * as yup from 'yup';
import { BTN_PRIMARY } from '../../constants/button-constants';
import { useQuery } from 'react-query';
import AppConfig from '../../config';
import { useSnackbar } from 'notistack';
import Storage from '../../utils/local-storage';

const RequestDemoForm: React.FunctionComponent<props> = ({ setValue, email }) => {
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();
  const storage = new Storage();

  const phoneRegexWithParenthesis = /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/; //(234) 132-4123
  const fetchDropdownOrgName = async () => {
    const res = await fetch(
      `${BASE_URL}/v1/demorequest/dropdownvalues?dropdownname=Organization Type`
    );
    return res.json();
  };
  const { data: orgname, status: orgnamestatus } = useQuery(`orgname`, fetchDropdownOrgName);

  const fetchDropdownUserCount = async () => {
    const res = await fetch(
      `${BASE_URL}/v1/demorequest/dropdownvalues?dropdownname=Number of users`
    );
    return res.json();
  };
  const { data: usernum, status: usernumstatus } = useQuery(`usernum`, fetchDropdownUserCount);

  const fetchDropdownSource = async () => {
    const res = await fetch(
      `${BASE_URL}/v1/demorequest/dropdownvalues?dropdownname=How did you hear about us`
    );
    return res.json();
  };
  const { data: sourcedata, status: sourcestatus } = useQuery(`sourcedata`, fetchDropdownSource);

  const phoneValidator = () => {
    return yup
      .string()
      .matches(phoneRegexWithParenthesis, {
        message: 'Please provide a valid Phone/Mobile number',
        excludeEmptyString: false
      })
      .required('Phone number is required');
  };

  const validationSchema = yup.object({
    first_name: yup.string().trim().required('First name is required'),
    last_name: yup.string().trim().required('Last name is required'),
    email_address: yup
      .string()
      .trim()
      .email('Please enter a valid email address')
      .required('Email address is required'),
    company_name: yup.string().trim().required('Company name is required'),
    phone_number: phoneValidator(),
    number_of_users: yup.string().required('Number of users is required'),
    business_type: yup.string().required('Organization type is required')
  });

  const postDemoRequest = async (data: any) => {
    const response = await fetch(`${BASE_URL}/v1/demorequest`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    response
      .json()
      .then(res => {
        if (res?.code === 500) {
          throw Error(res.message);
        } else {
          setValue(1);
          enqueueSnackbar('Request sent', { variant: 'success', header: 'Successfully sent' });
        }
      })
      .catch(err => {
        let message = err.message;
        enqueueSnackbar(message, { variant: 'error', header: 'Error' });
      });
    // if (!response.ok) {
    //   throw Error('something went wrong');
    // } else setValue(1);
    // return response.json();
  };

  return (
    <>
      <div className={styles.form_wrapper}>
        <div className={styles.form_heading}>Book a demo</div>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            company_name: '',
            email_address: email,
            phone_number: '',
            number_of_users: '',
            business_type: '',
            source: ''
          }}
          onSubmit={values => {
            storage.save('bookDemoName', values.first_name);
            storage.save('bookDemoEmail', values.email_address);
            values.phone_number = values.phone_number.replace(/\./g, '');
            postDemoRequest(values);
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          validateOnChange={true}
        >
          {props => (
            <Form className={styles.form_container}>
              <div className={styles.two_fields}>
                <div className={styles.single_field}>
                  <Field
                    name='first_name'
                    placeholder={'First name'}
                    title={'First name'}
                    type={'text'}
                    component={CustomisedTextField}
                    required={true}
                  />
                </div>

                <div className={styles.single_field}>
                  <Field
                    name='last_name'
                    placeholder={'Last name'}
                    title={'Last name'}
                    type={'text'}
                    component={CustomisedTextField}
                    required={true}
                  />
                </div>
              </div>
              <div className={styles.two_fields}>
                <div className={styles.single_field}>
                  <Field
                    name='company_name'
                    placeholder={'Company name'}
                    title={'Company name'}
                    type={'text'}
                    component={CustomisedTextField}
                    required={true}
                  />
                </div>

                <div className={styles.single_field}>
                  <Field
                    name='email_address'
                    placeholder={'Email address'}
                    title={'Email address'}
                    type={'text'}
                    component={CustomisedTextField}
                    required={true}
                  />
                </div>
              </div>

              <div className={styles.two_fields}>
                <div className={styles.single_field}>
                  <Field
                    name='phone_number'
                    placeholder={'Phone number'}
                    title={'Phone number'}
                    type={'text'}
                    isPhone={true}
                    component={CustomisedTextField}
                    required={true}
                  />
                </div>

                <div className={styles.single_field}>
                  <Field
                    name='number_of_users'
                    component={CustomSelectBox}
                    label={'Number of users'}
                    required={true}
                  >
                    {usernumstatus === 'success' &&
                      usernum.map((el: String) => <MenuItem value={`${el}`}>{el}</MenuItem>)}
                    {/* <MenuItem value={'1-10'}>1-10</MenuItem>
                    <MenuItem value={'1-10'}>20-30</MenuItem> */}
                  </Field>
                </div>
              </div>

              <div className={styles.two_fields}>
                <div className={styles.single_field}>
                  <Field
                    name='business_type'
                    component={CustomSelectBox}
                    label={'Organization type'}
                    required={true}
                  >
                    {orgnamestatus === 'success' &&
                      orgname.map((el: String) => <MenuItem value={`${el}`}>{el}</MenuItem>)}
                  </Field>
                </div>

                <div className={styles.single_field}>
                  <Field
                    name='source'
                    component={CustomSelectBox}
                    label={'How did you hear about us?'}
                  >
                    {sourcestatus === 'success' &&
                      sourcedata.map((el: String) => <MenuItem value={`${el}`}>{el}</MenuItem>)}
                  </Field>
                </div>
              </div>

              <div className={styles.request_access_button_wrapper}>
                <WrappedButton
                  btnText='Request Demo'
                  type='submit'
                  btnType={BTN_PRIMARY}
                  className={styles.request_access_button}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RequestDemoForm;
