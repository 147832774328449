import React, { useEffect, useState } from 'react';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './set-reminder.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, GREY_BUTTON } from '../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import AppConfig from '../../../config';
import { useSnackbar } from 'notistack';
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, DayValue, utils } from '@hassanmojab/react-modern-calendar-datepicker';
// import { Calendar, DayValue, utils } from 'react-modern-calendar-datepicker';
import * as yup from 'yup';
import Storage from '../../../utils/local-storage';

const SetReminder: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  bussinessId,
  queryClient
}) => {
  const [selectedDay, setSelectedDay] = useState<DayValue>(utils('en').getToday());
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();

  const setReminderPost = async (data: any) => {
    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/reminder`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: data
    };

    return await axios(config);
  };
  const { status: mutationStatus, mutateAsync: setReminder } = useMutation(setReminderPost, {
    onSuccess: (res: any) => {
      setShowModal(false);
      let message = res.data.message;
      queryClient.invalidateQueries(`getReminder`);
      enqueueSnackbar(message, { variant: 'success', header: 'Success' });
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '649px', width: '100%', zIndex: '999999999 !important' }}
      >
        <Formik
          initialValues={{
            title: ''
          }}
          validationSchema={yup.object({
            title: yup.string().trim().required('Title is required')
          })}
          onSubmit={values => {
            let date = new Date(`${selectedDay?.year}-${selectedDay?.month}-${selectedDay?.day}`);
            const data = {
              title: values.title,
              reminderDate: date,
              bussinessId: bussinessId,
              projectId: storage.get('projectId')
            };
            setReminder(data);
          }}
        >
          {props => (
            //TODO: Add member form validation
            <Form>
              <ModalHeader
                toggle={() => {
                  setShowModal(!showModal);
                }}
              >
                Set reminder
              </ModalHeader>
              <ModalBody>
                <div className={styles.form_wrapper}>
                  <div className={styles.single_field}>
                    <Field
                      name='title'
                      placeholder='Enter Title'
                      component={CustomisedTextField}
                      required={true}
                      // disabled={}
                    />
                  </div>
                  <Calendar
                    value={selectedDay}
                    onChange={setSelectedDay}
                    colorPrimary=' #2A72DD' // added this
                  />
                </div>
                {/* </div> */}
              </ModalBody>
              <ModalFooter>
                <div className={styles.button_wrapper}>
                  <WrappedButton
                    btnText='Cancel'
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setShowModal(!showModal);
                    }}
                    btnType={GREY_BUTTON}
                    className={styles.submit_button}
                  />
                  <WrappedButton
                    btnText={'Add'}
                    btnType={BTN_PRIMARY}
                    className={styles.submit_button}
                    // isInProgress={mutationStatus === 'loading' ? true : false}
                  />
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SetReminder;
