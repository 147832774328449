import { useEffect, useState } from 'react';
import { cx } from '../../utils/class-name-service';
import ResponseDetailsModal from '../modals/response-details';
import styles from './index.module.scss';

interface BusinessDetailPillProp {
  header: string;
  value: any;
  type?: any;
  updateDetails?: any;
  id?: any;
  responseDetailId?: any;
  section?: any;
}
function formatTime(isoTimeString: any) {
  // Convert ISO string to Date object
  const date = new Date(isoTimeString);

  // Format date object as desired
  const formatted = date.toLocaleString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  return formatted;
}
function BusinessDetailPill(props: BusinessDetailPillProp) {
  const [showModal, setShowModal] = useState(false);
  const [editTextHeader, setEditTextHeader] = useState('');
  const [editTextValue, setEditTextValue] = useState('');

  useEffect(() => {
    if (editTextValue && props?.id)
      props?.updateDetails(editTextValue, props.id, props.responseDetailId, 'text');
  }, [editTextValue]);

  return (
    <div className={styles.business_detail_pill}>
      <div
        className={
          (props.type === 'text' || props.type === 'date') && !props.section
            ? cx(styles.business_detail_pill_header, styles.business_detail_pill_header_hover)
            : cx(styles.business_detail_pill_header)
        }
        onClick={() => {
          if ((props.type === 'text' || props.type === 'date') && !props.section) {
            setShowModal(true);
            setEditTextHeader(props.header);
          }
        }}
      >
        {props.type === 'checkbox' && (
          <div className={styles.checkbox_options_wrapper}>
            <label className={cx(styles.checkbox, styles.bounce)}>
              <input
                type='checkbox'
                checked={props?.value?.checked}
                onChange={event => {
                  props.updateDetails(event.target.checked, props.id, props.responseDetailId);
                }}
              />
              <svg viewBox='0 0 21 21'>
                <polyline points='5 10.75 8.5 14.25 16 6'></polyline>
              </svg>
            </label>
          </div>
        )}

        {(props.type === 'text' || props.type === 'date') && !props.section && (
          <div className={styles.checkbox_options_wrapper}>
            <strong>&#x2b;</strong>
          </div>
        )}
        {props.header}
      </div>
      {props.type === 'checkbox' && props?.value?.checked && (
        <div className={styles.business_detail_pill_value}>
          <p>{formatTime(props?.value?.atTime)}</p>
          <span>{props?.value?.userName}</span>
        </div>
      )}
      {(props.type === 'text' || props.type === 'date') &&
        props?.value &&
        (props.section ? (
          <div className={styles.business_detail_pill_value}>
            <span>{formatTime(props?.value)}</span>
          </div>
        ) : (
          props?.value.value && (
            <div className={styles.business_detail_pill_value}>
              {props.id === 'other' || props.type === 'date' ? (
                <span> {props?.value?.value}</span>
              ) : (
                <span>
                  $ {props?.value?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </span>
              )}

              <p>{formatTime(props?.value?.atTime)}</p>
              <span>{props?.value?.userName}</span>

              <div
                className={styles.row_delete}
                onClick={() => {
                  props.updateDetails('', props.id, props.responseDetailId);
                }}
              >
                <img src='/images/del.svg' alt='' />
              </div>
            </div>
          )
        ))}
      <ResponseDetailsModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={editTextHeader}
        setEditTextValue={setEditTextValue}
        type={props.type}
      />
    </div>
  );
}

export default BusinessDetailPill;
