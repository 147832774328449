import React, { useEffect, useState } from 'react';
import { props } from './model';
import styles from './uploadFiles.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY, GREY_BUTTON } from '../../../constants/button-constants';
import DragAndDrop from '../../drag-and-drop';
import { HandleFileDelete } from '../../../utils/form-handler';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import ChooseFromProject from '../chooseFromProject';
import { useSnackbar } from 'notistack';

const UploadFiles: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  imagesUrl,
  setImagesUrl,
  showAttachedFiles,
  setShowAttachedFiles,
  setchangesMade
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();

  const deleteHandler = (file: any) => {
    let index = imagesUrl.indexOf(file);
    imagesUrl.splice(index, 1);
    setImagesUrl([...imagesUrl]);
  };
  function hasDuplicates(arr1: any, arr2: any, arr3: any) {
    const combinedArray = [...arr1, ...arr2, ...arr3];
    return new Set(combinedArray).size !== combinedArray.length;
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: step !== 1 ? '649px' : '769px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        >
          {step !== 1 ? (
            'Upload files'
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div className={styles.circular_hover} onClick={() => setStep(step => step - 1)}>
                <img src='/back-arrow.svg' alt='Back' width='24' height='24' />
              </div>
              <div> Choose from Project</div>
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          <div className={styles.dragAndDrop_container}>
            {step !== 1 ? (
              <>
                <DragAndDrop
                  imagesUrl={imagesUrl}
                  setImagesUrl={setImagesUrl}
                  comp={'AttachFiles'}
                />
                <div className={styles.applied_filters}>
                  {imagesUrl?.length > 0 &&
                    imagesUrl.map((link: any, index: any) => (
                      <div
                        className={styles.applied_filter}
                        key={index}
                        onClick={() => {
                          window.open(link);
                        }}
                      >
                        <abbr
                          title={link.substring(link.lastIndexOf('/') + 1, link.length)}
                          className={styles.applied_filter_text}
                          style={{ cursor: 'pointer' }}
                        >
                          {link.substring(link.lastIndexOf('/') + 1, link.length)}
                        </abbr>
                        <span
                          className={styles.applied_filter_close}
                          onClick={e => {
                            e.stopPropagation();
                            deleteHandler(link);
                          }}
                        >
                          X
                        </span>
                      </div>
                    ))}
                </div>
                <div className={styles.file_label}>
                  <div className={styles.or_underline}></div>
                  <div className={styles.label_styles}>Or</div>
                  <div className={styles.or_underline}></div>
                </div>
                <div className={styles.ChoosefromDashboard_btn}>
                  <WrappedButton
                    btnText='Choose from Project'
                    onClick={() => {
                      setStep(step => step + 1);
                    }}
                    btnType={BTN_PRIMARY}
                    className={styles.submit_button}
                    // isInProgress={mutationStatus === 'loading' ? true : false}
                  />
                </div>
              </>
            ) : (
              <>
                <ChooseFromProject
                  imagesUrl={imagesUrl}
                  setImagesUrl={setImagesUrl}
                  showAttachedFiles={showAttachedFiles}
                  setShowAttachedFiles={setShowAttachedFiles}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  setchangesMade={setchangesMade}
                />
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={styles.button_wrapper}>
            <WrappedButton
              btnText='Cancel'
              onClick={() => {
                setShowModal(!showModal);
              }}
              btnType={GREY_BUTTON}
              className={styles.submit_button}
            />
            <WrappedButton
              btnText='Import'
              onClick={() => {
                let arr = selectedFiles
                  ? selectedFiles
                      ?.filter((item: any) => item?.isChecked)
                      ?.map((item: any) => item.img)
                  : [];

                if (hasDuplicates(showAttachedFiles, imagesUrl, arr)) {
                  enqueueSnackbar('Cannot upload duplicate files', {
                    variant: 'error',
                    header: 'Error'
                  });
                } else {
                  if (arr?.length > 0)
                    setShowAttachedFiles([...showAttachedFiles, ...imagesUrl, ...arr]);
                  else setShowAttachedFiles([...showAttachedFiles, ...imagesUrl]);
                  if (setchangesMade) setchangesMade(true);
                  setShowModal(false);
                }
              }}
              btnType={BTN_PRIMARY}
              className={styles.submit_button}
              // isInProgress={mutationStatus === 'loading' ? true : false}
            />
          </div>
        </ModalFooter>
      </Modal>
      {isUploading ? (
        <Backdrop sx={{ color: '#0c252d', zIndex: '100' }} open>
          {<CircularProgress color='inherit' />}
        </Backdrop>
      ) : (
        <></>
      )}
    </>
  );
};

export default UploadFiles;
