import React, { useEffect, useState, useContext } from 'react';
import styles from './edit-email.module.scss';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import CustomisedTextField from '../form-elements/customised-text-field';
import WrappedButton from '../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../constants/button-constants';
import UploadFiles from '../modals/uploadFiles';
import { props } from './props';
import AppConfig from '../../config';
import axios from 'axios';
import Storage from '../../utils/local-storage';
import { useMutation, useQuery } from 'react-query';
import RichTextEditor from '../rich-text-Editor/RichTextEditor';
import { replaceVariableWithValue } from '../../utils/emailTemplate';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import SavedListModal from '../modals/saved-list';
import { ProjectContext } from '../../context/ProjectContext';
import { useNavigate } from 'react-router-dom';

const EditEmail: React.FunctionComponent<props> = ({
  templateId,
  enableEdit,
  nextButtonHandler,
  step,
  templateBusiness,
  loader,
  deleteRef,
  comp,
  setEnableEdit,
  shallowEdit,
  editedEmails,
  setEditedEmails,
  refetch1,
  savedCompetitorList,
  setCurrentTemplate
}) => {
  const [emailSubject, setEmailSubject] = useState('');
  const [message, setMessage] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [convertedContent, setConvertedContent] = useState<any>('');
  const [imagesUrl, setImagesUrl] = useState<any>([]);
  const [showAttachedFiles, setShowAttachedFiles] = useState<any>([]);
  const [changesMade, setchangesMade] = useState(false);
  const [fileDeletedStatus, setfileDeletedStatus] = useState(false);
  const [showSavedCompetitorModal, setShowSavedCompetitorModal] = useState(false);
  const { userData, setUserData } = useContext(ProjectContext);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedCompetitorList, setSelectedCompetitorList] = useState('');
  const navigate = useNavigate();

  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();
  const id = templateId;
  const getTemplatesDetail = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/template/getTemplate/${id}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    return res.data;
  };

  const {
    data: templateDetails,
    status: templateDetailStatus,
    isLoading,
    refetch
  } = useQuery(`getTemplatesDetail`, getTemplatesDetail);

  useEffect(() => {
    if (templateDetailStatus === 'success') {
      setTemplateName(templateDetails?.name);
      setEmailSubject(templateDetails?.emailSubject);
      setShowAttachedFiles(templateDetails?.filesAttach);
      setSelectedCompetitorList(templateDetails?.competitorList);
      let updatedBody;
      if (step === 1) {
        updatedBody = templateDetails?.emailMessage;
      } else {
        const editedBusiness = editedEmails.find((o: any) => o.businessId === templateBusiness._id);
        if (editedBusiness) {
          updatedBody = editedBusiness.message;
        } else {
          updatedBody = replaceVariableWithValue(templateDetails?.emailMessage, templateBusiness);
        }
      }
      setConvertedContent(updatedBody);
    }
  }, [templateId, templateDetails, templateBusiness, step]);

  useEffect(() => {
    refetch().then(res => {});
  }, [templateId]);

  const editTemplateHandler = async (data: any) => {
    const requestData = JSON.stringify({
      _id: templateId,
      emailSubject: data.emailSubject,
      emailMessage: data.message,
      filesAttach: data.showAttachedFiles,
      name: data.templateName,
      competitorList: selectedCompetitorList ? selectedCompetitorList : ''
    });
    if (data.message === '' || data.message === '<p></p>') {
      enqueueSnackbar('Message body is required', { variant: 'error', header: 'Error' });
    } else {
      const config = {
        method: 'put',
        url: `${BASE_URL}/v1/template/editTemplate`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storage.get('accessToken')}`
        },
        data: requestData
      };

      return await axios(config);
    }
  };
  const { status: mutationStatus, mutateAsync: editTemplate } = useMutation(editTemplateHandler, {
    onSuccess: res => {
      setCurrentTemplate(templateId);
      refetch();
      refetch1();

      if (fileDeletedStatus) {
        setfileDeletedStatus(false);
        enqueueSnackbar('File deleted Successfully', { variant: 'success', header: 'Success' });
      } else {
        enqueueSnackbar(res?.data.messgage, { variant: 'success', header: 'Success' });
        setEnableEdit(false);
        setchangesMade(false);
      }
    }
  });
  const onSubmit = (data: any) => {
    if (shallowEdit) {
      setEditedEmails([
        ...editedEmails,
        { businessId: templateBusiness._id, message: data.message }
      ]);
      setEnableEdit(false);
      setchangesMade(false);
    } else editTemplate({ ...data, showAttachedFiles });
  };
  const deleteHandler = (file: any) => {
    setIsUploading(true);

    // HandleFileDelete(file)
    //   .then(res => {
    //     const response = res.data.message;
    let index = showAttachedFiles.indexOf(file);
    showAttachedFiles.splice(index, 1);
    setchangesMade(true);
    setIsUploading(false);
    setShowAttachedFiles([...showAttachedFiles]);

    let data = {
      emailSubject: emailSubject,
      templateName,
      message: convertedContent ? convertedContent : '<p></p>',
      showAttachedFiles: [...showAttachedFiles]
    };
    setfileDeletedStatus(true);
    editTemplate(data);

    //   if (response == 'okay') {
    //   }
    // })
    // .catch(() => {
    //   setIsUploading(false);
    // });
  };
  const validationSchema = yup.object().shape({
    templateName: yup.string().required('Template name is required'),
    emailSubject: yup.string().required('Email subject is required')
    // message: yup.string().required('Message is required')
  });
  return (
    <div className={styles.create_template_container}>
      <div className={styles.header}>
        <h4 className={styles.heading}>{templateName}</h4>
        {!enableEdit && (
          <p className={styles.heading_info}>{userData.basicEmailCounter} emails left for today</p>
        )}
      </div>
      <div className={styles.business_detail_pill_value}>
        <Formik
          initialValues={{
            templateName,
            emailSubject,
            message: convertedContent ? convertedContent : '<p></p>'
          }}
          onSubmit={values => {
            onSubmit(values);
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          validateOnChange={true}
        >
          {({ dirty, isSubmitting }) => (
            <Form>
              <div className={styles.form_container}>
                <div className={styles.form_field}>
                  <Field
                    name='templateName'
                    placeholder={'Template name'}
                    title={'Template name'}
                    type={'text'}
                    component={CustomisedTextField}
                    disabled={!enableEdit || shallowEdit}
                  />
                </div>
                <div className={styles.form_field}>
                  <Field
                    name='emailSubject'
                    placeholder={'Email subject'}
                    title={'Email subject'}
                    type={'text'}
                    component={CustomisedTextField}
                    disabled={!enableEdit || shallowEdit}
                  />
                </div>
                <div className={styles.form_field}>
                  <Field
                    name='message'
                    type={'text'}
                    convertedContent={convertedContent}
                    component={RichTextEditor}
                    disabled={!enableEdit}
                  />
                </div>
                <div className={styles.applied_filters}>
                  {showAttachedFiles?.length > 0 &&
                    showAttachedFiles.map((link: any, index: any) => (
                      <div
                        className={styles.applied_filter}
                        key={index}
                        onClick={() => {
                          window.open(link);
                        }}
                      >
                        <img className={styles.attachment_img} src={'/Attachment-pin.svg'} />
                        <abbr
                          title={link.substring(link.lastIndexOf('/') + 1, link.length)}
                          className={styles.applied_filter_text}
                          style={{ cursor: 'pointer' }}
                        >
                          {link.substring(link.lastIndexOf('/') + 1, link.length)}
                        </abbr>
                        <span
                          className={styles.applied_filter_close}
                          style={{ display: enableEdit ? 'block' : 'none' }}
                          onClick={e => {
                            e.stopPropagation();
                            if (enableEdit) deleteHandler(link);
                          }}
                        >
                          X
                        </span>
                      </div>
                    ))}
                  {selectedCompetitorList && (
                    <div
                      className={styles.applied_filter}
                      onClick={() => {
                        navigate(`/competitor-report/${selectedCompetitorList}`);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <img className={styles.attachment_img} src={'/add_competitor.svg'} />
                      <abbr
                        title={
                          savedCompetitorList?.filter(
                            (item: any) => item.value === selectedCompetitorList
                          )[0]?.label
                        }
                        className={styles.applied_filter_text}
                      >
                        <span style={{ cursor: 'pointer' }}>
                          {
                            savedCompetitorList?.filter(
                              (item: any) => item.value === selectedCompetitorList
                            )[0]?.label
                          }
                        </span>
                      </abbr>
                      <span
                        className={styles.applied_filter_close}
                        style={{ display: enableEdit ? 'block' : 'none' }}
                        onClick={e => {
                          e.stopPropagation();
                          if (enableEdit) {
                            setSelectedCompetitorList('');
                            setchangesMade(true);
                          }
                        }}
                      >
                        X
                      </span>
                    </div>
                  )}
                </div>

                {enableEdit && !shallowEdit && (
                  <div style={{ display: 'flex', gap: '18px' }}>
                    <div
                      className={styles.attachfile_wrapper}
                      onClick={() => {
                        setImagesUrl([]);
                        setShowModal(true);
                      }}
                    >
                      <img className={styles.attachment_img} src={'/Attachment-pin.svg'} />
                      Attach Files
                    </div>
                    <div
                      className={styles.attachfile_wrapper}
                      onClick={() => {
                        setShowSavedCompetitorModal(true);
                      }}
                    >
                      <img className={styles.attachment_img} src={'/add_competitor.svg'} />
                      Add Competitors
                    </div>
                  </div>
                )}
                <div
                  className={styles.button_wrapper}
                  style={{ marginBottom: `${comp === 'create-campaign' && '1.5rem'}` }}
                >
                  {enableEdit && (
                    <WrappedButton
                      btnText='Save Changes'
                      btnType={BTN_SECONDARY}
                      isDisabled={!dirty && !changesMade}
                      className={styles.submit_button}
                    />
                  )}

                  {comp !== 'drip' && comp !== 'create-campaign' && (
                    <WrappedButton
                      isInProgress={loader ? true : false}
                      btnText={step === 2 ? 'Submit' : 'Next'}
                      onClick={(e: React.FormEvent<HTMLInputElement>) => {
                        e.preventDefault();
                        nextButtonHandler();
                      }}
                      isDisabled={dirty || changesMade}
                      btnType={BTN_PRIMARY}
                      className={styles.submit_button}
                      // isInProgress={mutationStatus === 'loading' ? true : false}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {showModal && (
        <UploadFiles
          showModal={showModal}
          setShowModal={setShowModal}
          imagesUrl={imagesUrl}
          setImagesUrl={setImagesUrl}
          showAttachedFiles={showAttachedFiles}
          setShowAttachedFiles={setShowAttachedFiles}
          setchangesMade={setchangesMade}
        />
      )}
      <SavedListModal
        showModal={showSavedCompetitorModal}
        setShowModal={setShowSavedCompetitorModal}
        savedList={savedCompetitorList}
        selectedCompetitorList={selectedCompetitorList}
        setSelectedCompetitorList={setSelectedCompetitorList}
        comp={'template'}
        setchangesMade={setchangesMade}
      />
      {isUploading ? (
        <Backdrop sx={{ color: '#0c252d', zIndex: '100' }} open>
          {<CircularProgress color='inherit' />}
        </Backdrop>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditEmail;
