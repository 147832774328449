import React, { useRef, useState } from 'react';
import { Avatar, Button, Box, Slider } from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';
import { Modal, ModalHeader } from 'reactstrap';
import { HandleProfilePictureUpload } from '../../../utils/form-handler';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY } from '../../../constants/button-constants';
import styles from '../../../containers/organisation-setting/profile/profile.module.scss';

interface props {
  showModal: boolean;
  setShowModal: Function;
  picture: any;
  setPicture: any;
  queryClient: any;
}
const ProfilePictureUploader: React.FC<props> = ({
  showModal,
  setShowModal,
  picture,
  setPicture,
  queryClient
}) => {
  let editor: any;
  let inputRef: any;
  const [loader, setLoader] = useState(false);
  // const [picture, setPicture] = useState({
  //   cropperOpen: false,
  //   img:'',
  //   zoom: 2,
  //   croppedImg:""
  //     // "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png"
  // });

  const handleSlider = (event: any, value: any) => {
    setPicture({
      ...picture,
      zoom: value
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false
    });
  };

  const setEditorRef: any = (ed: any) => {
    editor = ed;
  };

  const handleSave = (e: any) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      setLoader(true);
      HandleProfilePictureUpload(croppedImg)
        .then((res: any) => {
          setPicture({
            ...picture,
            img: '',
            cropperOpen: false,
            croppedImg: croppedImg
          });
          setLoader(false);
          queryClient.invalidateQueries(`userdetail`);
          setShowModal(false);
        })
        .catch(err => {
          setLoader(false);
        });
    }
  };

  const handleFileChange = (e: any) => {
    let url = URL.createObjectURL(e.target.files[0]);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true
    });
  };

  return (
    <div>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        centered={true}
        style={{ maxWidth: '649px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        >
          Upload Profile Picture
        </ModalHeader>
        <Box display='flex' minHeight={'400px'} justifyContent={'center'} alignItems={'center'}>
          {!picture.cropperOpen && (
            <Box width='45%'>
              <Avatar
                src={picture.croppedImg}
                style={{ width: '100%', height: 'auto', padding: '', marginBottom: '1rem' }}
              />
              <input
                type='file'
                accept='image/*'
                onChange={handleFileChange}
                hidden={true}
                ref={refParam => (inputRef = refParam)}
              />
              <Button
                variant='contained'
                style={{ backgroundColor: '#2a72dd', color: 'white', width: '100%' }}
                onClick={() => inputRef.click()}
              >
                Upload Your Profile Picture
                {/* <input type="file" accept="image/*" onChange={handleFileChange} /> */}
              </Button>
            </Box>
          )}

          {picture.cropperOpen && (
            <Box
              display='flex'
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <AvatarEditor
                ref={setEditorRef}
                image={picture.img}
                width={200}
                height={200}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                rotate={0}
                scale={picture.zoom}
                borderRadius={100}
              />
              <Slider
                aria-label='raceSlider'
                value={picture.zoom}
                min={1}
                max={10}
                step={0.1}
                onChange={handleSlider}
              ></Slider>
              <Box display={'flex'} justifyContent={'space-between'} width={'600px'}>
                <Button variant='contained' onClick={handleCancel} style={{ width: '98px' }}>
                  Cancel
                </Button>
                {/* <Button
                  variant='contained'
                  style={{ backgroundColor: '#2a72dd', color: 'white', width: '98px' }}
                  onClick={handleSave}
                >
                  Save
                </Button> */}
                <WrappedButton
                  isInProgress={loader ? true : false}
                  btnText={'SAVE'}
                  onClick={handleSave}
                  btnType={BTN_PRIMARY}
                  className={styles.save_botton}
                  // isInProgress={mutationStatus === 'loading' ? true : false}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};
export default ProfilePictureUploader;
