export const suggestions = [
  { text: 'Bussiness name', value: 'businessName' },
  { text: 'First name', value: 'firstName' },
  { text: 'Last name', value: 'lastName' },
  { text: 'Zip Code', value: 'zipCode' },
  { text: 'state', value: 'state' }
];

export const replaceVariableWithValue = (messageBody: any, businessDetails: any) => {
  suggestions.forEach(el => {
    const businessProperty = el.value;
    const wordToreplace = `@${businessProperty}`;
    let regex = new RegExp(wordToreplace, 'gi');
    messageBody = messageBody.replace(regex, businessDetails[businessProperty]);
  });
  return messageBody;
};
