import React, { useState, useEffect, useContext, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { components, InputActionMeta } from 'react-select';
import Storage from '../../utils/local-storage';
import AppConfig from '../../config';
import axios from 'axios';
import { ProjectContext } from '../../context/ProjectContext';
import { useNavigate } from 'react-router-dom';
import styles from './search-bar.module.scss';
import { debounce } from 'lodash';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '4px',
    flexDirection: 'row-reverse',
    cursor: 'pointer'
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#00B87C' : 'white',
    color: state.isSelected ? 'white' : '#333',
    cursor: 'pointer'
  }),
  clearIndicator: (base: any) => ({
    ...base,
    position: 'absolute',
    right: 0
  }),
  loadingIndicator: (base: any) => ({
    ...base,
    position: 'absolute',
    right: 0
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#384852 50%'
  })
};

const SearchIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.41663 2.33268C4.16146 2.33268 2.33329 4.16085 2.33329 6.41602C2.33329 8.67118 4.16146 10.4993 6.41663 10.4993C8.67179 10.4993 10.5 8.67118 10.5 6.41602C10.5 4.16085 8.67179 2.33268 6.41663 2.33268ZM1.16663 6.41602C1.16663 3.51652 3.51713 1.16602 6.41663 1.16602C9.31612 1.16602 11.6666 3.51652 11.6666 6.41602C11.6666 9.31551 9.31612 11.666 6.41663 11.666C3.51713 11.666 1.16663 9.31551 1.16663 6.41602Z'
      fill='#384852'
      fillOpacity='0.5'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.3 9.29976C9.52781 9.07195 9.89716 9.07195 10.125 9.29976L12.6625 11.8373C12.8903 12.0651 12.8903 12.4344 12.6625 12.6622C12.4347 12.89 12.0653 12.89 11.8375 12.6622L9.3 10.1247C9.0722 9.89691 9.0722 9.52757 9.3 9.29976Z'
      fill='#384852'
      fillOpacity='0.5'
    />
  </svg>
);

const SearchBar = () => {
  const [inputText, setInputText] = useState<string>('');
  const { selectedBusiness, setSelectedBusiness } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matchingBusinesses, setMatchingBusinesses] = useState<any>([]);
  const storage = new Storage();
  const navigate = useNavigate();
  const projectId = storage.get('projectId');
  const BASE_URL = AppConfig.API_BASE_URL;

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <SearchIcon />
        </components.DropdownIndicator>
      )
    );
  };
  const filterOptions = (options: any, inputValue: string) => {
    const { label, name, email } = options.data;
    return (
      label.toLowerCase().includes(inputValue.toLowerCase()) ||
      name.toLowerCase().includes(inputValue.toLowerCase()) ||
      email.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  useEffect(() => {
    setInputText('');
  }, []);

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const fetchBusiness = async (searchText: string) => {
        const config = {
          method: 'get',
          url: `${BASE_URL}/v1/project/getSearchResults/${projectId}?projectType=Local&search=${searchText}`,
          headers: {
            Authorization: `Bearer ${storage.get('accessToken')}`
          }
        };

        try {
          const response = await axios(config);
          const temp = response.data.businessList.map((item: any) => ({
            _id: item._id,
            label: item.businessName,
            email: item?.email.length ? item?.email[0] : '',
            name: `${item.firstName} ${item.lastName}`
          }));
          setMatchingBusinesses(temp);
          callback(temp);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      if (inputValue) {
        setIsLoading(true);
        fetchBusiness(inputValue);
      } else {
        callback([]);
      }
    }, 500),
    []
  );

  const handleInputChange = (searchText: string, meta: InputActionMeta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      setInputText(searchText);
    }
  };

  const handleSelectChange = (newValue: any) => {
    setSelectedBusiness(newValue);
    navigate(`/dashboard/${projectId}`);
  };

  return (
    <AsyncSelect
      value={selectedBusiness}
      inputValue={inputText}
      onChange={handleSelectChange}
      filterOption={filterOptions}
      onInputChange={handleInputChange}
      loadOptions={loadOptions}
      openMenuOnClick={false}
      placeholder='Search businesses by business name, email, first name etc...'
      styles={customStyles}
      isClearable
      isSearchable={true}
      noOptionsMessage={() => null}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator
      }}
      cacheOptions
      isLoading={isLoading}
      formatOptionLabel={(e: any) => (
        <>
          {
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                <img
                  src={'/images/business.svg'}
                  style={{ background: 'grey', borderRadius: '50%' }}
                />
              </span>
              <span style={{ marginLeft: '15px' }} className={styles.label}>
                {e.label}
              </span>
            </div>
          }
        </>
      )}
    />
  );
};

export default SearchBar;
