import axios from "axios";

class HttpHelperWithoutAuth {
    getRequest = async (url: string, ...args: any[]) => {
        const res = await axios.get(url, ...args);
        const { data, headers, error, status } = res as any;
        if ([200, 201, 204].includes(status)) {
            return {
                data,
                error: null,
                headers,
                status,
            };
        }

        return {
            data,
            error: error || data,
            status,
        };
    };

    postRequest = async (url: string, ...args: any[]) => {
        const res = await axios.post(url, ...args);
        const { data, headers, error, status } = res as any;

        if ([200, 201, 204].indexOf(status) > -1) {
            return {
                data,
                error: null,
                headers,
                status,
            };
        }

        return {
            data: null,
            error: error || data,
            status,
        };
    };

    putRequest = async (url: string, ...args: any[]) => {
        const res = await axios.put(url, ...args);
        const { data, headers, error, status } = res as any;

        if ([200, 201, 204].indexOf(status) > -1) {
            return {
                data,
                error: null,
                headers,
                status,
            };
        }

        return {
            data: null,
            error: error || data,
            status,
        };
    };

    patchRequest = async (url: string, ...args: any[]) => {
        const res = await axios.patch(url, ...args);

        const { data, headers, error, status } = res as any;
        if ([200, 201, 204].includes(status)) {
            return {
                data,
                error: null,
                headers,
                status,
            };
        }

        return {
            data: null,
            error: error || data,
            status,
        };
    };

    deleteRequest = async (url: string, ...args: any[]) => {
        const res = await axios.delete(url, ...args);

        const { data, error, status } = res as any;

        if ([200, 201, 204].includes(status)) {
            return {
                data,
                error: null,
                status,
            };
        }

        return {
            data: null,
            error: error || data,
            status,
        };
    };
}

export default HttpHelperWithoutAuth;
