export function distanceInMiles(lat1: any, lon1: any, lat2: any, lon2: any) {
  const R = 3958.8; // Earth's radius in miles
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance.toFixed(1);
}
export function toRadians(degrees: any) {
  return (degrees * Math.PI) / 180;
}
export function getObjectsWithUniqueIds(arr: any) {
  const idSet = new Set();
  return arr.filter((obj: any) => {
    if (!idSet.has(obj._id)) {
      idSet.add(obj._id);
      return true;
    }
    return false;
  });
}

export function compareArrayLengths(arr1: any, arr2: any) {
  if (arr1.length < arr2.length || arr1.length > arr2.length) {
    return true;
  }
  return false;
}

export const countNumberOfFiltersApplied = (oldFilters: any, newFilters: any) => {
  let maxChanged = false;
  let minChanged = false;
  let prefChanged = false;

  return Object.keys(newFilters).reduce((count, key) => {
    if (key in oldFilters) {
      if (key === 'maxDistance' || key === 'minDistance') {
        if (!maxChanged && !minChanged && oldFilters[key] !== newFilters[key]) {
          maxChanged = true;
          minChanged = true;
          return count + 1;
        }
      } else if (key === 'maxPreferredSize' || key === 'minPreferredSize') {
        if (!prefChanged && oldFilters[key] !== newFilters[key]) {
          prefChanged = true;
          return count + 1;
        }
      } else if (key === 'state' || key === 'zipCode' || key === 'subcategory') {
        if (JSON.stringify(oldFilters[key]) !== JSON.stringify(newFilters[key])) {
          return count + 1;
        }
      } else if (oldFilters[key] !== newFilters[key]) {
        return count + 1;
      }
    }
    return count;
  }, 0);
};
export const checkIfFilterApplied = (filterOptions: any) => {
  return Object.values(filterOptions).some(
    (value: any) =>
      value !== '' && value !== 0 && value !== 40 && value !== 100 && value.length !== 0
  );
};
