import axios, { AxiosRequestConfig } from "axios";
import Storage from "./local-storage";

const storage = new Storage();

class HttpHelper {
  sendRequest = async (args: AxiosRequestConfig<any>) => {
    try {
      const { url, headers } = args;
      let headerParams;
      if (headers && storage.check('accessToken')) {
        headerParams = {
          ...headers,
          Authorization: `Bearer ${storage.get('accessToken')}`,
        };
      } else if (storage.check('accessToken')) {
        headerParams = {
          Authorization: `Bearer ${storage.get('accessToken')}`,
        };
      } else {
        headerParams = {
          ...headers,
        };
      }
      const apiUrl = `${url}`;
      const response = await axios({
        ...args,
        headers: headerParams,
        url: apiUrl,
      });

      return response;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // logout();
      }
      throw error;
    }
  };

  getRequest = async (args: any) => {
    const res = await this.sendRequest({
      ...args,
      method: "get",
    });
    const { data, headers, error, status } = res as any;

    if ([200, 201, 204].includes(status)) {
      return {
        data,
        error: null,
        headers,
        status,
      };
    }

    return {
      data,
      error: error || data,
      status,
    };
  };

  postRequest = async (args: any) => {
    const res = await this.sendRequest({
      ...args,
      method: "post",
    });
    const { data, headers, error, status } = res as any;

    if ([200, 201, 204].indexOf(status) > -1) {
      return {
        data,
        error: null,
        headers,
        status,
      };
    }

    return {
      data: null,
      error: error || data,
      status,
    };
  };

  putRequest = async (args: any) => {
    const res = await this.sendRequest({
      ...args,
      method: "put",
    });
    const { data, headers, error, status } = res as any;

    if ([200, 201, 204].indexOf(status) > -1) {
      return {
        data,
        error: null,
        headers,
        status,
      };
    }

    return {
      data: null,
      error: error || data,
      status,
    };
  };


  patchRequest = async (args: any) => {
    const res = await this.sendRequest({
      ...args,
      method: "patch",
    });

    const { data, headers, error, status } = res as any;
    if ([200, 201, 204].includes(status)) {
      return {
        data,
        error: null,
        headers,
        status,
      };
    }

    return {
      data: null,
      error: error || data,
      status,
    };
  };

  deleteRequest = async (args: any) => {
    const res = await this.sendRequest({
      ...args,
      method: "delete",
    });

    const { data, error, status } = res as any;

    if ([200, 201, 204].includes(status)) {
      return {
        data,
        error: null,
        status,
      };
    }

    return {
      data: null,
      error: error || data,
      status,
    };
  };
}

export default HttpHelper;
