import { useState, useEffect } from 'react';
import { props } from './model';
import styles from './filters.module.scss';
import Slider from '@mui/material/Slider';
import AdditionalFiltersModal from '../../../components/modals/filters-modal';
import Box from '@mui/material/Box';
import SavedListModal from '../../../components/modals/saved-list';
import { cx } from '../../../utils/class-name-service';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { STATE, GroupByOptions } from '../../../constants/dropDown-constanst';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';
import HttpHelper from '../../../utils/http-helper';
import { deepCopy } from '../../../utils/deep-copy';
import axios from 'axios';
import { useQuery } from 'react-query';
import { customStyles, OriginalFilter } from '../../../constants/dashboard-constants';
import {
  checkIfFilterApplied,
  countNumberOfFiltersApplied
} from '../../../switch-statements/dashboard';
import { filter } from 'lodash';

const DashboardFilters: React.FunctionComponent<props> = ({
  selectedRows,
  setFilterOptions,
  filterOptions,
  projectType,
  setProjectType,
  setSelectedBusinessList,
  setSelectedBusinessListName,
  queryString,
  setQueryString,
  setMapCollaped,
  selectedBusinessList,
  selectedBusinessListName,
  dashboardQueryString,
  setDashboardQueryString,
  selectedSubcategory,
  setSelectedSubCategory,
  subcategoryOptions,
  setSubcategoryOptions,
  selectedCategory,
  setSelectedCategory,
  page,
  setPage,
  setPerPage,
  setSelectedRows,
  setDistanceQueryString,
  distanceQueryString,
  localGroup,
  setLocalGroup,
  setLocalGroupIds
}) => {
  const [additionalFilters, setAdditionalFilters] = useState<{ [char: string]: string | Number }>({
    minPreferredSize: 0,
    maxPreferredSize: 40,
    minDistance: 0,
    maxDistance: 100,
    note: '',
    Emails: '',
    Inquiries: '',
    Leads: '',
    Interested: '',
    EmailSent: '',
    Called: '',
    emailAddress: '',
    PreferredSQFT: '',
    LinkedInAddress: '',
    ChainStores: '',
    MultiUnitFranchisee: '',
    MultiBrandOperator: '',
    ExpansionToStateOfProject: ''
  });
  const [minValueDashboard, setMinValueDashboard] = useState(0);
  const [maxValueDashboard, setMaxValueDashboard] = useState(100);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(40);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const [showSavedBusinessModal, setShowSavedBusinessModal] = useState(false);
  const [showSavedCompetitorModal, setShowSavedCompetitorModal] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [groupCompanyOptions, setGroupCompanyOptions] = useState<any>([]);
  const [savedList, setsavedList] = useState([]);
  const [savedCompetitorList, setsavedCompetitorList] = useState([]);

  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  const navigate = useNavigate();
  const { getRequest } = new HttpHelper();

  const getSavedList = async () => {
    // Get all saved lists for project
    const url = `${BASE_URL}/v1/savedbusinesslist/${storage.get('projectId')}`;
    const { data } = await getRequest({ url });
    if (data.length <= 0) {
    } else {
      const bListArray: any = [];
      data.forEach((el: any) => {
        const obj: any = {};
        obj.value = el._id;
        obj.label = el.list_name;
        obj.count = el.business_ids.length;
        bListArray.push(obj);
      });
      setsavedList(bListArray);
    }
  };
  const getCompetitorList = async () => {
    // Get all saved lists for project
    const url = `${BASE_URL}/v1/savedcompetitorlist/${storage.get('projectId')}`;
    const { data } = await getRequest({ url });
    if (data.length <= 0) {
    } else {
      const bListArray: any = [];
      data.forEach((el: any) => {
        const obj: any = {};
        obj.value = el._id;
        obj.label = el.list_name;
        obj.count = el.business_ids.length;
        bListArray.push(obj);
      });
      setsavedCompetitorList(bListArray);
    }
  };

  const clearAllHandler = () => {
    setFilterOptions({
      state: [],
      zipCode: [],
      groupBy: '',
      groupCompany: '',
      savedSearches: '',
      category: '',
      subcategory: [],
      minDistance: 0,
      maxDistance: 100,
      minPreferredSize: 0,
      maxPreferredSize: 40,
      note: '',
      Emails: '',
      Inquiries: '',
      Leads: '',
      Interested: ''
    });
    setSelectedGroup(null);
    setSelectedCompany(null);
    setSelectedCategory(null);
    setSubcategoryOptions('');
    setSelectedSubCategory('');
    setQueryString('');
    setDashboardQueryString('');
    setDistanceQueryString('');
    setAdditionalFilters({
      minPreferredSize: 0,
      maxPreferredSize: 40,
      minDistance: 0,
      maxDistance: 100,
      note: '',
      Emails: '',
      Inquiries: '',
      Leads: '',
      Interested: '',
      EmailSent: '',
      Called: '',
      emailAddress: '',
      PreferredSQFT: '',
      LinkedInAddress: '',
      ChainStores: '',
      MultiUnitFranchisee: '',
      MultiBrandOperator: '',
      ExpansionToStateOfProject: ''
    });
    setMinValueDashboard(0);
    setMaxValueDashboard(100);
    setSelectedState(null);
    setMaxValue(40);
    setMinValue(0);
  };

  const getCategoryDetails = async () => {
    let config = {
      method: 'post',
      url: '/v1/project/categoryAndSubcategory',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: selectedCategory
        ? JSON.stringify({
            projectId: storage.get('projectId'),
            category: selectedCategory
          })
        : JSON.stringify({
            projectId: storage.get('projectId')
          })
    };

    axios
      .request(config)
      .then((response: any) => {
        // setBusinessDetails(response.data);
        if (!selectedCategory) {
          let outputArray = response.data.map((item: any) => {
            return { value: item, label: item };
          });
          setCategoryOptions(outputArray);
        } else {
          let outputArray = response.data.map((item: any) => {
            return { value: item, label: item };
          });
          setSubcategoryOptions(outputArray);
        }
      })
      .catch((error: any) => {});
  };

  const getGroupCompanyDetails = async () => {
    let config = {
      method: 'get',
      url: '/v1/project/groupCompanies',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };

    return axios.request(config);
  };

  const { data: GroupCompanyDetails, status: GroupCompanyDetailsStatus } = useQuery(
    `getGroupCompanyOptions`,
    getGroupCompanyDetails,
    {
      onSuccess: response => {
        let outputArray = response.data.message.map((item: any) => {
          return { value: item, label: item };
        });
        setGroupCompanyOptions(outputArray);
      }
    }
  );

  useEffect(() => {
    getCategoryDetails();
  }, [selectedCategory]);

  const handleFilterOptions = (value: any, filter: any) => {
    let zipCodeArr = filterOptions['zipCode'];
    let category = filterOptions['category'];
    let subcategory = filterOptions['subcategory'];
    let company = filterOptions['groupCompany'];
    let groupBy = filterOptions['groupBy'];
    let state = filterOptions['state'];

    if (filter === 'state') {
      setFilterOptions({
        ...filterOptions,
        [filter]: [...filterOptions[filter], value]
      });
      state = [...state, value];
    } else if (filter === 'zipCode') {
      setFilterOptions({
        ...filterOptions,
        [filter]: [...filterOptions[filter], value]
      });
      zipCodeArr = [...zipCodeArr, value];
    } else if (filter === 'subcategory' || filter === 'category') {
      if (filter === 'subcategory') {
        setFilterOptions({
          ...filterOptions,
          [filter]: [...filterOptions[filter], value]
        });
        subcategory = [...subcategory, value];
        setSelectedSubCategory('');
      } else {
        setFilterOptions((prev: any) => {
          return {
            ...prev,
            [filter]: value
          };
        });
        category = value;
        if (!value) {
          setSelectedCategory('');
        }
        setFilterOptions((prev: any) => {
          return {
            ...prev,
            ['subcategory']: []
          };
        });
        setSubcategoryOptions('');
        subcategory = [];
      }
    } else if (filter in additionalFilters) {
      let parsed = JSON.parse(queryString);
      let index = parsed.indexOf(filter);
      parsed.splice(index, 1);
      if (filter === 'minPreferredSize') {
        setFilterOptions((prev: any) => {
          return { ...prev, [filter]: 0 };
        });
        setQueryString(JSON.stringify(parsed));
        setAdditionalFilters((prev: any) => {
          return { ...prev, [filter]: 0 };
        });
        setMinValue(0);
      } else if (filter === 'maxPreferredSize') {
        setFilterOptions((prev: any) => {
          return { ...prev, [filter]: 40 };
        });
        setQueryString(JSON.stringify(parsed));
        setAdditionalFilters((prev: any) => {
          return { ...prev, [filter]: 40 };
        });
        setMaxValue(40);
      } else {
        setFilterOptions((prev: any) => {
          return { ...prev, [filter]: value };
        });
        setQueryString(JSON.stringify(parsed));
        setAdditionalFilters((prev: any) => {
          return { ...prev, [filter]: value };
        });
      }
    } else if (filter === 'groupCompany' || filter === 'groupBy') {
      setFilterOptions((prev: any) => {
        return { ...prev, [filter]: value };
      });
      if (filter === 'groupBy') {
        groupBy = value;
        setSelectedGroup(null);
      } else {
        company = value;
        setSelectedCompany(null);
      }
    }
    if (state.length > 0) {
      state = getStateAbbreviations(state);
      console.log({ state });
    }
    setDashboardQueryString(
      `zipCode=${JSON.stringify(zipCodeArr)}&state=${JSON.stringify(state)}
      &category=${encodeURIComponent(category)}&subcategory=${encodeURIComponent(
        JSON.stringify(subcategory)
      )}&groupBy=${groupBy}&company=${company}`
    );
  };
  function getStateAbbreviations(stateNames: any) {
    return STATE.filter((name: any) => {
      if (stateNames.includes(name['label'])) {
        return name['value'];
      }
    }).map((name: any) => name['value']);
  }
  const sliderFilterOptions = (value: any, filter: any) => {
    let projectLatLong;
    let minVal;
    let maxVal;
    if (filter === 'sliderDashboard') {
      minVal = value[0];
      maxVal = value[1];
      projectLatLong = storage.get('projectLatLong');
    }
    let queryString = `minValue=${minVal}&maxValue=${maxVal}&projectLatLong=${projectLatLong}`;
    setDistanceQueryString(queryString);
  };

  const handleDeleteFilterOptions = (value: any, filter: any) => {
    let zipCodeArr = filterOptions['zipCode'] || [];
    let subcategory = filterOptions['subcategory'];
    let category = filterOptions['category'];
    let array = deepCopy(filterOptions[filter]);
    let index = array.indexOf(value);
    array.splice(index, 1);
    setFilterOptions({
      ...filterOptions,
      [filter]: array
    });
    if (filter === 'zipCode') zipCodeArr = array;
    if (filter === 'state') setSelectedState(null);
    if (filter === 'subcategory') subcategory = array;
    let queryString = `zipCode=${JSON.stringify(zipCodeArr)}&category=${encodeURIComponent(
      category
    )}&subcategory=${encodeURIComponent(JSON.stringify(subcategory))}`;
    setDashboardQueryString(queryString);
  };

  useEffect(() => {
    (async () => {
      await getSavedList();
      await getCompetitorList();
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [showSavedBusinessModal, showSavedCompetitorModal]);

  return (
    <>
      <div className={styles.filters_container_bg}>
        <div className={styles.filters_container}>
          <div className={styles.state_dropdown_filter}>
            <Select
              value={categoryOptions.filter((item: any) => item['value'] === selectedCategory)}
              options={categoryOptions}
              styles={customStyles}
              placeholder='Category'
              components={{
                IndicatorSeparator: () => null
              }}
              isOptionDisabled={(option: any) => option.isdisabled} // disable an option
              onChange={(e: any) => {
                setSelectedCategory(e.value);
                setSelectedSubCategory('');

                handleFilterOptions(e.value, 'category');
              }}
            />
          </div>
          {subcategoryOptions.length > 0 && (
            <div className={styles.state_dropdown_filter}>
              <Select
                value={subcategoryOptions.filter(
                  (item: any) => item['value'] === selectedSubcategory
                )}
                options={subcategoryOptions}
                styles={customStyles}
                placeholder='Subcategory'
                components={{
                  IndicatorSeparator: () => null
                }}
                isOptionDisabled={(option: any) => option.isdisabled} // disable an option
                onChange={(e: any) => {
                  if (
                    filterOptions['subcategory'] &&
                    !filterOptions['subcategory']?.includes(e.value)
                  ) {
                    setSelectedSubCategory(e.value);
                    handleFilterOptions(e.value, 'subcategory');
                  }
                }}
              />
            </div>
          )}
          {projectType === 'National' ? (
            <>
              <div className={styles.state_dropdown_filter}>
                <Select
                  options={STATE}
                  styles={customStyles}
                  value={selectedState}
                  placeholder='State'
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  isOptionDisabled={(option: any) => option.isdisabled} // disable an option
                  onChange={(e: any) => {
                    handleFilterOptions(e.label, 'state');
                    setSelectedState(e);
                  }}
                />
              </div>
              <div className={styles.state_dropdown_filter}>
                <Select
                  value={GroupByOptions.filter((item: any) => item['value'] === selectedGroup)}
                  options={GroupByOptions}
                  styles={customStyles}
                  placeholder='Group by'
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  isOptionDisabled={(option: any) => option.isdisabled} // disable an option
                  onChange={(e: any) => {
                    handleFilterOptions(e.label, 'groupBy');
                    setSelectedGroup(e.value);
                  }}
                />
              </div>
              <div className={styles.state_dropdown_filter}>
                <Select
                  value={groupCompanyOptions.filter(
                    (item: any) => item['value'] === selectedCompany
                  )}
                  options={groupCompanyOptions}
                  styles={customStyles}
                  placeholder='Group company'
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  isOptionDisabled={(option: any) => option.isdisabled} // disable an option
                  onChange={(e: any) => {
                    handleFilterOptions(e.label, 'groupCompany');
                    setSelectedCompany(e.value);
                  }}
                />
              </div>
            </>
          ) : (
            <div>
              <input
                className={styles.zipcode_input_filter}
                type='text'
                placeholder='Zip code'
                minLength={5}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter' && e.target.value !== '') {
                    if (
                      filterOptions['zipCode'] &&
                      !filterOptions['zipCode']?.includes(e.target.value)
                    ) {
                      handleFilterOptions(e.target.value, 'zipCode');
                    }
                    e.target.value = '';
                  }
                }}
              />
            </div>
          )}

          <div className={styles.state_dropdown_filter}>
            <Select
              options={[
                {
                  value: '4',
                  label: 'Saved Searches',
                  isdisabled: true
                },
                ...savedList
              ]}
              placeholder='Saved searches'
              value={selectedBusinessListName}
              isOptionDisabled={(option: any) => option.isdisabled} // disable an option
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null
              }}
              onChange={(e: any) => {
                setSelectedBusinessList(e.value);
                setSelectedBusinessListName(e);
              }}
            />
          </div>
          <div className={styles.state_dropdown_filter}>
            <Select
              options={[
                {
                  value: '4',
                  label: 'Saved Competitor List',
                  isdisabled: true
                },
                ...savedCompetitorList
              ]}
              placeholder='Competitor list'
              // value={selectedBusinessListName}
              isOptionDisabled={(option: any) => option.isdisabled} // disable an option
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null
              }}
              onChange={(e: any) => {
                navigate(`/competitor-report/${e.value}`);
              }}
            />
          </div>
          <div
            className={
              countNumberOfFiltersApplied(OriginalFilter, filterOptions) > 0
                ? cx(styles.value_button, styles.active_button)
                : styles.value_button
            }
            onClick={() => {
              setShowAdditionalFilters(true);
            }}
          >
            <img src='/li_align-center.svg' alt='filter' />
            {countNumberOfFiltersApplied(OriginalFilter, filterOptions) > 0 ? (
              <>
                <div className={styles.filters_text_active}>Filters</div>
                <div className={styles.cicle_border}>
                  {countNumberOfFiltersApplied(OriginalFilter, filterOptions)}
                </div>
              </>
            ) : (
              <div className={styles.filters_text}>Filters</div>
            )}
          </div>
          <button
            className={styles.compose_email_button}
            onClick={() => {
              if (selectedRows.length > 0) {
                navigate('/templates', {
                  state: { selectedRows: selectedRows, competitorList: savedCompetitorList }
                });
              }
            }}
          >
            Compose Email
          </button>
          <button
            className={styles.save_to_list_button}
            onClick={() => {
              if (selectedRows.length) setShowSavedBusinessModal(true);
            }}
          >
            Save to List
          </button>
          {projectType === 'Local' && (
            <button
              className={styles.compose_email_button}
              onClick={() => {
                if (selectedRows.length) setShowSavedCompetitorModal(true);
              }}
            >
              Create Competitor List
            </button>
          )}
        </div>
      </div>

      <div className={styles.applied_filters_container}>
        {
          // Check if any filter is applied
          <div className={styles.applied_filters}>
            {selectedBusinessListName?.label && (
              <div className={styles.list_name}>
                <div
                  className={styles.circular_hover}
                  onClick={() => {
                    setSelectedBusinessList(null);
                    setSelectedBusinessListName(null);
                  }}
                >
                  <img src='/back-arrow.svg' alt='Back' width='20' height='20' />
                </div>

                {selectedBusinessListName?.label}
              </div>
            )}
            {localGroup && (
              <div className={styles.list_name}>
                <div
                  className={styles.circular_hover}
                  onClick={() => {
                    setLocalGroup(false);
                    setLocalGroupIds(null);
                    setProjectType('National');
                  }}
                >
                  <img src='/back-arrow.svg' alt='Back' width='20' height='20' />
                </div>
                Local Businesses near Project
              </div>
            )}
            {Object.entries(filterOptions).map(([key, value]: any) => {
              if (
                value &&
                value !== 100 &&
                value !== 40 &&
                // key !== 'minDistance' &&
                // key !== 'maxDistance' &&
                key !== 'state' &&
                key !== 'groupBy' &&
                key !== 'zipCode' &&
                key !== 'subcategory'

                // key !== 'maxPreferredSize' &&
                // key !== 'minPreferredSize'
              ) {
                return (
                  <div className={styles.applied_filter} key={key}>
                    <div className={styles.applied_filter_text}>{value}</div>
                    {key !== 'minDistance' && key !== 'maxDistance' && (
                      <span
                        className={styles.applied_filter_close}
                        onClick={() => handleFilterOptions('', key)}
                      >
                        X
                      </span>
                    )}
                  </div>
                );
              } else if (key === 'state' || key === 'zipCode' || key === 'subcategory') {
                return (
                  <>
                    {((key === 'state' || key === 'subcategory') && projectType === 'National') ||
                    ((key === 'zipCode' || key === 'subcategory') && projectType === 'Local') ? (
                      value.map((el: any) => (
                        <div className={styles.applied_filter} key={key}>
                          <div className={styles.applied_filter_text}>{el}</div>
                          <span
                            className={styles.applied_filter_close}
                            onClick={() => {
                              handleDeleteFilterOptions(el, key);
                            }}
                          >
                            X
                          </span>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                );
              } else if (value && key === 'groupBy' && projectType === 'National') {
                return (
                  <div
                    className={styles.applied_filter}
                    key={key}
                    style={{
                      background:
                        value === 'MBC'
                          ? 'rgb(255 238 240)'
                          : value === 'MBF'
                          ? 'rgb(244 239 255)'
                          : 'rgb(235 248 255)',
                      color:
                        value === 'MBC'
                          ? 'rgb(255, 44, 80)'
                          : value === 'MBF'
                          ? 'rgb(123, 57, 255)'
                          : 'rgb(0, 170, 255)',
                      border: 'none'
                    }}
                  >
                    <div className={styles.applied_filter_text}>{value}</div>
                    <span
                      className={styles.applied_filter_close}
                      onClick={() => handleFilterOptions('', key)}
                    >
                      X
                    </span>
                  </div>
                );
              }
            })}
            {checkIfFilterApplied(filterOptions) && (
              <div
                className={styles.clear_all}
                onClick={() => {
                  clearAllHandler();
                }}
              >
                Clear All
              </div>
            )}
            <div className={styles.toggle_type}>
              <div
                className={
                  projectType === 'Local'
                    ? cx(styles.toggle_type_local, styles.toggle_type_selected)
                    : styles.toggle_type_local
                }
                onClick={() => {
                  setProjectType('Local');
                  setPage(1);
                  setPerPage(20);
                  clearAllHandler();
                  storage.save('projectType', 'Local');
                  window.location.reload();
                }}
              >
                Local
              </div>
              <div
                className={
                  projectType === 'National'
                    ? cx(styles.toggle_type_national, styles.toggle_type_selected)
                    : styles.toggle_type_national
                }
                onClick={() => {
                  setProjectType('National');
                  setPage(1);
                  setPerPage(20);
                  clearAllHandler();
                  setMapCollaped(false);
                  storage.save('projectType', 'National');
                  window.location.reload();
                }}
              >
                National
              </div>
            </div>
          </div>
        }
      </div>

      <AdditionalFiltersModal
        showModal={showAdditionalFilters}
        setShowModal={setShowAdditionalFilters}
        handleFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        projectType={projectType}
        queryString={queryString}
        setQueryString={setQueryString}
        additionalFilters={additionalFilters}
        setAdditionalFilters={setAdditionalFilters}
        minValue={minValue}
        setMinValue={setMinValue}
        maxValue={maxValue}
        setMaxValue={setMaxValue}
        maxValueDashboard={maxValueDashboard}
        setMaxValueDashboard={setMaxValueDashboard}
        minValueDashboard={minValueDashboard}
        setMinValueDashboard={setMinValueDashboard}
        sliderFilterOptions={sliderFilterOptions}
        distanceQueryString={distanceQueryString}
        setDistanceQueryString={setDistanceQueryString}
      />
      <SavedListModal
        showModal={showSavedBusinessModal}
        setShowModal={setShowSavedBusinessModal}
        savedList={savedList}
        selectedRows={selectedRows}
        projectType={projectType}
      />
      <SavedListModal
        showModal={showSavedCompetitorModal}
        setShowModal={setShowSavedCompetitorModal}
        savedList={savedCompetitorList}
        selectedRows={selectedRows}
        comp={'competitorList'}
        projectType={projectType}
      />
    </>
  );
};

export default DashboardFilters;
