import React, { useEffect } from 'react';
import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './features.module.scss';
import { featureCardProps } from './model';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { cx } from '../../utils/class-name-service';
const FeatureCard: React.FunctionComponent<featureCardProps> = ({ title, image }) => {
  const isMobile = useCheckMobileScreen();
  return (
    <div style={{borderRadius: '8px', overflow:'hidden'}}>
      <div className={cx(styles.card)} data-aos='fade-up'>
        <img className={styles.card_icon} src={image} alt='' />
        <div className={styles.card_title}>{title}</div>

        <div className={cx(styles.card_shadow, styles.overlay)}></div>
      </div>
    </div>
  );
};

function TimeContainer() {
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();

  // , once: true
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const HOBBIES = [
    {
      title: 'Go fishing',
      icon: 'Reachtenants/1-1.png'
    },
    {
      title: 'Learn to cook',
      icon: 'Reachtenants/1-2.png'
    },
    {
      title: 'Go skiing',

      icon: 'Reachtenants/1-3.png'
    },
    {
      title: 'Go horseback riding',

      icon: 'Reachtenants/1-4.png'
    },
    {
      title: 'Read a book',

      icon: 'Reachtenants/1-5.png'
    },
    {
      title: 'Play pickleball',

      icon: 'Reachtenants/1-6.png'
    },
    {
      title: 'Play cards',

      icon: 'Reachtenants/1-7.png'
    },
    {
      title: 'Play golf',

      icon: 'Reachtenants/1-8.png'
    },
    {
      title: 'Play an instrument',

      icon: 'Reachtenants/1-9.png'
    },
    {
      title: 'Travel the world',

      icon: 'Reachtenants/1-10.png'
    },
    {
      title: 'Practice meditation',

      icon: 'Reachtenants/1-11.png'
    },
    {
      title: 'Watch the game',

      icon: 'Reachtenants/1-12.png'
    }
  ];
  const COMMUNITY = [
    {
      title: 'Coach sports',
      icon: 'Reachtenants/2-1.png'
    },
    {
      title: 'Drive a neighbor',
      icon: 'Reachtenants/2-2.png'
    },
    {
      title: 'Lead scouts club',
      icon: 'Reachtenants/2-3.png'
    },
    {
      title: 'Mentor a child',

      icon: 'Reachtenants/2-4.png'
    },
    {
      title: 'Visit the elderly',

      icon: 'Reachtenants/2-5.png'
    },
    {
      title: 'Visit the sick',

      icon: 'Reachtenants/2-6.png'
    },
    {
      title: 'Volunteer at a food bank',

      icon: 'Reachtenants/2-7.png'
    },
    {
      title: 'Volunteer at a soup kitchen',

      icon: 'Reachtenants/2-8.png'
    },
    {
      title: 'Help at a homeless shelter',

      icon: 'Reachtenants/2-9.png'
    },
    {
      title: 'Help at the humane society',

      icon: 'Reachtenants/2-10.png'
    },
    {
      title: 'Volunteer as a fireman',

      icon: 'Reachtenants/2-11.png'
    },
    {
      title: 'Help build a home',

      icon: 'Reachtenants/2-12.png'
    }
  ];
  const FAMILY = [
    {
      title: 'Go bowling',
      icon: 'Reachtenants/4.png'
    },
    {
      title: 'Have a game night',
      icon: 'Reachtenants/4-1.png'
    },
    {
      title: 'Take a vacation',

      icon: 'Reachtenants/4-2.png'
    },
    {
      title: 'Plan a surprise',

      icon: 'Reachtenants/4-3.png'
    },
    {
      title: 'Visit amusement park',

      icon: 'Reachtenants/4-4.png'
    },
    {
      title: 'Build a treehouse',

      icon: 'Reachtenants/4-5.png'
    },
    {
      title: 'Visit grandparents',

      icon: 'Reachtenants/4-6.png'
    },
    {
      title: 'Call your father',

      icon: 'Reachtenants/4-7.png'
    },
    {
      title: 'Have a picnic',

      icon: 'Reachtenants/4-8.png'
    },
    {
      title: 'Go hiking',

      icon: 'Reachtenants/4-9.png'
    },
    {
      title: 'Go whitewater rafting',

      icon: 'Reachtenants/4-10.png'
    },
    {
      title: 'Go sailing',

      icon: 'Reachtenants/4-11.png'
    }
  ];
  return (
    <div className={styles.landing_page_wrapper}>
      <div className={styles.max_width}>
        <HeaderLanding />
        <div className={styles.hero}>
          <div className={styles.hero_text} data-aos='fade-up'>
            <div className={styles.hero_text_title}>Time matters</div>
            <div className={styles.hero_text_description}>
              What will you do with all the time you <br />
              save by using <span style={{ color: '#D81F1F' }}>Reach</span>
              <span style={{ color: '#192d73' }}>Tenants?</span>
            </div>
          </div>
          <div className={styles.hero_image} data-aos='zoom-in-up'>
            <img
              src={!isMobile ? 'images/hobbies-map.png' : 'images/hobbies-map_mobile1.jpg'}
              alt=''
              width={465}
              style={{
                width: '100%',
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                borderRadius: '10px'
              }}
            />
            {isMobile && (
              <img
                src={'images/hobbies-map_mobile2.jpg'}
                alt=''
                width={465}
                style={{
                  width: '100%',
                  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                  borderRadius: '10px'
                }}
              />
            )}
          </div>
        </div>
        <div className={styles.features}>
          <div className={styles.features_header}>Hobbies</div>
          <div className={styles.card_container}>
            {HOBBIES.map(feature => {
              return <FeatureCard title={feature.title} image={feature.icon} />;
            })}
          </div>
        </div>
        <div className={styles.features}>
          <div className={styles.features_header}>Community Involvement</div>
          <div className={styles.card_container}>
            {COMMUNITY.map(feature => {
              return <FeatureCard title={feature.title} image={feature.icon} />;
            })}
          </div>
        </div>
        <div className={styles.features}>
          <div className={styles.features_header}>Family</div>
          <div className={styles.card_container}>
            {FAMILY.map(feature => {
              return <FeatureCard title={feature.title} image={feature.icon} />;
            })}
          </div>
        </div>

        <div className={styles.bottom_page_container}>
          <div className={styles.cta_wrapper}>
            <img
              src='/images/time-matter-bottom.jpg'
              className={styles.cta_image}
              data-aos='fade-up'
              width={380}
            ></img>

            <div className={styles.cta_details}>
              <div className={styles.cta_detail_heading}>
                The most effective way to reach your next tenant
              </div>

              <div
                className={styles.cta_button_wrapper}
                onClick={() => {
                  navigate('/request-demo');
                }}
              >
                Book a Demo
              </div>
            </div>
          </div>

          <FooterLanding />
        </div>
      </div>
    </div>
  );
}

export default TimeContainer;
