import { useContext } from 'react';
import ViewProjectForm from '../../containers/view-project/view-project-form';
import ViewProjectFormStepper from '../../containers/view-project/view-project-form-stepper';
import styles from './view-project-screen.module.scss';
import React, { useEffect, useState } from 'react';
import AppConfig from '../../config';
import HttpHelper from '../../utils/http-helper';
import axios from 'axios';
import Storage from '../../utils/local-storage';
import { useParams } from 'react-router-dom';
import { ProjectContext } from '../../context/ProjectContext';
import CircularLoader from '../../components/circular-loader';
import { useQuery } from 'react-query';

const ViewProjectScreen = () => {
  const { projectId } = useParams();
  const addProject = !projectId;
  const [loader, setLoader] = useState(false);
  const { setProjectData } = useContext(ProjectContext);
  const [activeStep, setActiveStep] = useState(addProject ? 0 : 4);
  const [Step, setStep] = useState(0);

  const [project, setProject] = useState<any>({});
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const projectCalling = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/project/individualProject/${projectId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: {}
    };

    return await axios(config);

    // setProject(temp.data);
    // setProjectData(temp.data);
  };
  const getViewProjectDetails = projectId ? true : false;
  const {
    data: ProjectDetails,
    status: ProjectDetailStatus,
    refetch
  } = useQuery(`getTemplatesDetail`, projectCalling, {
    enabled: getViewProjectDetails,
    onSuccess: res => {
      setProject(res.data);
      setProjectData(res.data);
    }
  });
  // useEffect(() => {
  //   (async () => {
  //     if (projectId) {
  //       setLoader(true);
  //       await projectCalling();
  //       setLoader(false);
  //     } else {
  //       setProject({});
  //     }
  //   })();
  // }, []);
  return (
    <>
      {(ProjectDetailStatus === 'success' && getViewProjectDetails) || !getViewProjectDetails ? (
        <div className={styles.screen_wrapper}>
          <ViewProjectFormStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setStep={setStep}
            project={project}
          />
          <ViewProjectForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setStep={setStep}
            project={project}
            step={Step}
            refetch={refetch}
          />
        </div>
      ) : (
        <div className={styles.loader_container}>
          <CircularLoader />
        </div>
      )}
    </>
  );
};

export default ViewProjectScreen;
