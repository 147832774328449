import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { ChartOptions, Chart } from 'chart.js';
import randomColor from 'randomcolor';

import styles from './pieChart.module.scss';
import { cx } from '../../../../utils/class-name-service';
import { props } from './model';

const PieChart: React.FC<props> = ({ overallMetrics, comp }) => {
  const backgroundColors = overallMetrics?.res?.map(() => randomColor());
  const [colors, setColors] = useState(backgroundColors);
  const data = {
    labels: overallMetrics?.res?.map(({ _id }: any) => _id),
    datasets: [
      {
        data: overallMetrics?.res?.map(({ count }: any) => count),
        backgroundColor: colors,
        hoverBackgroundColor: colors
      }
    ]
  };

  const options: ChartOptions<'pie'> = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
      tooltip: {
        usePointStyle: true
      }
    }
  };

  return (
    <div>
      <div className={styles.wrapper}>
        <h1 className={styles.header}>
          {comp === 'listSourceReport' ? 'Type of Sources' : 'Type of businesses'}
        </h1>
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default PieChart;
