import React from 'react';
import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './privacy-policy.module.scss';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.landing_page_wrapper}>
        <div className={styles.max_width}>
          <HeaderLanding />

          <div className={styles.content_wrapper}>
            <div className={styles.heading_cover_image}>
              <div className={styles.heading}>Privacy Policy</div>
              <img src='aboutUs-Eclipse1.svg' alt='' className={styles.eclipseOne} />
              <img src='aboutUs-Eclipse2.svg' alt='' className={styles.eclipseTwo} />

              {isMobile ? (
                <img src='/Privacy_policy.jpg' alt='About Us' className={styles.privacyImage} />
              ) : (
                <img src='/Privacy_policy.jpg' alt='About Us' className={styles.privacyImage} />
              )}
            </div>

            <div className={styles.content}>
              Reach Faster, Inc. d/b/a ReachTenants values its users' privacy. This Privacy Policy
              ("Policy") will help you understand how we collect and use personal information from
              those who visit our website or make use of our online facilities and services, and
              what we will and will not do with the information we collect. Our Policy has been
              designed and created to ensure those affiliated with ReachTenants of our commitment
              and realization of our obligation not only to meet but to exceed most existing privacy
              standards.
              <br />
              <br />
              We reserve the right to make changes to this Policy at any given time. If you want to
              make sure that you are up to date with the latest changes, we advise you to frequently
              visit this page. If at any point in time ReachTenants decides to make use of any
              personally identifiable information on file, in a manner vastly different from that
              which was stated when this information was initially collected, the user or users
              shall be promptly notified by email. Users at that time shall have the option as to
              whether to permit the use of their information in this separate manner.
              <br />
              <br />
              This Policy applies to ReachTenants and it governs any and all data collection and
              usage by us. Through the use of ReachTenants.com, you are, therefore, consenting to
              the data collection procedures expressed in this Policy.
              <br />
              <br />
              Please note that this Policy does not govern the collection and use of information by
              companies that ReachTenants does not control, nor by individuals not employed or
              managed by us. If you visit a website that we mention or link to, be sure to review
              its privacy policy before providing the site with information. It is highly
              recommended and suggested that you review the privacy policies and statements of any
              website you choose to use or frequent to better understand the way in which websites
              garner, make use of and share the information collected.
              <br />
              <br />
              Specifically, this Policy will inform you of the following:
              <br />
              <br />
              1. What personally identifiable information is collected from you through our website;
              <br />
              2. Why we collect personally identifiable information and the legal basis for such
              collection;
              <br />
              3. How we use the collected information and with whom it may be shared;
              <br />
              4. What choices are available to you regarding the use of your data; and
              <br />
              5. The security procedures in place to protect the misuse of your information.
              <br />
              <br />
              INFORMATION WE COLLECT:
              <br />
              <br />
              It is always up to you whether to disclose personally identifiable information to us,
              although if you elect not to do so, we reserve the right not to register you as a user
              or provide you with any products or services. This website collects various types of
              information, such as:
              <br />
              <br />
              · Voluntarily provided information which may include your name, address, email
              address, phone number, age, gender, billing and/or credit card information, etc. which
              may be used when you purchase products and/or services and to deliver the services you
              have requested.
              <br />
              <br />
              · Information automatically collected when visiting our website, which may include
              cookies, third-party tracking technologies, and server logs.
              <br />
              <br />
              In addition, ReachTenants may have the occasion to collect non-personal anonymous
              demographic information, such as age, gender, household income, political affiliation,
              race and religion, as well as the type of browser you are using, IP address, or type
              of operating system, which will assist us in providing and maintaining superior
              quality service.
              <br />
              <br />
              ReachTenants may also deem it necessary, from time to time, to follow websites that
              our users may frequent to gleam what types of services and products may be the most
              popular to customers or the general public or to let us know where our users are
              coming from.
              <br />
              <br />
              Please rest assured that this site will only collect personal information that you
              knowingly and willingly provide to us by way of surveys, completed membership forms,
              and emails. In addition, we may track how long users and visitors remain on the site,
              where they come from and how they travel around the website. ReachTenants also tracks
              information necessary to provide the reports it provides to you. It is the intent of
              this site to use personal information only for the purpose for which it was requested,
              and any additional uses specifically provided for on this Policy.
              <br />
              <br />
              WHY WE COLLECT INFORMATION FOR SO LONG
              <br />
              <br />
              We are collecting your data for several reasons:
              <br />
              <br />
              · To better understand your needs and provide you with the services you have
              requested;
              <br />
              · To fulfill our legitimate interest in improving our services and products;
              <br />
              · To send you promotional emails containing information we think you may like when we
              have your consent to do so;
              <br />
              · To contact you to fill out surveys or participate in other types of market research,
              when we have your consent to do so;
              <br />
              · To customize our website according to your online behavior and personal preferences.
              <br />
              · To provide you with reports to make your use of he site more productive.
              <br />
              <br />
              The data we collect from you will be stored for no longer than necessary. The length
              of time we retain said information will be determined based upon the following
              criteria: the length of time your personal information remains relevant; the length of
              time it is reasonable to keep records to demonstrate that we have fulfilled our duties
              and obligations; any limitation periods within which claims might be made; any
              retention periods prescribed by law or recommended by regulators, professional bodies
              or associations; the type of contract we have with you, the existence of your consent,
              and our legitimate interest in keeping such information as stated in this Policy.
              <br />
              <br />
              USE OF INFORMATION COLLECTED
              <br />
              <br />
              ReachTenants may collect and may make use of personal information to assist in the
              operation of our website and to ensure delivery of the services you need and request.
              At times, we may find it necessary to use personally identifiable information as a
              means to keep you informed of other possible products and/or services that may be
              available to you from ReachTenants.com
              <br />
              <br />
              ReachTenants may also be in contact with you about completing surveys and/or research
              questionnaires related to your opinion of current or potential future services that
              may be offered.
              <br />
              <br />
              ReachTenants may feel it necessary, from time to time, to contact you on behalf of our
              other external business partners with regards to a potential new offer that may be of
              interest to you. If you consent or show interest in presented offers, then, at that
              time, specific identifiable information, such as name, email address, and/or telephone
              number, may be shared with the third party.
              <br />
              <br />
              ReachTenants may find it beneficial to all our customers to share specific data with
              our trusted partners to conduct statistical analysis, provide you with email and/or
              postal mail, deliver support, and/or arrange for deliveries to be made. Those third
              parties shall be strictly prohibited from making use of your personal information,
              other than to deliver those services which you requested, and as such they are
              required, in accordance with this agreement, to maintain the strictest of
              confidentiality with regards to all your information.
              <br />
              <br />
              ReachTenants uses various third-party social media features including but not limited
              to LinkedIn, Facebook, Instagram, and other interactive programs. These may collect
              your IP address and require cookies to work properly. These services are governed by
              the privacy policies of the providers and are not within ReachTenants’ control.
              <br />
              <br />
              DISCLOSURE OF INFORMATION
              <br />
              <br />
              ReachTenants LLC may not use or disclose the information provided by you except under
              the following circumstances:
              <br />
              <br />
              · as necessary to provide services or products you have ordered;
              <br />
              · in other ways described in this Policy or to which you have otherwise consented;
              <br />
              · in the aggregate with other information in such a way so that your identity cannot
              reasonably be determined;
              <br />
              · as required by law, or in response to a subpoena or search warrant;
              <br />
              · to outside auditors who have agreed to keep the information confidential;
              <br />
              · as necessary to enforce the Terms of Use;
              <br />
              · as necessary to maintain, safeguard and preserve all the rights and property of
              ReachTenants.
              <br />
              <br />
              NON-MARKETING PURPOSE
              <br />
              <br />
              ReachTennats greatly respects your privacy. We do maintain and reserve the right to
              contact you if needed for non-marketing purposes (such as bug alerts, security
              breaches, account issues, and/or changes in ReachTenants’ products, and services). In
              certain circumstances, we may use our website, newsletter, newspapers, or other public
              means to post a notice.
              <br />
              <br />
              CHILDREN UNDER THE AGE OF 13
              <br />
              <br />
              ReachTenants website is not directed to and does not knowingly collect personally
              identifiable information from children under the age of thirteen (13). If it is
              determined that such information has been inadvertently collected on anyone under the
              age of thirteen (13), we shall immediately take the necessary steps to ensure that
              such information is deleted from our system's database, or in the alternative, that
              verifiable parental consent is obtained for the use and storage of such information.
              Anyone under the age of thirteen (13) must seek and obtain parent or guardian
              permission to use this website.
              <br />
              <br />
              UNSUBSCRIBE OR OPT-OUT
              <br />
              <br />
              All users and visitors to our website have the option to discontinue receiving
              communications from us by way of email or newsletters. To discontinue or unsubscribe
              from our website please send an email that you wish to unsubscribe to
              info@ReachTenants.com. If you wish to unsubscribe or opt out from any third-party
              websites, you must go to that specific website to unsubscribe or opt-out. ReachTenants
              will continue to adhere to this Policy with respect to any personal information
              previously collected.
              <br />
              <br />
              LINKS TO OTHER WEBSITES
              <br />
              <br />
              Our website does contain links to affiliates and other websites. ReachTenants does not
              claim nor accept responsibility for any privacy policies, practices, and/or procedures
              of other such websites. Therefore, we encourage all users and visitors to be aware
              when they leave our website and to read the privacy statements of every website that
              collects personally identifiable information. This Privacy Policy Agreement applies
              only and solely to the information collected by our website.
              <br />
              <br />
              NOTICE TO EUROPEAN UNION USERS
              <br />
              <br />
              ReachTenants operations are located primarily in the United States. If you provide
              information to us, the information will be transferred out of the European Union (EU)
              and sent to the United States. (The adequacy decision on the EU-US Privacy became
              operational on August 1, 2016. This framework protects the fundamental rights of
              anyone in the EU whose personal data is transferred to the United States for
              commercial purposes. It allows the free transfer of data to companies that are
              certified in the US under the Privacy Shield.) By providing personal information to
              us, you are consenting to its storage and use as described in this Policy.
              <br />
              <br />
              YOUR RIGHTS AS TO A DATA SUBJECT
              <br />
              <br />
              Under the regulations of the General Data Protection Regulation ("GDPR") of the EU you
              have certain rights as a Data Subject. These rights are as follows:
              <br />
              <br />
              · The right to be informed: this means we must inform you of how we intend to use your
              personal data and we do this through the terms of this Policy.
              <br />
              · The right of access: this means you have the right to request access to the data we
              hold about you and we must respond to those requests within one month. You can do this
              by sending an email to info@ReachTenants.com.
              <br />
              · The right to rectification: this means that if you believe some of the data we hold
              is incorrect, you have the right to have it corrected. You can do this by logging into
              your account with us, or by sending us an email with your request.
              <br />
              · The right to erasure: this means you can request that the information we hold be
              deleted, and we will comply unless we have a compelling reason not to, in which case
              you will be informed of the same. You can do this by sending an email to
              info@ReachTenants.com.
              <br />
              <br />
              The right to restrict processing: this means you can change your communication
              preferences or opt out of certain communications. You can do this by sending an email
              to info@ReachTenants.com.
              <br />
              <br />
              · The right of data portability: this means you can obtain and use the data we hold
              for your own purposes without explanation. If you wish to request a copy of your
              information, contact us at info@ReachTenants.com.
              <br />
              <br />
              The right to object: this means you can file a formal objection with us regarding our
              use of your information regarding third parties, or its processing where our legal
              basis is our legitimate interest in it. To do this, please send an email to
              info@ReachTenants.com.
              <br />
              <br />
              In addition to the rights above, please rest assured that we will always aim to
              encrypt and anonymize your personal information whenever possible. We also have
              protocols in place in the unlikely event that we suffer a data breach, and we will
              contact you if your personal information is ever at risk. For more details regarding
              our security protections see the section below or visit our website at
              ReachTenants.com.
              <br />
              <br />
              SECURITY
              <br />
              <br />
              ReachTenants takes precautions to protect your information. When you submit sensitive
              information via the website, your information is protected both online and offline.
              Wherever we collect sensitive information (e.g. credit card information), that
              information is encrypted and transmitted to us in a secure way. You can verify this by
              looking for a lock icon in the address bar and looking for "https" at the beginning of
              the address of the webpage.
              <br />
              <br />
              While we use encryption to protect sensitive information transmitted online, we also
              protect your information offline. Only employees who need the information to perform a
              specific job (for example, billing or customer service) are granted access to
              personally identifiable information. The computers and servers in which we store
              personally identifiable information are kept in a secure environment. This is all done
              to prevent any loss, misuse, unauthorized access, disclosure, or modification of the
              user's personal information under our control.
              <br />
              <br />
              This use of data complies with{' '}
              <a href='https://developers.google.com/terms/api-services-user-data-policy'>
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
              <br />
              <br />
              ACCEPTANCE OF TERMS
              <br />
              <br />
              By using this website, you are hereby accepting the terms and conditions stipulated
              within the Privacy Policy Agreement. If you are not in agreement with our terms and
              conditions, then you should refrain from further use of our sites. In addition, your
              continued use of our website following the posting of any updates or changes to our
              terms and conditions shall mean that you agree to and accept such changes.
              <br />
              <br />
              HOW TO CONTACT US
              <br />
              <br />
              If you have any questions or concerns regarding the Privacy Policy Agreement related
              to our website, please feel free to contact us at the following email, telephone
              number, or mailing address.
              <br />
              <br />
              Email: info@ReachTenants.com
              <br />
              Telephone Number: 305.655.1234
              <br />
              <br />
              Mailing Address:
              <br />
              <br />
              ReachTenants
              <br />
              915 NE 173rd Street
              <br />
              Miami, Florida 33162
              <br />
              <br />
              The data controller responsible for your personal information for the purposes of GDPR
              compliance is:
              <br />
              <br />
              Alan Sakowitz
              <br />
              <br />
              info@ReachTenants.com
              <br />
              305.655.1234
              <br />
              <br />
              915 NE 173rd Street
              <br />
              Miami, Florida 33162
            </div>
          </div>

          <div className={styles.bottom_page_container}>
            <div className={styles.cta_wrapper}>
              <img src='privacy-bottom.jpg' className={styles.cta_image} alt=''></img>

              <div className={styles.cta_details}>
                <div className={styles.cta_detail_heading}>
                  The most effective way to reach your next tenant
                </div>

                <div
                  className={styles.cta_button_wrapper}
                  onClick={() => navigate('/request-demo')}
                >
                  Book a Demo
                </div>
              </div>
            </div>

            <FooterLanding />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
