import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { CategoryScale, Chart, LinearScale, BarController, ChartOptions } from 'chart.js';
import styles from './barChart.module.scss';
import { cx } from '../../../../utils/class-name-service';

interface props {
  prospectorsData?: any;
  comp?: any;
}

const BarChart: React.FC<props> = ({ prospectorsData, comp }) => {
  Chart.register(CategoryScale, LinearScale, BarController);

  // Define data for year, month, and week charts

  // Select the appropriate data based on chartType
  console.log(prospectorsData, comp, '------------');

  const options: ChartOptions<'bar'> = {
    scales: {
      y: {
        stacked: true,
        border: {
          display: true
        },
        grid: {
          display: false
        }
      },
      x: {
        stacked: true,
        border: {
          display: true
        },
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: true
      },
      tooltip: {
        usePointStyle: true,
        mode: 'dataset'
      }
    }
  };

  return (
    <>
      {comp !== 'listSourceReport' && (
        <div className={styles.bar_chart_custom}>
          <div className={styles.wrapper}>
            <h2 className={styles.header}>
              {comp === 'listSourceReport'
                ? 'Comparision of Sources'
                : 'Comparision of Prospectors'}
            </h2>
          </div>
          <Bar data={prospectorsData} options={options} />
        </div>
      )}
      {comp === 'listSourceReport' && (
        <div className={styles.bar_chart}>
          <div className={styles.wrapper}>
            <h2 className={styles.header}>
              {comp === 'listSourceReport'
                ? 'Comparision of Sources'
                : 'Comparision of Prospectors'}
            </h2>
          </div>
          <Bar data={prospectorsData} options={options} />
        </div>
      )}
    </>
  );
};

export default BarChart;
