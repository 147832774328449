import React from 'react';
import { props } from './model';
import styles from './cards.module.scss';

const Card: React.FC<props> = ({
  title,
  description,
  image,
  link,
  linkText,
  authorImg,
  author,
  date,
}) => {
  return (
    <div
      className={styles.blog_card}
      onClick={() => {
        window.location.href = link;
      }}
    >
      <div className={styles.blog_image}>
        <img src={image} alt='blog' />
      </div>

      <div className={styles.blog_heading}>{title}</div>

      <div className={styles.blog_content}>{description}</div>

      {link && linkText && (
        <div
          className={styles.read_more_button}
          onClick={() => {
            window.location.href = link;
          }}
        >
          {linkText}
        </div>
      )}

      {/* {author && authorImg && date && (
        <div className={styles.blog_author_details}>
          <div className={styles.author_image}>
            <img src={authorImg}></img>
          </div>

          <div className={styles.author_details}>
            <div className={styles.author_name}>{author}</div>

            <div className={styles.author_date}>{date}</div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Card;
