import React from 'react'
import LandingPageContainer from '../../containers/landing-page';
import styles from './landing-page-screen.module.scss'

const LandingScreen = () => {
    return (
        <div className={styles.page_wrapper}>
            <LandingPageContainer />
        </div>
    )
}

export default LandingScreen;