import axios from 'axios';
import { useState, useEffect, useContext, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import styles from './playground.module.scss';
import { ProjectContext } from '../../context/ProjectContext';
import TableSettingModal from '../../components/modals/table-setting';
import SidePane from '../../components/side-pane';
import Storage from '../../utils/local-storage';
import { useParams } from 'react-router-dom';
import { deepCopy } from '../../utils/deep-copy';
import { props } from './model';
import AppConfig from '../../config';
import { originalOrder } from '../../constants/dropDown-constanst';
import CustomDataTableLoader from '../../components/customDatatableLoader';
import { BACKGROUND_COLOR, TEXT_COLOR } from '../../switch-statements/switch-statements';
import { distanceInMiles, toRadians } from '../../switch-statements/dashboard';
import {
  dashboardTableCustomStyles,
  conditionalRowStyles
} from '../../constants/dashboard-constants';
import { useQuery } from 'react-query';

const Playground: React.FunctionComponent<props> = ({
  columnOrder,
  queryString,
  dashboardQueryString,
  projectType,
  selectedBusinessList,
  handleChange,
  queryClient,
  page,
  setPage,
  action,
  preSelected,
  setLocalGroup,
  localGroup,
  localGroupIds,
  setLocalGroupIds,
  setProjectType,
  perPage,
  setPerPage,
  setShowPopup,
  setDistanceQueryString,
  distanceQueryString
}) => {
  const { selectedBusiness, setSelectedBusiness } = useContext(ProjectContext);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState<any>([]);
  const [BussinessList, setBussinessList] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState<any>([]);
  const [businessDetails, setBusinessDetails] = useState<any>({});
  const [sidePane, setSidePane] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false
  });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [responseDetailsUpdated, setResponseDetailsUpdated] = useState(false);
  const { changesMadeInBusiness, setchangesMadeInBusiness } = useContext(ProjectContext);
  const [dbOrder, setDbOrder] = useState<any>();

  const storage = new Storage();
  let { projectId } = useParams();
  const BASE_URL = AppConfig.API_BASE_URL;
  const getUserDetails = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/users/${storage.get('userId')}`
    };

    const temp = (await axios(config)).data;
    return temp;
  };

  const fetchBusiness = async (savedList?: any) => {
    setLoading(true);
    // let dashboardQuery = encodeURIComponent(dashboardQueryString);
    let config;
    if (localGroup) {
      config = {
        method: 'get',
        url: `${BASE_URL}/v1/project/getBusinessList/${projectId}?projectType=Local&localGroup=[${localGroupIds}]&page=${page}&per_page=${perPage}`, // saved list here is array of business
        headers: {
          Authorization: `Bearer ${storage.get('accessToken')}`
        }
      };
    } else {
      const accessToken = storage.get('accessToken');
      const queryParams =
        (queryString === '[]' || queryString === undefined) &&
        (dashboardQueryString === '[]' || dashboardQueryString === undefined) &&
        distanceQueryString === undefined
          ? `projectType=${projectType}&page=${page - 1}&per_page=${perPage}&delay=1&businessId=${
              selectedBusiness?._id
            }`
          : `filter=${queryString}&${dashboardQueryString}&${distanceQueryString}&projectType=${projectType}&page=${
              page - 1
            }&per_page=${perPage}&businessId=${selectedBusiness?._id}&delay=1`;

      const url = savedList
        ? `${BASE_URL}/v1/savedbusinesslist/get-all-business-for-list/${savedList}`
        : `${BASE_URL}/v1/project/getBusinessList/${projectId}?${queryParams}`;

      config = {
        method: 'get',
        url: url,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
    }

    await axios(config)
      .then(function (response) {
        setBussinessList(response.data.businessList);
        setData(response.data.businessList);
        setTotalRows(response.data.businessCount);
        let ColumnOrderDB = response.data.ColumnOrder;

        // let OrderObj = columnOrder.filter((item: any) => item?.projectId === projectId);

        let OrderArray =
          projectType === 'Local'
            ? ColumnOrderDB[0]?.orderForLocal
            : ColumnOrderDB[0]?.orderForNational;

        if (!OrderArray || OrderArray.length < 1) {
          OrderArray = originalOrder;
        }
        let hidden = originalOrder.filter((item: any) => !OrderArray.includes(item));
        let defaultColumn = [
          {
            name: 'Business Name',
            selector: (data: any) => data['businessName'],
            style: {
              color: '#2A72DD'
            },
            width: '250px',
            cell: (data: any) => {
              if (data?.listName === 'national') {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div
                      style={{
                        width: '160px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      onClick={() => {
                        setSidePane({ isPaneOpen: true, isPaneOpenLeft: false });
                        setBusinessDetails(data);
                      }}
                    >
                      {data?.businessName}
                    </div>
                    {data?.localGroup && data?.localGroup.length > 0 && (
                      <span
                        onClick={async () => {
                          setProjectType('Local');
                          setLocalGroupIds(data?.localGroup);
                          setLocalGroup(true);
                        }}
                        style={{
                          border: '1px solid #413DEE',
                          color: '#413DEE',
                          fontWeight: 700,
                          fontSize: '12px',
                          height: '18px',
                          width: '37px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '8px',
                          marginLeft: '4px',
                          gap: '2px'
                        }}
                      >
                        <img src='/localGroup.svg' alt='' />

                        {data?.localGroup.length}
                      </span>
                    )}
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      width: '250px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    onClick={() => {
                      setSidePane({ isPaneOpen: true, isPaneOpenLeft: false });
                      setBusinessDetails(data);
                    }}
                  >
                    {data?.businessName}
                  </div>
                );
              }
            },
            columnName: 'Business Name',
            omit: false,
            id: 'business_name'
          },

          {
            name: 'Category',
            selector: (data: any) => data['category'],
            columnName: 'Category',
            omit: false,
            id: 'category'
          },

          {
            name: 'Distance',
            selector: (data: any) => {
              const [projLat, projLong] = storage.get('projectLatLong').split(',').map(parseFloat);
              return distanceInMiles(projLat, projLong, data['lat'], data['long']);
            },
            columnName: 'Distance',
            omit: false,
            id: 'distance'
          },
          {
            name: 'Location Sales',
            selector: (data: any) => `${data['locationSales']}`,
            columnName: 'Location Sales',
            omit: false,
            id: 'Location_Sales_Total'
          },
          {
            name: 'Name',
            selector: (data: any) => `${data['prefix']} ${data['firstName']} ${data['lastName']}`,
            columnName: 'Name',
            omit: false,
            id: 'Name'
          },
          {
            name: 'Title',
            selector: (data: any) => `${data['title']}`,
            columnName: 'Title',
            omit: false,
            id: 'Title'
          },
          {
            name: 'Address',
            selector: (data: any) => `${data['address']}`,
            columnName: 'Address',
            omit: false,
            id: 'Address'
          },
          {
            name: 'States',
            selector: (data: any) => `${data['territory'].replace(/,/g, ", ")}`,
            columnName: 'States',
            omit: false,
            id: 'Territory'
          },
          {
            name: 'Phone',
            selector: (data: any) => `${data['phone'][0].replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3")}`,
            columnName: 'Phone',
            omit: false,
            id: 'Phone'
          },
          {
            name: 'Email',
            selector: (data: any) => `${data['email'][0]}`,
            columnName: 'Email',
            omit: false,
            id: 'Email'
          },
          {
            name: 'Zip',
            selector: (data: any) => `${data['zipCode']}`,
            columnName: 'Zip',
            omit: false,
            id: 'Zip_Code'
          },
          // {
          //   name: 'County',
          //   selector: (data: any) => `${data['county']}`,
          //   columnName: 'County',
          //   omit: false,
          //   id: 'County'
          // },
          {
            name: 'Franchise',
            selector: (data: any) => `${data['franchise']}`,
            columnName: 'Franchise',
            omit: false,
            id: 'Franchise'
          },
          {
            name: 'Website',
            selector: (data: any) => `${data['url']}`,
            columnName: 'Website',
            omit: false,
            id: 'URL'
          },
          // {
          //   name: 'Year Established',
          //   selector: (data: any) => `${data['yearEstablished']}`,
          //   columnName: 'Year Established',
          //   omit: false,
          //   id: 'Year_Established'
          // },
          {
            name: 'No. Of Employees',
            selector: (data: any) => `${data['numberEmployees']}`,
            columnName: 'No. Of Employees',
            omit: false,
            id: 'Number_Of_Employees'
          },

          {
            name: 'Status',
            selector: (data: any) => {
              if (data?.status?.length > 0) {
                let status = data['status'].filter((item: any) => item.projectId === projectId);
                return status.length > 0 && status[0].status;
              }
            },
            cell: (data: any) => {
              if (data?.status?.length > 0) {
                let status = data['status'].filter((item: any) => item.projectId === projectId);
                if (status.length > 0)
                  return (
                    <div
                      style={{
                        background: BACKGROUND_COLOR(status[0].status),
                        color: TEXT_COLOR(status[0].status),
                        height: '25px',
                        padding: '4px 10px'
                      }}
                    >
                      <p>{status[0].status}</p>
                    </div>
                  );
              }
            },
            columnName: 'Status',
            omit: false,
            id: 'status'
          }
        ];
        if (projectType === 'National') {
          defaultColumn = [
            ...defaultColumn,
            {
              name: 'Company Group',
              selector: (data: any) => data['company'],
              columnName: 'Company Group',
              omit: true,
              id: 'company_group'
            },
            {
              name: 'Group Type',
              selector: (data: any) => data['groupType'],
              columnName: 'Group Type',
              omit: true,
              id: 'group_type',
              cell: (data: any) => {
                if (data?.listName === 'national') {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      {data?.groupType !== 'none' && (
                        <span
                          style={{
                            background:
                              data?.groupType === 'mbc'
                                ? 'rgb(255 238 240)'
                                : data?.groupType === 'mbf'
                                ? 'rgb(244 239 255)'
                                : 'rgb(235 248 255)',
                            color:
                              data?.groupType === 'mbc'
                                ? 'rgb(255, 44, 80)'
                                : data?.groupType === 'mbf'
                                ? 'rgb(123, 57, 255)'
                                : 'rgb(0, 170, 255)',
                            fontWeight: 700,
                            fontSize: '10px',
                            height: '18px',
                            width: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            marginLeft: '4px'
                          }}
                        >
                          {data?.groupType.toUpperCase()}
                        </span>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        width: '250px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {data?.businessName}
                    </div>
                  );
                }
              }
            }
          ];
        }
        defaultColumn.sort((a: any, b: any) => {
          return OrderArray.indexOf(a.name) - OrderArray.indexOf(b.name);
        });
        let columnCopy = deepCopy(defaultColumn);
        let arr = columnCopy.map((col: any) => {
          col.omit = hidden.includes(col.columnName);
        });
        setColumns(columnCopy);
        setHiddenColumns(hidden);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  useEffect(() => {
    // if (columnOrder.length) {
    (async () => {
      if (selectedBusinessList) {
        await fetchBusiness(selectedBusinessList);
      } else {
        await fetchBusiness();
      }
    })();
    // }
  }, [
    selectedBusinessList,
    columnOrder,
    queryString,
    dashboardQueryString,
    distanceQueryString,
    responseDetailsUpdated,
    projectType,
    selectedBusiness,
    perPage,
    page,
    localGroup,
    changesMadeInBusiness
  ]);
  useEffect(() => {
    if (selectedBusiness?._id && BussinessList.length > 0) {
      setData(BussinessList);
      setBusinessDetails(BussinessList[0]);
      setSidePane({ isPaneOpen: true, isPaneOpenLeft: false });
    }
  }, [BussinessList]);

  useEffect(() => {
    let columnCopy = deepCopy(columns);
    columnCopy.map((col: any) => {
      col.omit = hiddenColumns.includes(col.columnName);
    });
    setColumns(columnCopy);
  }, [hiddenColumns]);

  // const rowSelectCritera = (row: any) => {
  //   if(selectionType === 'clear')
  //   return false;
  //   else return true
  // };

  // const rowSelectCritera = useMemo(() => {
  //   return (row: any) => selectionType === 'clear' ? false : true

  // }

  //   , []);

  const handleMouseEnter = (row: any, event: any) => {
    setShowPopup(row);
  };

  const handleMouseLeave = () => {
    setShowPopup(null);
  };

  return (
    <div>
      <div className={styles.table_container}>
        <DataTable
          keyField='_id'
          paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
          columns={columns}
          data={data}
          customStyles={dashboardTableCustomStyles}
          progressPending={loading}
          conditionalRowStyles={conditionalRowStyles}
          progressComponent={<CustomDataTableLoader />}
          selectableRows
          fixedHeader
          fixedHeaderScrollHeight='calc(100vh - 236px)'
          highlightOnHover
          pointerOnHover
          // onRowMouseEnter={handleMouseEnter}
          // onRowMouseLeave={handleMouseLeave}
          onRowClicked={e => {
            setSidePane({ isPaneOpen: true, isPaneOpenLeft: false });
            setBusinessDetails(e);
          }}
          pagination
          paginationServer
          paginationDefaultPage={page}
          // selectableRowsVisibleOnly={true}
          paginationPerPage={perPage}
          paginationServerOptions={{
            persistSelectedOnPageChange: true
          }}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={(newPerPage, page) => {
            setPage(page);
            setPerPage(newPerPage);
          }}
          onSelectedRowsChange={handleChange}
          onChangePage={page => {
            setPage(page);
          }}
        />

        <img
          className={styles.table_setting}
          src='/images/li_settings.svg'
          alt=''
          onClick={() => {
            setShowModal(true);
          }}
        />
      </div>
      <TableSettingModal
        showModal={showModal}
        setShowModal={setShowModal}
        columns={columns}
        setColumns={setColumns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        projectType={projectType}

        // queryClient={queryClient}
      />
      <SidePane
        sidePane={sidePane}
        setSidePane={setSidePane}
        businessDetails={businessDetails}
        setBusinessDetails={setBusinessDetails}
        setResponseDetailsUpdated={setResponseDetailsUpdated}
        responseDetailsUpdated={responseDetailsUpdated}
        queryClient={queryClient}
        projectType={projectType}
      />
    </div>
  );
};

export default Playground;
