import React from 'react';
import { props } from './model';
import { Formik, Form, Field, FieldArray } from 'formik';
import styles from './add-team-member.module.scss';
import { Modal, ModalHeader } from 'reactstrap';
import CustomisedTextField from '../../form-elements/customised-text-field';
import { useMutation } from 'react-query';
import axios from 'axios';
import AppConfig from '../../../config';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

const SICModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  selectedCodes
  // queryClient,
}) => {
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const saveSICInfoHandler = async (data: any) => {
    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/sic/save-sic-info`,
      data: { ...data, sicCodes: selectedCodes }
    };

    await axios(config);
  };
  const { status: SICInfoStatus, mutateAsync: SICInfo } = useMutation(saveSICInfoHandler, {
    onSuccess: () => {
      enqueueSnackbar('SIC Info Saved', { variant: 'success', header: 'Success' });
      navigate('/sic-codes');
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });
  const onSubmit = (data: any) => {
    SICInfo(data);
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '549px', width: '100%' }}
        centered
      >
        <ModalHeader
          toggle={() => {
            setShowModal(!showModal);
          }}
        ></ModalHeader>

        <Formik
          initialValues={{
            email: '',
            competitor: [{ radius: '', name: '' }]
          }}
          validationSchema={yup.object({
            email: yup.string().trim().required('Email address is required').email('Email address is invalid')
          })}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {({ values }) => (
            //TODO: Add member form validation
            <Form>
              <div className={styles.form_wrapper}>
                <div className={styles.single_field}>
                  <Field
                    name='email'
                    placeholder='Enter your mail id'
                    component={CustomisedTextField}
                    disabled={''}
                    required={true}
                  />
                </div>
                <div>
                  {/* <div className={styles.form_title}>Header name</div> */}
                  <div className={styles.note}>
                    List all names of businesses you would like to show the locations of on a map to
                    send to perspective tenants. It can be both prospective tenants and their
                    competitors. The businesses will be included in the search list of local
                    businesses whether they have an email address or not. It is recommend that you
                    select your 15 to 25 top choices for a tenant and/or its competitors within a 5
                    mile radius. But you can chose as many as you like and any radius. Keep in mind,
                    these names will be a part of your total search list.
                  </div>
                  <FieldArray name='competitor'>
                    {({ push }) => (
                      <>
                        {values.competitor?.map((competitor: any, index: any) => (
                          <div key={index}>
                            <div className={styles.two_fields}>
                              <div className={styles.single_field}>
                                <Field
                                  name={`competitor[${index}].radius`}
                                  placeholder='What radius you like'
                                  component={CustomisedTextField}
                                />
                              </div>

                              <div className={styles.single_field}>
                                <Field
                                  name={`competitor[${index}].name`}
                                  placeholder='Enter name'
                                  component={CustomisedTextField}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        <div
                          className={styles.add_more}
                          onClick={() => {
                            push({
                              radius: '',
                              name: ''
                            });
                          }}
                        >
                          + Add more space
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>
              <div className={styles.selected_code_footer}>
                <button className={styles.button} onClick={() => setShowModal(true)}>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SICModal;
