import React from 'react';
import { props } from './model';
import { TextField } from '@mui/material';
import styles from '../customised-text-field/customised-text-field.module.scss';

const CustomisedTextArea: React.FC<props> = ({ field, form, placeholder, disabled, ...props }) => {
  const { name, value, onChange } = field;
  const { setFieldValue, errors, touched } = form;

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    setFieldValue(field.name, e.target.value);
  };

  return (
    <>
      <div className={styles.field_label}>{placeholder}</div>
      <TextField
        variant='outlined'
        id={name}
        {...field}
        onChange={onChangeHandler}
        className={styles.cutomisedTextField}
        disabled={disabled}
        multiline
        rows={props.row !== undefined ? props.row : 9}
        label={props.label}
      ></TextField>

      {/* <input type="text" name={name} className={styles.cutomisedTextField}></input> */}
      {(value || touched[name]) && errors && errors[name] && (
        <div className={styles.error_message}>{errors[name]}</div>
      )}
    </>
  );
};

export default CustomisedTextArea;
