import React from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import BusinessDetailPill from '../../components/business-details';
import AuditTrailContainer from '../../containers/audit-trail';
import styles from '../../screens/playground/playground.module.scss';
import NotesPanel from './Notes/NotesPanel';
import SetReminder from '../modals/set-reminder';
import ResponseDetails from './ResponseDetails/ResponseDetails';
import AuditTrail from './AuditTrail/AuditTrail';
import BusinessDetails from './BusinessDetails/BusinessDetails';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={styles.side_pane_body}
    >
      {value === index && children}
    </div>
  );
}

interface SidePaneProps {
  sidePane?: any;
  businessDetails: any;
  setSidePane: any;
  queryClient: any;
  setResponseDetailsUpdated: any;
  responseDetailsUpdated: any;
  setBusinessDetails: any;
  projectType: any;
}

function SidePane(props: SidePaneProps) {
  const { sidePane, businessDetails, setSidePane, projectType, ...other } = props;
  const [value, setValue] = React.useState(0);
  const [showSetReminderModal, setShowSetReminderModal] = React.useState(false);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <SlidingPane
      className='some-custom-class'
      overlayClassName='some-custom-overlay-class'
      isOpen={sidePane.isPaneOpen}
      // closeIcon={<img src={'/images/close_side_pane.svg'} />}
      title={
        <div className={styles.sidepane_title}>
          <div>{businessDetails['businessName']} </div>
          <span className={styles.set_reminder} onClick={() => setShowSetReminderModal(true)}>
            Set Reminder
          </span>
        </div>
      }
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setSidePane({ isPaneOpen: false, isPaneOpenLeft: false });
      }}
      width='550px'
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab label={<p className={styles.side_nav}>Business details</p>} {...a11yProps(0)} />
          <Tab label={<p className={styles.side_nav}>Response details</p>} {...a11yProps(1)} />
          <Tab label={<p className={styles.side_nav}>Notes</p>} {...a11yProps(2)} />
          <Tab label={<p className={styles.side_nav}>Audit trail</p>} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <BusinessDetails
          queryClient={props.queryClient}
          business={props.businessDetails}
          setBusinessDetails={props.setBusinessDetails}
          projectType={projectType}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <ResponseDetails
          queryClient={props.queryClient}
          businessId={props.businessDetails._id}
          setResponseDetailsUpdated={props.setResponseDetailsUpdated}
          responseDetailsUpdated={props.responseDetailsUpdated}
        />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <NotesPanel queryClient={props.queryClient} businessId={props.businessDetails._id} />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <AuditTrail queryClient={props.queryClient} businessId={props.businessDetails._id} />
      </TabPanel>
      <SetReminder
        showModal={showSetReminderModal}
        setShowModal={setShowSetReminderModal}
        bussinessId={businessDetails._id}
        queryClient={props.queryClient}
      />
    </SlidingPane>
  );
}

export default SidePane;
