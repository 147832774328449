import React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import styles from './customised-select-box.module.scss';
import { props } from './model';
import InputLabel from '@mui/material/InputLabel';

const CustomSelectBox: React.FC<props> = ({ children, form,required, field, label }) => {
  const { name, value } = field;
  const { setFieldValue, errors, touched } = form;

  return (
    <>
      <FormControl variant='outlined' fullWidth>
        <label className={styles.field_label}>{`${label}${required?' *':''}`}</label>
        <Select
          labelId='demo-simple-select-outlined-label'
          id='demo-simple-select-outlined'
          name={name}
          value={value}
          onChange={e => {
            setFieldValue(name, e.target.value);
          }}
          error={(value || touched[name]) && errors && errors[name]}
        >
          {children}
        </Select>
        {(value || touched[name]) && errors && errors[name] && (
          <div className={styles.error_message}>{errors[name]}</div>
        )}
      </FormControl>
    </>
  );
};

export default CustomSelectBox;
