import React, { useEffect, useState, useCallback } from 'react';
import styles from './richTextEditor.module.scss';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import { convertToHTML } from 'draft-convert';
import Storage from '../../utils/local-storage';
import 'draft-js/dist/Draft.css';
import { props } from './props';
import { suggestions } from '../../utils/emailTemplate';

const RichTextEditor: React.FunctionComponent<props> = ({
  form,
  field,
  disabled,
  convertedContent,
  setConvertedContent
}) => {
  const { name } = field;
  const { setFieldValue } = form;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (convertedContent) {
      let blocksFromHTML = convertFromHTML(convertedContent);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, [convertedContent]);

  return (
    <div className={styles.editorContainer}>
      <Editor
        mention={{
          separator: ' ',
          trigger: '@',

          suggestions: suggestions
        }}
        editorState={editorState}
        onEditorStateChange={item => {
          setEditorState(item);
          let data = convertToHTML(item.getCurrentContent());
          setFieldValue(name, data);
        }}
        toolbar={{
          options: ['inline', 'textAlign', 'blockType']
        }}
        readOnly={disabled}
      />
    </div>
  );
};
export default RichTextEditor;
