import React, { useEffect, useState, useContext } from 'react';
import styles from './project-list.module.scss';
import Storage from '../../utils/local-storage';
import { useQuery } from 'react-query';
import axios from 'axios';
import { projectCardProps } from './model';
import { avatarGenerator } from '../../utils/avatarGenerator';
import { getFormattedDate } from '../../utils/date-utils';
import { useNavigate } from 'react-router-dom';
import CircularLoader from '../../components/circular-loader';
//Get Request for Project List
import AppConfig from '../../config';
import { ProjectContext } from '../../context/ProjectContext';

const getProjectList = async () => {
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const config = {
    method: 'get',
    url: `${BASE_URL}/v1/project/${storage.get('organizationId')}`,
    headers: {
      Authorization: `Bearer ${storage.get('accessToken')}`
    }
  };
  const res = await axios(config);
  return res.data;
};

// Card components

const ProjectCard: React.FunctionComponent<projectCardProps> = ({
  projectName,
  projectUser,
  projectDate,
  projectId,
  projectLatLong,
  length
}) => {
  const navigate = useNavigate();
  const storage = new Storage();
  let teamMember: [any];
  let leftTeamCount;
  if (projectUser.length > 4) {
    teamMember = projectUser.slice(0, 4);
    leftTeamCount = projectUser.length - 4;
  } else {
    teamMember = projectUser;
  }

  const userIcons = () => {
    if (projectUser.length > 0) {
      return (
        <>
          <div className={styles.avatars}>
            {teamMember.map((user: any) => {
              return (
                <span className={styles.avatar}>
                  <img
                    src={
                      user.profileImage
                        ? `https://reachtenants-dev-assets.s3.amazonaws.com/${user.profileImage}`
                        : avatarGenerator(user.firstName[0], user.lastName[0])
                    }
                    className={styles.user_icon}
                  />
                </span>
              );
            })}
          </div>
        </>
      );
    }
  };
  return (
    <div
      className={styles.card}
      onClick={() => {
        storage.save('projectId', projectId);
        storage.save('projectName', projectName);
        storage.save('projectLatLong', projectLatLong);
        navigate(`/dashboard/${projectId}`);
      }}
      style={{width: length === 1 ? '100%' : length === 2 ? '48%' : '373px'}}
    >
      <div className={styles.card_name}>{projectName}</div>
      <div className={styles.card_date}>Created: {getFormattedDate(new Date(projectDate))}</div>

      <div className={styles.card_footer}>
        <div className={styles.card_avtar} style={{ display: 'flex' }}>
          <div className={styles.card_footer_user}>{userIcons()}</div>
          {leftTeamCount && <span className={styles.count_avatar}>+{leftTeamCount}</span>}
        </div>

        <div>
          <img src='images/li_arrow-right.svg' alt='' className={styles.card_footer_next} />
        </div>
      </div>
    </div>
  );
};

//Project Screen

function ProjectListScreen() {
  const { projectData, setProjectData } = useContext(ProjectContext);
  const { selectedBusiness, setSelectedBusiness } = useContext(ProjectContext);
  const storage = new Storage();
  const navigate = useNavigate();
  const [projectListState, setProjectListState] = useState<any[]>([]);
  const [searchField, setSearchField] = useState('');

  const { data: projectList, status: projectListStatus } = useQuery(`userdetails`, getProjectList);

  const handleSearchChange = (e: any) => {
    setSearchField(e.target.value);
  };

  useEffect(() => {
    if (projectListStatus === 'success') {
      setProjectListState(projectList);
    }
    setSelectedBusiness('');
  });

  storage.delete('projectId');

  const filteredProject = projectListState.filter(project => {
    return project?.projectName?.toLowerCase().includes(searchField.toLowerCase());
    // Don't remove comment can be used to filter with multiple properties later
    // || project.emailtoLowerCase().includes(searchField.toLowerCase())
  });

  return (
    <div className={styles.page_container}>
      {projectListStatus === 'loading' ? (
        <div className={styles.loader_container}>
          <CircularLoader />
        </div>
      ) : (
        <div className={styles.page_wrapper}>
          <div className={styles.breadcrumbs}>
            <div className={styles.page_name}>My Projects</div>
          </div>
          <div className={styles.search_container}>
            <div className={styles.search_input}>
              <img src='images/li_search.svg' style={{ marginLeft: '15.5px' }} />

              <input type='text' placeholder='Search projects' onChange={handleSearchChange} />
            </div>

            {
              <div>
                {storage.get('role') !== 'prospector' && (
                  <button
                    className={styles.add_project}
                    onClick={() => {
                      navigate(`/add-project`);
                      setProjectData({});
                    }}
                  >
                    Add Project
                  </button>
                )}
              </div>
            }
          </div>

          <div className={styles.project_container}>
            {projectListStatus === 'success' && filteredProject.length ? (
              filteredProject.map((project: any, index: any) => {
                return (
                  <>
                    {!project.isDisabled && (
                      <ProjectCard
                        projectDate={project.createdAt}
                        projectName={project.projectName}
                        projectUser={project.users}
                        projectId={project._id}
                        projectLatLong={project.projectLatLong || '10,20'}
                        key={index}
                        length={filteredProject.length}
                      />
                    )}
                  </>
                );
              })
            ) : searchField === '' ? (
              <div className={styles.empty_state}>
                <div>
                  <img src='images/empty_project.svg' alt='' />
                </div>
                <div className={styles.empty_text}>Let's create your first project!</div>
                <div>
                  {storage.get('role') !== 'prospector' && (
                    <button
                      className={styles.add_project}
                      onClick={() => {
                        navigate(`/add-project`);
                      }}
                    >
                      Add Project
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.not_found}>
                <div>
                  <img src='images/no_project_found.svg' alt='' />
                </div>
                <div className={styles.empty_text}>Sorry no results found</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectListScreen;
