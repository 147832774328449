export const BACKGROUND_COLOR = (value: any) => {
  switch (value) {
    case 'Declined':
      return '#FFF2F2';
    case 'Closed':
      return '#F7FFF3';
    case 'Dead Lead':
      return '#F1F1F1';
    case 'Follow up':
      return '#FFF8F2';
    case 'Lead':
      return '#F7FFF3';
    case 'Prospect':
      return '#EAFAFA';
    default:
      return '#F2FAFF';
  }
};
export const TEXT_COLOR = (value: any) => {
  switch (value) {
    case 'Declined':
      return '#FF3737';
    case 'Closed':
      return '#39AC3E';
    case 'Dead Lead':
      return '#C9C6C5';
    case 'Follow up':
      return '#FF8B37';
    case 'Lead':
      return '#39AC3E';
    case 'Prospect':
      return '#2CD7EE';
    default:
      return '#37ABFF';
  }
};
