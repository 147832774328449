import React, { useState } from 'react';
import { deepCopy } from '../../../utils/deep-copy';
import styles from './customised-multi-select.module.scss';
import Select, { components } from 'react-select';
import { avatarGenerator } from '../../../utils/avatarGenerator';
import Storage from '../../../utils/local-storage';

const InputOption: React.FC<any> = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  // const onMouseDown = () => setIsActive(true);
  // const onMouseUp = () => setIsActive(false);
  // const onMouseLeave = () => setIsActive(false);
  // styles
  let bg = 'transparent';
  if ((isSelected && isFocused) || isSelected) bg = '#B2D4FF';
  else if (isFocused) bg = '#eee';
  else if (isActive) bg = '#B2D4FF';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    // color: 'sinherit',
    display: 'flex '
  };

  // prop assignment
  const props = {
    ...innerProps,
    // onMouseDown,
    // onMouseUp,
    // onMouseLeave,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type='checkbox'
        checked={isSelected}
        style={{ marginRight: '1rem' }}
        onChange={() => null}
      />
      {children}
    </components.Option>
  );
};

const CustomMultiSelect: React.FC<any> = ({ form, field, placeholder, options }) => {
  const { name, value, onChange } = field;
  const { setFieldValue, touched, errors } = form;
  const storage = new Storage();
  const [firstName, lastName] = storage.get('userName').split(' ');
  let defaultValue;
  if (name === 'users' && value) {
    defaultValue = options.filter((el: any) => value?.includes(el.value));
  }

  const handleChange = (selectedOption: any) => {
    // let newValue = deepCopy(value);
    // if (newValue.includes(selectedOption.value)) {
    //   newValue = newValue.filter((item: string) => item !== selectedOption.value);
    // } else {
    //   newValue.push(selectedOption.value);
    // }
    let value1 = selectedOption?.map((item: any) => item.value);

    setFieldValue(field.name, value1);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: '60px',
      borderRadius: '5px',
      boxShadow: (value || touched[name]) && errors && errors[name] && state.isFocused ? 0 : 1,
      // border:'1px solid #ff0000',
      // '&:active': {
      // border:'2px solid #ff0000'
      // },
      border:
        (value || touched[name]) && errors && errors[name]
          ? state.isFocused
            ? '2px solid #dc3545'
            : '1px solid #dc3545'
          : state.isFocused
          ? '2px solid #0d6efd'
          : '1px solid #adb5bd',
      '&:hover': {
        backgroundColor: '#F2F2F2',
        color: '#000000'
      }
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      '&:hover': {
        backgroundColor: '#F2F2F2',
        color: '#000000'
      }
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#2A72DD',
      color: '#FFFFFF',
      padding: '5px 10px',
      borderRadius: '8px'
    }),
    multiValueRemove: (provided: any, state: any) => ({
      ...provided,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#2A72DD',
        color: '#FFFFFF'
      }
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      color: '#ffffff'
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px',
      color: 'grey'
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: 'none'
    })
  };
  return (
    <>
      <div className={styles.field_label}>{placeholder} *</div>
      <Select
        value={defaultValue}
        isMulti
        aria-invalid={'true'}
        name={placeholder}
        placeholder={placeholder}
        options={options}
        onChange={handleChange}
        className='basic-multi-select'
        classNamePrefix='select'
        styles={customStyles}
        components={{
          Option: InputOption
        }}
        closeMenuOnSelect={false}
        // hideSelectedOptions={false}
        formatOptionLabel={(e: any) => (
          <>
            {
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <img src={avatarGenerator(e.label.split(' ')[0],e.label.split(' ')[1])} alt='' /> */}
                <span className={styles.avatar}>
                  <img
                    src={
                      e.img
                        ? e.img
                        : avatarGenerator(e.label.split(' ')[0][0], e.label.split(' ')[1][0])
                    }
                    alt='user'
                    className={styles.user_icon}
                  />
                </span>
                <span style={{ marginLeft: 5 }}>{e.label}</span>
              </div>
            }
          </>
        )}
      />
      {(value || touched[name]) && errors && errors[name] && (
        <div className={styles.error_message}>{errors[name]}</div>
      )}
    </>
  );
};

export default CustomMultiSelect;
