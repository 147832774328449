import React, { useState } from 'react';
import HeaderLanding from '../../components/core/layout/header-landing';
import { cx } from '../../utils/class-name-service';
import styles from './sic-codes.module.scss';
import { useQuery } from 'react-query';
import FooterLanding from '../../components/core/layout/footer-landing/index';
import AppConfig from '../../config';
import axios from 'axios';
import CircularLoader from '../../components/circular-loader';
import { useNavigate } from 'react-router-dom';

const SIC_CODES = [
  {
    title: 'AG - BIZ',
    startIndex: 1,
    endIndex: 9,
    results: [
      '1 - Agricultural Production Crops',
      '2 - Agricultural Production - Livestock & Animal Specialties ',
      '7 - Agricultural Services ',
      '8 - Forestry Services',
      '9 - Fishing, Hunting and Trapping'
    ]
  },
  {
    title: 'Mining',
    startIndex: 10,
    endIndex: 14,
    results: [
      '10 - Metal Mining',
      '12 - Coal Mining',
      '13 - Oil and Gas Extraction',
      '14 - Mining and Quarrying of Nonmetallic Minerals (except Fuels)'
    ]
  },
  {
    title: 'Construction',
    startIndex: 15,
    endIndex: 17,
    results: [
      '15 - Construction of Buildings',
      '16 - Heavy and Civil Engineering Construction',
      '17 - Special Trade Contractors'
    ]
  },
  {
    title: 'Manufacturing',
    startIndex: 20,
    endIndex: 39,
    results: []
  },
  {
    title: 'Transportation, Communication, Electric, Gas',
    startIndex: 40,
    endIndex: 49,
    results: []
  },
  {
    title: 'Wholesale and distributors',
    startIndex: 50,
    endIndex: 51,
    results: []
  },
  {
    title: 'Retail and restaurant',
    startIndex: 52,
    endIndex: 59,
    results: []
  },
  {
    title: 'Finance, Real estate',
    startIndex: 60,
    endIndex: 67,
    results: []
  },
  {
    title: 'Services',
    startIndex: 70,
    endIndex: 89,
    results: []
  },
  {
    title: 'Public admin',
    startIndex: 91,
    endIndex: 99,
    results: []
  }
];

const SICCodesScreen = () => {
  const BASE_URL = AppConfig.API_BASE_URL;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');

  const fetchSheetList = async () => {
    const res = await fetch(`${BASE_URL}/v1/sic/sic-sheet`);
    return res.json();
  };

  const { data: sheetList, status: sheetListStatus } = useQuery(`sheetList`, fetchSheetList);

  const [activeDropdown, setActiveDropdown] = useState<Number | null>(null);
  const [dropdownValue, setDropdownValue] = useState([]);

  const toggleDropdown = async (index: Number, item: String) => {
    if (activeDropdown === index) {
      setActiveDropdown(null);
      setDropdownValue([]);
    } else {
      setActiveDropdown(index);
      setDropdownValue([]);

      const config = {
        method: 'get',
        url: `${BASE_URL}/v1/sic/sic-industry-class?name=${item}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: item
      };

      const { data: industryClass } = await axios(config);
      setDropdownValue(industryClass);
    }
  };

  return (
    <>
      <div className={styles.page_wrapper}>
        <div className={styles.landing_page_wrapper}>
          <div className={styles.max_width}>
            <HeaderLanding />

            <div className={styles.content_wrapper}>
              <div className={styles.heading_cover_image}>
                <div className={styles.heading}>SIC code business lists</div>

                <div className={styles.sub_heading}>
                  Targeted leads from 15 million companies and 40 million executives
                </div>

                <div className={styles.search_field_wrapper}>
                  <img src='/li_search.svg'></img>

                  <input
                    type='text'
                    className={styles.input_field}
                    onChange={e => setSearchText(e.target.value)}
                    placeholder='Search for codes by keywords or numbers'
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter' && e.target.value !== '') {
                        setSearchText(e.target.value);
                        navigate(`/sic-codes-internal?search=${e.target.value}`);
                      }
                    }}
                  />

                  <div
                    className={styles.search_button}
                    onClick={() => {
                      if (searchText) navigate(`/sic-codes-internal?search=${searchText}`);
                    }}
                  >
                    Search
                  </div>
                </div>
              </div>

              <div className={styles.codes_dropdown_container}>
                {sheetListStatus === 'success' &&
                  sheetList.map((item: any, index: any) => {
                    return (
                      <div className={styles.dropdown_and_results_wrapper}>
                        <div
                          className={
                            activeDropdown === index
                              ? cx(styles.dropdown, styles.active_dropdown)
                              : styles.dropdown
                          }
                          id={`${index}`}
                          key={index}
                          onClick={() => {
                            toggleDropdown(index, item);
                          }}
                        >
                          <div className={styles.dropdown_text}>
                            <div className={styles.dropdown_text_item}>{item}</div>
                            {['RETAIL AND RESTAURANT', 'FINANCE-REAL ESTATE', 'SERVICES'].includes(
                              item
                            ) && <div className={styles.most_popular}>MOST POPULAR</div>}
                          </div>

                          {activeDropdown === index ? (
                            <img src='/Vector(StrokeUP).svg' className={styles.arrow_icon}></img>
                          ) : (
                            <img src='/Vector (Stroke).svg' />
                          )}
                        </div>
                        <div
                          className={
                            activeDropdown === index ? styles.results : styles.display_none
                          }
                          id={`dropdown_result_${index}`}
                        >
                          {dropdownValue.length > 0 ? (
                            dropdownValue.map((res, i) => {
                              return (
                                <div
                                  className={styles.result}
                                  onClick={() =>
                                    navigate(
                                      `/sic-codes-internal?code=${res[0]}&name=${item}&category=${res[2]}`
                                    )
                                  }
                                >
                                  {res[2]}
                                </div>
                              );
                            })
                          ) : (
                            <CircularLoader />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <FooterLanding />
          </div>
        </div>
      </div>
    </>
  );
};

export default SICCodesScreen;
