//TODO: MAP RUNNING TOTAL LEAD
import React from 'react';
import styles from '../../../screens/report/report.module.scss';
import SingleValueCard from '../statistic';
import BarChart from '../chart/BarChart';
import PieChart from '../chart/PieChart';
import RecentActivity from '../recent-Activity';
import { props } from './model';
import LineChart from '../chart/LineChart/LineChart';
import ProjectGoalCard from '../projectGoal/projectGoalStatistic';

const ListSourceReport: React.FC<props> = ({ overallMetrics, allSourceData }) => {
  const statistic = [
    {
      img: './images/average_user.svg',
      background: '#FFF9FE',
      count: overallMetrics?.leadCounts + overallMetrics?.prospectCounts,
      des: 'Total'
    },
    {
      img: './images/email_sent.svg',
      count: overallMetrics?.leadCounts,
      des: 'Leads',
      background: '#FFF7F7',
      percentage:
        overallMetrics.leadAveragePriceCostPercetage &&
        `$${overallMetrics.leadAveragePriceCostPercetage} per lead`
    },
    {
      img: './images/operate.svg',
      count: overallMetrics?.prospectCounts,
      des: 'Prospects',
      background: '#FFF9FE',
      percentage:
        overallMetrics.ProspectorAveragePriceCostPercetage &&
        `$${overallMetrics.ProspectorAveragePriceCostPercetage} per prospect`
    }
  ];

  return (
    <div className={styles.charts_container}>
      <div className={styles.statistic_wrapper}>
        {statistic.map((item: any) => (
          <SingleValueCard
            image={item.img}
            count={item.count}
            description={item.des}
            background={item.background}
            percentage={item.percentage}
          />
        ))}
      </div>
      <div className={styles.chart_wrapper}>
        {/* <div className={styles.bar_chart}> */}
        <BarChart prospectorsData={allSourceData} comp={'listSourceReport'} />
        {/* <p>Please add pricing for business sources to generate comparision graph</p>
          <button>Source Details</button> */}
        {/* </div> */}
        <div className={styles.right_chart} style={{ width: '274px' }}>
          {/* <div className={styles.pie_chart}> */}
          <PieChart overallMetrics={overallMetrics} comp={'listSourceReport'} />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ListSourceReport;
