import axios from 'axios';
import Storage from './local-storage';
import AppConfig from '../config';

const BASE_URL = AppConfig.API_BASE_URL;
const NYLAS_URLS = {
  connectEmail: `${BASE_URL}/v1/nylas/connect`,
  sendEmail: `${BASE_URL}/v1/nylas/send-single-email`
};

export const sendEmail = async (
  templateDetailId: any,
  businessListIds: any,
  editedEmails?: any
) => {
  const storage = new Storage();

  const url = NYLAS_URLS.sendEmail;
  const body = {
    templateDetailId,
    businessListIds,
    projectId: storage.get('projectId'),
    editedEmails
  };
  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    });

    return { error: false, message: response.data.message };
  } catch (e: any) {
    return { error: true, message: e.response.data.message };
  }
};
