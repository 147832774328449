import React, { useContext } from 'react';
import styles from './NotificationModal.module.scss';
import { props } from './model';
import { useQuery } from 'react-query';
import Storage from '../../../utils/local-storage';
import axios from 'axios';

const NotificationModal: React.FC<props> = ({}) => {
  const storage = new Storage();
  const today = new Date();
  const getReminder = async () => {
    const config = {
      method: 'get',
      url: `/v1/reminder/${storage.get('projectId')}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    return res.data;
  };
  const { data: reminder, status: reminderStatus } = useQuery(`getReminder`, getReminder, {
    refetchOnWindowFocus: true,
    onSuccess: res => {}
  });
  const formatDate = (dateStr: any) => {
    const isoDate = new Date(dateStr).toISOString();
    const formattedDate =
      isoDate.substring(5, 7) + '/' + isoDate.substring(8, 10) + '/' + isoDate.substring(0, 4);
    return formattedDate; // Output: "05/25/2023"
  };
  return (
    <div className={styles.dropdown_menu}>
      <h1 className={styles.header}>Notification</h1>
      <div className={styles.notificaltion_wrapper}>
        {reminder
          ?.filter((item: any) => new Date(item['reminderDate']) >= today)
          .map((item: any) => (
            <>
              <div className={styles.notification_item}>
                <div className={styles.notification_item_left}>
                  <div className={styles.reminder}>{item.title}</div>
                  <div className={styles.reminder_business}>{item.bussinessId.businessName}</div>
                </div>
                <div className={styles.timer}>{formatDate(item['reminderDate'])}</div>
              </div>
            </>
          ))}
        {reminder?.filter((item: any) => new Date(item['reminderDate']) >= today).length < 1 && (
          <>
            <div className={styles.no_notification}>
              <div>No notifications</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationModal;
