export const pricing_constant = [
  {
    feature: 'Number of projects',
    standard: '1',
    professional: '3',
    enterprise: '10'
  },
  {
    feature: 'Cost per additional project',
    standard: '$199',
    professional: '$179',
    enterprise: '$149'
  },
  {
    feature: 'Number of users',
    standard: '1',
    professional: '2',
    enterprise: '3'
  },
  {
    feature: 'Cost per additional user',
    standard: '$149',
    professional: '$129',
    enterprise: '$99'
  },
  {
    feature: 'Data rich contacts for local businesses',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },

  {
    feature: 'Import leads from other sources',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Sync platform emails with business emails',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Create unique teams for each project',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },

  {
    feature: 'Track sends and opens',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Marketing automation',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Mapping location of businesses',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },

  {
    feature: 'Do it yourself customization',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Mobile access',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Lead and prospect tracking',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Drip system for emailing',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Unlimited templates for emailing',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Powerful metrics',
    standard: 'true',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Training',
    standard: '$500',
    professional: '$250',
    enterprise: 'true'
  },
  {
    feature: 'Daily outgoing emails per user',
    standard: '50',
    professional: '80',
    enterprise: '100'
  },
  {
    feature: 'Data rich contacts for national businesses',
    standard: 'false',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Multi-brand companies indexed',
    standard: 'false',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Multi-unit franchisees indexed',
    standard: 'false',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Multi-brand franchisees indexed',
    standard: 'false',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Designate role permissions',
    standard: 'false',
    professional: 'true',
    enterprise: 'true'
  },
  {
    feature: 'Create competitor location reports',
    standard: 'false',
    professional: 'true',
    enterprise: 'true'
  }
];
