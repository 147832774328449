import React, { useEffect } from 'react';
import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './features.module.scss';
import { featureCardProps } from './model';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
const FeatureCard: React.FunctionComponent<featureCardProps> = ({ title, description, image }) => {
  const isMobile = useCheckMobileScreen();
  return (
    <div className={styles.card} data-aos='fade-up'>
      <img className={styles.card_icon} src={image} alt='' />
      <div className={styles.card_title}>{title}</div>
      <div className={styles.card_description}>{description}</div>
      <div className={styles.card_shadow}></div>
    </div>
  );
};

function FeaturesContainer() {
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();

  // , once: true
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const FEATURES = [
    {
      title: 'Centralized information',
      description:
        'Information flows and is easily accessible by every member of the team because all data is organized and centrally located.',
      icon: 'images/i1.svg'
    },
    {
      title: 'Full integrated',
      description: `Emails are automatically two-way synced in real time between the ReachTenants' platform and the prospector’s own communication devices and systems.`,
      icon: 'images/i2.svg'
    },
    {
      title: 'Opportunity management',
      description:
        'Information flows and is easily accessible by every member of the team because all data is organized and centrally located. ',
      icon: 'images/i3.svg'
    },
    {
      title: 'Automation',
      description:
        'Repetitive, tedious tasks are eliminated by allowing automation while still allowing the process to be seamless and deliver personalized communications that lead to higher productivity.',
      icon: 'images/i4.svg'
    },
    {
      title: 'Track communications',
      description:
        'All calls, emails, opens, comments, requests, notes, and documents sent are tracked and remain visible and organized with the business contact they relate to for easy responsive viewing.',
      icon: 'images/i5.svg'
    },
    {
      title: 'Competitor location reports',
      description:
        'Supercharge your leasing to national expansion tenants by showing the location of the vacant space, and within a radius, the locations of the targeted businesses and its competitors.',
      icon: 'images/i6.svg'
    },
    {
      title: 'Import other leads',
      description:
        'Leads from other sources have a convenient place in ReachTenants so everything important is in one place.',
      icon: 'images/i7.svg'
    },
    {
      title: 'Teams',
      description:
        'Perfect for solo prospectors and teams. Make teams with the same or different members for each project. ReachTenants gives teams full access to all relevant data in real time for increased collaboration.',
      icon: 'images/i8.svg'
    },
    {
      title: 'Do it yourself customization',
      description: `The system allows prospectors and administrators to easily adapt the program to best meet their needs and desires. Most fields in the list of records can be renamed, and the order of fields can be modified to each user’s preference.`,
      icon: 'images/i9.svg'
    },
    {
      title: 'User friendly and scalable',
      description:
        'ReachTenants is perfect for one project and one prospector and operates just as flawlessly with unlimited projects and/or prospectors. There are no arbitrary restraints, and it is intuitive.',
      icon: 'images/i10.svg'
    },
    {
      title: 'Business contacts',
      description:
        'Local business contacts include name and email address of owner or manager and are curated based upon the ideal tenants as determined by the prospector. It has a better impact than sending to info@, hello@ and support@.',
      icon: 'images/i11.svg'
    },
    {
      title: 'Metrics and accountability',
      description: `Early intelligence is key to keeping the team held accountable and focused on the objectives. Prospectors receive daily or weekly reports to give them the insights and feedback
        to provide top performance.`,
      icon: 'images/i12.svg'
    }
  ];
  return (
    <div className={styles.landing_page_wrapper}>
      <div className={styles.max_width}>
        <HeaderLanding />
        <div className={styles.hero}>
          <div className={styles.hero_text} data-aos='fade-up'>
            <div className={styles.hero_text_title}>
              Why prospect {isMobile && <br />}
              the <span style={{ color: '#D81F1F' }}>Reach</span>
              <span style={{ color: '#192d73' }}>Tenants</span> way?
            </div>
            <div className={styles.hero_text_description}>
              These features make it the most effective way to reach your next tenant
            </div>
          </div>
          <div className={styles.hero_image} data-aos='zoom-in-up'>
            <img
              src='images/feature-map.jpg'
              alt=''
              width={465}
              style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', borderRadius: '10px' }}
            />
          </div>
        </div>
        <div className={styles.features}>
          <div className={styles.features_header}>Our core features</div>
          <div className={styles.card_container}>
            {FEATURES.map(feature => {
              return (
                <FeatureCard
                  title={feature.title}
                  description={feature.description}
                  image={feature.icon}
                />
              );
            })}
          </div>
        </div>

        <div className={styles.bottom_page_container}>
          <div className={styles.cta_wrapper}>
            <img
              src='/images/time-matter-bottom.jpg'
              className={styles.cta_image}
              data-aos='fade-up'
              width={350}
            ></img>

            <div className={styles.cta_details}>
              <div className={styles.cta_detail_heading}>
                The most effective way to <br/> reach your next tenant
              </div>

              <div
                className={styles.cta_button_wrapper}
                onClick={() => {
                  navigate('/request-demo');
                }}
              >
                Book a Demo
              </div>
            </div>
          </div>

          <FooterLanding />
        </div>
      </div>
    </div>
  );
}

export default FeaturesContainer;
