import React, { useEffect, useState, useContext } from 'react';
import styles from '../../view-project-form.module.scss';
import { Formik, Form, Field } from 'formik';
import { props } from './model';
import CustomisedTextField from '../../../../../components/form-elements/customised-text-field/index';
import WrappedButton from '../../../../../components/form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import Storage from '../../../../../utils/local-storage';
import CustomMultiSelect from '../../../../../components/form-elements/customised-multi-select';
import AppConfig from '../../../../../config';
import { useQuery } from 'react-query';
import { ProjectContext } from '../../../../../context/ProjectContext';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const InitialDetailsForm: React.FC<props> = ({
  setActiveStep,
  project,
  setStep,
  activeStep,
  refetch
}) => {
  const { projectData, setProjectData } = useContext(ProjectContext);
  const [team, setTeam] = useState<any>([]);
  const storage = new Storage();
  let addproject = projectData._id == undefined;
  const BASE_URL = AppConfig.API_BASE_URL;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const fetchTeamMemberDropdown = async () => {
    let id = storage.get('organizationId');
    const res = await fetch(`${BASE_URL}/v1/organization/get-organization-users/${id}`, {
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    });
    return res.json();
  };
  const { data: teamMemberData, status: teamMemberStatus } = useQuery(
    `teamMemberData`,
    fetchTeamMemberDropdown
  );
  useEffect(() => {
    if (teamMemberStatus === 'success') {
      let option = teamMemberData?.team?.map((item: any) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
        img: item.profileImage
          ? `https://reachtenants-dev-assets.s3.amazonaws.com/${item.profileImage}`
          : ''
      }));
      option = option.filter((usr: any) => usr.value !== projectData.createdby);
      option = option.filter((usr: any) => usr.value !== storage.get('ownerUserId'));
      if (window.location.pathname === '/add-project')
        option = option.filter((usr: any) => usr.value !== storage.get('userId'));
      setTeam(option);
    }
  }, [teamMemberData]);

  const updateSaveChanges = async (data: any) => {
    const requestData = JSON.stringify({
      projectName: data.projectName,
      projectAddress: data.projectAddress,
      projectId: project._id,
      projectLatLong: data.projectLatLong,
      users: data.users
      // password: data.newPassword,
      // token: searchParams.get('token')
    });
    const config = {
      method: 'put',
      url: `${BASE_URL}/v1/project/updateProject`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };

    return await axios(config);
  };
  const postProject = async (data: any) => {
    const requestData = JSON.stringify({
      projectName: data.projectName,
      projectAddress: data.projectAddress,
      projectLatLong: data.projectLatLong,
      users: data.users
    });

    const config = {
      method: 'post',
      url: `${BASE_URL}/v1/project`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };

    setProjectData({ ...projectData, ...data });
    const { data: res } = await axios(config);
    return res;
  };
  const { status: mutationStatus, mutateAsync: updateProject } = useMutation(updateSaveChanges, {
    onSuccess: res => {
      let project = res.data.data;
      storage.save('projectName', project.projectName);
      storage.save('projectLatLong', project.projectLatLong);
      refetch();
      enqueueSnackbar('View Project Updated Successfully', {
        variant: 'success',
        header: 'Success'
      });
      navigate(`/dashboard/${storage.get('projectId')}`);

      // if (!addproject) window.location.href = `/view-project/${storage.get('projectId')}`;
      // onProjectNext();
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });

  const onProjectNext = () => {
    if (activeStep <= 1) setActiveStep(1);
    setStep(1);
  };
  const onUpdateChanges = (data: any) => {
    updateProject(data);
  };

  const validationSchema = yup.object({
    projectName: yup.string().required('Project name is required'),
    projectAddress: yup.string().trim().required('Project address is required')
    // users: yup.array().of(yup.string())
    // .min(1, 'Atleast One team member is required')
    // .required('Atleast One team member is required')
  });

  return (
    <>
      <div className={styles.form_container}>
        <div className={styles.form_title}>{addproject ? 'Add Project' : 'View Project'}</div>

        <Formik
          initialValues={{
            projectName: projectData.projectName,
            projectAddress: projectData.projectAddress,
            projectLatLong: projectData.projectLatLong,
            users: projectData.users
          }}
          validationSchema={validationSchema}
          onSubmit={data => {
            if (addproject && !storage.get('projectId')) {
              postProject(data)
                .then((res: any) => {
                  let project = res;
                  storage.save('projectId', project._id);
                  storage.save('projectName', project.projectName);
                  storage.save('projectLatLong', project.projectLatLong);
                  onProjectNext();
                })
                .catch(err => {
                  let message = err.response.data.message;
                  enqueueSnackbar(message, { variant: 'error', header: 'Error' });
                });
            } else {
              onProjectNext();
            }
          }}
        >
          {props => (
            <Form className={styles.form}>
              <div className={styles.form_field}>
                <Field
                  name='projectName'
                  placeholder={'Project name'}
                  title={'Project Name'}
                  type={'text'}
                  component={CustomisedTextField}
                  required={true}
                  // label={'Project Name'}
                />
              </div>

              <div className={styles.form_field}>
                <Field
                  name='projectAddress'
                  placeholder={'Project address'}
                  title={'Project Address'}
                  type={'address'}
                  component={CustomisedTextField}
                  required={true}
                  label={'Project Address'}
                />
                {/* <Autocomplete
                  apiKey={'AIzaSyB121Mc3-C9mbZgd8j0oFZqK1G6D_rAGEU'}
                  options={{
                    types: ['address'],
                    componentRestrictions: { country: 'us' }
                  }}
                  onPlaceSelected={place => {
                  }}
                /> */}
              </div>

              <div className={styles.form_field}>
                <Field
                  name='users'
                  placeholder={'Team members'}
                  title={'Team Members'}
                  options={team}
                  component={CustomMultiSelect}
                />
              </div>
              <div className={styles.add_project_footer}>
                <div className={styles.button_wrapper}>
                  {!addproject && (
                    <WrappedButton
                      btnText='Save Changes'
                      type='submit'
                      onClick={(e: React.FormEvent<HTMLInputElement>) => {
                        e.preventDefault();
                        onUpdateChanges(props.values);
                      }}
                      btnType={BTN_SECONDARY}
                      className={styles.submit_button}
                      isDisabled={!props.dirty || storage.get('role') === 'prospector'}
                    />
                  )}
                  {addproject && (
                    <WrappedButton
                      btnText='Next'
                      type='submit'
                      btnType={BTN_PRIMARY}
                      className={styles.submit_button}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default InitialDetailsForm;
