import { props } from './model';
import { Formik, Form, Field } from 'formik';
import styles from './report-error-modal.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import WrappedButton from '../../form-elements/buttons';
import { BTN_PRIMARY, GREY_BUTTON } from '../../../constants/button-constants';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import AppConfig from '../../../config';
import * as yup from 'yup';
import Storage from '../../../utils/local-storage';
import { useState } from 'react';
import CustomSelectField from '../../form-elements/customised-select-field';
import CustomisedTextArea from '../../form-elements/customised-text-area';

const ReportErrorModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  businessId
}) => {
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const postDetails = async (data: any) => {
    let config = {
      method: 'post',
      url: '/v1/businessDetail/reportError',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: JSON.stringify({
        ...data,
        businessId: businessId
      })
    };

    axios
      .request(config)
      .then((response: any) => {
        // setBusinessDetails(response.data);
      })
      .catch((error: any) => {});
  };
  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{
          width: '100%',
          zIndex: '999999999 !important'
        }}
      >
        <Formik
          initialValues={{
            type: '',
            msg: ''
          }}
          validationSchema={yup.object({})}
          onSubmit={values => {
            postDetails(values);
            setShowModal(!showModal);
          }}
        >
          {props => (
            <Form>
              <ModalHeader
                toggle={() => {
                  setShowModal(!showModal);
                }}
              >
                Report Error
              </ModalHeader>
              <ModalBody style={{ overflowY: 'scroll' }}>
                <div className={styles.form_wrapper}>
                  <div className={styles.single_field}>
                    <Field
                      name={'type'}
                      placeholder={'Select Issue'}
                      options={[
                        { label: 'Bug', value: 'bug' },
                        { label: 'Issue', value: 'issue' },
                        { label: 'Buggy', value: 'buggy' }
                      ]}
                      component={CustomSelectField}
                    />
                  </div>
                  <div className={styles.single_field}>
                    <Field
                      name={'msg'}
                      placeholder={`Message`}
                      component={CustomisedTextArea}
                      required={true}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className={styles.button_wrapper}>
                  <WrappedButton
                    btnText='Cancel'
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setShowModal(!showModal);
                    }}
                    btnType={GREY_BUTTON}
                    className={styles.submit_button}
                  />
                  <WrappedButton
                    btnText='Report'
                    btnType={BTN_PRIMARY}
                    className={styles.submit_button}
                    type={'submit'}
                    // isInProgress={mutationStatus === 'loading' ? true : false}
                  />
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ReportErrorModal;
