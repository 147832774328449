import React, { useEffect, useMemo, useRef, useState } from 'react';
import DashboardFilters from '../../containers/dashboard/filters';
import Map, { MapRef, Popup } from 'react-map-gl';
import styles from './dashboard.module.scss';
import Playground from '../playground';
import { NavigationControl } from 'react-map-gl';
import { Marker } from 'react-map-gl';
import Storage from '../../utils/local-storage';
import MapPin from '../../components/map-pin/MapPin';
const MAPBOX_TOKEN =
  'pk.eyJ1Ijoic2hpa2hhcnNoYXJtYSIsImEiOiJjbGJldzNpeXkwMGZ2M3BwNDh6bzdmcWw5In0.io_4UtjsI6cadWpGdThPxg';
const MAP_STYLE = 'mapbox://styles/mapbox/streets-v12';

interface DashboardScreen {
  queryClient: any;
  columnOrder: any;
}

const DashboardScreen = (props: DashboardScreen) => {
  const { columnOrder, queryClient } = props;
  const storage = new Storage();

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [page, setPage] = useState(1);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [queryString, setQueryString] = useState<any>();
  const [dashboardQueryString, setDashboardQueryString] = useState<any>();
  const [distanceQueryString, setDistanceQueryString] = useState<any>();
  const [projectType, setProjectType] = useState('Local');
  const [selectedBusinessList, setSelectedBusinessList] = React.useState<any>();
  const [selectedBusinessListName, setSelectedBusinessListName] = React.useState<any>(null);
  const [mapCollapsed, setMapCollaped] = React.useState(false);
  const [subcategoryOptions, setSubcategoryOptions] = useState<any>([]);
  const [selectedSubcategory, setSelectedSubCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [localGroup, setLocalGroup] = useState<boolean>(false);
  const [localGroupIds, setLocalGroupIds] = useState<any>([]);
  const [perPage, setPerPage] = useState(20);
  const [showPopup, setShowPopup] = useState<any>(null);
  const [preSelected, setPreSelected] = useState<any>({});
  const [action, setAction] = useState<any>({ fromUser: false });
  const [filterOptions, setFilterOptions] = useState({
    groupBy: '',
    groupCompany: '',
    state: [],
    category: '',
    subcategory: [],
    zipCode: []
    // minDistance: 0,
    // maxDistance: 100
  });
  const [longLat, setLongLat] = useState<any>([]);

  useEffect(() => {
    const projType = storage.get('projectType');
    if (projType) setProjectType(projType);
    setLongLat([
      storage.get('projectLatLong').split(',').map(parseFloat)[1],
      storage.get('projectLatLong').split(',').map(parseFloat)[0]
    ]);
  }, []);

  const handleChange = (rows: any) => {
    const allSelectedBusinesses = rows.selectedRows.map((el: any) => {
      return el;
    });
    setSelectedRows(allSelectedBusinesses);
  };

  const mapContainer = useRef<MapRef>(null);

  const markers = useMemo(
    () =>
      projectType === 'Local' &&
      selectedRows.map((el: any) => (
        <div
          onMouseEnter={() => {
            setShowPopup(el);
          }}
          onMouseLeave={() => {
            setShowPopup(null);
          }}
        >
          {el.lat !== 0.0 && el.long !== 0.0 && (
            <Marker key={el.id} longitude={el.long} latitude={el.lat}>
              {/* <svg
                width='26'
                height='37'
                viewBox='0 0 26 37'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13 0C5.82214 0 0 5.79975 0 12.95C0 22.6625 13 37 13 37C13 37 26 22.6625 26 12.95C26 5.79975 20.1779 0 13 0ZM13 17.575C10.4371 17.575 8.35714 15.503 8.35714 12.95C8.35714 10.397 10.4371 8.325 13 8.325C15.5629 8.325 17.6429 10.397 17.6429 12.95C17.6429 15.503 15.5629 17.575 13 17.575Z'
                  fill='#D81F1F'
                />
              </svg> */}
              <MapPin />
            </Marker>
          )}
        </div>
      )),
    [selectedRows]
  );

  return (
    <div className={styles.max_width}>
      <div className={styles.max_width}>
        <DashboardFilters
          selectedRows={selectedRows}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          projectType={projectType}
          setProjectType={setProjectType}
          setSelectedBusinessList={setSelectedBusinessList}
          setSelectedBusinessListName={setSelectedBusinessListName}
          selectedBusinessList={selectedBusinessList}
          selectedBusinessListName={selectedBusinessListName}
          queryString={queryString}
          setQueryString={setQueryString}
          setMapCollaped={setMapCollaped}
          setDashboardQueryString={setDashboardQueryString}
          dashboardQueryString={dashboardQueryString}
          selectedSubcategory={selectedSubcategory}
          setSelectedSubCategory={setSelectedSubCategory}
          subcategoryOptions={subcategoryOptions}
          setSubcategoryOptions={setSubcategoryOptions}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          perPage={perPage}
          setSelectedRows={setSelectedRows}
          setDistanceQueryString={setDistanceQueryString}
          distanceQueryString={distanceQueryString}
          localGroup={localGroup}
          setLocalGroup={setLocalGroup}
          setLocalGroupIds={setLocalGroupIds}
        />
        <div className={styles.dashboard_body}>
          <div
            className={
              projectType === 'Local' && !mapCollapsed
                ? styles.table_container
                : styles.table_container_national
            }
          >
            <Playground
              queryClient={queryClient}
              handleChange={handleChange}
              filterOptions={filterOptions}
              projectType={projectType}
              selectedBusinessList={selectedBusinessList}
              columnOrder={columnOrder}
              queryString={queryString}
              setQueryString={setQueryString}
              setDashboardQueryString={setDashboardQueryString}
              dashboardQueryString={dashboardQueryString}
              selectedSubcategory={selectedSubcategory}
              selectedCategory={selectedCategory}
              setLocalGroup={setLocalGroup}
              localGroup={localGroup}
              setLocalGroupIds={setLocalGroupIds}
              localGroupIds={localGroupIds}
              setProjectType={setProjectType}
              page={page}
              setPage={setPage}
              action={action}
              setAction={setAction}
              preSelected={preSelected}
              setPreSelected={setPreSelected}
              setPerPage={setPerPage}
              perPage={perPage}
              setShowPopup={setShowPopup}
              setDistanceQueryString={setDistanceQueryString}
              distanceQueryString={distanceQueryString}
            />
          </div>

          <div
            className={
              !mapCollapsed && projectType === 'Local'
                ? styles.map_container
                : styles.map_container_collaped
            }
          >
            {projectType === 'Local' && (
              <div
                className={mapCollapsed ? styles.map_arrow_collapsed : styles.map_arrow}
                onClick={() => setMapCollaped(!mapCollapsed)}
              >
                <img src={mapCollapsed ? '/images/leftArrow.svg' : '/images/rightArrow.svg'} />
              </div>
            )}
            <Map
              mapboxAccessToken={MAPBOX_TOKEN}
              mapStyle={MAP_STYLE}
              style={{
                width: `${!mapCollapsed && projectType === 'Local' ? '500px' : '0px'}`,
                height: 'calc(100vh - 170px)',
                borderRadius: '8px',
                transition: '1.5s'
              }}
              initialViewState={{
                longitude: storage.get('projectLatLong').split(',').map(parseFloat)[1],
                latitude: storage.get('projectLatLong').split(',').map(parseFloat)[0],
                zoom: 11
              }}
            >
              {markers}

              {longLat.length && (
                <Marker
                  longitude={storage.get('projectLatLong').split(',').map(parseFloat)[1]}
                  latitude={storage.get('projectLatLong').split(',').map(parseFloat)[0]}
                >
                  <svg
                    width='26'
                    height='37'
                    viewBox='0 0 26 37'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13 0C5.82214 0 0 5.79975 0 12.95C0 22.6625 13 37 13 37C13 37 26 22.6625 26 12.95C26 5.79975 20.1779 0 13 0ZM13 17.575C10.4371 17.575 8.35714 15.503 8.35714 12.95C8.35714 10.397 10.4371 8.325 13 8.325C15.5629 8.325 17.6429 10.397 17.6429 12.95C17.6429 15.503 15.5629 17.575 13 17.575Z'
                      fill='#D81F1F'
                    />
                  </svg>
                </Marker>
              )}
              {showPopup && projectType === 'Local' && (
                <Popup
                  anchor='bottom'
                  longitude={Number(showPopup.long)}
                  latitude={Number(showPopup.lat)}
                  onClose={() => setShowPopup(null)}
                >
                  <div style={{ width: '150px' }}>{showPopup.businessName}</div>
                </Popup>
              )}
              <NavigationControl position='bottom-right' showCompass={false} />
            </Map>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardScreen;
