import React, { useContext, useState } from 'react';
import styles from './footer-landing.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ProjectContext } from '../../../../context/ProjectContext';

const FOOTER_LINKS = [
  {
    name: 'About',
    link: '/about'
  },
  {
    name: 'Features',
    link: '/features'
  },
  {
    name: 'Calculators',
    link: '/calculators'
  },
  {
    name: 'Pricing',
    link: '/pricing'
  },
  {
    name: 'Blog',
    link: '/blog'
  },
  {
    name: 'Time',
    link: '/time'
  },
  {
    name: 'FAQ',
    link: '/faq'
  },
  {
    name: 'SIC-Codes',
    link: '/sic-codes'
  },
 
];

const FooterLanding = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { blogs, setBlogs } = useContext(ProjectContext);
  const currentYear = new Date().getFullYear(); // Get the current year
  const onChangeHandler = (e: any) => {
    setEmail(e.target.value);
  };
  function handleEmailValidation() {
    const regex = /\S+@\S+\.\S+/;
    if (regex.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div className={styles.footer_wrapper}>
        <div className={styles.rc_logo}>
          <img src='/RTLogo.svg' width={192} />
        </div>

        <div className={styles.footer_cta_container}>
          <div className={styles.links}>
            {FOOTER_LINKS.map(item => (
              <>
                {item.name !== 'Blog' || (item.name === 'Blog' && blogs && blogs.length > 0) ? (
                  <div
                    className={styles.link}
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    {item.name}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>

          <div className={styles.field_button_wrapper}>
            <input
              type='text'
              placeholder='Enter your email'
              onChange={e => onChangeHandler(e)}
              className={styles.input_field}
            />

            <div
              className={styles.button_wrapper}
              onClick={() => {
                if (email) {
                  if (handleEmailValidation()) navigate('/request-demo', { state: { email } });
                  else enqueueSnackbar('Enter valid Email', { variant: 'error', header: 'Error' });
                } else {
                  enqueueSnackbar('Email field is empty', {
                    variant: 'error',
                    header: 'Error'
                  });
                }
              }}
            >
              Book a Demo
            </div>
          </div>
        </div>

        <div className={styles.privacy}>
          <div className={styles.footer_privacy_container}>
            <div className={styles.links}>
              <div className={styles.link} onClick={() => navigate('/terms-of-use')}>
                Terms of Use
              </div>

              <div className={styles.link} onClick={() => navigate('/privacy-policy')}>
                Privacy Policy
              </div>
            </div>

            <div className={styles.link_rights}>
              © {currentYear} ReachTenants, Inc. or its affiliates. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterLanding;
