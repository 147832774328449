import React, { useState } from 'react';
import { deepCopy } from '../../../utils/deep-copy';
import styles from './customised-select.module.scss';
import Select, { components } from 'react-select';
import { avatarGenerator } from '../../../utils/avatarGenerator';
import Storage from '../../../utils/local-storage';

const CustomSelectField: React.FC<any> = ({ form, field, placeholder, options }) => {
  const { name, value, onChange } = field;
  const { setFieldValue, touched, errors } = form;
  const storage = new Storage();
  let defaultValue;
  if (name === 'users' && value) {
    defaultValue = options.filter((el: any) => value?.includes(el.value));
  }

  const handleChange = (e: any) => {
    setFieldValue(field.name, e.value);
  };

  const customStyles = {
    dropdownIndicator: (base: any) => ({
      ...base
    }),
    control: (base: any) => ({
      ...base,
      // :'80px',
      minHeight: '55px',
      borderRadius: '5px',
      minWidth: '150px',
      cursor: 'pointer',
      fontSize: '12px',
      fontFamily: 'Lato'
    }),
    placeholder: (base: any) => ({
      ...base,
      fontFamily: 'Lato',
      fontSize: '12px'
    }),
    menu: (base: any) => ({
      ...base,
      fontFamily: 'Lato',
      fontSize: '12px'
    })
  };
  return (
    <>
      <div className={styles.field_label}>{placeholder}</div>
      <Select
        value={options ? options.find((option: any) => option.value === field.value) : ''}
        options={options}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null
        }}
        placeholder={placeholder}
        isOptionDisabled={(option: any) => option.isdisabled} // disable an option
        onChange={(e: any) => {
          handleChange(e);
        }}
        isSearchable={false}
      />
      {(value || touched[name]) && errors && errors[name] && (
        <div className={styles.error_message}>{errors[name]}</div>
      )}
    </>
  );
};

export default CustomSelectField;
