import React, { useContext } from 'react';
import styles from './UserProfileModal.module.scss';
import { props } from './model';
import { avatarGenerator } from '../../../utils/avatarGenerator';
import { cx } from '../../../utils/class-name-service';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../utils/user-actions-helper';
import { ProjectContext } from '../../../context/ProjectContext';
import Storage from '../../../utils/local-storage';

const UserProfileModal: React.FC<props> = ({ name, email, userName }) => {
  const { imageURL, setImageURL } = useContext(ProjectContext);
  const { token, setToken } = useContext(ProjectContext);
  const storage = new Storage();
  const role = storage.get('role');

  const navigate = useNavigate();
  return (
    <div className={styles.dropdown_menu}>
      <div className={styles.user_details_container}>
        <div className={styles.avatar}>
          <img
            src={
              imageURL && !imageURL.includes('undefined')
                ? imageURL
                : avatarGenerator(name[0][0], name[1][0])
            }
            alt='user'
            className={styles.user_icon}
          />
        </div>
        <div className={styles.user_name_email}>
          <div className={styles.dropdown_menu_name}>{userName}</div>

          <div className={styles.dropdown_menu_email}>{email}</div>
        </div>
      </div>

      <div className={styles.border_line}></div>

      <div className={styles.options_container}>
        <div
          className={styles.option}
          onClick={() => {
            navigate('/settings');
          }}
        >
          <img src='/settings_icon.svg'></img>
          <div className={styles.option_text}>Settings</div>
        </div>

        {role !== 'prospector' && (
          <div
            className={styles.option}
            onClick={() => {
              navigate('/add-business-list');
            }}
          >
            <img src='/upload-csv.svg'></img>
            <div className={styles.option_text}>Add business list</div>
          </div>
        )}

        <div
          className={styles.option}
          onClick={() => {
            logout();
          }}
        >
          <img src='/logout_icon.svg'></img>
          <div className={cx(styles.option_text, styles.logout_text)}>Sign out</div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileModal;
