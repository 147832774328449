//TODO: MAP RUNNING TOTAL LEAD
import React from 'react';
import styles from '../../../screens/report/report.module.scss';
import SingleValueCard from '../statistic';
import BarChart from '../chart/BarChart';
import PieChart from '../chart/PieChart';
import RecentActivity from '../recent-Activity';
import { props } from './model';
import LineChart from '../chart/LineChart/LineChart';
import ProjectGoalCard from '../projectGoal/projectGoalStatistic';

const OverallReport: React.FC<props> = ({ overallMetrics, selectedDayRange }) => {
  const statistic = [
    {
      img: './images/email_sent.svg',
      count: overallMetrics.emailSent,
      des: 'Emails sent',
      background: '#FFF7F7'
    },
    {
      img: './images/email_opened.svg',
      count: overallMetrics.emailOpened,
      des: 'Emails opened',
      background: '#F3F8FF',
      percentage: overallMetrics.emailOpenedPercentage && `${overallMetrics.emailOpenedPercentage}%`
    },
    {
      img: './images/operate.svg',
      count: overallMetrics.uniqueSent,
      des: 'Unique contacts sent',
      background: '#F0F0FF'
    },
    {
      img: './images/average_user.svg',
      count: overallMetrics.uniqueOpened,
      des: 'Unique contacts opened',
      background: '#FFF9FE',
      percentage:
        overallMetrics.uniqueOpenedPercentage && `${overallMetrics.uniqueOpenedPercentage}%`
    },
    {
      img: './images/email_sent.svg',
      count: overallMetrics.declined,
      des: 'Declined',
      background: '#FFF9FE'
    },
    {
      img: './images/email_opened.svg',
      count: overallMetrics.deadlead,
      des: 'Dead leads',
      background: '#FFF9FE'
    },
    {
      img: './images/operate.svg',
      count: overallMetrics.tour,
      des: 'Tours requested',
      background: '#FFF9FE'
    },
    {
      img: './images/average_user.svg',
      background: '#FFF9FE',

      count: overallMetrics.offer,
      des: 'Offers made'
    },
    {
      img: './images/email_sent.svg',
      count: overallMetrics.leads,
      des: 'Leads',
      background: '#FFF7F7'
    },
    {
      img: './images/operate.svg',
      count: overallMetrics.prospect,
      des: 'Prospects',
      background: '#FFF9FE'
    }
  ];
  const ProspectLead = [
    {
      header: '',
      sent: overallMetrics.totalRunningLeads,
      outOf: overallMetrics.totalRunningProspects,
      description: 'Total number of running prospects:',
      background: '#3E72D8'
    }
  ];

  return (
    <div className={styles.charts_container}>
      <div className={styles.statistic_wrapper}>
        {statistic.map((item: any) => (
          <SingleValueCard
            image={item.img}
            count={item.count}
            description={item.des}
            background={item.background}
            percentage={item.percentage}
          />
        ))}
      </div>
      <div className={styles.chart_wrapper}>
        <div className={styles.bar_chart}>
          <LineChart
            selectedDayRange={selectedDayRange}
            labelSent={overallMetrics.lineChartLabel}
            valueSent={overallMetrics.lineChartValue}
            valueOpened={overallMetrics.lineChartValueOpened}
            valueProspect={overallMetrics.lineChartValueProspect}
            valueDeclined={overallMetrics.lineChartValueDeclined}
            valueDeadLeads={overallMetrics.lineChartValueDeadLeads}
            valueLeads={overallMetrics.lineChartValueLeads}
          />
        </div>
        <div className={styles.right_chart}>
          {/* <div className={styles.pie_chart}>
            <PieChart />
          </div> */}
          <div className={styles.recent_update}>
            {/* <div className={styles.header}>Recent Acitvity</div> */}
            {/* <div className={styles.activities}>
              {updates.map((item: any) => (
                <RecentActivity data={item.data} info={item.info} />
              ))}
            </div> */}
            <div className={styles.header}>Total running prospects vs Total running leads</div>
            {ProspectLead.map(item => (
              <ProjectGoalCard
                header={item.header}
                sent={item.sent}
                outOf={item.outOf}
                description={item.description}
                background={item.background}
                shadow={false}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallReport;
