import React, { useEffect, useState } from 'react';
import styles from './create-template.module.scss';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import CustomisedTextArea from '../form-elements/customised-text-area';
import CustomisedTextField from '../form-elements/customised-text-field';
import WrappedButton from '../form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../constants/button-constants';
import UploadFiles from '../modals/uploadFiles';
import AppConfig from '../../config';
import axios from 'axios';
import Storage from '../../utils/local-storage';
import { useMutation, useQuery } from 'react-query';
import RichTextEditor from '../rich-text-Editor/RichTextEditor';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { HandleFileDelete } from '../../utils/form-handler';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import SavedListModal from '../modals/saved-list';
interface props {
  setShowCreateTemplate: any;
  refetch: any;
  deleteRef?: any;
  comp?: string;
  savedCompetitorList?: any;
}
const CreateTemplate: React.FunctionComponent<props> = ({
  setShowCreateTemplate,
  refetch,
  deleteRef,
  comp,
  savedCompetitorList
}) => {
  const [emailSubject, setEmailSubject] = useState('');
  const [message, setMessage] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [convertedContent, setConvertedContent] = useState<string>('');
  const [imagesUrl, setImagesUrl] = useState<any>([]);
  const [showAttachedFiles, setShowAttachedFiles] = useState<any>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [showSavedCompetitorModal, setShowSavedCompetitorModal] = useState(false);
  const [selectedCompetitorList, setSelectedCompetitorList] = useState('');

  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const createTemplateHandler = async (data: any) => {
    const requestData = JSON.stringify({
      emailSubject: data.emailSubject,
      emailMessage: data.message,
      filesAttach: showAttachedFiles,
      name: data.templateName.trim(),
      projectId: storage.get('projectId'),
      competitorList: selectedCompetitorList ? selectedCompetitorList : undefined
    });

    if (data.message === '' || data.message === '<p></p>') {
      enqueueSnackbar('Message body is required', { variant: 'error', header: 'Error' });
    } else {
      const config = {
        method: 'post',
        url: `${BASE_URL}/v1/template/createTemplate`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storage.get('accessToken')}`
        },
        data: requestData
      };

      return await axios(config);
    }
  };
  const { status: mutationStatus, mutateAsync: createTemplate } = useMutation(
    createTemplateHandler,
    {
      onSuccess: res => {
        enqueueSnackbar(res?.data.message, { variant: 'success', header: 'Success' });
        refetch();
        setShowCreateTemplate(false);
      },
      onError: (err: any) => {
        let message = err.response.data.message;
        enqueueSnackbar('Message body is required', { variant: 'error', header: 'Error' });
      }
    }
  );
  const onSubmit = (data: any) => {
    createTemplate(data);
  };
  const deleteHandler = (file: any) => {
    setIsUploading(true);

    HandleFileDelete(file)
      .then(res => {
        const response = res.data.message;
        let index = showAttachedFiles.indexOf(file);
        showAttachedFiles.splice(index, 1);
        setIsUploading(false);
        setShowAttachedFiles([...showAttachedFiles]);
        if (response == 'okay') {
        }
      })
      .catch(() => {
        setIsUploading(false);
      });
  };
  const validationSchema = yup.object().shape({
    templateName: yup.string().required('Template name is required'),
    emailSubject: yup.string().required('Email subject is required')
    // message: yup.string().required('Message is required')
  });
  return (
    <div
      className={styles.create_template_container}
      // style={{ height: `${comp === 'drip' ? '70vh' : '88vh'}` }}
    >
      <div className={styles.create_template_form}>
        <div className={styles.header}>
          <h4 className={styles.heading}>Create Template</h4>
          {/* <p className={styles.heading_info}>100 emails per day</p> */}
        </div>
        <div className={styles.business_detail_pill_value}>
          <Formik
            initialValues={{
              templateName: '',
              emailSubject: '',
              message: ''
            }}
            onSubmit={values => {
              onSubmit(values);
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
          >
            {props => (
              <Form>
                <div className={styles.form_container}>
                  <div className={styles.form_field}>
                    <Field
                      name='templateName'
                      placeholder={'Template name'}
                      title={'Template name'}
                      type={'text'}
                      component={CustomisedTextField}
                      // disabled={!enableEdit}
                    />
                  </div>
                  <div className={styles.form_field}>
                    <Field
                      name='emailSubject'
                      placeholder={'Email subject'}
                      title={'Email subject'}
                      type={'text'}
                      component={CustomisedTextField}
                      // disabled={!enableEdit}
                    />
                  </div>
                  <div className={styles.form_field}>
                    <Field name='message' type={'text'} component={RichTextEditor} />
                  </div>

                  <div className={styles.applied_filters}>
                    {showAttachedFiles?.length > 0 &&
                      showAttachedFiles.map((link: any, index: any) => (
                        <div
                          className={styles.applied_filter}
                          key={index}
                          onClick={() => {
                            window.open(link);
                          }}
                        >
                          <abbr
                            title={link.substring(link.lastIndexOf('/') + 1, link.length)}
                            className={styles.applied_filter_text}
                            style={{ cursor: 'pointer' }}
                          >
                            {link.substring(link.lastIndexOf('/') + 1, link.length)}
                          </abbr>
                          <span
                            className={styles.applied_filter_close}
                            onClick={e => {
                              e.stopPropagation();
                              deleteHandler(link);
                            }}
                          >
                            X
                          </span>
                        </div>
                      ))}
                    {selectedCompetitorList && (
                      <div
                        className={styles.applied_filter}
                        onClick={() => {
                          // navigate(`/competitor-report/${selectedCompetitorList}`);
                          window.open(
                            `/competitor-report/${selectedCompetitorList}?creatTemplate='true`,
                            '_blank'
                          );
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <img className={styles.attachment_img} src={'/add_competitor.svg'} />
                        <abbr
                          title={
                            savedCompetitorList?.filter(
                              (item: any) => item.value === selectedCompetitorList
                            )[0]?.label
                          }
                          className={styles.applied_filter_text}
                        >
                          <span style={{ cursor: 'pointer' }}>
                            {
                              savedCompetitorList?.filter(
                                (item: any) => item.value === selectedCompetitorList
                              )[0]?.label
                            }
                          </span>
                        </abbr>
                        <span
                          className={styles.applied_filter_close}
                          onClick={e => {
                            e.stopPropagation();
                            setSelectedCompetitorList('');
                          }}
                        >
                          X
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ display: 'flex', gap: '18px' }}>
                    <div
                      className={styles.attachfile_wrapper}
                      onClick={() => {
                        setImagesUrl([]);
                        setShowModal(true);
                      }}
                    >
                      <img className={styles.attachment_img} src={'/Attachment-pin.svg'} />
                      Attach Files
                    </div>
                    <div
                      className={styles.attachfile_wrapper}
                      onClick={() => {
                        setShowSavedCompetitorModal(true);
                      }}
                    >
                      <img className={styles.attachment_img} src={'/add_competitor.svg'} />
                      Add Competitors
                    </div>
                  </div>
                  <div className={styles.button_wrapper}>
                    <WrappedButton
                      btnText='Save Template'
                      btnType={BTN_SECONDARY}
                      className={styles.submit_button}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {showModal && (
          <UploadFiles
            showModal={showModal}
            setShowModal={setShowModal}
            imagesUrl={imagesUrl}
            setImagesUrl={setImagesUrl}
            showAttachedFiles={showAttachedFiles}
            setShowAttachedFiles={setShowAttachedFiles}
          />
        )}
        <SavedListModal
          showModal={showSavedCompetitorModal}
          setShowModal={setShowSavedCompetitorModal}
          savedList={savedCompetitorList}
          selectedCompetitorList={selectedCompetitorList}
          setSelectedCompetitorList={setSelectedCompetitorList}
          comp={'template'}
        />
        {isUploading ? (
          <Backdrop sx={{ color: '#0c252d', zIndex: '100' }} open>
            {<CircularProgress color='inherit' />}
          </Backdrop>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CreateTemplate;
