import React, { useRef, useState, useEffect, useContext } from 'react';
import { props } from './model';
import { FileUploader } from 'react-drag-drop-files';
import styles from './drag-and-drop.module.scss';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import { HandleFileUpload, HandleFileDelete } from '../../utils/form-handler';
import { ProjectContext } from '../../context/ProjectContext';
import { useSnackbar } from 'notistack';

const DragAndDrop: React.FC<props> = ({
  name,
  values,
  onChange,
  imagesUrl,
  setImagesUrl,
  comp
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { projectData, setProjectData } = useContext(ProjectContext);
  const fileTypes =
    comp === 'AttachFiles'
      ? ['PDF', 'DOC', 'JPG', 'PNG']
      : comp === 'dueDeligenceFiles'
      ? ['PDF', 'DOC']
      : comp === 'newListUploader'
      ? ['CSV']
      : ['JPEG', 'JPG', 'PNG'];
  const fileSize = 10;
  // const [file, setFile] = useState(null);
  const [fileLink, setFileLink] = useState<any>('');
  const [sizeError, setSizeError] = useState(false);
  const hiddenFileInput = useRef(null);
  const [isUploading, setIsUploading] = useState(false);

  //   let dragDropValue = value;
  const deleteHandler = (file: any) => {
    setIsUploading(true);

    //   setFileLink('')
    HandleFileDelete(file)
      .then(res => {
        const response = res.data.message;
        let index = imagesUrl.indexOf(file);
        imagesUrl.splice(index, 1);
        setIsUploading(false);
        // setImagesUrl([]);
        if (response == 'okay') {
          setFileLink('');
        }
      })
      .catch(() => {
        setIsUploading(false);
      });
  };
  function hasDuplicates(arr: any) {
    return new Set(arr).size !== arr.length;
  }

  const handleChange = (file: any) => {
    setIsUploading(true);
    HandleFileUpload(file, name).then(res => {
      let data = res;
      setIsUploading(false);
      setFileLink(res);
      imagesUrl.push(res);
      if (hasDuplicates(imagesUrl)) {
        enqueueSnackbar('Cannot upload duplicate files', {
          variant: 'error',
          header: 'Error'
        });
        setImagesUrl([...new Set(imagesUrl)]);
      } else setImagesUrl([...imagesUrl]);
      if (comp === 'dueDeligenceFiles')
        setProjectData({ ...projectData, dueDeligenceFiles: imagesUrl });
      if (comp === 'newListUploader') setProjectData({ ...projectData, listUrl: imagesUrl });
      if (comp === 'UploadPhotos') setProjectData({ ...projectData, filesAndVideos: imagesUrl });
    });

    // .catch(err => {
    //     setErrorMsg(err);
    //     setIsUploading(false);
    // });
  };

  const uploadFileHandler = (comp: any) => {
    switch (comp) {
      case 'dueDeligenceFiles':
        return (
          <div className={styles.uploaded_file_container}>
            {imagesUrl?.length > 0 ? (
              <>
                {imagesUrl.map((link: any, index: any) => (
                  <div
                    key={index}
                    className={styles.upload_button}
                    onClick={() => {
                      window.open(link);
                    }}
                  >
                    <div className={styles.uploaded_file}>
                      {(link.substring(link.lastIndexOf('.') + 1, link.length) === 'pdf' ||
                        link.substring(link.lastIndexOf('.') + 1, link.length) === 'doc') && (
                        <img
                          className={styles.account_statement}
                          src={'/images/doc.svg'}
                          style={{ borderRadius: '10px 10px 0px 0px' }}
                        />
                      )}
                    </div>
                    <div className={styles.uploaded_file_bottom}>
                      <div className={styles.file_name}>
                        {link.substring(link.lastIndexOf('/') + 1, link.length)}
                      </div>
                      <img
                        className={styles.delete_icon}
                        onClick={e => {
                          e.stopPropagation();
                          deleteHandler(link);
                        }}
                        src={'/images/del.svg'}
                        alt=''
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        );
      case 'AttachFiles':
        return (
          <div className={styles.uploaded_file_container}>
            {/* {imagesUrl?.length > 0 && (
              <>
                {imagesUrl.map((link: any, index: any) => (
                  <div
                    key={index}
                    className={styles.upload_button}
                    onClick={() => {
                      window.open(link);
                    }}
                  >
                    <div className={styles.uploaded_file}>
                      {link.substring(link.lastIndexOf('.') + 1, link.length) === 'pdf' ||
                      link.substring(link.lastIndexOf('.') + 1, link.length) === 'doc' ? (
                        <img
                          className={styles.account_statement}
                          src={'/images/doc.svg'}
                          style={{ borderRadius: '10px 10px 0px 0px' }}
                        />
                      ) : link.substring(link.lastIndexOf('.') + 1, link.length) === 'csv' ? (
                        <img
                          className={styles.account_statement}
                          src={'/excelLogo.png'}
                          style={{ borderRadius: '10px 10px 0px 0px' }}
                        />
                      ) : (
                        <img
                          className={styles.account_statement}
                          src={link}
                          style={{ borderRadius: '10px 10px 0px 0px' }}
                        />
                      )}
                    </div>
                    <div className={styles.uploaded_file_bottom}>
                      <div className={styles.file_name}>
                        {link.substring(link.lastIndexOf('/') + 1, link.length)}
                      </div>
                      <img
                        className={styles.delete_icon}
                        onClick={e => {
                          e.stopPropagation();
                          deleteHandler(link);
                        }}
                        src={'/images/del.svg'}
                        alt=''
                      />
                    </div>
                  </div>
                ))}
              </>
            )} */}
          </div>
        );
      case 'UploadPhotos':
        return (
          <div className={styles.uploaded_file_container}>
            {imagesUrl?.length > 0 && (
              <>
                {imagesUrl.map((link: any, index: any) => (
                  <div
                    key={index}
                    className={styles.upload_button}
                    onClick={() => {
                      window.open(link);
                    }}
                  >
                    <div className={styles.uploaded_file}>
                      <img
                        className={styles.account_statement}
                        src={link}
                        style={{ borderRadius: '10px 10px 0px 0px' }}
                      />
                    </div>
                    <div className={styles.uploaded_file_bottom}>
                      <div className={styles.file_name}>
                        {link.substring(link.lastIndexOf('/') + 1, link.length)}
                      </div>
                      <img
                        className={styles.delete_icon}
                        onClick={e => {
                          e.stopPropagation();
                          deleteHandler(link);
                        }}
                        src={'/images/del.svg'}
                        alt=''
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        );

      case 'newListUploader':
        return (
          <div className={styles.uploaded_file_container}>
            {imagesUrl?.length > 0 && (
              <>
                {imagesUrl.map((link: any, index: any) => (
                  <div
                    key={index}
                    className={styles.upload_button}
                    onClick={() => {
                      window.open(link);
                    }}
                  >
                    <div className={styles.uploaded_file}>
                      {link.substring(link.lastIndexOf('.') + 1, link.length) === 'csv' && (
                        <img
                          className={styles.account_statement}
                          src={'/excelLogo.png'}
                          style={{ borderRadius: '10px 10px 0px 0px' }}
                        />
                      )}
                    </div>
                    <div className={styles.uploaded_file_bottom}>
                      <div className={styles.file_name}>
                        {link.substring(link.lastIndexOf('/') + 1, link.length)}
                      </div>
                      <img
                        className={styles.delete_icon}
                        onClick={e => {
                          e.stopPropagation();
                          deleteHandler(link);
                        }}
                        src={'/images/del.svg'}
                        alt=''
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        );
    }
  };

  return (
    <>
      <div className={styles.upload_wrapper}>
        <FileUploader
          multiple={true}
          handleChange={(file: any) => {
            Object.values(file).map((i: any) => {
              handleChange(i);
            });
            setSizeError(false);
          }}
          name='file'
          types={fileTypes}
          maxSize={fileSize}
          onSizeError={() => {
            setSizeError(true);
            setFileLink('');
          }}
          fileOrFiles={imagesUrl.length > 0 ? imagesUrl : null}
        >
          <div className={styles.file_uploader_container}>
            <img src={'/uploadIcon.svg'}></img>
            <div>
              <p className={styles.file_uploader_text}>Click here to upload</p>
            </div>
            <div className={styles.file_upload_types}>
              Files supported:{" "}
              {`${
                comp === 'AttachFiles'
                  ? ['PDF', 'DOC', 'JPG', 'PNG', 'JPEG']
                  : comp === 'dueDeligenceFiles'
                  ? 'PDF, DOC'
                  : comp === 'newListUploader'
                  ? ['CSV']
                  : 'JPEG, JPG, PNG'
              }`}
            </div>
          </div>
        </FileUploader>

        {comp === 'newListUploader' && (
          <div className={styles.download_file}>
            <a href='https://reachtenants-dev-assets.s3.us-east-1.amazonaws.com/devAssets/648705618f53010012dc09b4/SampleCSV.csv'>
              Download Sample.csv
            </a>
          </div>
        )}
        {uploadFileHandler(comp)}
        {sizeError && <div className={styles.error_msg}>File size is greater than 10 MB</div>}
        {isUploading ? (
          <Backdrop sx={{ color: '#0c252d', zIndex: '100' }} open>
            {<CircularProgress color='inherit' />}
          </Backdrop>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default DragAndDrop;
