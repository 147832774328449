import React, { useContext } from 'react';
import { props } from './model';
import BasicForm from './project-forms/basic-form';
import DueDiligence from './project-forms/due-diligence';
import InitialDetailsForm from './project-forms/initial-details-form';
import PropertyDescriptionForm from './project-forms/property-description-form';
import UploadPhotos from './project-forms/upload-photos';
import { useEffect, useState } from 'react';
import { ProjectContext } from '../../../context/ProjectContext';
import UploadNewList from './project-forms/new-list-upoad';

const ViewProjectForm: React.FC<props> = ({
  activeStep,
  setActiveStep,
  project,
  step,
  setStep,
  refetch
}) => {
  const { projectData } = useContext(ProjectContext);

  const returnForm = () => {
    switch (step) {
      case 1:
        return (
          <BasicForm
            setActiveStep={setActiveStep}
            project={project}
            setStep={setStep}
            activeStep={activeStep}
            refetch={refetch}
          />
        );
      case 2:
        return (
          <PropertyDescriptionForm
            setActiveStep={setActiveStep}
            project={project}
            setStep={setStep}
            activeStep={activeStep}
          />
        );
      case 3:
        return (
          <DueDiligence
            setActiveStep={setActiveStep}
            project={project}
            setStep={setStep}
            activeStep={activeStep}
          />
        );
      case 4:
        return (
          <UploadPhotos
            setActiveStep={setActiveStep}
            project={project}
            setStep={setStep}
            activeStep={activeStep}
          />
        );

      default:
        return (
          <InitialDetailsForm
            setActiveStep={setActiveStep}
            project={project}
            setStep={setStep}
            activeStep={activeStep}
            refetch={refetch}
          />
        );
    }
  };
  return <>{returnForm()} </>;
};

export default ViewProjectForm;
