import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './sic-codes-internal-screen.module.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { cx } from '../../utils/class-name-service';
import axios from 'axios';
import { useQuery } from 'react-query';
import SICModal from '../../components/modals/sic-modal';
import FooterLanding from '../../components/core/layout/footer-landing';
import CircularLoader from '../../components/circular-loader';

const SICCodesInternalScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCodes, setSelectedCodes] = useState<Array<string>>([]);
  const [open, setOpen] = useState(0);
  const [searchText, setSearchText] = useState<any>(searchParams.get('search'));
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [accordianData, setAccordianData] = useState<any>({});
  const getSICCategory = async () => {
    const config = {
      method: 'get',
      url: `/v1/sic/sic-category?code=${searchParams.get('code')}&name=${searchParams.get('name')}`
    };
    const res = await axios(config);
    return res.data;
  };
  const { data: sicCategoryDetails, status: sicCategoryStatus } = useQuery(
    `getSICCategory`,
    getSICCategory,
    {
      onSuccess: res => {
        let codes = res.map((item: any) => {
          return {
            code: item[0],
            description: item[2],
            isAccordion: item[4]?.length > 0,
            accordianData: item[4]?.length > 0 ? item[4] : []
          };
        });
        const accordianData = {
          sectionName: 'Section',
          sectionDescription: searchParams.get('category'),
          codes: codes
        };
        setAccordianData(accordianData);
      },
      enabled: !searchParams.get('search')
    }
  );

  const toggle = (id: React.SetStateAction<number>) => {
    if (open === id) {
      setOpen(0);
    } else {
      setOpen(id);
    }
  };
  useEffect(() => {
    if (searchParams.get('search')) {
      getSICSearchResult().then(res => {
        setLoader(false);
        let codes = res.map((item: any) => {
          return {
            code: item[1],
            description: item[2],
            isAccordion: false,
            accordianData: []
          };
        });
        const accordianData = {
          sectionName: 'Section',
          sectionDescription: searchText,
          codes: codes
        };
        setAccordianData(accordianData);
      });
    }
  }, [searchParams.get('search')]);

  const getSICSearchResult = async () => {
    setLoader(true);
    let searchString = isNaN(searchText) ? `keyword=${searchText}` : `code=${searchText}`;
    const config = {
      method: 'get',
      url: `v1/sic/searchSIC?${searchString}`
    };
    const res = await axios(config);
    return res.data;
  };

  const handleSelect = (code: string) => {
    if (selectedCodes.includes(code)) {
      setSelectedCodes(selectedCodes.filter(item => item !== code));
    } else {
      setSelectedCodes([...selectedCodes, code]);
    }
  };

  return (
    <>
      <div className={styles.page_wrapper}>
        <div className={styles.landing_page_wrapper}>
          <div className={styles.max_width}>
            <HeaderLanding />

            <div className={styles.content_wrapper}>
              <div className={styles.heading_cover_image}>
                <div className={styles.heading}>SIC code business lists</div>

                <div className={styles.sub_heading}>
                  Targeted leads from 15 million companies and 40 million executives
                </div>

                <div className={styles.search_field_wrapper}>
                  <img src='/li_search.svg'></img>

                  <input
                    type='text'
                    value={searchText}
                    className={styles.input_field}
                    onChange={e => setSearchText(e.target.value)}
                    placeholder='Search for codes by keywords or numbers'
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter' && e.target.value !== '') {
                        getSICSearchResult()
                          .then(res => {
                            setLoader(false);
                            let codes = res.map((item: any) => {
                              return {
                                code: item[1],
                                description: item[2],
                                isAccordion: false,
                                accordianData: []
                              };
                            });
                            const accordianData = {
                              sectionName: 'Section',
                              sectionDescription: searchText,
                              codes: codes
                            };
                            setAccordianData(accordianData);
                          })
                          .catch(err => {
                            navigate('/sic-codes');
                          });
                      }
                    }}
                  />

                  <div
                    className={styles.search_button}
                    onClick={() =>
                      getSICSearchResult()
                        .then(res => {
                          setLoader(false);
                          let codes = res.map((item: any) => {
                            return {
                              code: item[1],
                              description: item[2],
                              isAccordion: false,
                              accordianData: []
                            };
                          });
                          const accordianData = {
                            sectionName: 'Section',
                            sectionDescription: searchText,
                            codes: codes
                          };
                          setAccordianData(accordianData);
                        })
                        .catch(err => {
                          navigate('/sic-codes');
                        })
                    }
                  >
                    Search
                  </div>
                </div>
              </div>

              <div className={styles.sic_codes_list}>
                <div className={styles.heading_wrapper}>
                  <div className={styles.list_heading}>Code</div>
                  <div className={styles.list_heading}>Description</div>
                </div>
                <>
                  {!loader && Object.keys(accordianData).length !== 0 ? (
                    <>
                      <div className={styles.sub_heading_wrapper}>
                        <div className={styles.list_subheading}>{accordianData.sectionName}</div>
                        <div className={styles.list_subheading}>
                          {accordianData.sectionDescription}
                        </div>
                      </div>
                      <div className={styles.accordion_sic_code}>
                        {accordianData.codes.length > 0 ? (
                          accordianData.codes.map((item: any, index: any) =>
                            item.isAccordion ? (
                              <Accordion
                                disableGutters
                                key={index}
                                sx={{
                                  backgroundColor: '#FFFFFF',
                                  boxShadow: 'none'
                                }}
                                expanded={open === index + 1}
                                onChange={() => toggle(index + 1)}
                                square={true}
                              >
                                <AccordionSummary
                                  className={styles.faq_accordian_item_header}
                                  sx={{ padding: '0px 0px 0px 0px', margin: 0 }}
                                  expandIcon={
                                    open === index + 1 ? (
                                      <img src='/images/minus.svg' alt='arrow-down' />
                                    ) : (
                                      <img src='/images/plus.svg' alt='arrow-up' />
                                    )
                                  }
                                >
                                  <div className={styles.individual_sic_code}>
                                    <div className={styles.checkbox_wrapper}>
                                      <label className={cx(styles.checkbox, styles.bounce)}>
                                        <input
                                          type='checkbox'
                                          onClick={e => {
                                            e.stopPropagation();
                                            handleSelect(item.code);
                                          }}
                                          checked={selectedCodes.includes(item.code)}
                                        />
                                        <svg viewBox='0 0 21 21'>
                                          <polyline points='5 10.75 8.5 14.25 16 6'></polyline>
                                        </svg>
                                      </label>
                                    </div>

                                    <div className={styles.sic_code}>{item.code}</div>
                                    <div className={styles.sic_code_description}>
                                      {item.description}
                                    </div>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_accordian_item_body}>
                                  {item.accordianData.map((obj: any, index: any) => (
                                    <div
                                      className={cx(
                                        styles.individual_sic_code,
                                        styles.border_bottom
                                      )}
                                      style={{ paddingLeft: '2rem', borderBottom: 'none' }}
                                    >
                                      <div className={styles.sic_code}>{obj[1]}</div>
                                      <div className={styles.sic_code_description}>{obj[2]}</div>
                                    </div>
                                  ))}
                                </AccordionDetails>
                              </Accordion>
                            ) : (
                              <>
                                {item.code && (
                                  <Accordion
                                    disableGutters
                                    key={index}
                                    sx={{
                                      backgroundColor: '#FFFFFF',
                                      boxShadow: 'none'
                                    }}
                                    square={true}
                                  >
                                    <AccordionSummary
                                      className={styles.faq_accordian_item_header}
                                      sx={{ padding: '0px 0px 0px 0px', margin: 0 }}
                                    >
                                      <div className={styles.individual_sic_code}>
                                        <div className={styles.checkbox_wrapper}>
                                          <label className={cx(styles.checkbox, styles.bounce)}>
                                            <input
                                              type='checkbox'
                                              onChange={() => {
                                                handleSelect(item.code);
                                              }}
                                              checked={selectedCodes.includes(item.code)}
                                            />
                                            <svg viewBox='0 0 21 21'>
                                              <polyline points='5 10.75 8.5 14.25 16 6'></polyline>
                                            </svg>
                                          </label>
                                        </div>

                                        <div className={styles.sic_code}>{item.code}</div>
                                        <div className={styles.sic_code_description}>
                                          {item.description}
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                  </Accordion>
                                )}
                              </>
                            )
                          )
                        ) : (
                          <div className={styles.no_result_found}>
                            <p>No results Found</p>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '2rem'
                      }}
                    >
                      <CircularLoader />
                    </div>
                  )}
                </>
              </div>
            </div>
            <FooterLanding />
          </div>

          {selectedCodes.length > 0 && (
            <>
              <div className={styles.selected_code_footer}>
                <div className={styles.selected_text}>{selectedCodes.length} Selected</div>

                <div className={styles.button} onClick={() => setShowModal(true)}>
                  Next
                </div>
              </div>
            </>
          )}
          <SICModal
            showModal={showModal}
            setShowModal={setShowModal}
            selectedCodes={selectedCodes}
          />
        </div>
      </div>
    </>
  );
};

export default SICCodesInternalScreen;
