import React, { useEffect, useState } from 'react';
import styles from './notes-panel.module.scss';
import AppConfig from '../../../config';
import axios from 'axios';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { props } from './model';
import Storage from '../../../utils/local-storage';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';

const NotesPanel: React.FunctionComponent<props> = ({ queryClient, businessId }) => {
  const [note, setNote] = useState('');
  const [data1, setData1] = useState<[any] | any>();
  const [loading, setloading] = useState(false);

  const storage = new Storage();

  const saveNote = () => {
    addNewNote(note).then(() => {
      setNote('');
    });
  };

  const BASE_URL = AppConfig.API_BASE_URL;

  const fetchNotes = async () => {
    const res = await fetch(
      `${BASE_URL}/v1/note/${businessId}?projectId=${storage.get('projectId')}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${storage.get('accessToken')}`
        }
      }
    );
    return res.json();
  };

  const postNote = async (data: any) => {
    const response = await fetch(`${BASE_URL}/v1/note`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      body: JSON.stringify({ note: data, businessId, projectId: storage.get('projectId') })
    });
    if (!response.ok) throw Error('something went wrong');
    return response.json();
  };

  const { data, status } = useQuery(`note${businessId}`, fetchNotes, {
    onSuccess: res => {
      setData1(res.reverse());
    }
  });
  const { status: mutationStatus, mutateAsync: addNewNote } = useMutation(postNote, {
    onSuccess: () => queryClient.invalidateQueries(`note${businessId}`)
  });

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (note) {
          saveNote();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [note]);

  const onChangehandler = (e: any) => {
    setNote(e.target.value);
  };

  TimeAgo.addDefaultLocale(en);

  const timeAgo = new TimeAgo('en-US');
  return (
    <div className={styles.note_container}>
      <textarea placeholder='Enter something....' onChange={onChangehandler} value={note}>
        {/* {note} */}
      </textarea>
      <div className={styles.note_list}>
        {status === 'success' &&
          data1?.map((note: any) => {
            return (
              <div className={styles.note_item}>
                <div className={styles.note_info}>
                  <span>{`${note.user.firstName} ${note.user.lastName}`}</span>
                  <img src='/images/bullet-divider.svg' alt='' />
                  <span>{timeAgo.format(new Date(note.createdAt))}</span>
                </div>
                <div className={styles.note_text}>{note.note}</div>
                <div className={styles.note_divider}></div>
              </div>
            );
          })}
      </div>
      {status === 'loading' || mutationStatus === 'loading' ? (
        <Backdrop sx={{ color: '#0c252d', zIndex: '100' }} open>
          {<CircularProgress color='inherit' />}
        </Backdrop>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NotesPanel;
