export const avatarGenerator = (firstName: String, lastName: String) => {
  const colorsArray = ['#DD1C5E', '#BA5397', '#719A31', '#5766B6', '#F2C035'];
  const color =
    colorsArray[
      (firstName.toLowerCase().charCodeAt(0) - 96 + lastName.toLowerCase().charCodeAt(0) - 96) % 5
    ];

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = 200;
  canvas.height = 200;

  // Draw background
  context!.fillStyle = color;
  context!.fillRect(0, 0, canvas.width, canvas.height);

  // Draw text
  context!.font = 'bold 80px Assistant';
  context!.fillStyle = 'white';
  context!.textAlign = 'center';
  context!.textBaseline = 'middle';
  context!.fillText(
    `${firstName.toUpperCase()}${lastName.toUpperCase()}`,
    canvas.width / 2,
    canvas.height / 2
  );

  return canvas.toDataURL('image/png');
};
