import React from 'react';
import TimeContainer from '../../containers/time-matters';
import styles from './features.module.scss';

function TimeMatters() {
  return (
    <div className={styles.page_wrapper}>
      <TimeContainer />
    </div>
  );
}

export default TimeMatters;
