import { useEffect, useState } from 'react';
import './App.css';
import RequestDemo from './screens/request-demo';
import SignIn from './screens/sign-in';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import NewPassword from './screens/new-password/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import OrganisationSettingScreen from './screens/organisation-settings';
import ProjectListScreen from './screens/project-list';
import PricingScreen from './screens/pricing';
import LandingScreen from './screens/landing-page';
import FAQScreen from './screens/faq-screen';
import BlogsScreen from './screens/blogs-page';
import BlogDetailScreen from './screens/individual-blog';
import FeaturesScreen from './screens/features';
import AboutUs from './screens/about-us';
import TermsAndConditions from './screens/terms-and-conditions';
import PrivacyPolicy from './screens/privacy-policy';
import SICCodesScreen from './screens/sic-codes';
import Layout from './components/core/layout';
import DashboardScreen from './screens/dashboard';
import Template from './screens/template';
import { ProjectContext } from './context/ProjectContext';

import ViewProjectScreen from './screens/view-project';
import Storage from './utils/local-storage';
import AppConfig from './config';
import EmailAutomationFormScreen from './screens/email-automation-form/index';
import DripSystem from './screens/drip-system';
import WebReport from './screens/report';
import SICCodesInternalScreen from './screens/sic-codes-internal-screen';
import PrivateRoutes from './PrivateRoute';
import UploadCSV from './screens/upload-csv';
import Unsubscribe from './screens/unsubscribe';
import CompetitorReportScreen from './screens/competitor-report';
import EditBusinessScreen from './screens/editBusiness';
import TimeMatters from './screens/time';
import Calculator from './screens/calculator';

const queryClient = new QueryClient();

function App() {
  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  const [token, setToken] = useState(storage.get('accessToken'));
  const [projectData, setProjectData] = useState({});
  const [dripSystemDetails, setDripSystemDetails] = useState({});
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);
  const [imageURL, setImageURL] = useState('');
  const [userData, setUserData] = useState<any>({});
  const [columnOrder, setColumnOrder] = useState<any>([]);
  const [blogs, setBlogs] = useState<any>();
  const [changesMadeInBusiness, setchangesMadeInBusiness] = useState(false);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);

  const getUserDetails = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/users/${storage.get('userId')}`
    };

    const temp = await axios(config);
    return temp;
  };
  useEffect(() => {
    (async () => {
      if (storage.get('userId'))
        await getUserDetails().then((res: any) => {
          setUserData(res.data.user);
          setImageURL(
            `https://reachtenants-dev-assets.s3.amazonaws.com/${res.data.user.profileImage}`
          );

          if (res.data.user.columnLayout.length) setColumnOrder(res.data.user.columnLayout);
          else {
            let defaultOrder = [
              'Business name',
              'Name',
              'Email',
              'Zip',
              'Status',
              'Title',
              'Address',
              'States',
              'Phone',
              // 'County',
              'Franchise',
              'Website',
              // 'Year Established',
              'No. of employees',
              'Distance',
              'Location sales',
              'Category'
            ];
            setColumnOrder(defaultOrder);
          }
        });
    })();
  }, []);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ProjectContext.Provider
          value={{
            projectData,
            setProjectData,
            imageURL,
            setImageURL,
            selectedBusiness,
            setSelectedBusiness,
            dripSystemDetails,
            setDripSystemDetails,
            token,
            setToken,
            userData,
            setUserData,
            columnOrder,
            setColumnOrder,
            blogs,
            setBlogs,
            changesMadeInBusiness,
            setchangesMadeInBusiness
          }}
        >
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route
                  path='/competitor-report/:competitorListId'
                  element={<CompetitorReportScreen queryClient={queryClient} />}
                />
                <Route
                  path='/updateBusinessDetails/:businessId'
                  element={
                    <EditBusinessScreen queryClient={queryClient} columnOrder={columnOrder} />
                  }
                />
                <Route path='/unsubscribe' element={<Unsubscribe />} />
                <Route element={<PrivateRoutes />}>
                  <Route
                    path='/settings'
                    element={<OrganisationSettingScreen queryClient={queryClient} />}
                  />

                  <Route path='/add-project' element={<ViewProjectScreen />} />
                  <Route path='/view-project/:projectId' element={<ViewProjectScreen />} />
                  <Route path='/projects' element={<ProjectListScreen />} />
                  <Route
                    path='/dashboard/:projectId'
                    element={
                      <DashboardScreen queryClient={queryClient} columnOrder={columnOrder} />
                    }
                  />

                  <Route
                    path='/templates'
                    element={
                      <Template
                        queryClient={queryClient}
                        setEnableEdit={setEnableEdit}
                        enableEdit={enableEdit}
                        setShowCreateTemplate={setShowCreateTemplate}
                        showCreateTemplate={showCreateTemplate}
                      />
                    }
                  />
                  <Route
                    path='/activity-report'
                    element={<WebReport queryClient={queryClient} />}
                  />
                  {/* <Route path='/templates' element={<Template queryClient={queryClient} />} /> */}
                  <Route
                    path='/email-automation'
                    element={
                      <DripSystem
                        setEnableEdit={setEnableEdit}
                        enableEdit={enableEdit}
                        setShowCreateTemplate={setShowCreateTemplate}
                        showCreateTemplate={showCreateTemplate}
                      />
                    }
                  />
                  <Route path='/add-business-list' element={<UploadCSV />} />

                  <Route path='/create-automation-form' element={<EmailAutomationFormScreen />} />
                  <Route
                    path='/view-automation-form/:campaignId'
                    element={<EmailAutomationFormScreen />}
                  />

                  {window.location.pathname !== '/settings' &&
                    window.location.pathname !== '/set-password' && (
                      <>
                        <Route path='*' element={<Navigate to='/projects' />} />
                      </>
                    )}
                </Route>
                {!token && (
                  <>
                    <Route path='/' element={<LandingScreen />} />
                    <Route path='/request-demo' element={<RequestDemo />} />
                    <Route path='/sign-in' element={<SignIn />} />
                    <Route path='/set-password' element={<NewPassword />} />
                    <Route path='/pricing' element={<PricingScreen />} />
                    <Route path='/faq' element={<FAQScreen />} />
                    <Route path='/blog' element={<BlogsScreen />} />
                    <Route path='/blog/:id' element={<BlogDetailScreen />} />
                    <Route path='/about' element={<AboutUs />} />
                    <Route path='/sic-codes' element={<SICCodesScreen />} />
                    <Route path='/features' element={<FeaturesScreen />} />
                    <Route path='/time' element={<TimeMatters />} />
                    <Route path='/sic-codes-internal' element={<SICCodesInternalScreen />} />
                    <Route path='/unsubscribe' element={<Unsubscribe />} />
                    <Route path='/terms-of-use' element={<TermsAndConditions />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/calculators' element={<Calculator />} />
                  </>
                )}
              </Routes>
            </Layout>
          </BrowserRouter>
        </ProjectContext.Provider>
      </QueryClientProvider>
    </>
  );
}

export default App;
