import React, { useEffect } from 'react';

import styles from './index.module.scss';
import { props } from './props';

const Feature: React.FunctionComponent<props> = ({ featurename, featuredescription, image }) => {
  // useEffect(() => {
  //   Aos.init({ duration: 800 });
  // }, []);
  return (
    <>
      <div className={styles.featurecontainer}>
        <img src={image} width='34px' height='34px' className={styles.image} />

        <div className={styles.textcontainer}>
          <div className={styles.name}>{featurename}</div>
          <div className={styles.description}>{featuredescription}</div>
        </div>
      </div>
    </>
  );
};

export default Feature;
