import React, { useContext } from 'react';
import styles from '../../view-project-form.module.scss';
import { Formik, Form, Field } from 'formik';
import { props } from './model';
import CustomisedTextField from '../../../../../components/form-elements/customised-text-field';
import CustomisedTextArea from '../../../../../components/form-elements/customised-text-area';
import WrappedButton from '../../../../../components/form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../../../constants/button-constants';
import axios from 'axios';
import { useMutation } from 'react-query';
import Storage from '../../../../../utils/local-storage';
import AppConfig from '../../../../../config';
import { ProjectContext } from '../../../../../context/ProjectContext';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const PropertyDescriptionForm: React.FC<props> = ({
  setActiveStep,
  project,
  setStep,
  activeStep
}) => {
  const { projectData, setProjectData } = useContext(ProjectContext);

  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  const navigate = useNavigate();
  const addProject = project._id == undefined;
  const { enqueueSnackbar } = useSnackbar();

  let projectId = storage.get('projectId');
  const updateSaveChanges = async (data: any) => {
    const requestData = JSON.stringify({
      parcelId: data?.parcelId,
      propertyDescription: data?.propertyDescription,
      communityDescription: data?.communityDescription,
      misc: data?.misc,
      projectId: addProject ? projectId : project._id
    });

    const config = {
      method: 'put',
      url: `${BASE_URL}/v1/project/updateProject`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };
    setProjectData({ ...projectData, ...data });

    await axios(config);
  };
  const { status: mutationStatus, mutateAsync: updateProject } = useMutation(updateSaveChanges, {
    onSuccess: () => {
      if (!addProject) {
        enqueueSnackbar('Description Form Updated Successfully', {
          variant: 'success',
          header: 'Success'
        });
        navigate(`/dashboard/${storage.get('projectId')}`);
      }
      // if (!addProject) window.location.href = `/view-project/${storage.get('projectId')}`;
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });

  const onProjectNext = () => {
    if (activeStep <= 3) setActiveStep(3);
    setStep(3);
  };
  const onUpdateChanges = (data: any) => {
    updateProject(data);
  };
  return (
    <div className={styles.form_container}>
      <div className={styles.form_title}>Description</div>
      <Formik
        initialValues={{
          parcelId: projectData?.parcelId,
          propertyDescription: projectData?.propertyDescription,
          communityDescription: projectData?.communityDescription,
          misc: projectData?.misc
        }}
        onSubmit={values => {
          if (addProject) {
            onUpdateChanges(values);
            onProjectNext();
          } else {
            onProjectNext();
          }
        }}
      >
        {props => (
          <Form>
            <Field
              name='parcelId'
              placeholder={'Parcel ID'}
              title={'Parcel ID'}
              type={'text'}
              component={CustomisedTextField}
            />
            <Field
              name='propertyDescription'
              placeholder='Property description'
              component={CustomisedTextArea}
            />
            <Field
              name='communityDescription'
              placeholder='Community description'
              component={CustomisedTextArea}
            />
            <Field name='misc' placeholder='Miscellaneous' component={CustomisedTextArea} />

            <div className={styles.add_project_footer}>
              <div className={styles.button_wrapper}>
                {addProject && (
                  <WrappedButton
                    btnText='Skip'
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      onProjectNext();
                    }}
                    btnType={BTN_SECONDARY}
                    className={styles.submit_button}
                  />
                )}
                {!addProject && (
                  <WrappedButton
                    btnText='Save Changes'
                    btnType={BTN_SECONDARY}
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      onUpdateChanges(props.values);
                    }}
                    className={styles.submit_button}
                    isDisabled={!props.dirty}
                    type='submit'
                  />
                )}
                {addProject && (
                  <WrappedButton
                    btnText='Next'
                    type='submit'
                    btnType={BTN_PRIMARY}
                    className={styles.submit_button}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PropertyDescriptionForm;
