import React from 'react';
import { props } from './model';
import styles from '../../add-project/add-project-form-stepper/add-project-form-stepper.module.scss';
import { CREATE_CAMPAIGN_FORM_STEPS } from '../../../constants/create-campaign-contants';

const CreateCampaignStepper: React.FC<props> = ({ activeStep, setActiveStep, setStep, Step }) => {
  return (
    <div className={styles.stepper_wrapper}>
      <div className={styles.stepper}>
        {/* <div className={styles.automation_step_line}></div> */}
        {CREATE_CAMPAIGN_FORM_STEPS.map((step1, index) => {
          return (
            <>
              <div
                key={index}
                className={
                  index <= activeStep
                    ? styles.step_circle_wrapper_active
                    : styles.step_circle_wrapper
                }
                onClick={() => {
                  if (activeStep >= index) {
                    setStep(index);
                  }
                }}
              >
                <div className={styles.step_number}>{index + 1}</div>
              </div>

              {
                                    index !== CREATE_CAMPAIGN_FORM_STEPS.length - 1 && (
                                        <div className={styles.step_line}></div>
                                    )
                                }
            </>
          );
        })}
      </div>

      <div className={styles.step_titles_wrapper}>
        <div className={styles.title}>
          {CREATE_CAMPAIGN_FORM_STEPS.map((step1, index) => {
            return (
              <div
                key={index}
                className={index <= activeStep ? styles.step_title_active : styles.step_title}
                onClick={() => {
                  if (activeStep >= index) {
                    setStep(index);
                  }
                }}
              >
                {step1}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CreateCampaignStepper;
