import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './template.module.scss';
import axios from 'axios';
import Storage from '../../utils/local-storage';
import { useNavigate, useLocation } from 'react-router-dom';
import { cx } from '../../utils/class-name-service';
import { useMutation, useQuery } from 'react-query';
import AppConfig from '../../config';
import EditEmail from '../../components/edit-email';
import CreateTemplate from '../../components/create-template';
// import { connectAccount } from '../../utils/nylasApis';
import { sendEmail } from '../../utils/nylasApis';
import { replaceVariableWithValue } from '../../utils/emailTemplate';
import { useSnackbar } from 'notistack';
import CircularLoader from '../../components/circular-loader';
import { ProjectContext } from '../../context/ProjectContext';
import HttpHelper from '../../utils/http-helper';
import DeleteTemplateModal from '../../components/modals/delete-template';

const getTemplatesList = async () => {
  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const config = {
    method: 'get',
    url: `${BASE_URL}/v1/template/getTemplates/${storage.get('projectId')}`,
    headers: {
      Authorization: `Bearer ${storage.get('accessToken')}`
    }
  };
  const res = await axios(config);
  return res.data;
};

interface props {
  queryClient?: any;
  comp?: string;
  setStep2Data?: any;
  enableEdit?: any;
  setEnableEdit?: any;
  setShowCreateTemplate?: any;
  showCreateTemplate?: any;
}

const Template: React.FunctionComponent<props> = ({
  queryClient,
  comp,
  setStep2Data,
  enableEdit,
  setEnableEdit,
  showCreateTemplate,
  setShowCreateTemplate
}) => {
  const [showAction, setShowAction] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<Number>(-1);
  const [totalTemplate, setTotalTemplate] = useState<any>();
  const [currentTemplate, setCurrentTemplate] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<String>('');
  const [step, setStep] = useState(1);
  const [savedCompetitorList, setsavedCompetitorList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const location = useLocation();
  const { getRequest } = new HttpHelper();

  const [businessList, setBusinessList] = useState<any>(location?.state?.selectedRows);
  const [templateBusiness, setTemplateBusiness] = useState<any>(businessList && businessList[0]);
  const storage = new Storage();
  const Auth_TOKEN = storage.get('accessToken');
  const BASE_URL = AppConfig.API_BASE_URL;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setloader] = useState<any>(false);
  const deleteRef = useRef<any>(null);
  const { dripSystemDetails, setDripSystemDetails } = useContext(ProjectContext);
  const { userData, setUserData } = useContext(ProjectContext);
  const [shallowEdit, setShallowEdit] = useState(false);
  const [editedEmails, setEditedEmails] = useState<any>([]);

  const {
    data: templateList,
    status: templateListStatus,
    isLoading,
    refetch
  } = useQuery(`getTemplatesList`, getTemplatesList, {
    onSuccess: (res: any) => {
      setTotalTemplate(res.length);
    }
  });

  useEffect(() => {
    if (comp === 'create-campaign' && dripSystemDetails?.step2Data) {
      setSelectedItem(() => dripSystemDetails.step2Data);
      setStep2Data(() => dripSystemDetails.step2Data);
    } else if (templateListStatus === 'success') {
      if (currentTemplate) {
        setSelectedItem(() => currentTemplate);
      } else setSelectedItem(() => templateList[0]?._id);
      if (comp === 'create-campaign') setStep2Data(templateList[0]?._id);
    }
  }, [templateList]);

  const getCompetitorList = async () => {
    // Get all saved lists for project
    const url = `${BASE_URL}/v1/savedcompetitorlist/${storage.get('projectId')}`;
    const { data } = await getRequest({ url });
    if (data.length <= 0) {
    } else {
      const bListArray: any = [];
      data.forEach((el: any) => {
        const obj: any = {};
        obj.value = el._id;
        obj.label = el.list_name;
        obj.count = el.business_ids.length;
        bListArray.push(obj);
      });
      setsavedCompetitorList(bListArray);
    }
  };
  useEffect(() => {
    getCompetitorList();
  }, []);

  const deleteTemplateHandler = async (id: String) => {
    const config = {
      method: 'delete',
      url: `${BASE_URL}/v1/template/deleteTemplate/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth_TOKEN}`
      }
    };
    return await axios(config);
  };
  const getUserDetails = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/users/${storage.get('userId')}`
    };

    const temp = await axios(config);
    return temp.data;
  };
  const { data: userDetails, status: userDetailsStatus } = useQuery(`userdetail`, getUserDetails, {
    refetchOnWindowFocus: true,
    onSuccess: res => {
      setUserData(res.user);
    }
  });

  const { status: mutationStatus, mutateAsync: deleteTemplate } = useMutation(
    deleteTemplateHandler,
    {
      onSuccess: res => {
        setSelectedItem(templateList[0]?._id);
        refetch();
        // queryClient.invalidateQueries(`userdetails`);
      }
    }
  );
  const deleteBusiness = (businessId: any) => {
    const filteredBusinesses = businessList?.filter((item: any) => {
      return item['_id'] !== businessId;
    });
    if (filteredBusinesses.length === 0) {
      navigate(`/dashboard/${storage.get('projectId')}`);
    } else setBusinessList(filteredBusinesses);
  };

  const getTemplatesDetail = async (id: any) => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/v1/template/getTemplate/${id}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    return res.data;
  };

  const nextButtonHandler = async () => {
    if (step < 2) {
      setStep(() => step + 1);
    }
    if (step === 2) {
      setloader(true);
      const templatedetails = await getTemplatesDetail(selectedItem);
      const msg = replaceVariableWithValue(templatedetails?.emailMessage, templateBusiness);
      const bussinessListIds = businessList.map((item: any) => item._id);
      const { error, message } = await sendEmail(templatedetails._id, bussinessListIds);
      setloader(false);
      if (error) {
        enqueueSnackbar(message, { variant: 'error', header: 'Error' });
      } else {
        queryClient.invalidateQueries('userdetail');
        enqueueSnackbar(message, { variant: 'success', header: 'Success' });
        navigate(`/dashboard/${storage.get('projectId')}`);
      }
    }
  };
  const backButtonHnadler = () => {
    if (step > 1) {
      setStep(() => step - 1);
    } else {
    }
  };

  const renderTemplateComponent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className={styles.template_list_header}>
              <div className={styles.list_header}>
                {comp !== 'drip' && comp !== 'create-campaign' && (
                  <div
                    className={styles.circular_hover}
                    onClick={() => {
                      navigate(`/dashboard/${storage.get('projectId')}`);
                    }}
                  >
                    <img src='/back-arrow.svg' alt='Back' width='24' height='24' />
                  </div>
                )}
                Templates
              </div>

              {
                <div
                  className={styles.create_template}
                  onClick={() => {
                    setShowCreateTemplate(true);
                  }}
                >
                  Create Template
                </div>
              }
            </div>
            <div className={styles.list_container}>
              {templateList?.map((item: any, index: Number) => (
                <div
                  className={
                    selectedItem === item._id ? cx(styles.list, styles.list_selected) : styles.list
                  }
                  onMouseEnter={() => {
                    setShowAction(true);
                    setSelectedItemIndex(index);
                  }}
                  onMouseLeave={() => {
                    setShowAction(false);
                  }}
                  onClick={() => {
                    setSelectedItem(item._id);
                    setEnableEdit(false);
                    setShowCreateTemplate(false);
                    if (comp === 'create-campaign') setStep2Data(item._id);
                  }}
                >
                  <span
                    style={{
                      maxWidth: '350px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer'
                    }}
                  >
                    <abbr title={item.name}>{item.name}</abbr>
                  </span>
                  <span
                    style={{
                      display: selectedItemIndex === index && showAction ? 'inline-block' : 'none'
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <div
                        className={styles.row_delete}
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedItem(item._id);
                          setCurrentTemplate('');
                          setShowDeleteModal(true);

                          // deleteTemplate(item._id);
                        }}
                      >
                        <img src={'/images/del.svg'} alt='' />
                      </div>
                      <div
                        className={styles.row_edit}
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedItem(item._id);
                          if (comp === 'create-campaign') setStep2Data(item._id);
                          setShallowEdit(false);
                          setEnableEdit(true);
                        }}
                      >
                        <img src={'/images/pencil.svg'} alt='' />
                      </div>
                    </div>
                  </span>
                </div>
              ))}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className={styles.list_header}>
              <div
                className={styles.circular_hover}
                onClick={() => {
                  backButtonHnadler();
                }}
              >
                <img src='/back-arrow.svg' alt='Back' width='24' height='24' />
              </div>
              Business list
            </div>
            <div className={styles.list_container}>
              {businessList?.map((item: any, index: Number) => (
                <div className={styles.bussiness_list} onClick={() => setTemplateBusiness(item)}>
                  <div
                    style={{
                      height: '60px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      marginTop: '5px'
                    }}
                  >
                    <div className={styles.business_name}>{item.businessName}</div>
                    <div className={styles.business_email}>{item.email}</div>
                  </div>
                  <div
                    style={{
                      height: '60px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      className={styles.row_delete}
                      onClick={e => {
                        e.stopPropagation();
                        setTemplateBusiness(item);
                        deleteBusiness(item._id);
                      }}
                    >
                      <img src={'/images/del.svg'} alt='' />
                    </div>

                    <div style={{ display: 'flex' }}>
                      <div
                        className={styles.row_edit}
                        onClick={e => {
                          e.stopPropagation();
                          setShallowEdit(true);
                          setEnableEdit(true);
                        }}
                      >
                        <img src={'/images/pencil.svg'} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className={location.pathname.includes('template') ? styles.main_container_template:  styles.main_container}>
        <div className={location.pathname.includes('template') ? styles.left_column_template : styles.left_column}>{renderTemplateComponent()}</div>
        <div className={location.pathname.includes('template') ?styles.right_column_template : styles.right_column}>
          {templateListStatus === 'loading' ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularLoader />
            </div>
          ) : (
            <>
              {selectedItem && !showCreateTemplate && templateList?.length > 0 ? (
                <EditEmail
                  templateId={selectedItem}
                  enableEdit={enableEdit}
                  nextButtonHandler={nextButtonHandler}
                  step={step}
                  templateBusiness={templateBusiness}
                  savedCompetitorList={savedCompetitorList}
                  loader={loader}
                  deleteRef={deleteRef}
                  comp={comp}
                  setEnableEdit={setEnableEdit}
                  shallowEdit={shallowEdit}
                  setEditedEmails={setEditedEmails}
                  editedEmails={editedEmails}
                  refetch1={refetch}
                  setCurrentTemplate={setCurrentTemplate}
                />
              ) : (
                <CreateTemplate
                  deleteRef={deleteRef}
                  setShowCreateTemplate={setShowCreateTemplate}
                  savedCompetitorList={savedCompetitorList}
                  refetch={refetch}
                  comp={comp}
                />
              )}
            </>
          )}
        </div>
      </div>
      <DeleteTemplateModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        deleteTemplate={deleteTemplate}
        selectedItem={selectedItem}
      />
    </>
  );
};

export default Template;
