import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateCampaignForm from '../../containers/email-automation-form/create-campaign-form/index';
import CreateCampaignStepper from '../../containers/email-automation-form/create-campaign-form-stepper/index';
import styles from './email-automation-form.module.scss';
import Storage from '../../utils/local-storage';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ProjectContext } from '../../context/ProjectContext';

const EmailAutomationFormScreen = () => {
  const storage = new Storage();
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(campaignId ? 2 : 0);
  const [Step, setStep] = React.useState(0);
  const { dripSystemDetails, setDripSystemDetails } = useContext(ProjectContext);

  const getCampaignDetail = async () => {
    const config = {
      method: 'get',
      url: `/v1/emailAutomation/campaign/${campaignId}`,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`
      }
    };
    const res = await axios(config);
    return res.data;
  };
  const {
    data: campaignDetail,
    status: campaignDetailStatus,
    refetch
  } = useQuery(`getCampaignDetail`, getCampaignDetail, {
    enabled: campaignId ? true : false,
    onSuccess: res => {
      setDripSystemDetails(res);
    }
  });

  return (
    <>
      <div className={styles.create_automation_navbar}>
        <img
          src='/back_arrow.svg'
          alt='logo'
          onClick={() => {
            navigate('/email-automation');
          }}
          style={{ cursor: 'pointer' }}
        />
        <div className={styles.create_automation_navbar_text}>Email Automation</div>
      </div>
      <div className={styles.form_container}>
        <CreateCampaignStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setStep={setStep}
          Step={Step}
        />

        {((campaignId && campaignDetailStatus === 'success') || !campaignId) && (
          <CreateCampaignForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            Step={Step}
            setStep={setStep}
          />
        )}
      </div>
    </>
  );
};

export default EmailAutomationFormScreen;
