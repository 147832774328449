import React, { useEffect, useState } from 'react';
import { props } from './model';
import styles from './saved-list.module.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Storage from '../../../utils/local-storage';
import AppConfig from '../../../config';
import { deepCopy } from '../../../utils/deep-copy';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import HttpHelper from '../../../utils/http-helper';

const SavedListModal: React.FunctionComponent<props> = ({
  showModal,
  setShowModal,
  savedList,
  selectedRows,
  comp,
  setSelectedCompetitorList,
  setchangesMade,
  projectType
}) => {
  const [checked, setChecked] = useState(false);
  const [showCreateList, setShowCreateList] = useState(false);
  const [listName, setListName] = useState('');
  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  const [selectedList, setSeletedList] = useState('');
  const [error, setError] = useState<any>(false);

  const navigate = useNavigate();

  const createNewList = async () => {
    // Get all saved lists for project
    const url = !comp ? `${BASE_URL}/v1/savedbusinesslist/` : `${BASE_URL}/v1/savedcompetitorlist/`;
    const data = JSON.stringify({
      project: storage.get('projectId'),
      business_ids: selectedRows?.map((obj: any) => obj._id),
      list_name: listName.trim(),
      user: storage.get('userId'),
      projectLatLong: storage.get('projectLatLong'),
      projectType: projectType
    });

    const config = {
      method: 'post',
      url,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setShowCreateList(false);
        setShowModal(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateSavedList = async () => {
    const url = !comp ? `${BASE_URL}/v1/savedbusinesslist/` : `${BASE_URL}/v1/savedcompetitorlist/`;
    const data = JSON.stringify({
      savedBusinessListId: selectedList,
      bussinessIds: selectedRows?.map((obj: any) => obj._id),
      projectLatLong: storage.get('projectLatLong'),
      projectType: projectType
    });
    const config = {
      method: 'patch',
      url,
      headers: {
        Authorization: `Bearer ${storage.get('accessToken')}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        setShowCreateList(false);
        setShowModal(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = async () => {
    if (comp === 'template') {
      setSelectedCompetitorList(selectedList);
      if (setchangesMade) setchangesMade(true);
    } else {
      await updateSavedList();
    }
    setShowModal(false);
    setSeletedList('');
  };

  const handleOptionChange = (event: any) => {
    setSeletedList(event.target.value);
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal);
        }}
        style={{ maxWidth: '422px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => {
            if (showCreateList) setShowCreateList(false);
            else setShowModal(!showModal);
          }}
        >
          {!comp
            ? showCreateList
              ? 'Create New List'
              : 'Save to List'
            : comp === 'template'
            ? 'Competitor List'
            : showCreateList
            ? 'Create New Competitor List'
            : 'Save to Competitor List'}
        </ModalHeader>

        <ModalBody>
          {showCreateList ? (
            <div className={styles.create_list_form} style={{ display: 'flex' }}>
              <p>Enter name</p>
              <input
                type='text'
                onChange={e => {
                  setError(false);
                  setListName(e.target.value);
                }}
              />
              {error && <div className={styles.error_msg}>{error}</div>}
            </div>
          ) : (
            <div style={{ minHeight: '300px', maxHeight: '300px', overflowY: 'scroll' }}>
              {savedList?.map((el: any) => {
                return (
                  <div className={styles.list_div}>
                    <div className={styles.list_div_radio}>
                      <input
                        type='radio'
                        value={el.value}
                        checked={selectedList === el.value}
                        onChange={handleOptionChange}
                      />
                    </div>
                    <div className={styles.list_div_item}>
                      <div>{el.label}</div>
                      <p>{el.count} Items</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {showCreateList ? (
            <div className={styles.button_container}>
              <div className={styles.button_container}></div>
              <button
                className={styles.cancel_button}
                onClick={() => {
                  setShowCreateList(false);
                  setSeletedList('');
                }}
              >
                Cancel
              </button>
              <button
                className={styles.confirm_button}
                disabled={!listName}
                onClick={() => {
                  let arr = savedList.map((item: any) => {
                    return item.label;
                  });
                  if (arr.some((item: any) => item === listName.trim()))
                    setError('List with this name already exists');
                  if (listName.trim() === '') setError('Invalid List Name');
                  else createNewList();
                }}
              >
                Create
              </button>
            </div>
          ) : (
            <div className={styles.button_container}>
              <div className={styles.button_container}>
                {comp !== 'template' && (
                  <button
                    className={styles.create_button}
                    onClick={() => {
                      setListName('');
                      setError(false);
                      setShowCreateList(true);
                    }}
                  >
                    Create New List
                  </button>
                )}
              </div>
              <div className={styles.button_container}>
                <button
                  className={styles.cancel_button}
                  onClick={() => {
                    setShowModal(false);
                    setSeletedList('');
                  }}
                >
                  Cancel
                </button>
                <button
                  className={styles.confirm_button}
                  disabled={!selectedList}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {comp !== 'template' ? 'Save' : 'Confirm'}
                </button>
              </div>
            </div>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SavedListModal;
