import React from 'react';
import RequestDemoLeftSection from '../../components/RequestDemoLeftSection';
import NewPasswordContainer from '../../containers/new-password';
import styles from '../sign-in/sign-in.module.scss';

function NewPassword() {
  return (
    <>
      <div className={styles.screen_wrapper}>
        <RequestDemoLeftSection>
          <div className={styles.featurecolumn}>
            <img
              src={'/images/ReachTenants-logo.svg'}
              height='43px'
              width='204px'
              className={styles.logo}
            />
            <div className={styles.left_heading}>
              Welcome to
              <br /> ReachTenants
            </div>

            <div className={styles.left_sub_heading}>
              The most effective way to reach your next tenant
            </div>
          </div>
        </RequestDemoLeftSection>
        <NewPasswordContainer />
      </div>
    </>
  );
}

export default NewPassword;
