import React, { useEffect, useState } from 'react';
import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './calculator.module.scss';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { useNavigate } from 'react-router-dom';
import CalculatorContainer from '../../containers/calculator';
import HttpHelperWithoutAuth from '../../utils/http-helper-without-auth';
import AppConfig from '../../config';
import DevicesIcon from '@mui/icons-material/Devices';
const Calculator = () => {
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const BASE_URL = AppConfig.API_BASE_URL;

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const query = queryParams.get('query');
      console.log(query);
      if (query) {
        setLoading(true);

        console.log('Query parameter:', query);
        const { getRequest } = new HttpHelperWithoutAuth();
        const data = await getRequest(`${BASE_URL}/v1/calculator?query=${query}`);
        console.log(data.data);
        setData(data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.landing_page_wrapper}>
        <div className={styles.max_width}>
          <HeaderLanding />
          {isMobile ? (
            <div
              className={styles.calc_mobile_not_supported}
              style={{ maxWidth: '70%', margin: 'auto', minHeight: '85vh' }}
            >
              <DevicesIcon fontSize='large' />

              <h1>Access denied</h1>
              <p style={{ color: '#868e96', marginTop: '16px' }}>
                Unfortunately, calculators are not accessible on mobile and tablet devices. Please
                use calculators on desktop. If you have any questions feel free to reach out at{' '}
                <a href='mailto:support@reachtenants.com'>support@reachtenants.com</a>
              </p>
            </div>
          ) : (
            <>
              <div className={styles.content_wrapper}>
                <div className={styles.heading_cover_image}>
                  <div className={styles.heading}>
                    Accelerate your projections with these unique calculators
                  </div>
                </div>
              </div>
              {!loading && <CalculatorContainer data={data} />}

              <div className={styles.bottom_page_container}>
                <div className={styles.cta_wrapper}>
                  <img src='aboutus.jpg' className={styles.cta_image} alt=''></img>

                  <div className={styles.cta_details}>
                    <div className={styles.cta_detail_heading}>
                      The most effective way to reach your next tenant
                    </div>

                    <div
                      className={styles.cta_button_wrapper}
                      onClick={() => navigate('/request-demo')}
                    >
                      Book a Demo
                    </div>
                  </div>
                </div>

                <FooterLanding />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Calculator;
