import React, { useEffect, useState, useContext } from 'react';
import styles from './profile.module.scss';
import { Formik, Form, Field } from 'formik';
import CustomisedTextField from '../../../components/form-elements/customised-text-field/index';
import WrappedButton from '../../../components/form-elements/buttons';
import { props } from './model';
import { BTN_PRIMARY } from '../../../constants/button-constants';
import { avatarGenerator } from '../../../utils/avatarGenerator';
import ProfilePictureUploader from '../../../components/modals/uploadProfilePicture/ProfilePictureUploader';
import Storage from '../../../utils/local-storage';
import { ProjectContext } from '../../../context/ProjectContext';
import axios from 'axios';
import PasswordField from '../../../components/form-elements/password-field';
import { useSnackbar } from 'notistack';
import AppConfig from '../../../config';
import * as yup from 'yup';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

const Profile: React.FC<props> = ({ userDetails, queryClient }) => {
  const { enqueueSnackbar } = useSnackbar();
  const BASE_URL = AppConfig.API_BASE_URL;

  const { imageURL, setImageURL } = useContext(ProjectContext);
  const { userData, setUserData } = useContext(ProjectContext);
  const [responseEmail, setResponseEmail] = useState('');
  const storage = new Storage();
  const [profileDetails, setProfileDetails] = useState({
    first_name: '',
    last_name: '',
    email_address: '',
    phone_number: '',
    organisation_name: '',
    organisation_type: '',
    profile_img: '',
    user_address: '',
    user_city: '',
    user_state: '',
    user_zipcode: ''
  });
  const [passwordDetails, setPasswordDetails] = useState({
    new_password: '',
    confirm_new_password: ''
  });

  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: '',
    zoom: 2,
    croppedImg: ''
  });
  const [showModal, setShowModal] = useState(false);
  const handleAuth = () => {
    // Redirect to the backend endpoint that initiates the OAuth flow
    window.location.href = `${BASE_URL}/v1/auth/google`;
  };
  function formatPhoneNumber(input: any) {
    // Remove all non-numeric characters from the input
    let phoneNumber = input.replace(/\D/g, '');
    // If the phone number is longer than 10 digits, trim it down to 10 digits
    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.substring(0, 10);
    }
    // Add the dots in the correct places
    let formattedPhoneNumber = '';
    for (let i = 0; i < phoneNumber.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += '.';
      }
      formattedPhoneNumber += phoneNumber.charAt(i);
    }
    return formattedPhoneNumber;
  }
  useEffect(() => {
    const fetchTokens = async () => {
      const query = new URLSearchParams(window.location.search);
      const code = query.get('code');
      if (code) {
        try {
          const response = await axios.get(`${BASE_URL}/v1/auth/google/callback?code=${code}`, {
            headers: {
              Authorization: `Bearer ${storage.get('accessToken')}`
            }
          });
          console.log(response);
          if (response.data.email) {
            setResponseEmail(response.data.email);
          }
          // Save the tokens in your state or context for later use
        } catch (error) {
          console.error('Error fetching tokens:', error);
        }
      }
    };
    fetchTokens();
  }, []);
  useEffect(() => {
    if (userDetails) {
      setProfileDetails({
        first_name: userDetails.user.firstName,
        last_name: userDetails.user.lastName,
        email_address: userDetails.user.email,
        phone_number:
          userDetails.user.phoneNumber && formatPhoneNumber(userDetails.user.phoneNumber),
        organisation_name: userDetails.organization[0].organization_name,
        organisation_type: userDetails.organization[0].organization_type,
        profile_img: userDetails.user.profileImage,
        user_address: userDetails.user.user_address,
        user_city: userDetails.user.user_city,
        user_state: userDetails.user.user_state,
        user_zipcode: userDetails.user.user_zipcode
      });
    }
  }, [userDetails]);

  let img1: any;

  useEffect(() => {
    if (profileDetails.profile_img) {
      img1 = `https://reachtenants-dev-assets.s3.amazonaws.com/${profileDetails.profile_img}`;
    } else if (profileDetails?.first_name)
      img1 = avatarGenerator(profileDetails?.first_name[0], profileDetails?.last_name[0]);
    setImageURL(img1);
    setPicture({
      ...picture,
      croppedImg: img1
    });
  }, [profileDetails]);

  const resetPasswordApi = (password: any) => {
    const data = JSON.stringify({
      password,
      token: storage.get('accessToken')
    });

    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${BASE_URL}/v1/users/reset-password`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // setPasswordDetails({ new_password: '', confirm_new_password: '' });

        enqueueSnackbar('Password changed successfully', { variant: 'success', header: 'Success' });
      })
      .catch(function (error) {
        enqueueSnackbar(error, { variant: 'error', header: 'Error' });
      });
  };

  const updateUserDetailsAPI = (userDetails: any) => {
    const data = JSON.stringify(userDetails);

    const config = {
      method: 'patch',
      url: `${BASE_URL}/v1/users/updateUserDetail`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },

      data: data
    };

    axios(config)
      .then(function (response) {
        setUserData(response.data);
        storage.save('userName', `${response.data.firstName} ${response.data.lastName}`);
        queryClient.invalidateQueries(`userdetail`);
        enqueueSnackbar('User details updated', { variant: 'success', header: 'Success' });
      })
      .catch(function (error) {
        enqueueSnackbar(error, { variant: 'error', header: 'Error' });
      });
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');

  // useEffect(() => {
  //   if (code) {
  //     const config = {
  //       method: 'post',
  //       url: `${BASE_URL}/v1/nylas/oauth/callback?code=${code}`,
  //       headers: {
  //         Authorization: `Bearer ${storage.get('accessToken')}`
  //       }
  //     };

  //     axios(config)
  //       .then(function (response) {
  //         searchParams.delete('code');
  //         setSearchParams(searchParams);
  //         queryClient.invalidateQueries(`userdetail`);

  //       })
  //       .catch(function (error) {});
  //   }
  // }, []);

  return (
    <>
      <div className={styles.profile_wrapper}>
        <div className={styles.linear_gradient_bg}></div>
        <div className={styles.profile_picture}>
          <div className={styles.edit_icon} onClick={() => setShowModal(true)}>
            <img src='/EditPencil.svg' alt='Profile' />
          </div>
          <img
            src={picture.croppedImg}
            alt=''
            style={{
              borderRadius: '50%',
              width: '90px',
              height: '90px'
            }}
          />
          {showModal && (
            <ProfilePictureUploader
              showModal={showModal}
              setShowModal={setShowModal}
              picture={picture}
              setPicture={setPicture}
              queryClient={queryClient}
            />
          )}
        </div>
        <div className={styles.edit_details_form}>
          <div className={styles.form_wrapper}>
            <Formik
              initialValues={profileDetails}
              onSubmit={values => {
                updateUserDetailsAPI({
                  firstName: values.first_name,
                  lastName: values.last_name,
                  phoneNumber: values.phone_number,
                  user_address: values.user_address,
                  user_city: values.user_city,
                  user_state: values.user_state,
                  user_zipcode: values.user_zipcode
                });
              }}
              validationSchema={yup.object({
                first_name: yup.string().trim().required('First name is required'),
                last_name: yup.string().trim().required('Last name is required'),
                phone_number: yup.string().trim().required('Phone number is required')
              })}
              enableReinitialize
              validateOnChange={true}
            >
              {props => (
                <Form className={styles.form_container}>
                  <div className={styles.heading}>Profile</div>

                  <div className={styles.two_fields}>
                    <div className={styles.single_field}>
                      <Field
                        name='first_name'
                        placeholder={'First name'}
                        title={'First name'}
                        type={'text'}
                        component={CustomisedTextField}
                      />
                    </div>

                    <div className={styles.single_field}>
                      <Field
                        name='last_name'
                        placeholder={'Last name'}
                        title={'Last name'}
                        type={'text'}
                        component={CustomisedTextField}
                      />
                    </div>
                  </div>
                  <div className={styles.two_fields}>
                    <div className={styles.single_field}>
                      <Field
                        name='email_address'
                        placeholder={'Email'}
                        title={'Email'}
                        type={'text'}
                        component={CustomisedTextField}
                        disabled={true}
                      />
                    </div>

                    <div className={styles.single_field}>
                      <Field
                        name='phone_number'
                        placeholder={'Phone number'}
                        title={'Phone number'}
                        type={'text'}
                        isPhone={true}
                        component={CustomisedTextField}
                      />
                    </div>
                  </div>
                  <div className={styles.two_fields}>
                    <div className={styles.single_field}>
                      <Field
                        name='user_address'
                        placeholder={'Address'}
                        title={'Address'}
                        type={'text'}
                        component={CustomisedTextField}
                        disabled={false}
                      />
                    </div>
                    <div className={styles.single_field}>
                      <Field
                        name='user_city'
                        placeholder={'City'}
                        title={'City'}
                        type={'text'}
                        component={CustomisedTextField}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className={styles.two_fields}>
                    <div className={styles.single_field}>
                      <Field
                        name='user_state'
                        placeholder={'State'}
                        title={'State'}
                        type={'text'}
                        component={CustomisedTextField}
                        disabled={false}
                      />
                    </div>
                    <div className={styles.single_field}>
                      <Field
                        name='user_zipcode'
                        placeholder={'Zip code'}
                        title={'Zip code'}
                        type={'text'}
                        component={CustomisedTextField}
                        disabled={false}
                      />
                    </div>
                  </div>

                  <div className={styles.heading}>Organization</div>

                  <div className={styles.two_fields}>
                    <div className={styles.single_field}>
                      <Field
                        name='organisation_name'
                        placeholder={'Organization name'}
                        title={'Organization Name'}
                        type={'text'}
                        component={CustomisedTextField}
                        disabled={true}
                      />
                    </div>

                    <div className={styles.single_field}>
                      <Field
                        name='organisation_type'
                        placeholder={'Organization type'}
                        title={'Organization type'}
                        type={'text'}
                        component={CustomisedTextField}
                        disabled={true}
                      />
                    </div>
                  </div>

                  {/* <div className={styles.change_password_link}>Change Password</div> */}

                  <div className={styles.button_wrapper}>
                    <div className={styles.button_save_changes}>
                      <WrappedButton
                        btnText='Save Changes'
                        type='submit'
                        btnType={BTN_PRIMARY}
                        className={styles.request_access_button}
                        isDisabled={!props.dirty}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

            <Formik
              initialValues={{
                new_password: '',
                confirm_new_password: ''
              }}
              onSubmit={(values, { resetForm }) => {
                if (values.new_password) {
                  if (values.new_password === values.confirm_new_password) {
                    resetPasswordApi(values.new_password);
                    resetForm();
                    // setPasswordDetails({ new_password: '', confirm_new_password: '' });
                  } else {
                    enqueueSnackbar('Password does not match', {
                      variant: 'error',
                      header: 'Error'
                    });
                  }
                }
              }}
              validationSchema={yup.object({
                new_password: yup
                  .string()
                  .required('Password is required')
                  // .min(8, 'Password must be at least 8 characters')
                  .matches(
                    /^(?=.*?[A-Za-z]){1,}(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
                    'Password must contain atleast 1 letter, 1 number and 1 symbol. Minimum length is 8 characters'
                  ),
                confirm_new_password: yup
                  .string()
                  .required('Password is required')
                  .oneOf([yup.ref('new_password')], 'Passwords must match')
              })}
              enableReinitialize={true}
              validateOnChange={true}
            >
              {props => (
                <Form className={styles.form_container}>
                  <div className={styles.heading}>Password</div>
                  <div className={styles.two_fields}>
                    <div className={styles.single_field}>
                      <Field
                        name='new_password'
                        placeholder={'New password'}
                        title={'Change Password'}
                        type={'text'}
                        component={PasswordField}
                        disabled={false}
                      />
                    </div>
                    <div className={styles.single_field}>
                      <Field
                        name='confirm_new_password'
                        placeholder={'Confirm password'}
                        title={'Confirm Password'}
                        type={'text'}
                        component={PasswordField}
                        disabled={false}
                      />
                    </div>
                  </div>

                  <div className={styles.button_wrapper}>
                    <div className={styles.button_save_changes}>
                      <WrappedButton
                        btnText='Change Password'
                        type='submit'
                        btnType={BTN_PRIMARY}
                        className={styles.request_access_button}
                        isDisabled={false}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

            <div className={styles.heading} style={{ marginBottom: '10px' }}>
              Connect Your Account
            </div>
            {(userDetails.user?.googleData?.email || responseEmail) && (
              <p>Email: {userDetails.user?.googleData?.email || responseEmail}</p>
            )}

            <WrappedButton
              className={styles.connect_account_button}
              btnText='Connect Account'
              onClick={handleAuth}
            />
            {/* <button
              className={styles.gsimaterialbutton}
              onClick={() => {
                const config = {
                  method: 'get',
                  url: `${BASE_URL}/v1/nylas/connect`
                };

                axios(config)
                  .then(function (response) {
                    window.location.href = new URL(response.data).href;
                  })
                  .catch(function (error) {});
              }}
            >
              <div className={styles.gsimaterialbuttonstate}></div>
              <div className={styles.gsimaterialbuttoncontentwrapper}>
                <div className={styles.gsimaterialbuttonicon}>
                  <svg xmlns='http://www.w3.org/2000/svg' display='block' viewBox='0 0 48 48'>
                    <path
                      fill='#EA4335'
                      d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'
                    ></path>
                    <path
                      fill='#4285F4'
                      d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'
                    ></path>
                    <path
                      fill='#FBBC05'
                      d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'
                    ></path>
                    <path
                      fill='#34A853'
                      d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'
                    ></path>
                    <path fill='none' d='M0 0h48v48H0z'></path>
                  </svg>
                </div>
                <span className={styles.gsimaterialbuttoncontents}>Sign in with Google</span>
                <span style={{ display: 'none' }}>Sign in with Google</span>
              </div>
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
