import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mapbox-gl/dist/mapbox-gl.css';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CustomSnackbar from './components/snackbar/Snackbar';
import 'chart.js/auto';
import AppConfig from './config';
import axios from 'axios';
import { logout } from './utils/user-actions-helper';

declare module 'notistack' {
  interface VariantOverrides {
    success: {
      header?: string;
    };
    error: {
      header?: string;
    };
  }
}
axios.defaults.baseURL = `${AppConfig.API_BASE_URL}`;
axios.interceptors.request.use(
  (config: any) => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // if (error.response.status === 401) {
    //   logout();
    // }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={3}
      Components={{ success: CustomSnackbar, error: CustomSnackbar }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={2000}
    >
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
