import React, { ReactNode, useEffect } from 'react';
import Header from '../appshell/header';
import styles from './layout.module.scss';
import { useLocation } from 'react-router-dom';
import { getCurrentPathWithoutLastPart } from '../../../utils/getBaseUrl';
interface Props {
  children?: ReactNode;
}

const Layout = ({ children, ...props }: Props) => {
  const layoutLocation = [
    '/settings',
    '/add-project',
    '/projects',
    '/dashboard',
    '/templates',
    '/create-template',
    '/view-project',
    '/email-automation',
    '/activity-report',
    '/add-csv-business',
    '/competitor-report',
    '/updateBusinessDetails'
  ];

  const location = useLocation();

  useEffect(() => {
    //ask-why
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (layoutLocation.includes(getCurrentPathWithoutLastPart(location.pathname))) {
    return (
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <Header />
          <main className={styles.main}>{children}</main>
        </div>
      </div>
    );
  }
  return <>{children}</>;
};

export default Layout;
