import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import FooterLanding from '../../components/core/layout/footer-landing';
import HeaderLanding from '../../components/core/layout/header-landing';
import styles from './calculator-container.module.scss';
import { featureCardProps, calculatorCategoryProps, calculatorContainerProps } from './model';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { CALCULATORS } from '../../constants/calculators';
import HttpHelperWithoutAuth from '../../utils/http-helper-without-auth';
import CalculatorSave from '../../components/modals/calculator-save';
import { cx } from '../../utils/class-name-service';
import HUD from './HUD';
import CriticalDateCalculator from './CriticalDateCalculator';
import CustomCalcs from './CustomCalcs';
interface FormValues {
  [key: string]: string;
}

const PersonalFinanceStatement = () => {
  const [formValues, setFormValues] = useState<FormValues>({});
  const [assetTotal, setAssetTotal] = useState(0);
  const [liTotal, setLiTotal] = useState(0);
  const [liTotalNetWorth, setLiTotalNetWorth] = useState(0);
  const [liTotalFinal, setLiTotalFinal] = useState(0);
  const handleStringInputChange = (label: any, value: any) => {
    const inputValue = value;
    const formatted = inputValue;

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [label]: formatted
    }));
  };
  function s2n(inputString: any) {
    if (inputString) {
      const stringWithoutSymbols = inputString.replace(/[$,]/g, '');
      const result = parseFloat(stringWithoutSymbols);
      return result;
    } else return 0;
  }
  const handleInputChange = (label: any, value: any, unit = '$') => {
    let inputValue;
    let formatted: any;
    if (unit === '$') {
      inputValue = value.replace(/[^0-9]/g, '');
      formatted = Number(inputValue).toLocaleString();
    } else {
      inputValue = value;
      formatted = inputValue;
    }
    if (unit === '$') {
      setFormValues(prevValues => ({
        ...prevValues,
        [label]: formatted.charAt(0) === '$' ? formatted : `$${formatted}`
      }));
    } else {
      setFormValues(prevValues => ({
        ...prevValues,
        [label]: formatted
      }));
    }
    const assetLabel = [
      'cash',
      'saving',
      'ira',
      'accounts-note',
      'assets',
      'other',
      'automobiles',
      'stocks',
      'real-estate',
      'life-insurance'
    ];

    const libalityLabel = [
      'li-other-liabilities',
      'li-unpaid-tax',
      'li-real-section-4',
      'li-mortgages-real-estate',
      'li-loans-life-insurance',
      'li-installement-other',
      'li-installement-auto',
      'li-notes-payable',
      'li-account-payable'
    ];
    if (assetLabel.includes(label)) {
      let sum: any = 0;

      assetLabel.forEach(el => {
        if (el === label) {
          sum += s2n(formatted);
        } else if (formValues[el] !== undefined) {
          sum += s2n(formValues[el]);
        }
      });
      setAssetTotal(sum);
      setLiTotalNetWorth(sum - liTotal);
      setLiTotalFinal(sum + liTotal);
    }
    if (libalityLabel.includes(label)) {
      let sum: any = 0;

      libalityLabel.forEach(el => {
        if (el === label) {
          sum += s2n(formatted);
        } else if (formValues[el] !== undefined) {
          sum += s2n(formValues[el]);
        }
      });
      setLiTotal(sum);
      setLiTotalNetWorth(assetTotal - sum);
      setLiTotalFinal(sum + assetTotal);
    }
  };
  return (
    <div className={styles.calc_right}>
      <div className={styles.calc_right_div}>
        <div className={styles.calc_right_div_container}>
          <div className={styles.header}>Personal financial Statement</div>

          <div className={styles.form}>
            <div className={styles.form_item}>
              <p style={{ width: '50%' }}>Name</p>
              <div style={{ width: '50%' }} className={styles.input_container}>
                <input style={{ width: '100%' }} type='text' />
              </div>
            </div>
            <div className={styles.form_item}>
              <p style={{ width: '50%' }}>Cell Phone</p>
              <div style={{ width: '50%' }} className={styles.input_container}>
                <input style={{ width: '100%' }} type='text' />
              </div>
            </div>
            <div className={styles.form_item}>
              <p style={{ width: '50%' }}>Home Address</p>
              <div style={{ width: '50%' }} className={styles.input_container}>
                <input style={{ width: '100%' }} type='text' />
              </div>
            </div>
            <div className={styles.form_item}>
              <p style={{ width: '50%' }}>City, State, & Zip Code</p>
              <div style={{ width: '50%' }} className={styles.input_container}>
                <input style={{ width: '100%' }} type='text' />
              </div>
            </div>
            <div className={styles.form_item}>
              <p style={{ width: '50%' }}>Business Name of Applicant</p>
              <div style={{ width: '50%' }} className={styles.input_container}>
                <input style={{ width: '100%' }} type='text' />
              </div>
            </div>
            <div className={styles.form_item}>
              <p style={{ width: '50%' }}>Business Phone</p>
              <div style={{ width: '50%' }} className={styles.input_container}>
                <input style={{ width: '100%' }} type='text' />
              </div>
            </div>
            <div className={styles.form_item}>
              <p style={{ width: '50%' }}>Business Address</p>
              <div style={{ width: '50%' }} className={styles.input_container}>
                <input style={{ width: '100%' }} type='text' />
              </div>
            </div>
            <div className={styles.form_item}>
              <p style={{ width: '50%' }}>Business Type</p>
              <div style={{ width: '50%' }} className={styles.input_container}>
                <input style={{ width: '100%' }} type='text' />
              </div>
            </div>

            <div className={cx(styles.table, styles.table_60_40)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>ASSETS</div>
                <div className={styles.th}>Amount</div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Cash on Hand & in bank</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('cash', e.target.value)}
                      value={formValues['cash']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Savings Accounts</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('saving', e.target.value)}
                      value={formValues['saving']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  IRA or Other Retirement Account (Describe in Section 5)
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('ira', e.target.value)}
                      value={formValues['ira']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Accounts & Notes Receivable (Describe in Section 5)</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('accounts-note', e.target.value)}
                      value={formValues['accounts-note']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  Life Insurance–Cash Surrender Value Only (Describe in Section 8)
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('life-insurance', e.target.value)}
                      value={formValues['life-insurance']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Real Estate (Describe in Section 4) </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('real-estate', e.target.value)}
                      value={formValues['real-estate']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Stocks and Bonds (Describe in Section 3) </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('stocks', e.target.value)}
                      value={formValues['stocks']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  Automobiles (Describe in Section 5, and includeYear/Make/Model)
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('automobiles', e.target.value)}
                      value={formValues['automobiles']}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.tr}>
                <div className={styles.th}>Other Personal Property (Describe in Section 5) </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('other', e.target.value)}
                      value={formValues['other']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Assets (Describe in Section 5)</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('assets', e.target.value)}
                      value={formValues['assets']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Total</div>
                <div className={cx(styles.th, styles.th_bold)}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input style={{ width: '100%' }} type='text' readOnly value={assetTotal} />
                  </div>
                </div>
              </div>
            </div>

            {/* LIABILITY */}

            <div className={cx(styles.table, styles.table_60_40)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>LIABILITIES</div>
                <div className={styles.th}>Amount</div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Accounts Payable</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('li-account-payable', e.target.value)}
                      value={formValues['li-account-payable']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  Notes Payable to Banks and Others (Describe in Section 2)
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('li-notes-payable', e.target.value)}
                      value={formValues['li-notes-payable']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Installment Account (Auto) (Describe in Section 5)</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('li-installement-auto', e.target.value)}
                      value={formValues['li-installement-auto']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Installment Account (Other) (Describe in Section 5)</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('li-installement-other', e.target.value)}
                      value={formValues['li-installement-other']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Loan(s) Against Life Insurance</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('li-loans-life-insurance', e.target.value)}
                      value={formValues['li-loans-life-insurance']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Mortgages on Real Estate (Describe in Section 4)</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('li-mortgages-real-estate', e.target.value)}
                      value={formValues['li-mortgages-real-estate']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Real Estate (Describe in Section 4) </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('li-real-section-4', e.target.value)}
                      value={formValues['li-real-section-4']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Unpaid Taxes (Describe in Section 6)</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('li-unpaid-tax', e.target.value)}
                      value={formValues['li-unpaid-tax']}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.tr}>
                <div className={styles.th}>Other Liabilities (Describe in Section 7) </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('li-other-liabilities', e.target.value)}
                      value={formValues['li-other-liabilities']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Total Liabilities</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input style={{ width: '100%' }} type='text' value={liTotal} readOnly />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Net Worth</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      // onChange={e => handleInputChange('Net Worth', e.target.value)}
                      value={liTotalNetWorth}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Total</div>
                <div className={cx(styles.th, styles.th_bold)}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input style={{ width: '100%' }} type='text' value={liTotalFinal} readOnly />
                  </div>
                </div>
              </div>
            </div>
            {/* Section 1. Source of Income */}

            <div className={cx(styles.table, styles.table_60_40)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>Section 1. Source of Income</div>
                <div className={styles.th}>Amount</div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Salary</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('3-salary', e.target.value)}
                      value={formValues['3-salary']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Net Investment Income</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('3-net-investment', e.target.value)}
                      value={formValues['3-net-investment']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Real Estate Income</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('3-real-estate', e.target.value)}
                      value={formValues['3-real-estate']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Other Income (Describe below)</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('3-other', e.target.value)}
                      value={formValues['3-other']}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Contingent Liabilities */}

            <div className={cx(styles.table, styles.table_60_40)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>Contingent Liabilities</div>
                <div className={styles.th}>Amount</div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>As Endorser or Co-Maker</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('4-endorsor', e.target.value)}
                      value={formValues['4-endorsor']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Legal Claims & Judgments</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('4-legal-claim', e.target.value)}
                      value={formValues['4-legal-claim']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Provision for Federal Income Tax</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('4-provision', e.target.value)}
                      value={formValues['4-provision']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>Other Special Debt</div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('4-other', e.target.value)}
                      value={formValues['4-other']}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={cx(styles.table, styles.table_5)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>Names and addresses of note holder(s)</div>
                <div className={styles.th}>Original balance</div>
                <div className={styles.th}>Current balance</div>
                <div className={styles.th}>Payment amount</div>

                <div className={styles.th}>Frequency amount</div>
                <div className={styles.th}>How secured or endorsed type of collateral</div>
              </div>

              <div className={styles.tr}>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleStringInputChange('sec2-1-1', e.target.value)}
                      value={formValues['sec2-1-1']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-2-1', e.target.value)}
                      value={formValues['sec2-2-1']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-3-1', e.target.value)}
                      value={formValues['sec2-3-1']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-4-1', e.target.value)}
                      value={formValues['sec2-4-1']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-5-1', e.target.value)}
                      value={formValues['sec2-5-1']}
                    />
                  </div>
                </div>

                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleStringInputChange('sec2-6-1', e.target.value)}
                      value={formValues['sec2-6-1']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleStringInputChange('sec2-1-2', e.target.value)}
                      value={formValues['sec2-1-2']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-2-2', e.target.value)}
                      value={formValues['sec2-2-2']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-3-2', e.target.value)}
                      value={formValues['sec2-3-2']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-4-2', e.target.value)}
                      value={formValues['sec2-4-2']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-5-2', e.target.value)}
                      value={formValues['sec2-5-2']}
                    />
                  </div>
                </div>

                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleStringInputChange('sec2-6-2', e.target.value)}
                      value={formValues['sec2-6-2']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleStringInputChange('sec2-1-3', e.target.value)}
                      value={formValues['sec2-1-3']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-2-3', e.target.value)}
                      value={formValues['sec2-2-3']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-3-3', e.target.value)}
                      value={formValues['sec2-3-3']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-4-3', e.target.value)}
                      value={formValues['sec2-4-3']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-5-3', e.target.value)}
                      value={formValues['sec2-5-3']}
                    />
                  </div>
                </div>

                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleStringInputChange('sec2-6-3', e.target.value)}
                      value={formValues['sec2-6-3']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleStringInputChange('sec2-1-4', e.target.value)}
                      value={formValues['sec2-1-4']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-2-4', e.target.value)}
                      value={formValues['sec2-2-4']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-3-4', e.target.value)}
                      value={formValues['sec2-3-4']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-4-4', e.target.value)}
                      value={formValues['sec2-4-4']}
                    />
                  </div>
                </div>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleInputChange('sec2-5-4', e.target.value)}
                      value={formValues['sec2-5-4']}
                    />
                  </div>
                </div>

                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <input
                      style={{ width: '100%' }}
                      type='text'
                      onChange={e => handleStringInputChange('sec2-6-4', e.target.value)}
                      value={formValues['sec2-6-4']}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={cx(styles.table, styles.table_60_40)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>
                  Description of Other Income in Section 1 (Alimony or child support payments should
                  not be disclosed in “Other Income” unless it is desired to have such)
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <textarea
                      style={{ width: '100%' }}
                      onChange={e => handleInputChange('description-of-income', e.target.value, '')}
                      value={formValues['description-of-income']}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(styles.table, styles.table_60_40)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>
                  Section 5. Other personal property and other assets.(Describe, and, if any is
                  pledged as security, state name and address of lien holder, amount of lien, terms
                  of payment and, if delinquent, describe delinquency.)
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <textarea
                      style={{ width: '100%' }}
                      onChange={e =>
                        handleInputChange('personal-property-asset', e.target.value, '')
                      }
                      value={formValues['personal-property-asset']}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(styles.table, styles.table_60_40)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>
                  Section 6. Unpaid taxes. (Describe in detail as to type, to whom payable, when
                  due, amount, and to what property, if any, a taxlien attaches.)
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <textarea
                      style={{ width: '100%' }}
                      onChange={e =>
                        handleInputChange('section-6-unpaid-taxes', e.target.value, '')
                      }
                      value={formValues['section-6-unpaid-taxes']}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(styles.table, styles.table_60_40)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>Section 7. Other liabilities. (Describe in detail.)</div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <textarea
                      style={{ width: '100%' }}
                      onChange={e =>
                        handleInputChange('section-7-other-liablities', e.target.value, '')
                      }
                      value={formValues['section-7-other-liablities']}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(styles.table, styles.table_60_40)}>
              <div className={cx(styles.tr, styles.trh)}>
                <div className={styles.th}>
                  Section 8. Life insurance held (Give face amount and cash surrender value of
                  policies – name of insurance company and beneficiaries.)
                </div>
              </div>
              <div className={styles.tr}>
                <div className={styles.th}>
                  <div style={{ width: '50%' }} className={styles.input_container}>
                    <textarea
                      style={{ width: '100%' }}
                      onChange={e =>
                        handleInputChange('section-8-life-insurance', e.target.value, '')
                      }
                      value={formValues['section-8-life-insurance']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.disclaimer}>
            I authorize the landlord, developer, real estate agent, or a representative of theirs to
            obtain a credit report on me and to make inquiries as necessary to verify the accuracy
            of the statements made and to determine my creditworthiness. By signing this form, I
            certify under penalty of perjury that all information on this form and any additional
            supporting information submitted with this form is true and complete to the best of my
            knowledge.
          </div>

          <div className={styles.salutation}>
            <div className={styles.salutation_left}>
              <div className={styles.form_item}>
                <p style={{ width: '200px' }}>Signature</p>
                <div style={{ width: '100%' }} className={styles.input_container}>
                  <input style={{ width: '100%' }} type='text' />
                </div>
              </div>
              <div className={styles.form_item}>
                <p style={{ width: '200px' }}>Print name</p>
                <div style={{ width: '100%' }} className={styles.input_container}>
                  <input style={{ width: '100%' }} type='text' />
                </div>
              </div>
              <div className={styles.form_item}>
                <p style={{ width: '200px' }}>Signature</p>
                <div style={{ width: '100%' }} className={styles.input_container}>
                  <input style={{ width: '100%' }} type='text' />
                </div>
              </div>
              <div className={styles.form_item}>
                <p style={{ width: '200px' }}>Print name</p>
                <div style={{ width: '100%' }} className={styles.input_container}>
                  <input style={{ width: '100%' }} type='text' />
                </div>
              </div>
            </div>

            <div className={styles.salutation_right}>
              <div className={styles.form_item}>
                <p style={{ width: '200px' }}>Date</p>
                <div style={{ width: '100%' }} className={styles.input_container}>
                  <input style={{ width: '100%' }} type='text' />
                </div>
              </div>
              <div className={styles.form_item}>
                <p style={{ width: '200px' }}>Social security No.</p>
                <div style={{ width: '100%' }} className={styles.input_container}>
                  <input style={{ width: '100%' }} type='text' />
                </div>
              </div>
              <div className={styles.form_item}>
                <p style={{ width: '200px' }}>Date</p>
                <div style={{ width: '100%' }} className={styles.input_container}>
                  <input style={{ width: '100%' }} type='text' />
                </div>
              </div>
              <div className={styles.form_item}>
                <p style={{ width: '200px' }}>Social security No.</p>
                <div style={{ width: '100%' }} className={styles.input_container}>
                  <input style={{ width: '100%' }} type='text' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CalculatorCategory: React.FunctionComponent<calculatorCategoryProps> = ({
  id,
  setSelectedCategory,
  data
}) => {
  const [selectedCalc, setSelectedCalc] = useState(0);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [showSetReminderModal, setShowSetReminderModal] = React.useState(false);
  const [autoResult, setAutoResult] = useState<any>();
  const [autoMultiResult, setAutoMultiResult] = useState<any>();
  const [solveFor, setSolveFor] = useState('');
  const [conclusion, setConclusion] = useState<any>();

  //SOLVE_FOR
  useEffect(() => {
    const calc = CALCULATORS.find(obj => obj.id === id);
    if (calc?.subcategories && calc?.subcategories.length > 0) {
      const calculatorName = calc?.subcategories[selectedCalc].name;

      if (calculatorName === 'FORM_BREAK_EVEN_ON_OPERATIONS_CALCULATOR') {
        setSolveFor('squareFeetLeased');
      } else if (calculatorName === 'FORM_BREAK_EVEN_ON_SALE_FOR_MERCHANT_DEVELOPER_CALCULATOR') {
        setSolveFor('squareFeetLeased');
      } else if (calculatorName === 'FORM_PRESENT_VALUE_OR_FUTURE_VALUE_CALCULATOR') {
        setSolveFor('present');
      } else if (calculatorName === 'DEBT_COVERAGE_RATIO') {
        setSolveFor('noival');
      } else if (calculatorName === 'FLOOR_AREA_RATIO') {
        setSolveFor('bui');
      } else if (calculatorName === 'OPERATIONS_EXPENSE_RATIO') {
        setSolveFor('opexp');
      } else if (calculatorName === 'MAXIMUM_LAND_COST_CALCULATOR') {
        setSolveFor('rentToCharge');
      } else if (calculatorName === 'FORM_RENT_TO_CHARGE') {
        setSolveFor('costOfLand');
      }
    }
  }, [selectedCalc]);

  const getResultKeyValueLabel = () => {
    const calculatorName = CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc].name;
    if (
      calculatorName === 'FORM_RAISE_GRADE_CALCULATOR' ||
      calculatorName === 'FORM_DECREASE_GRADE_CALCULATOR'
    ) {
      return ['Cost per cubic yard', 'Total cost'];
    }
    if (calculatorName === 'FORM_AMORTIZATION_CALCULATOR') {
      return ['APR', 'Monthly Payment'];
    }
    if (calculatorName === 'FORM_DEFEASANCE_CALCULATOR') {
      return ['Yield of Treasuries', 'Defeasance Premium'];
    }
    if (calculatorName === 'FORM_PRESENT_VALUE_OR_FUTURE_VALUE_CALCULATOR') {
      return ['Return Rate', 'Required Principal'];
    }
    if (calculatorName === 'FLOOR_AREA_RATIO') {
      return ['Size of Building', 'Floor Area Ratio'];
    }
    if (calculatorName === 'OPERATIONS_EXPENSE_RATIO') {
      return ['Size of Building', 'Floor Area Ratio'];
    }
    if (calculatorName === 'DEBT_COVERAGE_RATIO') {
      return ['NOI', 'Debt Coverage Ratio'];
    }
    if (calculatorName === 'MAXIMUM_LAND_COST_CALCULATOR') {
      return ['Rate of Return', 'Rent To Charge'];
    } else return ['', ''];
  };

  const showDollar = (type: any) => {
    //show dollar on value
    if (type === 'value') {
      const calcValue = [
        'FORM_DECREASE_GRADE_CALCULATOR',
        'FORM_RAISE_GRADE_CALCULATOR',
        'FORM_AMORTIZATION_CALCULATOR',
        'FORM_BREAK_EVEN_ON_SALE_FOR_MERCHANT_DEVELOPER_CALCULATOR',
        'FORM_BREAK_EVEN_ON_OPERATIONS_CALCULATOR',
        'FORM_PRESENT_VALUE_OR_FUTURE_VALUE_CALCULATOR',
        'MAXIMUM_LAND_COST_CALCULATOR'
      ];
      const calculatorName = CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc]
        .name;

      if (calculatorName && calcValue.includes(calculatorName)) {
        return true;
      } else return false;
    }

    if (type === 'key') {
      const calcKey = [
        'DEBT_COVERAGE_RATIO',
        'FORM_DECREASE_GRADE_CALCULATOR',
        'FORM_RAISE_GRADE_CALCULATOR'
      ];

      const calculatorName = CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc]
        .name;
      if (calculatorName && calcKey.includes(calculatorName)) {
        return true;
      } else return false;
    }
  };
  const showPercent = (type: any) => {
    //show dollar on value
    if (type === 'value') {
      const calcValue: any = ['DEBT_COVERAGE_RATIO', 'FLOOR_AREA_RATIO'];
      const calculatorName = CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc]
        .name;

      if (calculatorName && calcValue.includes(calculatorName)) {
        return true;
      } else return false;
    }

    if (type === 'key') {
      const calcKey: any = ['MAXIMUM_LAND_COST_CALCULATOR'];

      const calculatorName = CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc]
        .name;
      if (calculatorName && calcKey.includes(calculatorName)) {
        return true;
      } else return false;
    }
  };

  useEffect(() => {
    if (data && data.calculator_subcategory) {
      const selectedCalcCategory = CALCULATORS.find(obj => obj.id === data.calculator_type);
      if (data.calculator_type === 7) {
      } else {
        const index = selectedCalcCategory?.subcategories.findIndex(
          obj => obj.name === data.calculator_subcategory
        );
        setSelectedCalc(index || 0);
      }

      setFormValues(data.data);
      handleAutoCalculation(data.data);
    }
  }, []);

  const componentRef = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'calculator'
  });

  function s2n(inputString: any) {
    if (inputString) {
      const stringWithoutSymbols = inputString.replace(/[$,]/g, '');
      const result = parseFloat(stringWithoutSymbols);
      return result;
    } else return 0;
  }

  function isValidValue(value: any) {
    return (
      value !== 0 &&
      !isNaN(value) &&
      value !== undefined &&
      value !== Infinity &&
      value !== -Infinity &&
      value !== 'Infinity' &&
      value !== '-Infinity'
    );
  }

  function formatNumber(num: any) {
    let numStr = num.toString();
    let decimalIndex = numStr.indexOf('.');
    if (decimalIndex !== -1) {
      numStr = numStr.substring(0, decimalIndex + 3);
    }
    let formattedNumber = parseFloat(numStr).toFixed(2);
    formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedNumber;
  }
  const fn = formatNumber;

  const handleAutoCalculation = (newVal: any) => {
    const calculator = CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc];
    const calculatorName = CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc].name;
    console.log(formValues, newVal, calculatorName);

    if (calculatorName === 'FORM_RAISE_GRADE_CALCULATOR') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };

      const { height, area, cost } = formValueCopy;
      if (height && area && cost) {
        let variations: any = [];
        const vol = (s2n(height) * s2n(area)) / 27;
        const baseValue = vol * s2n(cost);
        const costVariations = [-4, -2, 0, 2, 4];
        costVariations.forEach(variation => {
          const variedCost = s2n(cost) + variation;

          // Ensure varied cost is not less than or equal to 0
          if (variedCost > 0) {
            const result = vol * variedCost;
            variations.push({
              key: variedCost,
              value: Math.round(result * 100) / 100 // Round off to two decimal places
            });
            setAutoResult(variations);
            setConclusion(`
            With an area of ${area} square feet of
            land being raised ${height} feet in height, ${(
              Math.round(vol * 100) / 100
            ).toLocaleString()}
            cubic yards of fill will need to be delivered. With a cost per cubic
            yard of $${cost},
            the total cost will be $${(Math.round(baseValue * 100) / 100).toLocaleString()}.
            You will need ${(
              Math.round((vol / 15) * 100) / 100
            ).toLocaleString()} fifteen cubic yard trucks to deliver
            the fill.
            `);
          }
        });
      }
    } else if (calculatorName === 'FORM_DECREASE_GRADE_CALCULATOR') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };

      const { height, area, cost } = formValueCopy;
      if (height && area && cost) {
        let variations: any = [];
        const vol = (s2n(height) * s2n(area)) / 27;
        const baseValue = vol * s2n(cost);
        const costVariations = [-4, -2, 0, 2, 4];
        costVariations.forEach(variation => {
          const variedCost = s2n(cost) + variation;

          // Ensure varied cost is not less than or equal to 0
          if (variedCost > 0) {
            const result = vol * variedCost;
            variations.push({
              key: variedCost,
              value: Math.round(result * 100) / 100 // Round off to two decimal places
            });
            setAutoResult(variations);
            setConclusion(
              `With an area of ${area.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })} square feet of land being reduced ${height.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })} ${height != 1 ? 'feet' : 'foot'}, 
              ${vol.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })} total cubic yards of dirt need to be removed. With a cost per cubic yard of $${cost.toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}, the total cost will be $${(Math.round(baseValue * 100) / 100).toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}. This will require ${(Math.round((vol / 15) * 100) / 100).toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )} fifteen cubic yard trucks to remove the dirt.`
            );
          }
        });
      }
    } else if (calculatorName === 'FORM_RENT_PRORATION') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { expNumDays, fullNumDays, amount } = formValueCopy;
      expNumDays = s2n(expNumDays);
      fullNumDays = s2n(fullNumDays);
      amount = s2n(amount);
      if (expNumDays && fullNumDays && amount) {
        let expDays = expNumDays;
        let fullDays = fullNumDays;
        let pro = (expDays / fullDays) * amount;
        let diem = amount / fullDays;
        // setAutoResult([{ key: 'Prorated amount', value: `$${Math.round(vol * 100) / 100}` }]);
        let message = `To prorate $${formatNumber(amount)} rent over ${expDays.toLocaleString()} ${
          expDays === 1 ? 'day' : 'days'
        } that would normally cover ${fullDays.toLocaleString()} ${
          fullDays === 1 ? 'day' : 'days'
        }, the per diem would be $${formatNumber(
          diem
        )} and the prorated amount would be $${formatNumber(pro)}.`;
        setConclusion(message);
      }
    } else if (calculatorName === 'FORM_EXPENSE_PRORATION') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { expNumDays, fullNumDays, amount } = formValueCopy;
      expNumDays = s2n(expNumDays);
      fullNumDays = s2n(fullNumDays);
      amount = s2n(amount);
      if (expNumDays && fullNumDays && amount) {
        let expDays = expNumDays;
        let fullDays = fullNumDays;
        let pro = (expDays / fullDays) * amount;
        let diem = amount / fullDays;

        const fullDaysText = fullDays === 1 ? ' day' : ' days';
        const expDaysText = expDays === 1 ? ' day' : ' days';

        const content = `
            With a $${amount.toFixed(
              2
            )} expense that is normally paid over the course of ${fullDays}${fullDaysText} being prorated over ${expDays}${expDaysText}, the per diem is $${diem.toFixed(
          2
        )} and the total proration is $${pro.toFixed(2)}.
        `;
        setConclusion(content);
      }
    } else if (calculatorName === 'FORM_AMORTIZATION_CALCULATOR') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { rate, term, amount, period } = formValueCopy;
      rate = s2n(rate);
      term = s2n(term);
      amount = s2n(amount);
      period = s2n(period);

      if (rate && term && amount) {
        let variations: any = [];

        const costVariations = [-0.5, -0.25, 0, 0.25, 0.5];
        costVariations.forEach(variation => {
          const varied = rate + variation;

          // Ensure varied cost is not less than or equal to 0
          if (varied > 0) {
            let payment = 0,
              principal = 0,
              interest = 0;
            let r = varied / 12;
            r /= 100;
            let top = 1 - (1 + r);
            let pow = Math.pow(1 + r, term);
            let bottom = 1 - pow;
            payment = Math.round(amount * (top / bottom + r) * 100) / 100;
            // Assuming 'period' is obtained from user input, similar to $_REQUEST['period'] in PHP
            let amount2 = amount;
            for (let count = 0; count < period; count++) {
              interest = amount2 * r;
              principal = payment - interest;
              amount2 -= principal;
            }
            interest = Math.round((interest / payment) * 100 * 100) / 100;
            principal = 100 - interest;
            variations.push({
              key: varied,
              value: Math.round(payment * 100) / 100 // Round off to two decimal places
            });
            setAutoResult(variations);
            if (variation === 0) {
              const conclusion =
                `With an interest rate of ${formatNumber(
                  rate
                )}% per annum compounded monthly on a $${formatNumber(
                  amount.toFixed(2)
                ).toLocaleString()} loan to be repaid in ${formatNumber(
                  term
                )} equal monthly payments beginning one month after the loan is given, the payment amount is $${formatNumber(
                  payment.toFixed(2)
                )} per month.` +
                (principal !== 0
                  ? ` Payment number ${period} is comprised of ${formatNumber(
                      principal
                    )}% principal and ${formatNumber(interest)}% interest.`
                  : '') +
                ' Different payment amounts for different interest rates are shown above.';

              setConclusion(conclusion);
            }
          }
        });
      }
    } else if (calculatorName === 'FORM_DEFEASANCE_CALCULATOR') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let {
        yieldToMaturity,
        loanAmount,
        amortizationTerm,
        maturity,
        interestRate,
        interestDays,
        months
      } = formValueCopy;

      if (interestDays === '365/360') interestDays = 360;
      if (interestDays === '365/365') interestDays = 365;

      yieldToMaturity = s2n(yieldToMaturity);
      loanAmount = s2n(loanAmount);
      amortizationTerm = s2n(amortizationTerm);
      maturity = s2n(maturity);
      interestRate = s2n(interestRate);
      interestDays = s2n(interestDays);
      months = s2n(months);

      let variations: any = [];

      const yieldVariation = [-0.25, -0.125, 0, 0.125, 0.25];

      yieldVariation.forEach(variation => {
        let varied = yieldToMaturity + variation;

        // Save the original interest rate for resetting after calculations
        let origInterestRate = interestRate;

        // Recalculate interest rate based on the day count basis
        interestRate = interestRate * (365 / interestDays);

        // Ensure interestRate and varied are not zero
        if (interestRate === 0 || varied === 0) {
          console.error('Interest rate or varied yield cannot be zero.');
          return;
        }

        interestRate /= 100;
        varied /= 100;

        const minus = amortizationTerm - months;

        // Calculate monthly payment
        const monthlyPayment = parseFloat(
          (
            (loanAmount * (interestRate / 12)) /
            (1 - 1 / Math.pow(1 + interestRate / 12, amortizationTerm))
          ).toFixed(2)
        );

        // Calculate balloon payment
        const balloonPayment = parseFloat(
          (
            (monthlyPayment *
              (1 - 1 / Math.pow(1 + interestRate / 12, amortizationTerm - maturity))) /
              (interestRate / 12) +
            monthlyPayment
          ).toFixed(2)
        );

        // Calculate loan balance at defeasance date
        const loanBalanceAtDefDate = parseFloat(
          (
            (monthlyPayment *
              (1 - 1 / Math.pow(1 + interestRate / 12, amortizationTerm - (maturity - months)))) /
            (interestRate / 12)
          ).toFixed(2)
        );

        console.log(
          {
            balloonPayment,
            monthlyPayment,
            varied,
            months,
            interestRate
          },
          'Values before calculating k1 and k2'
        );

        // Calculate k1
        let k1 = parseFloat(
          ((balloonPayment - monthlyPayment) / Math.pow(1 + varied / 12, months)).toFixed(2)
        );

        // Calculate k2
        let k2 = parseFloat(
          ((monthlyPayment * (1 - 1 / Math.pow(1 + varied / 12, months))) / (varied / 12)).toFixed(
            2
          )
        );

        // Log k1, k2 after calculation
        console.log({ k1, k2 }, 'k1 and k2 values');

        // Calculate bondsNeeded
        let bondsNeeded = parseFloat((k1 + k2).toFixed(2));

        // Log the final value of bondsNeeded
        console.log({ bondsNeeded }, 'bondsNeeded value');
        console.log({ k1, k2, bondsNeeded }, 'Intermediate Results');

        if (variation === 0) {
          setFormValues(prevValues => ({
            ...prevValues,
            ['teasury_ammount']: `$${bondsNeeded}`
          }));
        }

        // Calculate defeasance premium
        const defeasancePremium = parseFloat((bondsNeeded - loanBalanceAtDefDate).toFixed(2));

        // Reset interestRate and varied to percentage format for further use
        interestRate *= 100;
        varied *= 100;

        // Push results to variations array
        variations.push({
          key: parseFloat(varied.toFixed(2)),
          value: parseFloat(defeasancePremium.toFixed(2)) // Round off to two decimal places
        });

        setAutoResult(variations);
      });
    } else if (calculatorName === 'FORM_BREAK_EVEN_ON_OPERATIONS_CALCULATOR') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let {
        leasableSquareFootage,
        fixedCosts,
        annualDebt,
        annualRent,
        projectedAnnualRent,
        additionalFeetLeased,
        variableCosts,
        squareFeetLeased,
        solveFor
      } = formValueCopy;

      if (!solveFor) {
        solveFor = 'squareFeetLeased';
        setFormValues(prevValues => ({
          ...prevValues,
          ['solveFor']: 'squareFeetLeased'
        }));
      }

      fixedCosts = s2n(fixedCosts) || 0;
      annualDebt = s2n(annualDebt) || 0;
      annualRent = s2n(annualRent) || 0;
      projectedAnnualRent = s2n(projectedAnnualRent) || 0;
      additionalFeetLeased = s2n(additionalFeetLeased) || 0;
      variableCosts = s2n(variableCosts) || 0;
      squareFeetLeased = s2n(squareFeetLeased) || 0;
      leasableSquareFootage = s2n(leasableSquareFootage) || 0;

      setAutoResult([]);
      let resultValue = 0;

      switch (solveFor) {
        case 'squareFeetLeased':
          resultValue =
            (fixedCosts +
              annualDebt -
              annualRent -
              projectedAnnualRent * additionalFeetLeased +
              variableCosts * additionalFeetLeased) /
            -variableCosts;
          setAutoResult([
            { key: 'Total Amount of Square Feet Leased', value: `${resultValue.toFixed(2)}` }
          ]);
          break;

        case 'annualRent':
          resultValue = -(
            -fixedCosts -
            annualDebt -
            variableCosts * squareFeetLeased +
            projectedAnnualRent * additionalFeetLeased -
            variableCosts * additionalFeetLeased
          );
          setAutoResult([
            {
              key: 'Annual Rent for Leased Space (including passthroughs and other income)',
              value: `${resultValue.toFixed(2)}`
            }
          ]);
          break;

        case 'projectedAnnualRent':
          resultValue =
            (annualRent -
              fixedCosts -
              annualDebt -
              variableCosts * squareFeetLeased -
              variableCosts * additionalFeetLeased) /
            -additionalFeetLeased;
          setAutoResult([
            {
              key: 'Projected Average Annual Rent PSF of Vacant Space (including passthroughs)',
              value: `${resultValue.toFixed(2)}`
            }
          ]);
          break;

        case 'fixedCosts':
          resultValue =
            annualRent -
            annualDebt -
            variableCosts * squareFeetLeased +
            projectedAnnualRent * additionalFeetLeased -
            variableCosts * additionalFeetLeased;
          if (resultValue < 1) resultValue = 1;
          setAutoResult([
            { key: 'Fixed Annual Operating Costs', value: `${resultValue.toFixed(2)}` }
          ]);
          break;

        case 'variableCosts':
          resultValue =
            (annualRent - fixedCosts - annualDebt + projectedAnnualRent * additionalFeetLeased) /
            (additionalFeetLeased + squareFeetLeased);
          setAutoResult([
            { key: 'Variable Annual Operating Costs PSF', value: `${resultValue.toFixed(2)}` }
          ]);
          break;

        case 'annualDebt':
          resultValue =
            annualRent -
            fixedCosts -
            variableCosts * squareFeetLeased +
            projectedAnnualRent * additionalFeetLeased -
            variableCosts * additionalFeetLeased;
          setAutoResult([{ key: 'Annual Interest on Debt', value: `${resultValue.toFixed(2)}` }]);
          break;

        case 'additionalFeetLeased':
          resultValue =
            (annualRent - fixedCosts - annualDebt - variableCosts * squareFeetLeased) /
            (variableCosts - projectedAnnualRent);
          setAutoResult([
            {
              key: 'Additional Number of Square Feet That Need to be Leased',
              value: `${resultValue.toFixed(2)}`
            }
          ]);
          break;
      }

      if (autoResult && autoResult.length > 0) {
        let vacantPercent = (
          (additionalFeetLeased / (leasableSquareFootage - squareFeetLeased)) *
          100
        ).toFixed(2);
        let totalOccupancy = (
          ((additionalFeetLeased + squareFeetLeased) / leasableSquareFootage) *
          100
        ).toFixed(2);
        let finalMessage = '';

        if (leasableSquareFootage - squareFeetLeased < additionalFeetLeased) {
          finalMessage = 'There are not enough square feet available to lease and break even.';
        } else if (additionalFeetLeased < 0) {
          finalMessage = 'This project already breaks even!';
        } else {
          let message = `With ${fn(
            leasableSquareFootage.toFixed(2)
          )} leasable square feet, of which ${squareFeetLeased.toLocaleString()} is already leased with an income of $${annualRent.toFixed(
            2
          )}, and $${fn(
            projectedAnnualRent.toFixed(2)
          )} psf projected annual rent for the vacant space with $${fn(
            fixedCosts.toFixed(2)
          )} fixed operating costs and $${fn(
            variableCosts.toFixed(2)
          )} psf variable operating costs with an annual debt service of $${fn(
            annualDebt.toFixed(2)
          )}, then ${fn(
            additionalFeetLeased.toFixed(2)
          )} additional square feet need to be leased to break even, which is ${vacantPercent}% of the vacant space to reach a total occupancy of ${totalOccupancy}%.`;

          if (squareFeetLeased + additionalFeetLeased > leasableSquareFootage) {
            message +=
              ' Since you would need to lease more additional space than is available in the project, you cannot break even at this cap rate and this projected rent PSF. Try raising the projected rent PSF.';
          }

          finalMessage = message;
        }
        setConclusion(finalMessage);
      }
    } else if (calculatorName === 'FORM_BREAK_EVEN_ON_SALE_FOR_MERCHANT_DEVELOPER_CALCULATOR') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };

      let {
        capRate,
        squareFeetLeased,
        annualRent,
        fixedAnnualCosts,
        projectedAverageAnnualRent,
        squareFeetToBeLeased,
        fixedLandConstructionSoftCosts,
        projectedClosingCosts,
        projectedBrokerageFee,
        variableAnnualCosts,
        variableLandConstructionSoftCosts,
        leasableSquareFootage,
        solveFor
      } = formValueCopy;

      if (!solveFor) {
        solveFor = 'squareFeetLeased';
        setFormValues(prevValues => ({
          ...prevValues,
          ['solveFor']: 'squareFeetLeased'
        }));
      }

      capRate = s2n(capRate);
      squareFeetLeased = s2n(squareFeetLeased);
      annualRent = s2n(annualRent);
      fixedAnnualCosts = s2n(fixedAnnualCosts);
      projectedAverageAnnualRent = s2n(projectedAverageAnnualRent);
      squareFeetToBeLeased = s2n(squareFeetToBeLeased);
      fixedLandConstructionSoftCosts = s2n(fixedLandConstructionSoftCosts);
      projectedClosingCosts = s2n(projectedClosingCosts);
      projectedBrokerageFee = s2n(projectedBrokerageFee);
      variableAnnualCosts = s2n(variableAnnualCosts);
      variableLandConstructionSoftCosts = s2n(variableLandConstructionSoftCosts);
      leasableSquareFootage = s2n(leasableSquareFootage);

      // Conversion of cap rate from percentage to decimal form for calculations
      capRate /= 100;

      setAutoResult([]);

      switch (solveFor) {
        case 'squareFeetLeased':
          squareFeetLeased =
            ((annualRent -
              fixedAnnualCosts +
              (projectedAverageAnnualRent - variableAnnualCosts) * squareFeetToBeLeased) /
              capRate -
              fixedLandConstructionSoftCosts -
              projectedClosingCosts -
              projectedBrokerageFee -
              variableLandConstructionSoftCosts * squareFeetToBeLeased) /
            (variableLandConstructionSoftCosts + variableAnnualCosts / capRate);
          setAutoResult([
            {
              key: 'Total Amount of Square Feet Leased',
              value: `${Math.round(squareFeetLeased * 100) / 100}`
            }
          ]);
          break;

        case 'annualRent':
          annualRent =
            (fixedLandConstructionSoftCosts +
              projectedClosingCosts +
              projectedBrokerageFee +
              variableLandConstructionSoftCosts * (squareFeetLeased + squareFeetToBeLeased)) *
              capRate +
            fixedAnnualCosts +
            variableAnnualCosts * squareFeetLeased -
            (projectedAverageAnnualRent - variableAnnualCosts) * squareFeetToBeLeased;
          setAutoResult([
            {
              key: 'Annual Rent for Leased Space (including passthroughs and other income)',
              value: `${Math.round(annualRent * 100) / 100}`
            }
          ]);
          break;

        case 'projectedAverageAnnualRent':
          projectedAverageAnnualRent =
            ((fixedLandConstructionSoftCosts +
              projectedClosingCosts +
              projectedBrokerageFee +
              variableLandConstructionSoftCosts * (squareFeetLeased + squareFeetToBeLeased)) *
              capRate -
              annualRent +
              fixedAnnualCosts +
              variableAnnualCosts * (squareFeetLeased + squareFeetToBeLeased)) /
            squareFeetToBeLeased;
          setAutoResult([
            {
              key: 'Projected Average Annual Rent psf of Vacant Space (including passthroughs)',
              value: `${Math.round(projectedAverageAnnualRent * 100) / 100}`
            }
          ]);
          break;

        case 'fixedAnnualCosts':
          fixedAnnualCosts =
            annualRent -
            variableAnnualCosts * squareFeetLeased +
            (projectedAverageAnnualRent - variableAnnualCosts) * squareFeetToBeLeased -
            (fixedLandConstructionSoftCosts +
              projectedClosingCosts +
              projectedBrokerageFee +
              variableLandConstructionSoftCosts * (squareFeetLeased + squareFeetToBeLeased)) *
              capRate;
          setAutoResult([
            {
              key: 'Fixed Annual Operating Costs',
              value: `${Math.round(fixedAnnualCosts * 100) / 100}`
            }
          ]);
          break;

        case 'variableAnnualCosts':
          variableAnnualCosts =
            (annualRent -
              fixedAnnualCosts +
              projectedAverageAnnualRent * squareFeetToBeLeased -
              (fixedLandConstructionSoftCosts +
                projectedClosingCosts +
                projectedBrokerageFee +
                variableLandConstructionSoftCosts * (squareFeetLeased + squareFeetToBeLeased)) *
                capRate) /
            (squareFeetLeased + squareFeetToBeLeased);
          setAutoResult([
            {
              key: 'Variable Annual Operating Costs',
              value: `${Math.round(variableAnnualCosts * 100) / 100}`
            }
          ]);
          break;

        case 'capRate':
          capRate =
            ((annualRent -
              fixedAnnualCosts -
              variableAnnualCosts * squareFeetLeased +
              (projectedAverageAnnualRent - variableAnnualCosts) * squareFeetToBeLeased) /
              (fixedLandConstructionSoftCosts +
                projectedClosingCosts +
                projectedBrokerageFee +
                (squareFeetLeased + squareFeetToBeLeased) * variableLandConstructionSoftCosts)) *
            100; //multiplied by 10 to make it same as rebo
          setAutoResult([
            { key: 'Anticipated Cap Rate on Sale', value: `${Math.round(capRate * 100) / 100}` }
          ]);
          break;

        case 'fixedLandConstructionSoftCosts':
          fixedLandConstructionSoftCosts =
            (annualRent -
              fixedAnnualCosts -
              variableAnnualCosts * squareFeetLeased +
              (projectedAverageAnnualRent - variableAnnualCosts) * squareFeetToBeLeased) /
              capRate -
            projectedClosingCosts -
            projectedBrokerageFee -
            (squareFeetLeased + squareFeetToBeLeased) * variableLandConstructionSoftCosts;
          setAutoResult([
            {
              key: 'Fixed Land, Construction & Soft Costs',
              value: `${Math.round(fixedLandConstructionSoftCosts * 100) / 100}`
            }
          ]);
          break;

        case 'projectedClosingCosts':
          projectedClosingCosts =
            (annualRent -
              fixedAnnualCosts -
              variableAnnualCosts * squareFeetLeased +
              (projectedAverageAnnualRent - variableAnnualCosts) * squareFeetToBeLeased) /
              capRate -
            fixedLandConstructionSoftCosts -
            projectedBrokerageFee -
            (squareFeetLeased + squareFeetToBeLeased) * variableLandConstructionSoftCosts;
          setAutoResult([
            {
              key: 'Projected Closing Costs of Sale',
              value: `${Math.round(projectedClosingCosts * 100) / 100}`
            }
          ]);
          break;

        case 'projectedBrokerageFee':
          projectedBrokerageFee =
            (annualRent -
              fixedAnnualCosts -
              variableAnnualCosts * squareFeetLeased +
              (projectedAverageAnnualRent - variableAnnualCosts) * squareFeetToBeLeased) /
              capRate -
            fixedLandConstructionSoftCosts -
            projectedClosingCosts -
            (squareFeetLeased + squareFeetToBeLeased) * variableLandConstructionSoftCosts;
          setAutoResult([
            {
              key: 'Projected Brokerage fee on Sale',
              value: `${Math.round(projectedBrokerageFee * 100) / 100}`
            }
          ]);
          break;

        case 'variableLandConstructionSoftCosts':
          variableLandConstructionSoftCosts =
            ((annualRent -
              fixedAnnualCosts -
              variableAnnualCosts * squareFeetLeased +
              (projectedAverageAnnualRent - variableAnnualCosts) * squareFeetToBeLeased) /
              capRate -
              fixedLandConstructionSoftCosts -
              projectedClosingCosts -
              projectedBrokerageFee) /
            (squareFeetLeased + squareFeetToBeLeased);
          setAutoResult([
            {
              key: 'Variable Land, Construction and Soft Costs PSF Leased',
              value: `${Math.round(variableLandConstructionSoftCosts * 100) / 100}`
            }
          ]);
          break;

        case 'squareFeetToBeLeased':
          squareFeetToBeLeased =
            ((fixedLandConstructionSoftCosts +
              projectedClosingCosts +
              projectedBrokerageFee +
              variableLandConstructionSoftCosts * squareFeetLeased) *
              capRate +
              fixedAnnualCosts +
              variableAnnualCosts * squareFeetLeased -
              annualRent) /
            (projectedAverageAnnualRent -
              variableAnnualCosts -
              variableLandConstructionSoftCosts * capRate);
          setAutoResult([
            {
              key: 'Additional Number of Square Feet That Need to be Leased',
              value: `${Math.round(squareFeetToBeLeased * 100) / 100}`
            }
          ]);
          break;

        // Default case can be added if needed
      }

      // Calculate additional percentage variables for output in the CONCLUSIONS paragraph
      let vacantPercent = (squareFeetToBeLeased / (leasableSquareFootage - squareFeetLeased)) * 100;
      let totalOccupancy =
        ((squareFeetToBeLeased + squareFeetLeased) / leasableSquareFootage) * 100;

      let message = `With ${leasableSquareFootage.toLocaleString()} leasable square feet, of which ${squareFeetLeased.toLocaleString()} is 
        already leased with a gross income of $${annualRent.toFixed(
          2
        )}, and $${projectedAverageAnnualRent.toFixed(2)} psf projected gross 
        annual rent for the vacant space with $${fixedAnnualCosts.toFixed(
          2
        )} fixed operating costs and $${variableAnnualCosts.toFixed(2)} psf variable 
        operating expenses, and with $${fixedLandConstructionSoftCosts.toFixed(
          2
        )} of fixed, land, construction, and soft costs, projected closing costs 
        of $${projectedClosingCosts.toFixed(
          2
        )}, a projected brokerage fee of $${projectedBrokerageFee.toFixed(
        2
      )}, variable land, construction, and 
        soft costs of $${variableLandConstructionSoftCosts.toFixed(
          2
        )} psf, at an anticipated cap rate of ${(capRate * 100).toFixed(4)}%, 
        then ${squareFeetToBeLeased.toFixed(
          2
        )} additional square feet need to be leased to break even, which is ${vacantPercent.toFixed(
        2
      )}% of 
        the vacant space to reach a total occupancy of ${totalOccupancy.toFixed(2)}%.`;

      if (squareFeetLeased + squareFeetToBeLeased > leasableSquareFootage) {
        message +=
          ' Since you would need to lease more additional space than is available in the project, you cannot break even at this cap rate and this projected rent PSF. Try raising the projected rent PSF.';
      }
      setConclusion(message);
    } else if (calculatorName === 'UNIT_CONVERSIONS_CALCULATOR') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };

      let squareMeters, squareInches, squareFeet, squareYards, acres, squareMiles;
      let { formType, number } = formValueCopy;
      console.log(formValueCopy, 1816);
      number = s2n(number);
      if (!formType) {
        formType = 'square meters';
        setFormValues((prevValues: any) => ({
          ...prevValues,
          ['formType']: 'square meters'
        }));
      }
      switch (formType) {
        case 'square meters':
          squareMeters = parseFloat(number.toFixed(2));
          squareInches = parseFloat((number * 1550.0031).toFixed(2));
          squareFeet = parseFloat((number * 10.7639104).toFixed(2));
          squareYards = parseFloat((number * 1.19599005).toFixed(2));
          acres = parseFloat((number * 0.000249105381).toFixed(5));
          squareMiles = parseFloat((number * 0.000000386102159).toFixed(5));

          if (formType && number) {
            let message =
              `${formatNumber(number)} ${formType} is equivalent to ` +
              `${formatNumber(squareInches)} square inches, ` +
              `${formatNumber(squareFeet)} square feet, ` +
              `${formatNumber(squareYards)} square yards, ` +
              `${formatNumber(acres)} acres, and ` +
              `${formatNumber(squareMiles)} square miles.`;

            setConclusion(message);
          }
          break;

        case 'square inches':
          squareMeters = parseFloat((number * 0.00046516).toFixed(2));
          squareInches = parseFloat(number.toFixed(2));
          squareFeet = parseFloat((number * 0.00694444444).toFixed(2));
          squareYards = parseFloat((number * 0.000771604938).toFixed(2));
          acres = parseFloat((number * 0.000000159422508).toFixed(5));
          squareMiles = parseFloat((number * 0.000000000249097669).toFixed(5));

          if (formType && number) {
            let message =
              `${formatNumber(number)} ${formType} is equivalent to ` +
              `${formatNumber(squareFeet)} square feet, ` +
              `${formatNumber(squareMeters)} square meters, ` +
              `${formatNumber(squareYards)} square yards, ` +
              `${formatNumber(acres)} acres, and ` +
              `${formatNumber(squareMiles)} square miles.`;

            setConclusion(message);
          }
          break;

        case 'square feet':
          squareFeet = parseFloat(number.toFixed(2));
          squareInches = parseFloat((number * 144).toFixed(2));
          squareYards = parseFloat((number * 0.111111111).toFixed(2));
          squareMeters = parseFloat((number * 0.09290304).toFixed(2));
          acres = parseFloat((number * 0.0000229568411).toFixed(5));
          squareMiles = parseFloat((number * 0.0000000358700643).toFixed(5));
          if (formType && number) {
            let message =
              `${formatNumber(number)} ${formType} is equivalent to ` +
              `${formatNumber(squareInches)} square inches, ` +
              `${formatNumber(squareMeters)} square meters, ` +
              `${formatNumber(squareYards)} square yards, ` +
              `${formatNumber(acres)} acres, and ` +
              `${formatNumber(squareMiles)} square miles.`;

            setConclusion(message);
          }
          break;

        case 'square yards':
          squareYards = parseFloat(number.toFixed(2));
          squareMeters = parseFloat((number * 0.83612736).toFixed(2));
          squareInches = parseFloat((number * 1296).toFixed(2));
          squareFeet = parseFloat((number * 9).toFixed(2));
          acres = parseFloat((number * 0.00020661157).toFixed(5));
          squareMiles = parseFloat((number * 0.000000322830579).toFixed(5));
          if (formType && number) {
            let message =
              `${formatNumber(number)} ${formType} is equivalent to ` +
              `${formatNumber(squareInches)} square inches, ` +
              `${formatNumber(squareFeet)} square feet, ` +
              `${formatNumber(squareMeters)} square meters, ` +
              `${formatNumber(acres)} acres, and ` +
              `${formatNumber(squareMiles)} square miles.`;

            setConclusion(message);
          }
          break;

        case 'acres':
          acres = parseFloat(number.toFixed(5));
          squareInches = parseFloat((number * 6272640).toFixed(2));
          squareMeters = parseFloat((number * 4046.85642).toFixed(2));
          squareFeet = parseFloat((number * 43560).toFixed(2));
          squareYards = parseFloat((number * 4840).toFixed(2));
          squareMiles = parseFloat((number * 0.0015625).toFixed(5));
          if (formType && number) {
            let message =
              `${formatNumber(number)} ${formType} is equivalent to ` +
              `${formatNumber(squareInches)} square inches, ` +
              `${formatNumber(squareFeet)} square feet, ` +
              `${formatNumber(squareMeters)} square meters, ` +
              `${formatNumber(squareYards)} square yards, ` +
              `${formatNumber(squareMiles)} square miles.`;

            setConclusion(message);
          }
          break;

        case 'square miles':
          squareMeters = parseFloat((number * 2589988.11).toFixed(5));
          squareMiles = parseFloat(number.toFixed(5));
          squareInches = parseFloat((number * 4014489600).toFixed(5));
          squareFeet = parseFloat((number * 27878400).toFixed(5));
          squareYards = parseFloat((number * 3097600).toFixed(5));
          acres = parseFloat((number * 640).toFixed(5));

          if (formType && number) {
            let message =
              `${formatNumber(number)} ${formType} is equivalent to ` +
              `${formatNumber(squareInches)} square inches, ` +
              `${formatNumber(squareFeet)} square feet, ` +
              `${formatNumber(squareMeters)} square meters, ` +
              `${formatNumber(squareYards)} square yards, ` +
              `${formatNumber(acres)} acres, and `;

            setConclusion(message);
          }
          break;
      }
      // if (formType && number) {
      //   let message =
      //     `${formatNumber(number)} ${formType} is equivalent to ` +
      //     `${formatNumber(squareInches)} square inches, ` +
      //     `${formatNumber(squareFeet)} square feet, ` +
      //     `${formatNumber(squareMeters)} square meters, ` +
      //     `${formatNumber(squareYards)} square yards, ` +
      //     `${formatNumber(acres)} acres, and ` +
      //     `${formatNumber(squareMiles)} square miles.`;

      //   setConclusion(message);
      // }
    } else if (calculatorName === 'FORM_PRESENT_VALUE_OR_FUTURE_VALUE_CALCULATOR') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { present, future, rate, years, solveFor } = formValueCopy;
      if (!solveFor) {
        solveFor = 'present';
        setFormValues((prevValues: any) => ({
          ...prevValues,
          ['solveFor']: 'present'
        }));
      }
      present = s2n(present);
      future = s2n(future);
      rate = s2n(rate);
      years = s2n(years);
      let basePresent, baseFuture;
      let variations: any = [];
      const costVariations = [-0.5, -0.25, 0, 0.25, 0.5];
      costVariations.forEach(variation => {
        let apr = 1 + (rate + variation) / 100;
        let interest = Math.pow(apr, years);
        switch (solveFor) {
          case 'future':
            if (variation === 0) {
              baseFuture = present * interest;
            }
            variations.push({ key: rate + variation, value: present * interest });
            break;
          case 'present':
            if (variation === 0) {
              basePresent = future / interest;
            }
            variations.push({ key: rate + variation, value: future / interest });
            break;
        }
      });
      setAutoResult(variations);
      let message = `With a rate of return of ${rate}% per annum compounded annually over the course of ${years} years, `;

      if (solveFor === 'present') {
        message += `a principal of $${formatNumber(basePresent)} will become $${formatNumber(
          future
        )}.`;
      } else {
        message += `in order to achieve a value of $${formatNumber(
          baseFuture
        )} the starting principal must be $${formatNumber(present)}.`;
      }
      setConclusion(message);
    } else if (calculatorName === 'DEBT_COVERAGE_RATIO') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { debtcovratio, annualdebt, noival, solveFor } = formValueCopy;
      if (!solveFor) {
        solveFor = 'noival';
        setFormValues((prevValues: any) => ({
          ...prevValues,
          ['solveFor']: 'noival'
        }));
      }
      debtcovratio = s2n(debtcovratio);
      annualdebt = s2n(annualdebt);
      noival = s2n(noival);

      let variations: any = [];
      const costVariations = [-3, -1.5, 0, 1.5, 3];

      switch (solveFor) {
        case 'noival':
          noival = (debtcovratio / 100) * annualdebt;
          break;
        case 'annualdebt':
          annualdebt = noival / (debtcovratio / 100);

          break;
        case 'debtcovratio':
          debtcovratio = (noival / annualdebt) * 100;

          break;
      }
      let noi_1 = noival - noival * 5 * 0.03;

      for (let i = 0; i < 10; i++) {
        let debtcovratio_var = (noi_1 / annualdebt) * 100;

        variations.push({
          key: noi_1.toFixed(2),
          value: debtcovratio_var.toFixed(3)
        });

        noi_1 += noival * 0.03; // Increment the noi_1 variable by 3% of the initial NOI value
      }

      setAutoResult(variations);
      let message = `With Net Operating Income (NOI) of $${formatNumber(
        noival
      )} and annual debt service of $${formatNumber(
        annualdebt
      )}, the debt coverage ratio is ${formatNumber(debtcovratio)}%.`;
      if (noival && annualdebt && debtcovratio) {
        setConclusion(message);
      }
    } else if (calculatorName === 'FLOOR_AREA_RATIO') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { bui, land, flratio, solveFor } = formValueCopy;
      if (!solveFor) {
        solveFor = 'bui';
        setFormValues((prevValues: any) => ({
          ...prevValues,
          ['solveFor']: 'bui'
        }));
      }

      bui = s2n(bui);
      land = s2n(land);
      flratio = s2n(flratio);

      switch (solveFor) {
        case 'bui':
          bui = (flratio / 100) * land;
          break;
        case 'land':
          land = bui / (flratio / 100);

          break;
        case 'flratio':
          flratio = (bui / land) * 100;
          break;
      }
      let variations: any = [];

      let bui_1 = bui - bui * 5 * 0.03;

      for (let i = 0; i < 11; i++) {
        let flratio_var = (bui_1 / land) * 100;
        variations.push({
          key: ((flratio_var / 100) * land).toFixed(2),
          value: flratio_var.toFixed(3)
        });

        bui_1 += bui * 0.03; // Increment the bui_1 variable by 3% of the initial BUI value
      }
      setAutoResult(variations);

      let message = `With a building size of ${formatNumber(
        bui
      )} sq. ft. and a land size of ${formatNumber(
        land
      )} sq. ft. the floor area ratio is ${formatNumber(flratio)} %.`;
      setConclusion(message);
    } else if (calculatorName === 'OPERATIONS_EXPENSE_RATIO') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { opexp, gross, opexpratio, solveFor } = formValueCopy;
      if (!solveFor) {
        solveFor = 'opexp';
        setFormValues((prevValues: any) => ({
          ...prevValues,
          ['solveFor']: 'opexp'
        }));
      }

      opexp = s2n(opexp);
      gross = s2n(gross);
      opexpratio = s2n(opexpratio);
      switch (solveFor) {
        case 'opexp':
          opexp = +(gross * (opexpratio / 100)).toFixed(2);
          break;
        case 'gross':
          if (opexpratio <= 0) {
          } else {
            gross = +(opexp / (opexpratio / 100)).toFixed(2);
          }
          break;
        case 'opexpratio':
          if (gross <= 0) {
          } else {
            opexpratio = +((opexp / gross) * 100).toFixed(3);
          }
          break;
      }

      let message = `With operating expenses of $${formatNumber(
        opexp
      )} and gross rental income of $${formatNumber(
        gross
      )}, the operating expense ratio is  ${formatNumber(opexpratio)}%.`;
      setConclusion(message);
    } else if (calculatorName === 'CALCULATE_DAYS_BETWEEN') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { from_date, to_date, first_day, last_day } = formValueCopy;

      if (!from_date || !to_date) {
        return 0; // Or some error message indicating missing dates
      }
      const startDate = new Date(from_date);
      const endDate = new Date(to_date);

      // Calculate the difference in milliseconds
      const diffInMs = endDate.getTime() - startDate.getTime();

      // Convert milliseconds to days
      let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      if (diffInDays > 0) {
        diffInDays = diffInDays - 1;
      } else {
        diffInDays = 0;
      }

      // Adjust based on whether the first day and/or last day should be counted
      if (first_day) {
        diffInDays += 1; // Include the first day in the count
      }
      if (last_day) {
        diffInDays += 1; // Include the last day in the count
      }

      // Return the total number of days
      setConclusion(`Total number of days: ${diffInDays}`);
    } else if (calculatorName === 'MAXIMUM_LAND_COST_CALCULATOR') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let {
        rentToCharge,
        vacancyFactor,
        softCosts,
        returnRate,
        leasableSquareFeet,
        costOfLand,
        passthroughs,
        acres,
        solveFor
      } = formValueCopy;
      if (!solveFor) {
        solveFor = 'rent';
        setFormValues((prevValues: any) => ({
          ...prevValues,
          ['solveFor']: 'rentToCharge'
        }));
      }
      rentToCharge = s2n(rentToCharge);
      vacancyFactor = s2n(vacancyFactor);
      softCosts = s2n(softCosts);
      returnRate = s2n(returnRate);
      passthroughs = s2n(passthroughs);
      leasableSquareFeet = s2n(leasableSquareFeet);
      costOfLand = s2n(costOfLand);
      acres = s2n(acres);
      if (!acres) {
        return 0;
      }

      returnRate /= 100;
      vacancyFactor /= 100;

      console.log(solveFor);
      switch (solveFor) {
        case 'costOfLand':
          costOfLand =
            Math.round(
              ((rentToCharge * leasableSquareFeet * (1 - vacancyFactor) -
                leasableSquareFeet * vacancyFactor * passthroughs) /
                returnRate -
                leasableSquareFeet * softCosts) *
                100
            ) / 100;
          break;
        case 'rentToCharge':
          rentToCharge =
            Math.round(
              (((leasableSquareFeet * softCosts + costOfLand) * returnRate +
                leasableSquareFeet * vacancyFactor * passthroughs) /
                ((1 - vacancyFactor) * leasableSquareFeet)) *
                100
            ) / 100;
          console.log(rentToCharge);
          break;
        case 'leasableSquareFeet':
          leasableSquareFeet =
            Math.round(
              ((costOfLand * returnRate) /
                (rentToCharge -
                  rentToCharge * vacancyFactor -
                  softCosts * returnRate -
                  vacancyFactor * passthroughs)) *
                100
            ) / 100;
          break;
        case 'returnRate':
          returnRate =
            Math.round(
              ((rentToCharge * (1 - vacancyFactor) * leasableSquareFeet -
                leasableSquareFeet * vacancyFactor * passthroughs) /
                (leasableSquareFeet * softCosts + costOfLand)) *
                10000
            ) / 10000;
          break;
        case 'softCosts':
          softCosts =
            Math.round(
              (((rentToCharge * leasableSquareFeet * (1 - vacancyFactor) -
                leasableSquareFeet * vacancyFactor * passthroughs) /
                returnRate -
                costOfLand) /
                leasableSquareFeet) *
                100
            ) / 100;
          break;
        case 'passthroughs':
          passthroughs =
            Math.round(
              ((rentToCharge * leasableSquareFeet * (1 - vacancyFactor) -
                (leasableSquareFeet * softCosts + costOfLand) * returnRate) /
                (leasableSquareFeet * vacancyFactor)) *
                100
            ) / 100;
          break;
        case 'vacancyFactor':
          vacancyFactor =
            Math.round(
              ((rentToCharge * leasableSquareFeet -
                (leasableSquareFeet * softCosts * returnRate + costOfLand * returnRate)) /
                (leasableSquareFeet * passthroughs + rentToCharge * leasableSquareFeet)) *
                10000
            ) / 10000;
          break;
      }

      // Adjusting back the rates for output
      returnRate *= 100;
      vacancyFactor *= 100;

      // Calculating perAcre and perFoot assuming acres is an input
      let perAcre = Math.round((costOfLand / acres) * 100) / 100;
      let perFoot = Math.round((perAcre / 43560) * 100) / 100;

      let content = `
With a rental rate of $${fn(
        rentToCharge.toFixed(2)
      )}, ${leasableSquareFeet.toLocaleString()} leasable square feet, construction and soft costs of $${fn(
        softCosts.toFixed(2)
      )} psf, with an anticipated vacancy factor of ${fn(
        vacancyFactor
      )}%, passthroughs of $${passthroughs.toFixed(
        2
      )} per square foot and a desired return rate of ${returnRate}%, the maximum land cost should be $${fn(
        costOfLand.toFixed(2)
      )} which is $${fn(perAcre.toFixed(2))} per acre and $${fn(
        perFoot.toFixed(2)
      )} per foot. Different maximum amounts for different rental assumptions are shown below.
`;
      setConclusion(content);

      let returnRateVariation = returnRate + 1.25; // Assuming returnRate is defined
      let variations: any = [];

      for (let i = 0; i < 11; i++) {
        let rentToChargeVariation =
          Math.round(
            (((leasableSquareFeet * softCosts + costOfLand) * (returnRateVariation / 100) +
              leasableSquareFeet * (vacancyFactor / 100) * passthroughs) /
              ((1 - vacancyFactor / 100) * leasableSquareFeet)) *
              100
          ) / 100;
        variations.push({
          key: fn(returnRateVariation.toFixed(2)),
          value: fn(rentToChargeVariation.toFixed(3))
        });
        returnRateVariation -= 0.25;
      }
      setAutoResult(variations);
    } else if (calculatorName === 'VALUE_OF_PROPERTY_SUBJECT_TO_GROUND_LEASES') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { noi, leasableSquareFeet, capRate, yearsRemaining } = formValueCopy;
      noi = s2n(noi);
      leasableSquareFeet = s2n(leasableSquareFeet);
      capRate = s2n(capRate);
      yearsRemaining = s2n(yearsRemaining);

      let c = noi / 12;
      let r = capRate / 100 / 12;
      let n = yearsRemaining * 12;
      let valueOfLease = c * (1 + (1 - 1 / Math.pow(1 + r, n - 1)) / r);

      let valuePerSF = valueOfLease / leasableSquareFeet;
      const noiFormatted = noi.toFixed(2);
      const leasableSquareFeetFormatted = leasableSquareFeet.toFixed(2);
      const leasableUnit = leasableSquareFeet === 1 ? 'foot' : 'feet';
      const capRateFormatted = capRate.toFixed(2);
      const yearsRemainingFormatted = yearsRemaining.toFixed(2);
      const yearsUnit = yearsRemaining === 1 ? 'year' : 'years';
      const valueOfLeaseFormatted = valueOfLease.toFixed(2);
      const valuePerSFFormatted = valuePerSF.toFixed(2);

      const content = `
      
            A property subject to a ground lease having a NOI of $${noiFormatted}, with ${leasableSquareFeetFormatted} leasable square ${leasableUnit},
            at a CAP Rate of ${capRateFormatted}% with ${yearsRemainingFormatted} ${yearsUnit} remaining on the ground lease,
            has a value of $${valueOfLeaseFormatted} which is $${valuePerSFFormatted} per leasable square foot.
       
    `;
      setConclusion(content);
    } else if (calculatorName === 'VALUE_OF_PROPERTY') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { noi, leasableSquareFeet, capRate, loantovalue, interestrate, amortization } =
        formValueCopy;
      noi = s2n(noi);
      leasableSquareFeet = s2n(leasableSquareFeet);
      capRate = s2n(capRate);
      loantovalue = s2n(loantovalue);
      interestrate = s2n(interestrate);
      amortization = s2n(amortization);

      let valueOfProperty = noi / (capRate / 100);
      let mortgage = valueOfProperty * (loantovalue / 100);
      let intRateMonth = interestrate / 1200;
      let amortMonths = amortization * 12;
      let debtMonth =
        (mortgage * intRateMonth * Math.pow(1 + intRateMonth, amortMonths)) /
        (Math.pow(1 + intRateMonth, amortMonths) - 1);
      let debtService = debtMonth * 12;
      let annualCashFlow = noi - debtService;
      let initialInvestment = valueOfProperty - mortgage;
      let leverageReturn = ((noi - debtService) / initialInvestment) * 100;
      const amortizationTerm = amortization === 1 ? 'year' : 'years'; // Adjusting term based on value

      const content = `
    With an NOI of $ ${fn(noi.toFixed(2))}, 
    leasable square feet of ${fn(leasableSquareFeet)}, 
    a CAP rate of ${fn(capRate.toFixed(2))} %, 
    a ${fn(loantovalue.toFixed(2))} % loan to value 
    with a ${fn(interestrate.toFixed(2))} % interest rate on the loan and an amortization of 
    ${fn(amortization.toFixed(2))} ${amortizationTerm}, 
    the Value of the Property is $ ${fn(valueOfProperty.toFixed(2))}, 
    the Principal amount of the mortgage will be $ ${fn(mortgage.toFixed(2))}, 
    with first year debt service of $ ${fn(debtService.toFixed(2))} 
    and a first year cash flow of $ ${fn(annualCashFlow.toFixed(2))}.  
    The initial equity investment will be $ ${fn(initialInvestment.toFixed(2))} and the 
    leveraged return on that investment will be ${fn(leverageReturn.toFixed(2))} %.
`;
      setConclusion(content);

      let capRateVariation = capRate + 1.25;
      let capRateVariationArray = [];
      let var_valueofpropertyArray = [];
      let var_mortgageArray = [];
      let var_debtserviceArray = [];
      let var_annualcashflowArray = [];
      let var_leveragereturnArray = [];
      for (let i = 0; i < 11; i++) {
        // Calculating the variables
        let var_valueofproperty = noi / (capRateVariation / 100);
        let var_mortgage = var_valueofproperty * (loantovalue / 100);
        let var_debt_month =
          (var_mortgage * intRateMonth * Math.pow(1 + intRateMonth, amortMonths)) /
          (Math.pow(1 + intRateMonth, amortMonths) - 1);
        let var_debtservice = var_debt_month * 12;
        let var_annualcashflow = noi - var_debtservice;
        let var_intialinvestment = var_valueofproperty - var_mortgage;
        let var_leveragereturn =
          ((noi - var_debtservice) / (var_valueofproperty - var_mortgage)) * 100;

        // Preparing the output
        let rowClass = 'stylecalc1';
        let style = '';
        capRateVariationArray.push(`${fn(capRateVariation.toFixed(2))}%`);
        var_valueofpropertyArray.push(`$${fn(var_valueofproperty)}`);
        var_mortgageArray.push(fn(var_mortgage));
        var_debtserviceArray.push(`$${fn(var_debtservice)}`);
        var_annualcashflowArray.push(`$${fn(var_annualcashflow)}`);
        var_leveragereturnArray.push(`${fn(var_leveragereturn)}%`);

        capRateVariation -= 0.25; // Decrement
      }
      if (noi && leasableSquareFeet && capRate && loantovalue && interestrate && amortization)
        setAutoMultiResult({
          'CAP Rate': capRateVariationArray,
          Value: var_valueofpropertyArray,
          Mortgage: var_mortgageArray,
          'Debt Service': var_debtserviceArray,
          'Cash Flow': var_annualcashflowArray,
          'Leveraged Return': var_leveragereturnArray
        });
    } else if (calculatorName === 'FORM_RENT_TO_CHARGE') {
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let {
        rentToCharge,
        vacancyFactor,
        passthroughs,
        softCosts,
        returnRate,
        leasableSquareFeet,
        costOfLand,
        solveFor
      } = formValueCopy;
      rentToCharge = s2n(rentToCharge);
      vacancyFactor = s2n(vacancyFactor);
      passthroughs = s2n(passthroughs);
      softCosts = s2n(softCosts);
      returnRate = s2n(returnRate);
      leasableSquareFeet = s2n(leasableSquareFeet);
      costOfLand = s2n(costOfLand);

      if (!solveFor) {
        solveFor = 'costOfLand';
        setFormValues((prevValues: any) => ({
          ...prevValues,
          ['solveFor']: 'costOfLand'
        }));
      }

      returnRate /= 100;
      vacancyFactor /= 100;

      let result; // To hold the result of the calculation

      switch (solveFor) {
        case 'costOfLand':
          result =
            Math.round(
              ((rentToCharge * leasableSquareFeet * (1 - vacancyFactor) -
                leasableSquareFeet * vacancyFactor * passthroughs) /
                returnRate -
                leasableSquareFeet * softCosts) *
                100
            ) / 100;
          break;
        case 'rentToCharge':
          result =
            Math.round(
              (((leasableSquareFeet * softCosts + costOfLand) * returnRate +
                leasableSquareFeet * vacancyFactor * passthroughs) /
                ((1 - vacancyFactor) * leasableSquareFeet)) *
                100
            ) / 100;
          break;
        case 'leasableSquareFeet':
          result =
            Math.round(
              ((costOfLand * returnRate) /
                (rentToCharge -
                  rentToCharge * vacancyFactor -
                  softCosts * returnRate -
                  vacancyFactor * passthroughs)) *
                100
            ) / 100;
          break;
        case 'returnRate':
          result =
            Math.round(
              ((rentToCharge * (1 - vacancyFactor) * leasableSquareFeet -
                leasableSquareFeet * vacancyFactor * passthroughs) /
                (leasableSquareFeet * softCosts + costOfLand)) *
                10000
            ) / 100;
          break;
        case 'softCosts':
          result =
            Math.round(
              (((rentToCharge * leasableSquareFeet * (1 - vacancyFactor) -
                leasableSquareFeet * vacancyFactor * passthroughs) /
                returnRate -
                costOfLand) /
                leasableSquareFeet) *
                100
            ) / 100;
          break;
        case 'passthroughs':
          result =
            Math.round(
              ((rentToCharge * leasableSquareFeet * (1 - vacancyFactor) -
                (leasableSquareFeet * softCosts + costOfLand) * returnRate) /
                (leasableSquareFeet * vacancyFactor)) *
                100
            ) / 100;
          break;
        case 'vacancyFactor':
          result = Math.round(
            (((rentToCharge * leasableSquareFeet -
              leasableSquareFeet * softCosts * returnRate +
              costOfLand * returnRate) /
              (leasableSquareFeet * passthroughs + rentToCharge * leasableSquareFeet)) *
              10000) /
              100
          );
          break;
      }

      returnRate *= 100;
      vacancyFactor *= 100;

      const content = `
With land that costs $${costOfLand.toFixed(2)}, ${leasableSquareFeet.toLocaleString(undefined, {
        maximumFractionDigits: 0
      })} leasable square feet, construction and soft costs of $${softCosts.toFixed(
        2
      )} psf, with an anticipated vacancy factor of ${vacancyFactor}%,
passthroughs of $${passthroughs.toFixed(
        2
      )} per square foot and a desired return rate of ${returnRate}%, $${rentToCharge.toFixed(
        2
      )} rent NNN must be charged psf. Rent to charge based upon different rates of return are shown below.
`;
      setConclusion(content);

      // Assuming `result` will be used for further operations or display
    } else if (calculatorName === 'PROPERTY_INCOME_AND_VALUE') {
    } else if (calculatorName === 'FORM_NET_RENT') {
      const content = '';
      const formValueCopy = { ...JSON.parse(JSON.stringify(formValues)), ...newVal };
      let { term, br, n, ta, oc } = formValueCopy;
      term = s2n(term);
      br = s2n(br);
      n = s2n(n);
      ta = s2n(ta);
      oc = s2n(oc);
      const x = br * (term - n) - ta - oc * term;
      const ner = x / term;
      setConclusion(
        `Your net effective rent is $${ner.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}/mo.`
      );
    }
  };
  function removeCalculatorIfAtEnd(str: any) {
    const wordToRemove = 'calculator';
    const regex = new RegExp(wordToRemove + '$');

    if (regex.test(str)) {
      return str.slice(0, -wordToRemove.length).trim();
    }
    const wordToRemove1 = 'Calculator';
    const regex1 = new RegExp(wordToRemove1 + '$');

    if (regex1.test(str)) {
      return str.slice(0, -wordToRemove1.length).trim();
    }

    return str;
  }
  const handleStringInputChange = (label: any, value: any) => {
    const inputValue = value;
    const formatted = inputValue;

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [label]: formatted
    }));
    handleAutoCalculation({ [label]: formatted });
  };
  const handleDateInputChange = (label: any, value: any) => {
    // Assuming value is in a format parseable by Date or is already a Date object
    const date = new Date(value);
    // Format the date to YYYY-MM-DD
    const formatted = date.toISOString().split('T')[0];

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [label]: formatted
    }));

    handleAutoCalculation({ [label]: formatted });
  };
  const handleInputChange = (label: any, value: any, unit: any) => {
    console.log('1111');
    // Allow digits and a single decimal point
    const inputValue = value.replace(/[^0-9.]/g, '');

    // Ensure only one decimal point exists in the input
    const parts = inputValue.split('.');
    let formatted = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas to the integer part

    if (parts.length > 1) {
      formatted += '.' + parts[1]; // Add the decimal part if it exists
    }

    if (unit === '$') {
      setFormValues(prevValues => ({
        ...prevValues,
        [label]: formatted.charAt(0) === '$' ? formatted : `$${formatted}`
      }));
    } else {
      setFormValues(prevValues => ({
        ...prevValues,
        [label]: formatted
      }));
    }
    handleAutoCalculation({ [label]: formatted });
  };

  const calculateResult = () => {};
  const saveForm = async () => {
    setShowSetReminderModal(true);
  };
  return (
    <div>
      <div className={styles.calcMenu}>
        <div className={styles.calcback} onClick={() => setSelectedCategory(0)}>
          &larr; Back
        </div>
        <div className={styles.calcPrint}>
          <div onClick={handlePrint}>Print</div>
          <div onClick={() => saveForm()}>Save</div>
        </div>
      </div>
      {id === 7 && (
        <div className={styles.calc_body}>
          <CriticalDateCalculator r={componentRef} />
        </div>
      )}
      {id === 6 && (
        <div className={styles.calc_body}>
          <PersonalFinanceStatement />
        </div>
      )}
      {Boolean(id !== 6 && id !== 7) && (
        <div className={styles.calc_body}>
          {Boolean(
            CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc].name !== 'FORM_HUD'
          ) && (
            <div className={styles.calc_left}>
              <div className={styles.calc_left_div}>
                <div className={styles.calc_left_div_header}>
                  <span>{CALCULATORS.find(obj => obj.id === id)?.title}</span>
                </div>
                <div className={styles.calc_left_div_items}>
                  {CALCULATORS.find(obj => obj.id === id)?.subcategories.map(
                    (subCategory, index) => {
                      return (
                        <div
                          className={
                            selectedCalc === index
                              ? styles.calc_left_div_item_active
                              : styles.calc_left_div_item
                          }
                          onClick={() => {
                            setFormValues(prevValues => ({}));
                            setConclusion('');
                            setSolveFor('');
                            setAutoResult([]);
                            setSelectedCalc(index);
                          }}
                        >
                          <span>{removeCalculatorIfAtEnd(subCategory.title)}</span>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              {/* <div className={styles.calc_left_div}>
                <div className={styles.calc_left_div_header}>
                  <span>Other calculators</span>
                </div>
                <div className={styles.calc_left_div_items}>
                  <div className={styles.calc_left_div_item}>
                    <span>Proration and apportionment calculators</span>
                  </div>
                  <div className={styles.calc_left_div_item}>
                    <span>Timing, measurement and ratio calculators</span>
                  </div>
                  <div className={styles.calc_left_div_item}>
                    <span>Property value and investor return calculators </span>
                  </div>
                  <div className={styles.calc_left_div_item}>
                    <span>Mortgage calculators</span>
                  </div>
                  <div className={styles.calc_left_div_item}>
                    <span>Break even calculators</span>
                  </div>
                </div>
              </div> */}
            </div>
          )}

          <div className={styles.calc_right} ref={componentRef}>
            <div className={styles.calc_right_div}>
              <div className={styles.calc_right_div_container}>
                <div className={styles.header}>
                  {CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc].title}
                </div>

                <div className={styles.form}>
                  {Boolean(
                    CALCULATORS.find(obj => obj.id === id) &&
                      CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc] &&
                      CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc]
                        ?.multiSolve &&
                      CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc]
                        ?.multiSolve.length
                  ) && (
                    <div className={styles.form_item}>
                      <p>Select the value you want to calculate?</p>

                      <div className={styles.input_container}>
                        <select
                          onChange={(e: any) => {
                            setConclusion('');
                            setAutoResult([]);
                            setSolveFor(e.target.value);
                            setFormValues((prevValues: any) => ({
                              ...prevValues,
                              ['solveFor']: e.target.value
                            }));
                            handleAutoCalculation({ solveFor: e.target.value });
                          }}
                          // value={data.data[element.name]}
                          value={solveFor}
                        >
                          {CALCULATORS.find(obj => obj.id === id)?.subcategories[
                            selectedCalc
                          ]?.multiSolve.map((el: any) => (
                            <option value={el.name}>{el.label}</option>
                          ))}
                        </select>
                        {/* {element.unit != '$' && (
                         <div className={styles.input_after}>{element.unit}</div>
                       )} */}
                      </div>
                    </div>
                  )}
                  {CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc].name ===
                  'FORM_HUD' ? (
                    <HUD />
                  ) : (
                    <>
                      {CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc].form
                        .length === 0 ? (
                        <CustomCalcs
                          calculatorName={
                            CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc].name
                          }
                          handleStringInputChange={handleStringInputChange}
                          formValues={formValues}
                          setFormValues={setFormValues}
                        />
                      ) : (
                        CALCULATORS.find(obj => obj.id === id)?.subcategories[
                          selectedCalc
                        ].form.map((element: any) => {
                          return (
                            <>
                              {element.name !== solveFor && (
                                <div className={styles.form_item}>
                                  <p>{element.label}</p>
                                  {element.type === 'number' && (
                                    <div className={styles.input_container}>
                                      <input
                                        type='text'
                                        onChange={e =>
                                          handleInputChange(
                                            element.name,
                                            e.target.value,
                                            element.unit
                                          )
                                        }
                                        value={
                                          formValues[element.name] ? formValues[element.name] : ''
                                        }
                                      />
                                      {element.unit != '$' && (
                                        <div className={styles.input_after}>{element.unit}</div>
                                      )}
                                    </div>
                                  )}

                                  {element.type === 'date' && (
                                    <div className={styles.input_container}>
                                      <input
                                        type='date'
                                        onChange={e =>
                                          handleStringInputChange(element.name, e.target.value)
                                        }
                                        value={
                                          formValues[element.name] ? formValues[element.name] : ''
                                        }
                                      />
                                    </div>
                                  )}

                                  {element.type === 'select' && (
                                    <div className={styles.input_container}>
                                      <select
                                        onChange={e =>
                                          handleStringInputChange(element.name, e.target.value)
                                        }
                                        value={
                                          formValues[element.name] ? formValues[element.name] : ''
                                        }
                                      >
                                        {element.option.map((el: any) => (
                                          <option value={el}>{el}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )}

                                  {element.type === 'numberAndSelect' && (
                                    <div className={styles.input_container}>
                                      <input
                                        className={styles.merged_input}
                                        type='text'
                                        onChange={e =>
                                          handleInputChange(
                                            element.name,
                                            e.target.value,
                                            element.unit
                                          )
                                        }
                                        value={
                                          formValues[element.name] ? formValues[element.name] : ''
                                        }
                                      />
                                      <select
                                        className={styles.merged_select}
                                        style={{ width: 'auto' }}
                                        onChange={e =>
                                          handleStringInputChange(
                                            element.nameSelect,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          formValues[element.nameSelect]
                                            ? formValues[element.nameSelect]
                                            : ''
                                        }
                                      >
                                        {element.option.map((el: any) => (
                                          <option value={el}>{el}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })
                      )}
                    </>
                  )}
                </div>

                {/* isValidValue(autoResult[0].value) is used for break even validation*/}
                {autoResult?.length > 0 && isValidValue(autoResult[0].value) && (
                  <>
                    <div className={styles.result}>
                      <div style={{ width: '335px' }}>
                        <div className={styles.header}>Results</div>
                        <div className={styles.result_section}>
                          <div className={styles.result_section_left}>
                            <div className={styles.sub_header}>{getResultKeyValueLabel()[0]}</div>
                            {autoResult.map((res: any) => {
                              return (
                                <div>
                                  {showDollar('key') && <span>$</span>}
                                  {res.key}
                                  {showPercent('key') && <span>%</span>}
                                </div>
                              );
                            })}
                          </div>
                          <div className={styles.result_section_left}>
                            <div className={styles.sub_header}>{getResultKeyValueLabel()[1]}</div>
                            {autoResult.map((res: any) => {
                              return (
                                <div>
                                  {showDollar('value') && <span>$</span>}
                                  {formatNumber(res.value)}
                                  {showPercent('value') && <span>%</span>}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {autoMultiResult && Object.keys(autoMultiResult).length > 0 && (
                  <>
                    <div className={styles.result}>
                      <div style={{ width: '100%' }}>
                        <div className={styles.header}>Results</div>
                        <div className={styles.result_section}>
                          {Object.keys(autoMultiResult).map(key => {
                            return (
                              <div>
                                <div className={styles.headerKey}>{key}</div>
                                <div className={styles.arrayValues}>
                                  {autoMultiResult[key].map((value: any) => {
                                    return <div className={styles.value}>{value}</div>;
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {conclusion && (
                  <div className={styles.info}>
                    <div>Conclusion</div>
                    <p>{conclusion}</p>
                  </div>
                )}

                {CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc].name !==
                  'FORM_HUD' && (
                  <>
                    <div className={styles.disclaimer}>
                      <span>Disclaimer:</span> This tool provides estimates only. Verify the result
                      before making financial decisions.
                    </div>
                    <div className={styles.info}>
                      <div>Purpose</div>
                      <p>
                        {
                          CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc]
                            .purpose
                        }
                      </p>
                    </div>
                    <div className={styles.info}>
                      <div>Instructions</div>
                      <p>
                        {
                          CALCULATORS.find(obj => obj.id === id)?.subcategories[selectedCalc]
                            .instructions
                        }
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <CalculatorSave
        showModal={showSetReminderModal}
        setShowModal={setShowSetReminderModal}
        selectedCalc={selectedCalc}
        id={id}
        formValues={formValues}
      />
    </div>
  );
};

const FeatureCard: React.FunctionComponent<featureCardProps> = ({
  title,
  image,
  id,
  setSelectedCategory,
  subcategories
}) => {
  const isMobile = useCheckMobileScreen();
  return (
    <div
      className={styles.card}
      onClick={() => {
        setSelectedCategory(id);
      }}
    >
      <img className={styles.card_icon} src={image} alt='' />
      <div className={styles.card_title}>
        {title} {Boolean(subcategories.length) && <span>({subcategories.length})</span>}
      </div>
      {/* {Boolean(subcategories.length) && <span>{subcategories.length}</span>} */}

      {/* <div className={styles.card_shadow}></div> */}
    </div>
  );
};

const CalculatorContainer: React.FunctionComponent<calculatorContainerProps> = ({ data }) => {
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [selectedCategory, setSelectedCategory] = useState(0);
  useEffect(() => {
    if (data && data.calculator_type) {
      setSelectedCategory(data.calculator_type);
    }
    // Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className={styles.landing_page_wrapper}>
      <div className={styles.max_width}>
        {selectedCategory ? (
          <div className={styles.features_category}>
            <CalculatorCategory
              id={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              data={data}
            />
          </div>
        ) : (
          <div className={styles.features}>
            <div className={styles.features_header}>Calculators</div>
            <div className={styles.card_container}>
              {CALCULATORS.map(calculator => {
                return (
                  <FeatureCard
                    title={calculator.title}
                    image={calculator.icon}
                    id={calculator.id}
                    setSelectedCategory={setSelectedCategory}
                    subcategories={calculator.subcategories}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalculatorContainer;
