import React, { useEffect, useMemo, useRef, useState } from 'react';
import DashboardFilters from '../../containers/dashboard/filters';
import Map, { MapRef } from 'react-map-gl';
import styles from './dashboard.module.scss';
import Playground from '../playground';
import { NavigationControl } from 'react-map-gl';
import { Marker } from 'react-map-gl';
import Storage from '../../utils/local-storage';
import { cx } from '../../utils/class-name-service';
import EditPlayground from '../editPlayground';
interface DashboardScreen {
  queryClient: any;
  columnOrder: any;
}
const storage = new Storage();
const EditBusinessScreen = (props: DashboardScreen) => {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [queryString, setQueryString] = useState<any>();
  const [dashboardQueryString, setDashboardQueryString] = useState<any>();
  const [projectType, setProjectType] = useState('Local');
  const [selectedBusinessList, setSelectedBusinessList] = React.useState<any>();
  const [selectedBusinessListName, setSelectedBusinessListName] = React.useState<any>(null);
  const [mapCollapsed, setMapCollaped] = React.useState(false);
  const [subcategoryOptions, setSubcategoryOptions] = useState<any>([]);
  const [selectedSubcategory, setSelectedSubCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { columnOrder } = props;

  const [filterOptions, setFilterOptions] = useState({
    state: [],
    category: '',
    subcategory: [],
    zipCode: [],
    minDistance: 0,
    maxDistance: 100
  });

  const handleChange = (selectedRows: any) => {
    //TODO: change with sheet business id
    const allSelectedBusinesses = selectedRows.selectedRows.map((el: any) => {
      return el;
    });
    setSelectedRows(allSelectedBusinesses);
  };

  // Toggle the state so React Data Table changes to clearSelectedRows are triggered
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  return (
    <div className={styles.max_width}>
      <div className={styles.max_width}>
        <div className={styles.dashboard_body}>
          <div className={styles.table_container_national}>
            {selectedBusinessListName?.label && (
              <div className={styles.list_name}>
                <div
                  className={styles.circular_hover}
                  onClick={() => {
                    setSelectedBusinessList(null);
                    setSelectedBusinessListName(null);
                  }}
                >
                  <img src='/back-arrow.svg' alt='Back' width='20' height='20' />
                </div>

                {selectedBusinessListName?.label}
              </div>
            )}
            <EditPlayground
              queryClient={props.queryClient}
              setSelectedRows={setSelectedRows}
              handleChange={handleChange}
              filterOptions={filterOptions}
              projectType={projectType}
              selectedBusinessList={selectedBusinessList}
              columnOrder={columnOrder}
              queryString={queryString}
              setQueryString={setQueryString}
              setDashboardQueryString={setDashboardQueryString}
              dashboardQueryString={dashboardQueryString}
              selectedSubcategory={selectedSubcategory}
              selectedCategory={selectedCategory}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBusinessScreen;
