import React from 'react';
import PricingContainer from '../../containers/pricing';
import styles from './pricing.module.scss';

function PricingScreen() {
  return (
    <div className={styles.page_wrapper}>
      <PricingContainer />
    </div>
  );
}

export default PricingScreen;
