import React, { useContext, useEffect } from 'react';
import styles from '../../view-project-form.module.scss';
import { props } from './model';
import { Formik, Form, Field } from 'formik';
import DragAndDrop from '../../../../../components/drag-and-drop';
import WrappedButton from '../../../../../components/form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../../../constants/button-constants';
import { useState } from 'react';
import axios from 'axios';
import AppConfig from '../../../../../config';
import Storage from '../../../../../utils/local-storage';
import { ProjectContext } from '../../../../../context/ProjectContext';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const DueDiligence: React.FC<props> = ({ setActiveStep, project, setStep, activeStep }) => {
  const { projectData, setProjectData } = useContext(ProjectContext);
  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  const navigate = useNavigate();
  const addProject = project._id == undefined;
  const { enqueueSnackbar } = useSnackbar();
  const [imagesUrl, setImagesUrl] = useState(
    projectData.dueDeligenceFiles ? projectData.dueDeligenceFiles : []
  );
  let projectId = storage.get('projectId');
  const updateSaveChanges = async () => {
    const requestData1 = {
      dueDeligenceFiles: imagesUrl,
      projectId: addProject ? projectId : project._id
    };
    const requestData = JSON.stringify(requestData1);

    const config = {
      method: 'put',
      url: `${BASE_URL}/v1/project/updateProject`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: requestData
    };
    setProjectData({ ...projectData, ...requestData1 });

    await axios(config);
  };

  const onProjectNext = () => {
    if (activeStep <= 4) setActiveStep(4);
    setStep(4);
  };

  return (
    <>
      <div className={styles.form_container_due}>
        <div className={styles.form_title}>
          {/* Due diligence, flyers and offering memorandum */}
          Documents
        </div>
        <DragAndDrop imagesUrl={imagesUrl} setImagesUrl={setImagesUrl} comp={'dueDeligenceFiles'} />

        <div className={styles.add_project_footer}>
          <div className={styles.button_wrapper}>
            {addProject && (
              <WrappedButton
                btnText='Skip'
                onClick={() => {
                  onProjectNext();
                }}
                btnType={BTN_SECONDARY}
                className={styles.submit_button}
              />
            )}
            {!addProject && (
              <WrappedButton
                btnText='Save Changes'
                btnType={BTN_SECONDARY}
                onClick={(e: React.FormEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  updateSaveChanges()
                    .then(() => {
                      enqueueSnackbar('Documents are  Updated Successfully', {
                        variant: 'success',
                        header: 'Success'
                      });
                      navigate(`/dashboard/${storage.get('projectId')}`);
                      // if (!addProject)
                      //   window.location.href = `/view-project/${storage.get('projectId')}`;
                    })
                    .catch(err => {
                      let message = err.response.data.message;
                      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
                    });
                }}
                className={styles.submit_button}
                type='submit'
                isDisabled={storage.get('role') === 'prospector'}
              />
            )}
            {addProject && (
              <WrappedButton
                btnText='Next'
                onClick={(e: React.FormEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  if (addProject) {
                    updateSaveChanges()
                      .then(() => {})
                      .catch(err => {
                        let message = err.response.data.message;
                        enqueueSnackbar(message, { variant: 'error', header: 'Error' });
                      });
                    onProjectNext();
                  } else {
                    onProjectNext();
                  }
                }}
                btnType={BTN_PRIMARY}
                className={styles.submit_button}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DueDiligence;
