import React from 'react';
import { props } from './model';
import styles from './view-project-form-stepper.module.scss';
import { ADD_PROJECT_FORM_STEPS, VIEW_PROJECT_FORM_STEPS } from '../../../constants/project-setup-constants';
import { useParams } from 'react-router-dom';

const ViewProjectFormStepper: React.FC<props> = ({ activeStep,setActiveStep, project,setStep }) => {
    const { projectId } = useParams();
    const addProject = projectId == undefined;
    let stepper = addProject ? ADD_PROJECT_FORM_STEPS : VIEW_PROJECT_FORM_STEPS;

    return (
        <div className={styles.stepper_wrapper}>
            <div className={styles.stepper}>
                {
                    stepper.map((step, index) => {
                        return (
                            <>
                                <div key={index} className={index <= activeStep ? styles.step_circle_wrapper_active : styles.step_circle_wrapper} onClick={()=>{
                                    if(index <= activeStep){
                                        setStep(index)
                                    }
                                }}>
                                    <div className={styles.step_number}>
                                        {index + 1}
                                    </div>
                                </div>
                                {
                                    index !== stepper.length - 1 && (
                                        <div className={styles.step_line}></div>
                                    )
                                }
                            </>
                        )
                    })
                }
            </div>

            <div className={styles.step_titles_wrapper}>
                <div className={styles.title}>
                    {
                        stepper.map((step, index) => {
                            return (
                                <div key={index} className={index <= activeStep ? styles.step_title_active : styles.step_title} onClick={()=>{
                                    if(index <= activeStep){
                                        setStep(index)
                                    }
                                }}>
                                    {step}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ViewProjectFormStepper