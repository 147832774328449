import React, { useEffect, useState, useContext } from 'react';
import styles from '../../view-project-form.module.scss';
import { props } from './model';
import { Formik, Form, Field, FieldArray } from 'formik';
import TagSelect from '../../../../../components/form-elements/tag-select/index';
import CustomisedTextField from '../../../../../components/form-elements/customised-text-field';
import WrappedButton from '../../../../../components/form-elements/buttons';
import { BTN_PRIMARY, BTN_SECONDARY } from '../../../../../constants/button-constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import AppConfig from '../../../../../config';
import Storage from '../../../../../utils/local-storage';
import { ProjectContext } from '../../../../../context/ProjectContext';
import CustomSelectField from '../../../../../components/form-elements/customised-select-field';
import {
  sqOptions,
  poolOptions,
  garageOptions,
  options,
  bathroomsOptions,
  bedroomsOptions,
  commercialOptions,
  YearlyOptions
} from '../../../../../constants/dropDown-constanst';
import CustomisedTextArea from '../../../../../components/form-elements/customised-text-area';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const BasicForm: React.FC<props> = ({ setActiveStep, project, setStep, activeStep, refetch }) => {
  const { projectData, setProjectData } = useContext(ProjectContext);

  const storage = new Storage();
  const BASE_URL = AppConfig.API_BASE_URL;
  const addProject = project._id == undefined;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const updateSaveChanges = async (data: any) => {
    let projectId = storage.get('projectId');
    let Data;
    if (data?.propertyType === 'Residential')
      Data = {
        propertyType: data?.propertyType,
        listingType: data?.listingType,
        totalSquareFootage: '',
        spaceCosts: data.spaceCosts,
        compSpaceCosts: [],
        numbeOfBuildings: '',
        landSizeType: '', //commercial
        totalContiguousSpace: '', //commercial
        type: data.type, //residential
        CompType: '', //commercial
        demographicTrafficCount: {}
      };
    if (data?.propertyType === 'Commercial') {
      Data = {
        propertyType: data?.propertyType,
        listingType: [],
        totalSquareFootage: data?.totalSquareFootage,
        spaceCosts: [],
        compSpaceCosts: data.compSpaceCosts,
        numbeOfBuildings: data.numbeOfBuildings,
        landSize: data.landSize,
        landSizeType: data.landSizeType, //commercial
        totalContiguousSpace: data.totalContiguousSpace, //commercial
        type: '', //residential
        CompType: data.CompType, //commercial
        demographicTrafficCount: {
          populationInOneMiles: data.populationInOneMiles, //commercial fields
          populationInThreeMiles: data.populationInThreeMiles, //commercial fields
          populationInFiveMiles: data.populationInFiveMiles, //commercial fields
          mediumInOneMiles: data?.mediumInOneMiles, //commercial fields
          mediumInThreeMiles: data?.mediumInThreeMiles, //commercial fields
          mediumInFiveMiles: data?.mediumInFiveMiles, //commercial fields
          mediumAverageIncomeInMiles: data?.mediumAverageIncomeInMiles, //commercial fields
          mediumAverageIncomeInThreeMiles: data?.mediumAverageIncomeInThreeMiles, //commercial fields
          mediumAverageIncomeInFiveMiles: data?.mediumAverageIncomeInFiveMiles, //commercial fields
          trafficCount: data?.trafficCount, //commercial fields
          numberOfParkingSpaces: data?.numberOfParkingSpaces //commercial fields
        }
      };
    }
    if (data?.propertyType === 'Other') {
      Data = { propertyType: data?.propertyType, customField: data.customField };
    }

    const requestData = JSON.stringify(Data);

    const config = {
      method: 'put',
      url: `${BASE_URL}/v1/project/updateProject`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      data: { basicInfo: Data, projectId: addProject ? projectId : project._id }
    };
    setProjectData({ ...projectData, basicInfo: Data });

    await axios(config);
  };

  const { status: mutationStatus, mutateAsync: updateProject } = useMutation(updateSaveChanges, {
    onSuccess: () => {
      if (!addProject) {
        enqueueSnackbar('Details Form Updated Successfully', {
          variant: 'success',
          header: 'Success'
        });
        refetch();
        navigate(`/dashboard/${storage.get('projectId')}`);
      }
      // if (!addProject) window.location.href = `/view-project/${storage.get('projectId')}`;
    },
    onError: (err: any) => {
      let message = err.response.data.message;
      enqueueSnackbar(message, { variant: 'error', header: 'Error' });
    }
  });

  const onProjectNext = () => {
    if (activeStep <= 2) setActiveStep(2);
    setStep(2);
  };
  const onUpdateChanges = (data: any) => {
    updateProject(data);
  };
  const formHandler = (propertyType: any, values: any) => {
    switch (propertyType) {
      case 'Residential':
        return (
          <>
            <div className={styles.form_container}>
              <div className={styles.note}>
                While detailed project specifics aren't mandatory, it's advisable to centralize
                relevant information for quick access during prospect discussions. Include enough
                details to address inquiries and easily share documents via email.
              </div>

              <div className={styles.form_title}>Details</div>

              <div className={styles.form_field}>
                <Field
                  name='propertyType'
                  placeholder='Property type'
                  component={TagSelect}
                  tags={['Commercial', 'Residential', 'Other']}
                />
              </div>

              <div className={styles.form_field}>
                <Field
                  name='listingType'
                  placeholder='Listing Type'
                  component={TagSelect}
                  tags={[
                    'Apartment',
                    'Attic',
                    'Basement',
                    'Co-op',
                    'Condominium',
                    'Duplex',
                    'Efficiency',
                    'Loft',
                    'One story house',
                    'Room with outside entrance',
                    'Single family home',
                    'Townhouse',
                    'Triplex',
                    'Two story house',
                    'Villa',
                    'Other'
                  ]}
                  isMulti={true}
                />
              </div>

              <div className={styles.form_field}>
                <Field name='type' options={commercialOptions} component={CustomSelectField} />
              </div>
            </div>
            <div className={styles.form_container}>
              <div className={styles.form_title}>Spaces & Costs</div>
              <FieldArray name='spaceCosts'>
                {({ insert, push }) => (
                  <>
                    {values.spaceCosts?.map((spaceCost: any, index: any) => (
                      <div key={index}>
                        <div className={styles.two_fields}>
                          <div className={styles.single_field}>
                            <Field
                              name={`spaceCosts[${index}].floor`}
                              placeholder='Floor'
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>

                          <div className={styles.single_field}>
                            <Field
                              name={`spaceCosts[${index}].spaceAvailable`}
                              placeholder='Unit number'
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>
                        </div>

                        {/* <div className={styles.form_field}>
                          <Field
                            name={`spaceCosts[${index}].totalSquareFeet`}
                            placeholder='Total square feet'
                            component={CustomisedTextField}
                          />
                        </div> */}
                        <div className={styles.two_fields}>
                          <div className={styles.single_field}>
                            <Field
                              name={`spaceCosts[${index}].squareFeet`}
                              placeholder='Total square feet'
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>

                          <div className={styles.single_field}>
                            <Field
                              name={`spaceCosts[${index}].squareFeetType`}
                              placeholder=''
                              options={sqOptions}
                              component={CustomSelectField}
                            />
                          </div>
                        </div>

                        {(values.type === 'For Rent' ||
                          values.type === 'For Rent and For Sale') && (
                          <>
                            <div className={styles.two_fields}>
                              <div className={styles.single_field}>
                                <Field
                                  name={`spaceCosts[${index}].rent`}
                                  placeholder='Rent (per Sq.ft)'
                                  isNumber={true}
                                  component={CustomisedTextField}
                                />
                              </div>

                              <div className={styles.single_field}>
                                <Field
                                  name={`spaceCosts[${index}].rentType`}
                                  options={YearlyOptions}
                                  component={CustomSelectField}
                                />
                              </div>
                            </div>
                            <div className={styles.two_fields}>
                              <div className={styles.single_field}>
                                <Field
                                  name={`spaceCosts[${index}].rentPerMonth`}
                                  placeholder={`Rent (per ${values.spaceCosts[
                                    index
                                  ].rentType.toLowerCase()})`}
                                  isNumber={true}
                                  component={CustomisedTextField}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {(values.type === 'For Sale' ||
                          values.type === 'For Rent and For Sale') && (
                          <>
                            <div className={styles.two_fields}>
                              <div className={styles.single_field}>
                                <Field
                                  name={`spaceCosts[${index}].sale`}
                                  placeholder='Sale'
                                  isNumber={true}
                                  component={CustomisedTextField}
                                />
                              </div>

                              <div className={styles.single_field}>
                                <Field
                                  name={`spaceCosts[${index}].saleType`}
                                  options={options}
                                  component={CustomSelectField}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className={styles.form_field}>
                          <Field
                            name={`spaceCosts[${index}].totalParkingSpaces`}
                            placeholder='Total parking spaces'
                            isNumber={true}
                            component={CustomisedTextField}
                          />
                        </div>

                        <div className={styles.form_field}>
                          <Field
                            name={`spaceCosts[${index}].additionalCosts`}
                            placeholder='Additional costs'
                            component={CustomisedTextArea}
                            // tags={['Residential', 'Commercial', 'Other']}
                          />
                        </div>

                        <>
                          <div className={styles.two_fields}>
                            <div className={styles.single_field}>
                              <Field
                                name={`spaceCosts[${index}].NumberOfBedrooms`}
                                placeholder='Number of bedrooms'
                                options={bedroomsOptions}
                                component={CustomSelectField}
                              />
                            </div>

                            <div className={styles.single_field}>
                              <Field
                                name={`spaceCosts[${index}].NumberOfBathrooms`}
                                placeholder='Number of bathrooms'
                                options={bathroomsOptions}
                                component={CustomSelectField}
                              />
                            </div>
                          </div>
                          <div className={styles.two_fields}>
                            <div className={styles.single_field}>
                              <Field
                                name={`spaceCosts[${index}].garage`}
                                placeholder='Garage'
                                options={garageOptions}
                                component={CustomSelectField}
                              />
                            </div>

                            <div className={styles.single_field}>
                              <Field
                                name={`spaceCosts[${index}].pool`}
                                placeholder='Pool'
                                options={poolOptions}
                                component={CustomSelectField}
                              />
                            </div>
                          </div>
                        </>
                      </div>
                    ))}
                    <div
                      className={styles.add_more}
                      onClick={() => {
                        push({
                          floor: '',
                          spaceAvailable: '',
                          totalSquareFeet: '',
                          squareFeet: '',
                          squareFeetType: 'Under Air',
                          rent: '',
                          rentType: 'Month',
                          rentPerMonth: '',
                          sale: '',
                          saleType: 'Square Feet',
                          totalParkingSpaces: '',
                          passThroughs: '',
                          rentType1: 'Month',
                          insurance: '',
                          insurance_month: '',
                          realEstateTax: '',
                          realEstateTax_month: '',
                          CAM: '',
                          CAM_month: '',
                          additionalCosts: '',
                          NumberOfBedrooms: '0',
                          NumberOfBathrooms: '0',
                          garage: 'Car port',
                          pool: 'None'
                        });
                      }}
                    >
                      + Add More Space
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
          </>
        );
      case 'Commercial':
        return (
          <>
            <div className={styles.form_container}>
              <div className={styles.note}>
                While detailed project specifics aren't mandatory, it's advisable to centralize
                relevant information for quick access during prospect discussions. Include enough
                details to address inquiries and easily share documents via email.
              </div>

              <div className={styles.form_title}>Details</div>

              <div className={styles.form_field}>
                <Field
                  name='propertyType'
                  placeholder='Property type'
                  component={TagSelect}
                  tags={['Commercial', 'Residential', 'Other']}
                />
              </div>

              <div className={styles.form_field}>
                <div className={styles.two_fields}>
                  <div className={styles.single_field}>
                    <Field
                      name='totalSquareFootage'
                      placeholder={
                        values.propertyType === 'Commercial'
                          ? 'Total square footage of building(s)'
                          : 'Total sqaure footage'
                      }
                      isNumber={true}
                      component={CustomisedTextField}
                    />
                  </div>
                  <div className={styles.single_field}>
                    <Field
                      name='numbeOfBuildings'
                      placeholder={'Number of buildings'}
                      isNumber={true}
                      component={CustomisedTextField}
                    />
                  </div>
                </div>
                <div className={styles.two_fields}>
                  <div className={styles.single_field}>
                    <Field
                      name={`landSize`}
                      placeholder='Land size'
                      options={options}
                      component={CustomisedTextField}
                    />
                  </div>

                  <div className={styles.single_field}>
                    <Field name={`landSizeType`} options={options} component={CustomSelectField} />
                  </div>
                </div>
                <div className={styles.two_fields}>
                  <div className={styles.single_field}>
                    <Field
                      name='totalContiguousSpace'
                      placeholder={'Total contiguous space (Sq.ft)'}
                      isNumber={true}
                      component={CustomisedTextField}
                    />
                  </div>
                  <div className={styles.single_field}>
                    <Field
                      name='CompType'
                      options={commercialOptions}
                      component={CustomSelectField}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.form_container}>
              <div className={styles.form_title}>Spaces & Costs</div>
              <FieldArray name='compSpaceCosts'>
                {({ insert, push }) => (
                  <>
                    {values.compSpaceCosts?.map((spaceCost: any, index: any) => (
                      <div key={index}>
                        <div className={styles.two_fields}>
                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].floor`}
                              placeholder='Floor'
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>

                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].spaceAvailable`}
                              placeholder='Unit number'
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>
                        </div>

                        {/* <div className={styles.form_field}>
                          <Field
                            name={`compSpaceCosts[${index}].totalSquareFeet`}
                            placeholder='Total square feet'
                            component={CustomisedTextField}
                          />
                        </div> */}

                        <div className={styles.two_fields}>
                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].totalSquareFeet`}
                              placeholder='Total square feet'
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>

                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].squareFeetType`}
                              placeholder=''
                              options={sqOptions}
                              component={CustomSelectField}
                            />
                          </div>
                        </div>

                        {(values.CompType === 'For Rent' ||
                          values.CompType === 'For Rent and For Sale') && (
                          <>
                            <div className={styles.two_fields}>
                              <div className={styles.single_field}>
                                <Field
                                  name={`compSpaceCosts[${index}].rent`}
                                  placeholder='Rent (per sq.ft)'
                                  isNumber={true}
                                  component={CustomisedTextField}
                                />
                              </div>

                              <div className={styles.single_field}>
                                <Field
                                  name={`compSpaceCosts[${index}].rentType`}
                                  options={YearlyOptions}
                                  component={CustomSelectField}
                                />
                              </div>
                            </div>
                            <div className={styles.two_fields}>
                              <div className={styles.single_field}>
                                <Field
                                  name={`compSpaceCosts[${index}].rentPerMonth`}
                                  placeholder={`Rent (per ${values.compSpaceCosts[
                                    index
                                  ].rentType.toLowerCase()})`}
                                  isNumber={true}
                                  component={CustomisedTextField}
                                />
                              </div>
                              <div className={styles.single_field}>
                                <Field
                                  name={`compSpaceCosts[${index}].totalParkingSpaces`}
                                  placeholder='Total parking spaces'
                                  isNumber={true}
                                  component={CustomisedTextField}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {(values.CompType === 'For Sale' ||
                          values.CompType === 'For Rent and For Sale') && (
                          <>
                            <div className={styles.two_fields}>
                              <div className={styles.single_field}>
                                <Field
                                  name={`compSpaceCosts[${index}].sale`}
                                  placeholder='Sale'
                                  isNumber={true}
                                  component={CustomisedTextField}
                                />
                              </div>

                              <div className={styles.single_field}>
                                <Field
                                  name={`compSpaceCosts[${index}].saleType`}
                                  options={options}
                                  isNumber={true}
                                  component={CustomSelectField}
                                />
                              </div>
                            </div>
                            <div className={styles.form_field}>
                              <Field
                                name={`compSpaceCosts[${index}].totalParkingSpaces`}
                                placeholder='Total parking spaces'
                                isNumber={true}
                                component={CustomisedTextField}
                              />
                            </div>
                          </>
                        )}

                        <div className={styles.two_fields}>
                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].passThroughs`}
                              placeholder='Passthroughs'
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>
                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].rentType1`}
                              options={YearlyOptions}
                              component={CustomSelectField}
                            />
                          </div>
                        </div>
                        <div
                          className={styles.spaceAndCost_subsection}
                        >{`${values.compSpaceCosts[index].rentType1}ly cost`}</div>

                        <div className={styles.two_fields}>
                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].insurance`}
                              placeholder='Insurance (per SF) '
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>

                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].insurance_month`}
                              placeholder={`Insurance (per ${values.compSpaceCosts[
                                index
                              ].rentType1.toLowerCase()})`}
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>
                        </div>
                        <div className={styles.two_fields}>
                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].realEstateTax`}
                              placeholder='Real estate taxes (per SF)'
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>

                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].realEstateTax_month`}
                              placeholder={`Real estate taxes (per ${values.compSpaceCosts[
                                index
                              ].rentType1.toLowerCase()})`}
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>
                        </div>
                        <div className={styles.two_fields}>
                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].CAM`}
                              placeholder='Common area maintenance (per SF)'
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>

                          <div className={styles.single_field}>
                            <Field
                              name={`compSpaceCosts[${index}].CAM_month`}
                              placeholder={`Common area maintenance (per ${values.compSpaceCosts[
                                index
                              ].rentType1.toLowerCase()})`}
                              isNumber={true}
                              component={CustomisedTextField}
                            />
                          </div>
                        </div>

                        <div className={styles.form_field}>
                          <Field
                            name={`compSpaceCosts[${index}].additionalCosts`}
                            placeholder='Additional costs'
                            isNumber={true}
                            component={CustomisedTextArea}
                          />
                        </div>
                      </div>
                    ))}
                    <div
                      className={styles.add_more}
                      onClick={() => {
                        push({
                          floor: '',
                          spaceAvailable: '',
                          totalSquareFeet: '',
                          squareFeet: '',
                          squareFeetType: 'Under Air',
                          rent: '',
                          rentType: 'Month',
                          rentPerMonth: '',
                          sale: '',
                          saleType: 'Square Feet',
                          totalParkingSpaces: '',
                          passThroughs: '',
                          rentType1: 'Month',
                          insurance: '',
                          insurance_month: '',
                          realEstateTax: '',
                          realEstateTax_month: '',
                          CAM: '',
                          CAM_month: '',
                          additionalCosts: ''
                        });
                      }}
                    >
                      + Add more space
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
            <div className={styles.form_container}>
              <div className={styles.form_title}>Demographics & Traffic Counts</div>

              <div className={styles.three_fields}>
                <div className={styles.three_field_single}>
                  <Field
                    name='populationInOneMiles'
                    placeholder='Population in 1 mile'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>

                <div className={styles.three_field_single}>
                  <Field
                    name='populationInThreeMiles'
                    placeholder='Population in 3 miles'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>
                <div className={styles.three_field_single}>
                  <Field
                    name='populationInFiveMiles'
                    placeholder='Population in 5 miles'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>
              </div>

              <div className={styles.three_fields}>
                <div className={styles.three_field_single}>
                  <Field
                    name='mediumInOneMiles'
                    placeholder='Median age in 1 mile'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>

                <div className={styles.three_field_single}>
                  <Field
                    name='mediumInThreeMiles'
                    placeholder='Median age in 3 miles'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>
                <div className={styles.three_field_single}>
                  <Field
                    name='mediumInFiveMiles'
                    placeholder='Median age in 5 miles'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>
              </div>
              <div className={styles.three_fields}>
                <div className={styles.three_field_single}>
                  <Field
                    name='mediumAverageIncomeInMiles'
                    placeholder='Median income in 1 mile ($)'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>
                <div className={styles.three_field_single}>
                  <Field
                    name='mediumAverageIncomeInThreeMiles'
                    placeholder='Median income in 3 miles ($)'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>
                <div className={styles.three_field_single}>
                  <Field
                    name='mediumAverageIncomeInFiveMiles'
                    placeholder='Median income in 5 miles ($)'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>
              </div>

              <div className={styles.two_fields}>
                <div className={styles.single_field}>
                  <Field
                    name={`stname1`}
                    placeholder='Street Name'
                    isNumber={false}
                    component={CustomisedTextField}
                  />
                </div>

                <div className={styles.single_field}>
                  <Field
                    name={`trafficCount1`}
                    placeholder='Annual Average Daily Traffic (AADT)'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>
              </div>
              <div className={styles.two_fields}>
                <div className={styles.single_field}>
                  <Field
                    name={`stname2`}
                    placeholder='Street Name'
                    isNumber={false}
                    component={CustomisedTextField}
                  />
                </div>

                <div className={styles.single_field}>
                  <Field
                    name={`trafficCount2`}
                    placeholder='Annual Average Daily Traffic (AADT)'
                    isNumber={true}
                    component={CustomisedTextField}
                  />
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className={styles.form_container}>
              <div className={styles.note}>
                While detailed project specifics aren't mandatory, it's advisable to centralize
                relevant information for quick access during prospect discussions. Include enough
                details to address inquiries and easily share documents via email.
              </div>

              <div className={styles.form_title}>Details</div>
              <div className={styles.form_field}>
                <Field
                  name='propertyType'
                  placeholder='Property type'
                  component={TagSelect}
                  tags={['Commercial', 'Residential', 'Other']}
                />
              </div>

              <FieldArray name='customField'>
                {({ insert, push }) => (
                  <>
                    {values.customField?.map((customField: any, index: any) => (
                      <div key={index}>
                        <div className={styles.two_fields_stacked}>
                          <div className={styles.single_field}>
                            <Field
                              name={`customField[${index}].fieldName`}
                              // placeholder='Field name'
                              label='Field name'
                              component={CustomisedTextField}
                            />
                          </div>

                          <div>
                            <Field
                              name={`customField[${index}].value`}
                              // placeholder='Value'
                              label='Value'
                              component={CustomisedTextArea}
                              row={0}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div
                      className={styles.add_more}
                      onClick={() => {
                        push({ fieldName: '', value: '' });
                      }}
                    >
                      + Add more fields
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
          </>
        );
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          propertyType: projectData?.basicInfo?.propertyType
            ? projectData?.basicInfo?.propertyType
            : 'Commercial', //residential, comercial and other
          listingType:
            projectData?.basicInfo?.listingType?.length > 0
              ? projectData?.basicInfo?.listingType
              : [], //residential
          totalSquareFootage: projectData?.basicInfo?.totalSquareFootage, //commercial
          numbeOfBuildings: projectData?.basicInfo?.numbeOfBuildings, //commercial
          landSize: projectData?.basicInfo?.landSize, //commercial
          landSizeType: projectData?.basicInfo?.landSizeType
            ? projectData?.basicInfo?.landSizeType
            : 'Square Feet', //commercial
          totalContiguousSpace: projectData?.basicInfo?.totalContiguousSpace, //commercial
          type: projectData?.basicInfo?.type ? projectData?.basicInfo?.type : 'For Rent', //residential
          CompType: projectData?.basicInfo?.CompType
            ? projectData?.basicInfo?.CompType
            : 'For Rent', //commercial

          spaceCosts:
            projectData?.basicInfo?.spaceCosts?.length > 0
              ? projectData?.basicInfo?.spaceCosts.map((item: any) => ({
                  floor: item.floor,
                  spaceAvailable: item.spaceAvailable,
                  totalSquareFeet: item.totalSquareFeet,
                  squareFeet: item.squareFeet,
                  squareFeetType: item.squareFeetType ? item.squareFeetType : 'Under Air',
                  rent: item.rent,
                  rentType: item.rentType ? item.rentType : 'Month',
                  rentPerMonth: item.rentPerMonth,
                  sale: item.sale,
                  saleType: item.saleType ? item.saleType : 'Square Feet',
                  totalParkingSpaces: item.totalParkingSpaces,
                  additionalCosts: item.additionalCosts,
                  NumberOfBedrooms: item.NumberOfBedrooms,
                  NumberOfBathrooms: item.NumberOfBathrooms,
                  garage: item.garage,
                  pool: item.pool
                }))
              : [
                  {
                    floor: '',
                    spaceAvailable: '',
                    totalSquareFeet: '',
                    squareFeet: '',
                    squareFeetType: 'Under Air',
                    rent: '',
                    rentType: 'Month',
                    rentPerMonth: '',
                    sale: '',
                    saleType: 'Square Feet',
                    totalParkingSpaces: '',
                    additionalCosts: '',
                    NumberOfBedrooms: '0',
                    NumberOfBathrooms: '0',
                    garage: 'Car port',
                    pool: 'None'
                  }
                ], //residential
          compSpaceCosts:
            projectData?.basicInfo?.compSpaceCosts?.length > 0
              ? projectData?.basicInfo?.compSpaceCosts.map((item: any) => ({
                  floor: item.floor,
                  spaceAvailable: item.spaceAvailable,
                  totalSquareFeet: item.totalSquareFeet,
                  squareFeet: item.squareFeet,
                  squareFeetType: item.squareFeetType ? item.squareFeetType : 'Under Air',
                  rent: item.rent,
                  rentType: item.rentType ? item.rentType : 'Month',
                  rentPerMonth: item.rentPerMonth,
                  sale: item.sale,
                  saleType: item.saleType ? item.saleType : 'Square Feet',
                  totalParkingSpaces: item.totalParkingSpaces,
                  passThroughs: item.passThroughs,
                  rentType1: item.rentType1 ? item.rentType1 : 'Month',
                  additionalCosts: item.additionalCosts,
                  insurance: item.insurance,
                  insurance_month: item.insurance_month,
                  realEstateTax: item.realEstateTax,
                  realEstateTax_month: item.realEstateTax_month,
                  CAM: item.CAM,
                  CAM_month: item.CAM_month
                }))
              : [
                  {
                    floor: '',
                    spaceAvailable: '',
                    totalSquareFeet: '',
                    squareFeet: '',
                    squareFeetType: 'Under Air',
                    rent: '',
                    rentType: 'Month',
                    rentPerMonth: '',
                    sale: '',
                    saleType: 'Square Feet',
                    totalParkingSpaces: '',
                    passThroughs: '',
                    rentType1: 'Month',
                    insurance: '',
                    insurance_month: '',
                    realEstateTax: '',
                    realEstateTax_month: '',
                    CAM: '',
                    CAM_month: '',
                    additionalCosts: ''
                  }
                ], //commercial

          populationInOneMiles:
            projectData?.basicInfo?.demographicTrafficCount?.populationInOneMiles, //commercial fields
          populationInThreeMiles:
            projectData?.basicInfo?.demographicTrafficCount?.populationInThreeMiles, //commercial fields
          populationInFiveMiles:
            projectData?.basicInfo?.demographicTrafficCount?.populationInFiveMiles, //commercial fields
          mediumInOneMiles: projectData?.basicInfo?.demographicTrafficCount?.mediumInOneMiles, //commercial fields
          mediumInThreeMiles: projectData?.basicInfo?.demographicTrafficCount?.mediumInThreeMiles, //commercial fields
          mediumInFiveMiles: projectData?.basicInfo?.demographicTrafficCount?.mediumInFiveMiles, //commercial fields
          mediumAverageIncomeInMiles:
            projectData?.basicInfo?.demographicTrafficCount?.mediumAverageIncomeInMiles, //commercial fields
          mediumAverageIncomeInThreeMiles:
            projectData?.basicInfo?.demographicTrafficCount?.mediumAverageIncomeInThreeMiles, //commercial fields
          mediumAverageIncomeInFiveMiles:
            projectData?.basicInfo?.demographicTrafficCount?.mediumAverageIncomeInFiveMiles, //commercial fields
          trafficCount: projectData?.basicInfo?.demographicTrafficCount?.trafficCount, //commercial fields
          numberOfParkingSpaces:
            projectData?.basicInfo?.demographicTrafficCount?.numberOfParkingSpaces, //commercial fields
          customField:
            projectData?.basicInfo?.customField?.length > 0
              ? projectData?.basicInfo?.customField.map((item: any) => ({
                  fieldName: item.fieldName,
                  value: item.value
                }))
              : [{ fieldName: '', value: '' }] //other fields
        }}
        onSubmit={data => {
          if (addProject) {
            onUpdateChanges(data);
            onProjectNext();
          } else {
            onProjectNext();
          }
        }}
        enableReinitialize={true}
      >
        {({ values, dirty }) => (
          <Form>
            {formHandler(values.propertyType, values)}

            <div className={styles.add_project_footer}>
              <div className={styles.button_wrapper}>
                {addProject && (
                  <WrappedButton
                    btnText='Skip'
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      onProjectNext();
                    }}
                    btnType={BTN_SECONDARY}
                    className={styles.submit_button}
                  />
                )}
                {!addProject && (
                  <WrappedButton
                    btnText='Save Changes'
                    btnType={BTN_SECONDARY}
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      onUpdateChanges(values);
                    }}
                    className={styles.submit_button}
                    isDisabled={!dirty}
                    type='submit'
                  />
                )}
                {addProject && (
                  <WrappedButton
                    btnText='Next'
                    type='submit'
                    btnType={BTN_PRIMARY}
                    className={styles.submit_button}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BasicForm;
