import React, { useState } from 'react';

import styles from './response-details.module.scss';
import { props } from './model';
import BusinessDetailPill from '../../business-details';
import AppConfig from '../../../config';
import Storage from '../../../utils/local-storage';
import { useMutation, useQuery } from 'react-query';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';

const ResponseDetails: React.FunctionComponent<props> = ({
  queryClient,
  businessId,
  setResponseDetailsUpdated,
  responseDetailsUpdated
}) => {
  const RESPONSE_DETAILS_CONSTANTS = [
    {
      section: 'Emails & calls',
      pills: [
        { label: 'First email sent', readOnly: true, value: {}, type: 'text', id: 'createdAt' },
        {
          label: 'First email opened',
          readOnly: true,
          value: {},
          type: 'text',
          id: 'readAt'
        },
        { label: 'First call out', readOnly: false, value: {}, type: 'date', id: 'firstCallOut' },
        {
          label: 'Latest call out',
          readOnly: false,
          value: {},
          type: 'date',
          id: 'latestCallOut'
        },
        { label: 'First call in', readOnly: false, value: {}, type: 'date', id: 'firstCallIn' },
        { label: 'Latest call in', readOnly: false, value: {}, type: 'date', id: 'latestCallIn' }
      ]
    },
    {
      section: 'Requested information',
      pills: [
        {
          label: 'Property address',
          readOnly: false,
          value: {},
          type: 'checkbox',
          id: 'propertyAddress'
        },
        { label: 'Rent price', readOnly: false, value: {}, type: 'checkbox', id: 'rentPrice' },
        { label: 'Land size', readOnly: false, value: {}, type: 'checkbox', id: 'landSize' },
        { label: 'Cotenancies', readOnly: false, value: {}, type: 'checkbox', id: 'contenancies' },
        { label: 'Terms', readOnly: false, value: {}, type: 'checkbox', id: 'terms' },
        { label: 'Flyer', readOnly: false, value: {}, type: 'checkbox', id: 'flyer' },
        { label: 'Sale price', readOnly: false, value: {}, type: 'checkbox', id: 'salePrice' },
        { label: 'Unit price', readOnly: false, value: {}, type: 'checkbox', id: 'unitPrice' },
        {
          label: 'Do not contact',
          readOnly: false,
          value: {},
          type: 'checkbox',
          id: 'doNotContact'
        },
        {
          label: 'Do not contact for this project',
          readOnly: false,
          value: {},
          type: 'checkbox',
          id: 'doNotContactForThisProject'
        },
        {
          label: 'Schedule a tour',
          readOnly: false,
          value: {},
          type: 'checkbox',
          id: 'scheduleATour'
        },
        { label: 'Other', readOnly: false, value: {}, type: 'text', id: 'other' }
      ]
    },
    {
      section: 'Offers',
      pills: [
        {
          label: 'Expressed serious interest',
          readOnly: false,
          value: {},
          type: 'checkbox',
          id: 'expressedSeriousInterest'
        },

        {
          label: 'Prospect offer',
          readOnly: false,
          type: 'text',
          id: 'prospectOffer'
        },
        {
          label: 'Owner offer',
          readOnly: false,
          type: 'text',
          id: 'ownerOffer'
        },
        {
          label: 'Letter of intent',
          readOnly: false,
          type: 'text',
          id: 'loi'
        },
        {
          label: 'Signed leases',
          readOnly: false,
          type: 'text',
          id: 'signedLeases'
        },
        {
          label: 'Signed contract to purchase',
          readOnly: false,
          type: 'text',
          id: 'signedContractToPurchase'
        },
        {
          label: 'Tenant takes possession of property',
          readOnly: false,
          type: 'checkbox',
          id: 'tenantTakesDeliveryOfSpace'
        },
        {
          label: 'Buyer closed',
          readOnly: false,
          type: 'checkbox',
          id: 'buyerPaysForSpace'
        },
        {
          label: 'Declined',
          readOnly: false,
          type: 'checkbox',
          id: 'declined'
        },
        {
          label: 'Dead lead',
          readOnly: false,
          type: 'checkbox',
          id: 'deadLead'
        }
      ]
    }
  ];

  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();

  const fetchDetails = async () => {
    const res = await fetch(
      `${BASE_URL}/v1/responseDetails/getDetail?businessId=${businessId}&projectId=${storage.get(
        'projectId'
      )}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${storage.get('accessToken')}`
        }
      }
    );
    return res.json();
  };

  const postDetails = async (data: any) => {
    const response = await fetch(`${BASE_URL}/v1/responseDetails/updateDetail`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storage.get('accessToken')}`
      },
      body: JSON.stringify({ data, businessId, projectId: storage.get('projectId') })
    });
    if (!response.ok) throw Error('something went wrong');
    return response.json();
  };

  const { data, status } = useQuery(`responseDetails${businessId}`, fetchDetails, {
    onSuccess: res => {}
  });
  const { status: mutationStatus, mutateAsync: updateDetailsAPI } = useMutation(postDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(`responseDetails${businessId}`);
      setResponseDetailsUpdated(!responseDetailsUpdated);
    }
  });

  const updateDetails = (value: any, id: any, responseDetailId: any, type?: any) => {
    if (type === 'text') {
      const body = {
        [id]: {
          value: value,
          userChecked: storage.get('userId'),
          atTime: Date.now()
        },
        _id: responseDetailId
      };
      updateDetailsAPI(body).then(() => {});
    } else {
      const body = {
        [id]: {
          checked: value,
          userChecked: storage.get('userId'),
          atTime: Date.now()
        },
        _id: responseDetailId
      };
      updateDetailsAPI(body).then(() => {});
    }
  };

  return (
    <div>
      {RESPONSE_DETAILS_CONSTANTS.map((section: any) => {
        return (
          <>
            {status === 'success' && (
              <div style={{ marginBottom: '20px' }}>
                <div className={styles.section_header}>{section.section}</div>
                <div className={styles.business_details}>
                  <div className={styles.business_details_left}>
                    {section.pills.map((pill: any, index: any) => {
                      return (
                        <>
                          {index % 2 === 0 && (
                            <BusinessDetailPill
                              header={pill.label}
                              value={
                                pill.readOnly
                                  ? data.emailSent && data.emailSent.length
                                    ? data.emailSent[0][pill.id]
                                    : ''
                                  : data[pill.id]
                              }
                              key={index}
                              type={pill.type}
                              updateDetails={updateDetails}
                              id={pill.id}
                              responseDetailId={data._id}
                              section={pill.readOnly}
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div className={styles.business_details_right}>
                    {section.pills.map((pill: any, index: any) => {
                      return (
                        <>
                          {index % 2 !== 0 && (
                            <>
                              <BusinessDetailPill
                                header={pill.label}
                                value={
                                  pill.readOnly
                                    ? data.emailOpened && data.emailOpened.length
                                      ? data.emailOpened[0][pill.id]
                                      : ''
                                    : data[pill.id]
                                }
                                key={index}
                                type={pill.type}
                                updateDetails={updateDetails}
                                id={pill.id}
                                responseDetailId={data._id}
                                section={pill.readOnly}
                              />
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
      {status === 'loading' || mutationStatus === 'loading' ? (
        <Backdrop sx={{ color: '#0c252d', zIndex: '100' }} open>
          {<CircularProgress color='inherit' />}
        </Backdrop>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ResponseDetails;
