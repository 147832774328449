import Storage from './local-storage';
import HttpHelper from './http-helper';
import HttpHelperWithoutAuth from './http-helper-without-auth';
import AppConfig from '../config';
import axios from 'axios';

const { getRequest } = new HttpHelper();
const { putRequest } = new HttpHelperWithoutAuth();

export const HandleFileUpload = async (file: any, name: string | undefined) => {
  const storage = new Storage();

  const fileName = file.name;
  const filePath = `devAssets/` + storage.get('userId');
  const fileType = file.type;
  let searchParams = new URLSearchParams();
  searchParams.append('fileName', fileName);
  searchParams.append('filePath', filePath);
  searchParams.append('fileType', fileType);

  let url = '/v1/file-upload/get-signed-url' + '?' + searchParams.toString();
  try {
    const { data } = await getRequest({ url });
    if (data?.urls[0]) {
      var options = {
        headers: {
          'Content-Type': file.type
        }
        // onUploadProgress: (progressEvent) => {
        //     formItem.progressFileUpload[lenAlreadyFile + i] =
        //         progressEvent.loaded;
        //     setForm(deepCopy(newForm));
        // },
      };
      await putRequest(data.urls[0], file, options);
      let imageUrl = `https://reachtenants-dev-assets.s3.amazonaws.com/devAssets/${storage.get(
        'userId'
      )}/${fileName}`;
      return imageUrl;
    }
  } catch (error) {
    throw error;
  }
};
export const HandleFileDelete = async (fileLink: any) => {
  const BASE_URL = AppConfig.API_BASE_URL;
  const storage = new Storage();
  // const data = JSON.stringify({
  //   filepath: `${fileLink}`
  // });
  const config = {
    method: 'delete',
    url: `${BASE_URL}/v1/file-upload/delete-file?filekey=${fileLink}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${storage.get('accessToken')}`
    }
  };

  const response = await axios(config);
  const data1 = await response;
  return data1;
};

export const HandleProfilePictureUpload = async (file: any) => {
  const storage = new Storage();
  const config = {
    method: 'post',
    // url: `${BASE_URL}/v1/file-upload/upload-profile-image`,
    url: `/v1/file-upload/upload-profile-image`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${storage.get('accessToken')}`
    },
    data: {
      base64Data: file
    }
  };

  const response = await axios(config);
  const data1 = await response;
  return data1;
};
