import styles from '../../screens/playground/playground.module.scss';

function CustomDataTableLoader() {
  return (
    <div className={styles.table_container}>
      <table className={styles.tg}>
        <tr>
          <th className={styles.tgcly1}>
            <div className={styles.line}></div>
          </th>
          <th className={styles.tgcly1}>
            <div className={styles.line}></div>
          </th>
          <th className={styles.tgcly1}>
            <div className={styles.line}></div>
          </th>
          <th className={styles.tgcly1}>
            <div className={styles.line}></div>
          </th>
          <th className={styles.tgcly1}>
            <div className={styles.line}></div>
          </th>
        </tr>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 3].map(el => {
          return (
            <tr>
              <td className={styles.tgcly1}>
                <div className={styles.line}></div>
              </td>
              <td className={styles.tgcly1}>
                <div className={styles.line}></div>
              </td>
              <td className={styles.tgcly1}>
                <div className={styles.line}></div>
              </td>
              <td className={styles.tgcly1}>
                <div className={styles.line}></div>
              </td>
              <td className={styles.tgcly1}>
                <div className={styles.line}></div>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default CustomDataTableLoader;
